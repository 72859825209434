<template lang="pug">

  div(class="component-container")

    SpinnerLayerComponent(v-if="isLoadingKanban" class="spinner-layer")

    section(class="lexon-kanban-container")

      ejs-kanban(
        v-show="!renderEmptyKanbanDiv && !isLoadingKanban"
        ref="kanban"
        class="lexon-kanban"
        keyField="state"
        :dataSource="kanbanData"
        :cardSettings="cardSettings"
        :swimlaneSettings="swimlaneSettings"
        :enableTooltip="true"
        :tooltipTemplate= "tooltipTemplate"
        :cssClass="swimlaneSettings.keyField"
        :dialogSettings="dialogSettings"
        :dragStop="saveAction"
        :dragStart="checkIfDisableDragAndDrop"
        :queryCellInfo="checkIfRenderEmptyKanbanDivFiltersBehaviour"
      )
        e-columns
          e-column(
            v-for="(column, columnIndex) in kanbanColumns"
            :key="columnIndex"
            :headerText="column.headerText"
            :keyField="column.keyField"
            :template="columnsTemplate"
          )

      div(v-show="renderEmptyKanbanDiv" class="empty-kanban")
        span(:class="[noResultsIcon, 'no-registers-icon']")
        span(class="text") {{ $t('components.no_results.no_search_results_text') }}
        span(class="text try-again") {{ $t('components.no_results.try_again') }}

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { extend } from '@syncfusion/ej2-base'
import { DragEventArgs, KanbanPlugin, QueryCellInfoEventArgs } from '@syncfusion/ej2-vue-kanban'
import { Query, Predicate } from '@syncfusion/ej2-data'
import { SidebarComponent, SidebarPlugin } from '@syncfusion/ej2-vue-navigations'
import { Icons } from '@/icons/icons'
import {
  KanbanSidebarCheckboxInfo,
  KanbanCardItem,
  KanbanSidebarFilter
} from '@/components/LexonKanban/types/LexonKanbanTypes'
import LexonKanbanSwimlaneTemplate from '@/components/LexonKanban/templates/LexonKanbanSwimlaneTemplate.vue'
import LexonKanbanDialogTemplate from '@/components/LexonKanban/templates/LexonKanbanDialogTemplate.vue'
import LexonKanbanTooltipTemplate from '@/components/LexonKanban/templates/LexonKanbanTooltipTemplate.vue'
import LexonKanbanColumnTemplate from '@/components/LexonKanban/templates/LexonKanbanColumnTemplate.vue'
import LexonKanbanCardTemplate from '@/components/LexonKanban/templates/LexonKanbanCardTemplate.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

Vue.use(KanbanPlugin)
Vue.component(SidebarPlugin.name, SidebarComponent)

@Component({
  components: {
    SpinnerLayerComponent
  }
})
export default class LexonKanbanComponent extends Mixins(PermissionsMixin) {
  @Prop({
    type: Array
  })
  actions!: KanbanCardItem[]

  @Prop({
    type: String
  })
  searchTerm!: string

  @Prop({
    type: String
  })
  groupByTerm!: string

  @Prop({
    type: String
  })
  sortByOptionProp!: string

  @Prop({
    type: Object
  })
  toggleColumnInfo!: {}

  @Prop({
    type: Boolean
  })
  dockIsClosedProp!: boolean

  @Prop({
    type: Array
  })
  kanbanColumns!: []

  @Prop({
    type: Array
  })
  responsibles!: []

  @Prop({
    type: Object
  })
  filtersInfo!: { responsible: string[]; actionTypeText: string[] }

  $refs!: {
    kanban: any
  }

  isLoadingKanban = true

  searchField = 'subject'

  cardSettings = {
    contentField: 'description',
    headerField: 'id',
    template: this.cardTemplate
  }

  swimlaneSettings = {
    keyField: this.groupByTerm,
    template: this.swinlaneTemplate,
    sortDirection: this.sortByOptionProp
  }

  dialogSettings = {
    template: this.dialogTemplate,
    model: {
      cssClass: 'lexon-actions-panel-dialog',
      width: 400
    }
  }

  renderEmptyKanbanDiv: boolean = false

  noResultsIcon: string = Icons.CLOSE_SEARCH

  get kanbanInstance() {
    return this.$refs.kanban.ej2Instances
  }

  get kanbanData() {
    return extend([], this.actions, undefined, true) as []
  }

  @Watch('searchTerm')
  filterActionsBySearchTerm(searchValue: string): void {
    if (searchValue && searchValue !== '') {
      this.filter(searchValue)
      this.checkIfRenderEmptyKanbanDivSearchBehaviour(searchValue)
    } else {
      this.filter()
    }
  }

  @Watch('toggleColumnInfo')
  toggleColumn(selectedCheckboxInfo: KanbanSidebarCheckboxInfo): void {
    if (selectedCheckboxInfo.visible) {
      this.$refs.kanban.showColumn(selectedCheckboxInfo.keyField)
    } else {
      this.$refs.kanban.hideColumn(selectedCheckboxInfo.keyField)
    }
  }

  @Watch('kanbanData')
  async loadKanban() {
    if (this.isLoadingKanban) {
      await this.$nextTick()
      this.renderKanban()
    }
  }

  @Watch('filtersInfo', { deep: true })
  async checkFilters() {
    const searchTerm = this.searchTerm ? this.searchTerm : null
    this.filter(searchTerm)
  }

  mounted() {
    this.$root.$on('closeKanbanDialog', this.closeKanbanDialog)
    this.setCardsSortSettings()

    if (this.actions && this.actions.length) {
      this.renderKanban()
    }
  }

  renderKanban() {
    this.showOrHideColumnsDependingOnUserConfiguration()
    this.collapseSwimlanesOnLoad()
    this.filter()

    setTimeout(() => {
      this.isLoadingKanban = false
    })
  }

  columnsTemplate() {
    return { template: LexonKanbanColumnTemplate }
  }

  tooltipTemplate() {
    return { template: LexonKanbanTooltipTemplate }
  }

  swinlaneTemplate() {
    return { template: LexonKanbanSwimlaneTemplate }
  }

  dialogTemplate() {
    return {
      template: {
        extends: LexonKanbanDialogTemplate,
        propsData: {
          responsibles: this.responsibles
        }
      }
    }
  }

  cardTemplate() {
    return { template: LexonKanbanCardTemplate }
  }

  collapseSwimlanesOnLoad(): void {
    setTimeout(() => {
      const currentSwimlanes = this.$el.querySelectorAll('.e-swimlane-row')

      if (currentSwimlanes.length) {
        currentSwimlanes.forEach((_swinlane, index) => {
          this.kanbanInstance.actionModule.rowExpandCollapse(currentSwimlanes[index])
        })
      }
    })
  }

  filter(searchValue: null | string = null) {
    const { responsible, actionTypeText } = this.filtersInfo
    // @ts-ignore
    let responsiblePredicate: any = new Predicate()
    // @ts-ignore
    let actionTypeTextPredicate: any = new Predicate()
    let filterQuery: any = new Query()

    if (responsible && responsible.length) {
      responsible.forEach((responsible) => {
        responsiblePredicate = responsiblePredicate.or(KanbanSidebarFilter.RESPONSIBLE, 'equal', responsible)
      })
    }

    if (actionTypeText && actionTypeText.length) {
      actionTypeText.forEach((actionTypeText) => {
        actionTypeTextPredicate = actionTypeTextPredicate.or(KanbanSidebarFilter.TYPE, 'equal', actionTypeText)
      })
    }

    if (searchValue) {
      if (responsible.length && actionTypeText.length) {
        filterQuery = new Query()
          .where(responsiblePredicate)
          .where(actionTypeTextPredicate)
          .search(searchValue, this.searchField, 'contains', true, true)
      } else if (responsible.length) {
        filterQuery = new Query()
          .where(responsiblePredicate)
          .search(searchValue, this.searchField, 'contains', true, true)
      } else if (actionTypeText.length) {
        filterQuery = new Query()
          .where(actionTypeTextPredicate)
          .search(searchValue, this.searchField, 'contains', true, true)
      } else {
        filterQuery = new Query().search(searchValue, this.searchField, 'contains', true, true)
      }
    } else {
      if (responsible.length && actionTypeText.length) {
        filterQuery = new Query().where(responsiblePredicate).where(actionTypeTextPredicate)
      } else if (responsible.length) {
        filterQuery = new Query().where(responsiblePredicate)
      } else if (actionTypeText.length) {
        filterQuery = new Query().where(actionTypeTextPredicate)
      }
    }

    this.kanbanInstance.query = filterQuery
  }

  checkIfRenderEmptyKanbanDivSearchBehaviour(searchValue: string): void {
    if (searchValue) {
      const dataSource = this.$refs.kanban.dataSource
      const filteredDataSource = dataSource.filter((item: any) =>
        item.subject.toLowerCase().includes(searchValue.toLowerCase())
      )
      if (!filteredDataSource.length) {
        this.renderEmptyKanbanDiv = true
      } else {
        this.renderEmptyKanbanDiv = false
      }
    } else {
      this.renderEmptyKanbanDiv = false
    }
  }

  checkIfRenderEmptyKanbanDivFiltersBehaviour(args: QueryCellInfoEventArgs) {
    if (args.requestType === 'swimlaneRow' && args.data) {
      const filterActionsNumber = args.data[0].count
      if (filterActionsNumber) {
        this.renderEmptyKanbanDiv = false
      } else {
        this.renderEmptyKanbanDiv = true
      }
    }
  }

  setCardsSortSettings() {
    this.kanbanInstance.sortSettings.sortBy = 'Custom'
    this.kanbanInstance.sortSettings.field = this.searchField
    this.kanbanInstance.sortSettings.direction = this.sortByOptionProp
  }

  closeKanbanDialog() {
    setTimeout(() => {
      this.kanbanInstance.closeDialog()
    })
  }

  // Bloquea drag and drop si el usuario no tiene permisos de guardadao para este tipo de actuación
  checkIfDisableDragAndDrop(args: DragEventArgs) {
    const actionData = args.data[0]
    const { idEntityType } = actionData
    const { canSave } = this.checkEntityPermissionsGetter(idEntityType)
    if (!canSave) {
      args.cancel = true
    }
  }

  saveAction(args: DragEventArgs) {
    const actionData = args.data[0]
    const dataToSend = {
      id: actionData.id,
      subject: actionData.subject,
      idActionType: actionData.idActionType,
      state: actionData.state,
      userId: actionData.userId
    }
    this.$root.$emit('saveAction', dataToSend)
  }

  showOrHideColumnsDependingOnUserConfiguration(): void {
    this.kanbanColumns.forEach((column: any) => {
      if (column.value) {
        this.$refs.kanban.showColumn(column.keyField)
      } else {
        this.$refs.kanban.hideColumn(column.keyField)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@syncfusion/ej2-vue-kanban/styles/material.css';

.component-container {
  width: 100%;
  min-height: calc(100vh - 200px);

  .spinner-layer {
    position: absolute;
    width: 100%;
    background-color: $white-01;
    z-index: 1;
  }

  .lexon-kanban-container {
    @include flex;
    width: 100%;
    background-color: $white-01;

    .empty-kanban {
      @include flex($flex-direction: column);
      font-family: $corporate-font;
      width: 100%;
      min-height: calc(100vh - 200px);
      font-size: 20px;
      color: $blue-04;
      padding-top: 40px;

      .no-registers-icon {
        font-size: 100px;
        margin-bottom: 10px;
      }

      .try-again {
        font-size: 14px;
      }
    }

    .lexon-kanban {
      padding-top: 16px;
      min-height: calc(100vh - 200px);

      ::v-deep .e-kanban-content {
        @include scroll-styles;
        margin-top: 10px;
        margin-right: 10px;
        height: calc(100vh - 300px);
      }

      ::v-deep .e-header-cells {
        background-color: #d0d3e2 !important;
      }

      ::v-deep .e-swimlane-header {
        display: flex;
        align-items: center;

        .e-swimlane-row-collapse {
          &::before {
            font-size: 15px;
            font-family: $lf-icons-font;
            content: '\e90d';
          }

          &:focus {
            background-color: transparent !important;
          }

          &:hover {
            background-color: $gray-04 !important;
          }
        }

        .e-swimlane-row-expand {
          &::before {
            font-size: 15px;
            font-family: $lf-icons-font;
            content: '\e90b';
          }

          &:focus {
            background-color: transparent !important;
          }

          &:hover {
            background-color: $gray-04 !important;
          }
        }

        .e-icons {
          height: 30px !important;
        }

        .e-item-count {
          position: relative;
          top: 1px;
          color: $gray-02;
          margin-left: 4px;
        }
      }
    }
  }
}
</style>
