<template lang="pug">

  v-dialog(
    v-if="isOpened"
    v-model="isOpened"
    :transition="transition"
    persistent
    scrollable
    hide-overlay
    v-bind="dialogProps"
  )
    v-card
      header(class="header")
        v-card-title {{ title }}
        v-spacer
        span(
          :class="[closeData.icon, 'close-icon']"
          @click="executeAction(closeData.action)"
        )
      v-card-text(
        class="card-text sub-header"
        v-if="subTitle"
      )
        span(v-html="subTitle")
      v-card-text(class="card-text")
        slot(name="body")
          FormGeneratorComponent(
            :schema="formSchema"
            :fieldValues="formFieldValues"
            :context="context"
            formTag="selectForm"
            class="form-generator"
          )
      v-card-actions(v-if="showActionButtons")
        v-spacer
        ActionsBarComponent(
            :buttons="buttons"
            @execute-action="executeAction"
            class="action-buttons"
          )

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { i18n } from '@/plugins/vue-i18n'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const namespace = ModuleNamespaces.FORMS

interface ButtonsText {
  closeButton: string
  saveButton: string
}

@Component({
  components: {
    ActionsBarComponent,
    FormGeneratorComponent
  }
})
export default class PromptDialogComponent extends Vue {
  @Prop({
    type: Number,
    default: 352
  })
  width!: number

  @Prop({
    type: String,
    required: true
  })
  title!: string

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  subTitle!: string

  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Boolean,
    default: false
  })
  isOpened!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showActionButtons!: boolean

  @Prop({
    type: String,
    default: ''
  })
  transition!: string

  @Prop({
    type: Array
  })
  formSchema!: () => []

  @Prop({
    type: Object,
    default: () => ({})
  })
  formFieldValues!: object

  @Prop({
    type: Object,
    default: () => {
      return {
        closeButton: i18n.t('action_buttons.close'),
        saveButton: i18n.t('action_buttons.save')
      }
    }
  })
  texts!: ButtonsText

  @Getter('checkIfFormIsValid', { namespace })
  checkIfFormIsValid: (context: string) => boolean

  closeData = {
    icon: Icons.CLOSE,
    action: ActionName.CLOSE
  }

  @Emit()
  executeAction(value: string) {
    return value
  }

  get buttons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.closeButton,
        class: 'secondary-color',
        action: ActionName.CLOSE,
        mode: 'square',
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.saveButton,
        class: 'main-color',
        mode: 'square',
        action: ActionName.SAVE,
        show: this.checkIfFormIsValid(this.context)
      }
    ]
  }

  get dialogProps(): object {
    return {
      width: this.width,
      title: this.title
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  background-color: $dialog-background-color;
  pointer-events: unset;

  ::v-deep .v-dialog {
    @include borders;
    box-shadow: none;
    border-radius: 0;

    .v-card {
      background-color: $white-01;
      border-radius: 0;
      padding: 10px 10px 0;

      .header {
        @include flex;
        height: 48px;

        .v-card__title {
          font-family: $corporate-font;
          font-size: 24px;
          color: $corporate-color;
        }

        .close-icon {
          color: $corporate-color;
          font-size: 16px;
          padding: 0 16px;
          cursor: pointer;
        }
      }

      .sub-header {
        @include flex;
        span {
          @include flex;
          font-family: $corporate-font;
          color: $blue-04;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          padding: 20px 0;
        }
      }

      .card-text {
        padding: 8px 12px;

        > div {
          @include flex($flex-direction: column);

          > div {
            padding: 0;
          }
        }
      }

      .action-buttons {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
