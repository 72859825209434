<template lang="pug">

  div(class="expedient-insurers-container")

    SpinnerLayerComponent(v-show="loadingInitialData" class="spinner-layer")

    div(v-show="!loadingInitialData")

      TabHeaderComponent(
        v-if="!initialRenderMode"
        @closeForm="closeForm"
        @saveForm="saveInsurer"
        @removeItem="showModal"
        :buttons="buttons"
        :title="formTitle"
        class="tab-header"
      )

      FormGeneratorComponent(
        v-if="formSchema && formData"
        v-resize="checkIfHideFormField"
        :schema="formSchemaParsed"
        :fieldValues="formDataParsed"
        :context="context"
        :permissionsEntity="permissionsEntity"
        @saveFormData="prepareFormDataToSave"
        :key="componentKey"
      )

      SimpleGridTableComponent(
        v-if="initialRenderMode && formSchema"
        :columns="listConfiguration['Config']"
        :itemsData="listItems"
        :showHeaderTitle="true"
        :showGrid="true"
        :showButtons="false"
        :showHeaderButtons="isPortalUser ? false : true"
        :headerIcon="insurersGridIcon"
        :title="texts.insurersGridMessage"
        :showFilters="true"
        :redirectOnEdit="false"
        :contextMenuItems="contextMenuItemsInsurers1"
        :permissionsEntity="permissionsEntity"
        isInTab
        @simpleGridActionNew="openInsurerForm"
        @simpleGridActionEdit="editInsurer"
        @simpleGridActionRemove="showModal"
      )
        span(class="simple-header-title" slot="header-title") {{ texts.insurersGridMessage }}

</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { RenderedForm } from '@/components/expedients/ExpedientInsurers/types/ExpedientInsurersComponentTypes'
import { Icons } from '@/icons/icons'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import SimpleGridTableComponent from '@/components/grids/SimpleGridTable/SimpleGridTableComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { entity, Entity } from '@/store/modules/entities/entitiesTypes'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import { ExpedientFields } from '@/views/Expedients/types/ExpedientFieldsTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'

const configurationModule = ModuleNamespaces.CONFIGURATION
const endpointModule: string = ModuleNamespaces.ENDPOINT
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const formsModule = ModuleNamespaces.FORMS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const menusModule = ModuleNamespaces.MENUS

@Component({
  components: {
    FormGeneratorComponent,
    SimpleGridTableComponent,
    TabHeaderComponent,
    SpinnerLayerComponent
  }
})
export default class ExpedientInsurersComponent extends Mixins(GridContextMenuOptionsMixin) {
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: { activeStages: number; stageId: number }

  @Getter('getSelectedExpedientInsurer', { namespace: expedientsModule })
  selectedExpedientInsurer: {}

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  listItems: []

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getDrawerStatus', { namespace: menusModule })
  drawerInMiniStatus: boolean

  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Mutation('RESET_SELECTED_EXPEDIENT_INSURER', { namespace: expedientsModule })
  resetInsurer: () => void

  @Mutation('RESET_REGISTER_FORM_DATA', { namespace: formsModule })
  resetFormData: () => void

  @Mutation('RESET_CURRENT_VIEW_CONFIGURATION', { namespace: configurationModule })
  resetViewConfiguration: (context: string) => void

  @Action('fetchExpedientInsurers', { namespace: expedientsModule })
  fetchExpedientInsurers: ({}) => []

  @Action('saveExpedientInsurer', { namespace: expedientsModule })
  saveInsurerAction: ({}) => void

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfigurationAction: ({}) => {}

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void

  @Action('removeExpedientInsurer', { namespace: expedientsModule })
  removeInsurerAction: ({}) => void

  @Action('fetchSelectedExpedientInsurer', { namespace: expedientsModule })
  fetchSelectedExpedientInsurer: ({}) => {}

  @Action('saveRegisterFormData', { namespace: formsModule })
  saveRegisterFormData: ({}) => Promise<number>

  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  componentKey = 0

  initialRenderMode = true

  insurersGridIcon = Icons.HANDSHAKE

  permissionsEntity = entity.expedients.type

  texts = {
    buttons: {
      close: this.$t('action_buttons.close'),
      remove: this.$t('action_buttons.remove'),
      save: this.$t('action_buttons.save')
    },
    dialogRemoveInsurer: this.$t('components.expedient_insurers.dialog_remove_insurer'),
    formTitles: {
      editInsurer: this.$t('components.expedient_insurers.edit_insurer'),
      newInsurer: this.$t('components.expedient_insurers.new_insurer')
    },
    insurersGridMessage: this.$t('components.expedient_insurers.grid_title')
  }

  insurerFormInEditionMode = false

  selectedInsurerId = 0

  expedientContext = ContextName.EXPEDIENTS

  loadingInitialData = false

  @Watch('initialRenderMode')
  async refreshForm() {
    this.componentKey += 1
  }

  get buttons() {
    const { canSave } = this.viewPermission

    const buttons = [
      ...(canSave && this.insurerFormInEditionMode
        ? [
            {
              icon: Icons.REMOVE,
              tooltip: this.texts.buttons.remove,
              action: ActionName.REMOVE,
              show: true
            }
          ]
        : []),
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.buttons.close,
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      ...(canSave
        ? [
            {
              icon: Icons.CHECK,
              tooltip: this.texts.buttons.save,
              class: 'green-color',
              action: ActionName.SAVE,
              show: this.checkIfFormIsValid(this.context)
            }
          ]
        : [])
    ]

    return buttons
  }

  get contextMenuItemsInsurers1() {
    const { editItem, lookItem, removeItem } = this.contextMenuOptionsTexts
    const { canSave } = this.viewPermission
    return [
      {
        text: canSave ? editItem : lookItem,
        iconCss: canSave ? Icons.EDIT : Icons.VISIBLE
      },
      ...(canSave
        ? [
            {
              separator: true
            },
            {
              text: removeItem,
              iconCss: Icons.REMOVE,
              action: this.showModal
            }
          ]
        : [])
    ]
  }

  get formTitle() {
    return this.insurerFormInEditionMode ? this.texts.formTitles.editInsurer : this.texts.formTitles.newInsurer
  }

  get formSchema() {
    return this.getCurrentViewConfiguration(this.context)
  }

  get selectedExpedientId(): string {
    return this.selectedExpedientGeneralData.idExpedient
  }

  get context() {
    return ContextName.EXPEDIENT_INSURERS
  }

  get selectedExpedientGeneralData() {
    return this.selectedRegisterData(ContextName.EXPEDIENTS) as any
  }

  get formData() {
    if (this.initialRenderMode) {
      const {
        amountCompensation,
        amountPersonalDamage,
        amountPropertyDamage,
        dateComplaint,
        dateEntry,
        dateOfLoss,
        directCivilResponse
      } = this.selectedExpedientGeneralData as any

      return {
        amountCompensation,
        amountPersonalDamage,
        amountPropertyDamage,
        dateComplaint,
        dateEntry,
        dateOfLoss,
        directCivilResponse
      }
    } else {
      const {
        brandCar,
        driver,
        idAgency,
        idInsurer,
        inFavour,
        loss,
        modelCar,
        notes,
        numberPlate,
        occupant,
        otherPeople,
        pedestrian,
        policyNumber,
        mainIntervener
      } = this.selectedExpedientInsurer as any

      return {
        brandCar,
        driver,
        idAgency,
        idInsurer,
        inFavour,
        loss,
        modelCar,
        notes,
        numberPlate,
        occupant,
        otherPeople,
        pedestrian,
        policyNumber,
        mainIntervener
      }
    }
  }

  get hideFormField() {
    return this.$vuetify.breakpoint.lgAndDown
  }

  get formSchemaParsed() {
    if (!this.initialRenderMode) {
      const stageField = (this as any).formSchema.find((item: any) => item.id === ExpedientFields.STAGE)
      if (stageField) {
        stageField.hidden = this.expedientStageState && this.expedientStageState.activeStages === 0 ? true : false
      }
    }
    return this.formSchema
  }

  get formDataParsed() {
    if (!this.initialRenderMode) {
      ;(this as any).formData.idStage =
        (this as any).selectedExpedientInsurer.idStage || this.expedientStageState.stageId
    }
    return this.formData
  }

  async created() {
    await this.fetchCurrentViewConfiguration(RenderedForm.EXPEDIENT_INSURER)
    await this.fetchExpedientInsurers({
      selectedRegisterId: this.selectedExpedientId,
      filter: {
        idStage: this.expedientStageState.stageId
      }
    })
  }

  beforeDestroy() {
    this.resetInsurer()
    this.resetViewConfiguration(this.context)
  }

  checkIfHideFormField() {
    const hiddenField = this.$el.querySelector('.hidden-field') as HTMLElement
    if (hiddenField) {
      if (this.hideFormField || !this.drawerInMiniStatus) {
        hiddenField.style.setProperty('display', 'none', 'important')
      } else {
        hiddenField.style.setProperty('display', 'inline-block', 'important')
      }
    }
  }

  async fetchCurrentViewConfiguration(formAlias: string) {
    await this.fetchCurrentViewConfigurationAction({
      objectType: ConfigurationTypes.VIEW,
      alias: formAlias,
      context: this.context
    })
  }

  toggleRenderMode() {
    this.initialRenderMode = !this.initialRenderMode
  }

  async openInsurerForm() {
    this.$emit('disabledTabs')
    this.$emit('openForm')

    const { canSave } = this.checkEntityPermissionsGetter(1)

    if (canSave) {
      await this.saveRegisterFormData({
        endpoint: this.endpoints(this.entity(this.expedientContext).type).save,
        idSelectedRegister: this.entity(this.expedientContext).id,
        goToURL: null,
        context: this.expedientContext
      })
    }

    await this.fetchCurrentViewConfiguration(RenderedForm.EXPEDIENT_INSURER_FORM)
    this.componentKey += 1
    this.toggleRenderMode()
  }

  async closeForm() {
    this.loadingInitialData = true
    await this.fetchCurrentViewConfiguration(RenderedForm.EXPEDIENT_INSURER)
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.expedientContext).type).get,
      context: this.expedientContext
    })
    await this.fetchExpedientInsurers({
      selectedRegisterId: this.selectedExpedientId,
      filter: {
        idStage: this.expedientStageState.stageId
      }
    })
    this.loadingInitialData = false
    this.initialRenderMode = true
    this.insurerFormInEditionMode = false
    this.$emit('enabledTabs')
    this.$emit('closeForm')
    this.resetFormData()
    this.resetInsurer()
  }

  async saveInsurer() {
    const dataToSend = {
      ...this.formData,
      idExpedient: this.selectedExpedientId,
      id: this.insurerFormInEditionMode ? this.selectedInsurerId : 0
    }
    await this.saveInsurerAction(dataToSend)
    this.closeForm()
  }

  showModal(selectedInsurer: any) {
    const selectedRegisterId = selectedInsurer ? selectedInsurer.id : this.selectedInsurerId
    this.selectedInsurerId = selectedRegisterId
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: (this as any).selectedExpedientGeneralData.idOwnFile,
        text: this.texts.dialogRemoveInsurer
      }),
      action: this.removeInsurer
    })
  }

  async removeInsurer() {
    await this.removeInsurerAction(this.selectedInsurerId)
    await this.fetchExpedientInsurers({
      selectedRegisterId: this.selectedExpedientId,
      filter: {
        idStage: this.expedientStageState.stageId
      }
    })
    this.closeForm()
  }

  prepareFormDataToSave() {
    this.prepareFormData(this.formData)
  }

  async editInsurer(selectedRecord: any) {
    if (this.isPortalUser) {
      return
    }

    this.selectedInsurerId = selectedRecord.id
    this.insurerFormInEditionMode = true
    await this.fetchSelectedExpedientInsurer({
      idExpedientInsurance: selectedRecord.id,
      filter: {
        idStage: (this as any).selectedExpedientInsurer.idStage
      }
    })
    this.openInsurerForm()
  }
}
</script>

<style lang="scss" scoped>
.expedient-insurers-container {
  .spinner-layer {
    --spinner-layer-min-height: 470px;
  }

  .tab-header {
    text-transform: uppercase;
  }
}
</style>
