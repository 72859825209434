// import '@/plugins/gtm'
import '@/classComponentHooks'
import { Vue } from 'vue-property-decorator'
import App from '@/App.vue'
import { router } from '@/router/router'
import store from '@/store/store'
import vuetify from '@/plugins/vuetify'
import { i18n } from '@/plugins/vue-i18n'
import trackerPlugin from '@/plugins/tracker'
import '@/plugins/v-tooltip'
import '@/plugins/vue-cookies'
import '@/plugins/syncfusion'
import '@/plugins/vee-validate/vee-validate'
import moment from 'moment'
import '@/styles/main.scss'
import '@/helpers/string'
import '@/directives/Security/SecurityDirective'

Vue.prototype.$moment = moment
Vue.use(trackerPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
