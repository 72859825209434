import { ActionTree } from 'vuex'
import { ContactsState, contactsMutationTypes } from '@/store/modules/contacts/contactsTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SpinnerDashboardContext, spinnerMutationTypes } from '@/store/modules/spinner/spinnerTypes'
import { MainService } from '@/services/MainService'
import { ContactsService } from '@/services/ContactsService'
import { listItemsMutationTypes } from '../listItems/listItemsTypes'

const listItemsModule: string = ModuleNamespaces.LIST_ITEMS
const contactsModule: string = ModuleNamespaces.CONTACTS

export const actions: ActionTree<ContactsState, RootState> = {
  async fetchContacts({ commit }, contactType) {
    try {
      const url = `contacts/${contactType}`
      const { data } = await new ContactsService().getData(url)
      commit(contactsMutationTypes.FETCH_CONTACTS, data)
    } catch (error) {
      commit(`${contactsModule}/RESET_CONTACTS`, null, { root: true })
    }
  },
  async fetchContactsFilter({ commit }, { contactType, params }): Promise<void> {
    try {
      const url = `contacts/${contactType}`
      const { data } = await new MainService().postData(url, params)
      commit(contactsMutationTypes.FETCH_CONTACTS, data)
      return data
    } catch (error) {
      commit(`${contactsModule}/RESET_CONTACTS`, null, { root: true })
    }
  },
  async fetchContactsContact({ commit }, { contactType = null, idContact = null }) {
    try {
      const url = `contact/contacts/${contactType}/${idContact}/get`
      const { data } = await new ContactsService().getData(url)
      commit(`${listItemsModule}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {
      commit(`${contactsModule}/RESET_CONTACTS`, null, { root: true })
    }
  },
  setContactTypeSelected({ commit }, contactType) {
    commit(contactsMutationTypes.CONTACT_TYPE_SELECTED, contactType)
  },
  async fetchContact({}, { contactType = null, params = {} }) {
    try {
      const url = `contact/${contactType}/get`
      const { data } = await new MainService().postData(url, params)
      return data
    } catch (error) { }
  },
  async saveContact({ commit }, { contactType, formData }) {
    try {
      const url = `/contacts/${contactType}/save`
      const { data } = await new MainService().postData(url, formData)
      return data
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.SHOW_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.LAST_CUSTOMERS_LIST,
        { root: true }
      )
    }
  }
}
