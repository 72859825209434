<script lang="ts">
import { Component } from 'vue-property-decorator'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import { Action, Getter } from 'vuex-class'
import { ListResult } from '@/store/modules/actions/actionsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import { mixins } from 'vue-class-component'
import { to, from, next } from '@/router/types/routerTypes'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import store from '@/store/store'
import { URLS } from '@/router/routes/urlRoutes'

const actionsModule: string = ModuleNamespaces.ACTIONS
const billingModule: string = ModuleNamespaces.BILLING
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const contactsModule: string = ModuleNamespaces.CONTACTS
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const professionalsModule: string = ModuleNamespaces.PROFESSIONALS
const reportsModule: string = ModuleNamespaces.REPORTS
const spinnerModule: string = ModuleNamespaces.SPINNER
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component
export default class ListViewMixin extends mixins(GridContextMenuOptionsMixin, GridToolbarOptionsMixin) {
  @Getter('getAllContacts', { namespace: contactsModule }) contactsData: []
  @Getter('getAllActions', { namespace: actionsModule }) actionsData: ListResult
  @Getter('getAllExpedients', { namespace: expedientsModule }) expedientsData: ListResult
  @Getter('getExpedientsListWithFormattedDates', { namespace: expedientsModule }) expedientsDataFormatedDates: []
  @Getter('getBillingWithFormattedDates', { namespace: billingModule }) billingData: []
  @Getter('getCurrentListConfiguration', { namespace: configurationModule }) listConfiguration: {
    Alias: string
    Config: string
  }
  @Getter('getProfessionalsListWithFormattedDates', { namespace: professionalsModule }) professionalsData: []
  @Getter('getReportsListWithFormattedDates', { namespace: reportsModule }) reportsData: []
  @Getter('getReportData', { namespace: reportsModule }) reportData: []
  @Getter('getRememberUserCustomConfigurationList', { namespace: configurationModule }) rememberUserConfig: boolean
  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule }) listItems: []
  @Getter('getShowSpinnerLayer', { namespace: spinnerModule }) showSpinnerLayer: boolean

  @Action('fetchContacts', { namespace: contactsModule })
  fetchContacts: (contactType: string) => []

  @Action('saveSelectedEntityName', { namespace: entitiesModule })
  saveSelectedEntityName: (type: string) => void

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}

  @Action('hideSpinnerLayer', { namespace: spinnerModule })
  hideSpinnerLayerAction: () => void

  async beforeRouteEnter(to: to, from: from, next: next<ListViewMixin>) {
    next((vm) => {
      if (from.path.includes(to.path.slice(0, -1)) || vm.allActionsViewRememberUserCustomConfigurationList(to, from)) {
        store.dispatch(`${ModuleNamespaces.CONFIGURATION}/rememberUserCustomConfigurationList`, true)
      } else {
        store.dispatch(`${ModuleNamespaces.CONFIGURATION}/rememberUserCustomConfigurationList`, false)
      }
    })
  }

  beforeCreate() {
    this.$store.commit(`${contactsModule}/RESET_CONTACTS`)
    this.$store.commit(`${configurationModule}/RESET_CURRENT_LIST_CONFIGURATION`)
  }

  formatData(listData: ListResult) {
    return formatFieldsTypeDateEPOCH(listData.data)
  }

  formatDataPaginated(listData: ListResult) {
    const actions = formatFieldsTypeDateEPOCH(listData.data)
    const gridResult: DataResult = { result: actions, count: parseInt(listData.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  toggleArchivedContextMenu(event: MouseEvent, idContextMenu: string) {
    if (event) {
      const btn: EventTarget | null = event.target
      if (btn) {
        const rect: ClientRect | DOMRect = (btn as HTMLHtmlElement).getBoundingClientRect()
        const elem: any = document.getElementById(idContextMenu)
        if (null !== elem) {
          elem.ej2_instances[0].open(rect.top + 50, rect.left + 60)
        }
      }
    }
  }

  allActionsViewRememberUserCustomConfigurationList(to: to, from: from) {
    const isAllActionsView = to.path === `/${URLS.ACTIONS}/${URLS.ACTION_ALL}`
    const comeFromEditOrCreateSomeActionView = Boolean(
      from.meta?.parentUrl === URLS.ACTIONS && from.params.selectedRegisterId
    )
    return isAllActionsView && comeFromEditOrCreateSomeActionView
  }

  async loadContactViewsData(contactType: string, listName: string) {
    await this.fetchContacts(contactType)
    await this.saveSelectedEntityName(contactType)
    await this.fetchCurrentListConfiguration(listName)
  }
}
</script>
