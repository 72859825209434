<template lang="pug">

  DashboardWidgetChartBarType(
    :chartTitle="title"
    id="expedients-chart"
    :chartDataProp="chartData"
    :showSpinner="showSpinner"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardWidgetChartBarType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetChartBarType.vue'
import { DashboardChartBarItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetChartBarType
  }
})
export default class DashboardExpedientsCounterChartWidget extends Vue {
  @Action('fetchDashboardExpedientsCounterDataChart', { namespace: dashboardWidgetsModule })
  fetchDashboardExpedientsCounterChartDataAction: () => []

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showWidgetSpinner: (context: string) => boolean

  @Getter('getDashboardExpedientCounterChart', { namespace: dashboardWidgetsModule })
  chartData: DashboardChartBarItem[]

  title = this.$t('components.dashboard_widget_chart_type.expedient_counter')

  get showSpinner() {
    return this.showWidgetSpinner(SpinnerDashboardContext.EXPEDIENTS_COUNTER_CHART)
  }

  created() {
    // Hace la petición solo si ha guardado algun expediente. Utiliza el estado del spinner como un flag
    if (this.showSpinner) {
      this.fetchDashboardExpedientsCounterChartDataAction()
    }
  }
}
</script>
