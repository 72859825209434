import { ActionTree } from 'vuex'
import { CustomersState, customersMutationTypes } from '@/store/modules/customers/customersTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { CustomersService } from '@/services/CustomersService'

export const actions: ActionTree<CustomersState, RootState> = {
  async fetchTotalNumberOfCustomers({ commit }) {
    try {
      const url = 'contacts/customers/total/dashboard'
      const { data } = await new CustomersService().getData(url)
      commit(customersMutationTypes.FETCH_TOTAL_NUMBER_OF_CUSTOMERS, data[0]['total'])
    } catch (error) {}
  },
  async fetchMaxCustomerReference() {
    try {
      const url = '/contacts/max-reference/get'
      const { data } = await new CustomersService().getData(url)
      return data.reference
    } catch (error) {}
  }
}
