<template lang="pug">
  v-dialog(
    v-if="showDialog"
    v-model="showDialog"
    persistent
    scrollable
    max-width="600px"
  )
    template(v-slot:default)
      v-card(
        class="modal-add-folder"
      )
        header(class="header")
          v-card-title(
            class="modal-title"
          )
            span() {{texts.title}}
          span(
            :class="[icons.close, 'close-icon']"
            @click.stop="close"
          )
        v-card-text(
          style="height: 450px;"
        )

          TemplateSelectorTreeComponent(
            :idEntityType="idEntityType"
            @templateSelected="getTemplateSelected"
            :loading="treeLoading"
            :key="componentKey"
            :generateTemplate="generatingTemplate"
          )

        v-card-actions(
          class="modal-footer"
        )
          div(
            class="right"
          )
            button(
              @click.stop="close"
              class="button secondary-action-button"
              title="texts.cancel"
            ) {{ texts.cancel }}
            button(
              v-if="hasSelectedTemplate"
              @click.stop="generate"
              class="button main-action-button"
              :title="texts.generate"
            ) {{ texts.generate }}

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ITemplate } from '@/store/modules/template/templateTypes'
import NoResultsComponent from '@/components/search/NoResults/NoResultsComponent.vue'
import TemplateSelectorTreeComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorTreeComponent.vue'

@Component({
  components: {
    SpinnerLayerComponent,
    NoResultsComponent,
    TemplateSelectorTreeComponent
  }
})
export default class TemplateSelectorComponent extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  showDialog!: boolean

  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  generatingTemplate: boolean = false

  templateSelected: ITemplate | null = null

  texts = {
    title: this.$t('components.file_manager.dialogs.select_template'),
    cancel: this.$t('action_buttons.cancel'),
    generate: this.$t('action_buttons.generate')
  }

  icons = {
    document: Icons.TEMPLATE,
    folder: Icons.FOLDER,
    folderOpen: Icons.FOLDER_OPEN,
    on: Icons.RADIO_ACTIVE,
    off: Icons.CIRCLE_O,
    close: Icons.CLOSE
  }

  componentKey: number = 0

  treeLoading: boolean = false

  async updateComponent() {
    return (this.componentKey += 1)
  }

  getTemplateSelected(template: any) {
    this.templateSelected = template
  }

  get hasSelectedTemplate() {
    return this.templateSelected !== null
  }

  close() {
    this.$emit('closeTemplateDialog')
  }

  generate() {
    this.$emit('generateTemplateDialog', this.templateSelected)
    this.templateSelected = null
    this.generatingTemplate = true
    this.treeLoading = true
    this.updateComponent().then(() => {
      this.treeLoading = false
      this.generatingTemplate = false
    })
  }
}
</script>
<style lang="scss" scoped>
.v-dialog {
  .v-sheet.v-card {
    border-radius: 0px;
    border: 1px solid $blue-01;
  }
}
.v-card {
  .header {
    @include flex;
    height: 48px;

    .v-card__title {
      font-family: $corporate-font;
      font-size: 22px;
      color: $corporate-color;
      flex-grow: 4;
    }

    .close-icon {
      color: $corporate-color;
      font-size: 13px;
      padding: 0 16px;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
.v-dialog__content {
  background-color: $dialog-background-color;
  pointer-events: unset;
}

.modal-title {
  font-family: $corporate-font;
  background-color: $white-01;
  color: $corporate-color;
  height: 18px;
  font-size: 24px !important;
  letter-spacing: 0;
  line-height: 18px;
}

.modal-add-folder {
  ::v-deep .v-card__text {
    @include scroll-styles();
    .resume {
      color: $gray-01;
      display: flex;
      list-style: none;
      padding: 20px 0px 0px;
      .icon-type {
        font-size: 26px;
        margin-right: 10px;
      }
      .content {
        padding-right: 8px;
      }
      .v-chip {
        margin: 0 10px 0 0;
        padding: 0 5px 0 10px;
        font-weight: bold;
        border-width: 2px;
        &.resume-ok {
          color: $green-01;
          border-color: $green-01;
        }
        &.resume-fail {
          color: $red-10;
          border-color: $red-10;
        }
        &.v-size--default {
          height: auto;
        }
      }
      .spacer {
        flex-grow: 0 !important;
        border-right: 1px solid;
        margin-right: 10px;
      }
    }
    .simple-grid {
      margin-top: 20px;
      .e-headertext {
        text-transform: initial;
      }
    }
    .loading {
      display: flex;
      //font-size: 16px;
      flex-direction: column;
      justify-content: flex-end;
      height: 55%;
      align-items: center;
      .loading-text {
        color: $corporate-color;
        font-size: 14px;
      }
    }
    .is-directory,
    [class*='lf-icon-folder'] {
      color: $corporate-color;
      margin-right: 15px;
      cursor: pointer;
      span {
        font-size: 11px;
      }
    }
    .v-treeview-node--disabled {
      span {
        color: $gray-02;
      }
    }
    .v-treeview-node__toggle {
      width: 20px;
      &::before {
        display: none;
      }
    }
  }
  ::v-deep .spinner-layer-item {
    margin-left: 0px;
    .spinner-lefebvre {
      margin-right: 9px;
      width: $spinner-xs;
      height: $spinner-xs;
    }
  }
  ::v-deep .v-treeview-node__level {
    width: 20px;
  }
  ::v-deep .v-treeview-node__root {
    padding-left: 0;
    min-height: 25px;
  }
  ::v-deep .v-icon--link {
    font-size: 12px;
    color: $corporate-color;
  }
  ::v-deep .v-treeview-node__prepend {
    font-family: $lf-icons-font;
    color: $corporate-color;
    margin-right: 0px;
    min-width: auto;
    .e-filemanager {
      span {
        background-image: none;
      }
      .radio-tree {
        position: relative;
        float: left;
        .v-input--selection-controls__ripple {
          height: 22px;
          width: 23px;
          left: -7px;
          top: calc(50% - 18px);
        }
        .v-input--selection-controls__input {
          .lf-icon-step-first {
            color: $corporate-color;
          }
        }
        .v-icon {
          font-size: 18px;
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    ::v-deep .right {
      display: flex;
      margin-left: auto;
    }
    ::v-deep .main-action-button {
      @include main-action-button--rectangle;
    }
    ::v-deep .secondary-action-button {
      @include secondary-action-button--rectangle;
    }
  }

  ::v-deep .uploaded-fail,
  ::v-deep .invalid {
    color: $red-01;
    margin-left: 10px;
  }

  ::v-deep .spinner-layer {
    --spinner-layer-min-height: 32%;
    .spinner-lefebvre {
      width: $spinner-md;
      height: $spinner-md;
    }
  }

  ::v-deep .uploaded-ok {
    margin-left: 10px;
    color: $green-01;
  }

  ::v-deep .v-treeview-node__label {
    font-family: $gray-01;
    flex: none;
    font-size: 13px;
    padding-right: 20px;
  }

  ::v-deep .v-icon.v-icon::after {
    background-color: transparent;
  }

  ::v-deep .v-treeview-node__append {
    display: flex;
    margin-left: 10px;
    font-style: italic;
    font-size: 11px;
    color: $gray-01;
  }
}

.template-not-found {
  font-size: 16px;
  text-align: center;
  width: 95%;
  position: absolute;
  margin-top: 24px;
  color: $blue-01;
}
</style>
