<template lang="pug">

  div(class="duplicate-expedient-container")

    //- HEADER
    CustomDialogHeaderComponent(:headerTitle="texts.title")

    p(class="explanation") {{ texts.explanation }}

    //- FIELDS
    ValidationObserver(
        ref="nameInput"
        v-slot="{ validate }"
        class="buttons-container"
      )
      LexonTextFieldComponent(
        v-model="expedientName"
        :label="texts.nameLabel"
        id="name"
        name="name"
        validationRules="required"
        @input="validateExpedientNameField"
      )
      LexonAutocompleteComponent(
        id="sections"
        name="sections"
        v-model="selectedSections"
        :propItems="sections"
        :label="texts.sectionsLabel"
        multiple
        chips
        hasRelatedField
        itemName="name"
        itemValue="id"
        validationRules="required"
        :showSelectAll="true"
        :selectAllText="texts.allSections"
      )

    //- BUTTONS
    CustomDialogActionButtonsComponent(:disabledButtons="disabledActionButton" :mainAction="duplicateExpedient")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ValidationObserver } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SectionToDuplicate } from '@/store/modules/expedients/expedientsTypes'

const dialogModule: string = ModuleNamespaces.DIALOG
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    LexonAutocompleteComponent: () =>
      import('@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'),
    LexonTextFieldComponent: () => import('@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'),
    ValidationObserver
  }
})
export default class DuplicateExpedientDialogComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: { id: string; name: string }

  @Action('duplicateExpedient', { namespace: expedientsModule })
  duplicateExpedientAction: (expedientData: {}) => void

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Getter('getExpedientSectionsToDuplicate', { namespace: expedientsModule })
  sections: SectionToDuplicate[]

  closeIcon = Icons.CLOSE

  expedientName = ''

  selectedSections = []

  texts = {
    allSections: this.$t('action_buttons.select_all'),
    explanation: this.$t('components.duplicate_expedient.explanation'),
    nameLabel: this.$t('components.duplicate_expedient.name_label'),
    sectionsLabel: this.$t('components.duplicate_expedient.sections_label'),
    title: this.$t('components.duplicate_expedient.title')
  }

  $refs!: {
    nameInput: InstanceType<typeof ValidationObserver>
  }

  get disabledActionButton() {
    return !this.selectedSections.length || !this.expedientName
  }

  mounted() {
    this.expedientName = `DUPLICADO - ${this.componentProps.name}`
  }

  async validateExpedientNameField() {
    await this.$nextTick()
    this.$refs.nameInput.validate()
  }

  duplicateExpedient() {
    const expedientData = {
      id: this.componentProps.id,
      name: this.expedientName,
      sections: this.selectedSections,
      originalName: this.componentProps.name
    }
    this.closeCustomDialog()
    this.duplicateExpedientAction(expedientData)
  }
}
</script>

<style lang="scss" scoped>
.duplicate-expedient-container {
  .explanation {
    font-family: $corporate-font-medium;
    font-size: 20px;
    color: $blue-04;
    text-align: center;
    margin-top: 70px;
  }

  .buttons-container {
    display: block;
    margin-bottom: 200px;

    #sections {
      ::v-deep .v-menu__content.menuable__content__active {
        overflow: hidden;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
