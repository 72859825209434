import { ActionTree } from 'vuex'
import { ReportsState, reportsMutationTypes } from '@/store/modules/reports/reportsTypes'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { ReportsService } from '@/services/ReportsService'
import { i18n } from '@/plugins/vue-i18n'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { adaptReportsList } from '@/store/modules/reports/reportsAdapters'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { MainService } from '@/services/MainService'

const spinnerModule = ModuleNamespaces.SPINNER
const alertModule = ModuleNamespaces.ALERTS

export const actions: ActionTree<ReportsState, RootState> = {
  async fetchListReports({ commit }) {
    try {
      const url = 'report'
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_REPORTS, data)
    } catch (error) {}
  },
  async fetchReport({ commit, dispatch }, id: any) {
    try {
      commit(reportsMutationTypes.RESET_REPORT)
      const url = `report/${id}`
      const { data } = await new ReportsService().getData(url)
      const url2 = `report/${id}/data`
      let sourceData = await new ReportsService().getData(url2)
      sourceData = sourceData.data
      commit(reportsMutationTypes.FETCH_REPORT, data)
      commit(reportsMutationTypes.FETCH_REPORT_DATA, sourceData)
    } catch (error) {
    } finally {
      await dispatch(`${spinnerModule}/hideSpinnerLayer`, null, { root: true })
    }
  },
  async fetchReportData({ commit, dispatch }, id: any) {
    try {
      await dispatch(`${spinnerModule}/showSpinner`, null, { root: true })
      const url = `report/${id}/data`
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_REPORT_DATA, data)
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    }
  },
  async postReports({ commit, dispatch }, report: any) {
    report.name = report.reportName
    delete report.sourceData
    try {
      await dispatch(`${spinnerModule}/showSpinner`, null, { root: true })
      const url = `report`
      const { data } = await new MainService().postData(url, report)
      commit(reportsMutationTypes.POST_REPORT, data)
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
      await dispatch(
        `${alertModule}/showAlert`,
        {
          type: AlertsTypes.SUCCESS,
          message: i18n.t('components.alerts.report_save_success'),
          componentWhereIsRendered: ComponentWhereIsRendered.GRID_PIVOT_TABLE
        },
        { root: true }
      )
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
      await dispatch(
        `${alertModule}/showAlert`,
        {
          type: AlertsTypes.ERROR,
          message: i18n.t('components.alerts.report_save_error'),
          componentWhereIsRendered: ComponentWhereIsRendered.GRID_PIVOT_TABLE
        },
        { root: true }
      )
    }
  },
  async deleteReport({ commit, dispatch }, id: any) {
    try {
      await dispatch(`${spinnerModule}/showSpinner`, null, { root: true })
      const url = `report/${id}`
      const { data } = await new ReportsService().deleteData(url)
      commit(reportsMutationTypes.DELETE_REPORT, data)
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    }
  },
  async fetchDashboardReports({ commit, dispatch }) {
    try {
      const url = 'reports/dashboard'
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_DASHBOARD_REPORTS, data)
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    } catch (error) {
      await dispatch(`${spinnerModule}/hideSpinner`, null, { root: true })
    }
  },
  async fetchTotalNumberOfReports({ commit }) {
    try {
      const url = '/reports/total/dashboard'
      const { data } = await new ReportsService().getData(url)
      commit(reportsMutationTypes.FETCH_TOTAL_NUMBER_OF_REPORTS, data[0]['Total'])
    } catch (error) {}
  },
  async resetReports({ commit }) {
    commit(reportsMutationTypes.RESET_REPORT)
  },
  async setFirstLoad({ commit }, status: boolean) {
    commit(reportsMutationTypes.SET_FIRST_LOAD, status)
  },
  // NEW REPORTS LIST ENDPOINTS
  async fetchReportsList({ commit }) {
    try {
      const url = 'v2/report/all'
      const { data } = await new ReportsService().getData(url)
      adaptReportsList(data)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {
      console.error('Error fetching or validating report list:', error)
    }
  },
  async fetchOneReportInfo({ commit }, filters: any) {
    try {
      const jsonString = JSON.stringify(filters)
      const encodedJsonString = encodeURIComponent(jsonString)
      const url = `v2/report?filters=${encodedJsonString}`
      const { data } = await new ReportsService().getData(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  }
}
