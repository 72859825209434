<script lang="ts">
import { mixins } from 'vue-class-component'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  ColumnChooser,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  Freeze,
  GridComponent,
  GridPlugin,
  Group,
  IFilter,
  LazyLoadGroup,
  Page,
  PdfExport,
  Reorder,
  Resize,
  RowSelectEventArgs,
  RowSelectingEventArgs,
  Search,
  Sort,
  Toolbar
} from '@syncfusion/ej2-vue-grids'
import { ContextMenuPlugin, ToolbarPlugin } from '@syncfusion/ej2-vue-navigations'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces, joomlaEntitiesData } from '@/store/types/storeGlobalTypes'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { vars } from '@/styles/styleVars'
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export'
import { mttMilano } from '@/fonts/mtt-milano'
import { FilterSettings, filterSettingsTypesOperator } from '@/components/grids/GridTable/types/GridTableTypes'
import ActionTypeTemplateComponent from '@/components/grids/ColumnTemplates/ActionTypeTemplateComponent.vue'
import ColorsColumnTemplateComponent from '@/components/grids/ColumnTemplates/ColorsColumnTemplateComponent.vue'
import ContextMenuTemplateComponent from '@/components/grids/ColumnTemplates/ContextMenuTemplateComponent.vue'
import DataToHourTemplateComponent from '@/components/grids/ColumnTemplates/DataToHourTemplateComponent.vue'
import DocumentTypeTemplateComponent from '@/components/grids/ColumnTemplates/DocumentTypeTemplateComponent.vue'
import DocumentsAttachmentTemplateComponent from '@/components/grids/ColumnTemplates/DocumentsAttachmentTemplateComponent.vue'
import IconColumnTemplateComponent from '@/components/grids/ColumnTemplates/IconColumnTemplateComponent.vue'
import MonitoringDialogAuditColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogAuditColumnTemplateComponent.vue'
import MonitoringDialogRecipientColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogRecipientColumnTemplateComponent.vue'
import MonitoringDialogRequestColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogRequestColumnTemplateComponent.vue'
import MonitoringDialogStatusColumnTemplateComponent from '@/components/grids/ColumnTemplates/firma/MonitoringDialogStatusColumnTemplateComponent.vue'
import StatusTemplateComponent from '@/components/grids/ColumnTemplates/StatusTemplateComponent.vue'
import { actualDate, changeDateFormat } from '@/helpers/dateTime'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import { DocumentPathInfo } from '@/store/modules/fileManager/fileManagerTypes'
import PartialbankdraftTemplateComponent from '@/components/grids/ColumnTemplates/PartialbankdraftTemplateComponent.vue'

Vue.use(ContextMenuPlugin)
Vue.use(ToolbarPlugin)
Vue.use(GridPlugin)

const entitiesModule = ModuleNamespaces.ENTITIES
const fileManagerModule = ModuleNamespaces.FILE_MANAGER

const toolbarItems = [
  {
    id: 'print',
    prefixIcon: Icons.PRINT,
    tooltipText: i18n.t('components.grid_table.tooltip.print'),
    align: 'right',
    cssClass: 'first-item'
  },
  {
    id: 'export',
    prefixIcon: Icons.DOWNLOAD,
    tooltipText: i18n.t('components.grid_table.tooltip.export'),
    align: 'right'
  }
]

@Component({
  provide: {
    grid: [
      ColumnChooser,
      ContextMenu,
      DetailRow,
      ExcelExport,
      Filter,
      Freeze,
      Group,
      LazyLoadGroup,
      Page,
      PdfExport,
      Reorder,
      Resize,
      Search,
      Sort,
      Toolbar
    ]
  }
})
export default class GridTableTemplatesMixin extends mixins(GridContextMenuOptionsMixin) {
  @Prop({
    type: [Array, Object],
    required: true
  })
  itemsData!: []

  @Prop({
    type: Array,
    default: () => toolbarItems
  })
  toolbarOptions!: () => []

  @Prop({
    type: String
  })
  title!: string

  @Prop({
    type: String,
    default: 'EUR'
  })
  currencyCode!: string

  @Prop({
    type: Boolean,
    default: true
  })
  isFrozenColsMode!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  activeOnClickCell!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  allowPaging: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  allowSelection: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  isInTab!: boolean

  @Prop({
    type: Number,
    default: 1
  })
  frozenColumns!: number

  @Prop({
    type: Function
  })
  rowDataBound!: () => void

  @Prop({
    type: String
  })
  excelExportedFileName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showFirstColumn!: boolean

  @Getter('getSelectedEntityName', { namespace: entitiesModule }) selectedEntityName: string

  @Getter('getMenuParentCategoryName', { namespace: entitiesModule }) menuParentCategoryName: string

  @Action('fetchDocumentPathInfo', { namespace: fileManagerModule })
  fetchDocumentPathInfoAction: (idDocument: string) => DocumentPathInfo

  gridElement: any = null

  frozenCols = this.frozenColumns

  checkboxFilter: IFilter = {
    type: 'CheckBox'
  }

  filterSettings: FilterSettings = {
    type: 'Menu',
    ignoreAccent: true,
    columns: [],
    operators: filterSettingsTypesOperator
  }

  selectedRegister: any = null

  // TODO - Tipar
  $refs!: {
    grid: InstanceType<typeof GridComponent>
    contextMenuComponent: any
    customToolbarContextMenuComponent: any
    openFileComponent: any
  }

  get bothGridsSharedProps(): object {
    return {
      allowExcelExport: true,
      allowFiltering: true,
      allowPaging: this.allowPaging,
      allowPdfExport: true,
      allowReordering: true,
      allowResizing: true,
      allowSorting: true,
      allowSelection: this.allowSelection,
      currencyCode: this.currencyCode,
      dataSource: this.itemsData,
      enablePersistance: true,
      excelExportComplete: this.showActionsColumn,
      frozenColumns: this.isFrozenColsMode ? this.frozenCols : null,
      contextMenuItems: this.renderedContextMenuItems,
      pdfExportComplete: this.pdfExportComplete,
      pdfQueryCellInfo: this.formatZeroValues,
      rowSelected: this.onRowSelected,
      rowDeselected: this.onRowSelected,
      rowDataBound: this.rowDataBound ? this.rowDataBound : null,
      rowHeight: '40'
    }
  }

  // Creo que se puede eliminar
  get getEntityData() {
    const entityObject = joomlaEntitiesData.filter((entityData) => entityData.name === (this as any).selectedEntityName)
    return this.menuParentCategoryName === ParentCategory.CONTACTS && entityObject.length > 1
      ? entityObject[1]
      : entityObject[0]
  }

  get checkIfToolbarIsHidden() {
    return this.toolbarOptions.length ? '' : 'toolbar-hidden'
  }

  mounted() {
    this.hideHeaderTitles()
    setTimeout(() => {
      this.getTextColorInYesOrNoColumns()
      this.getTextColorInDateColumns()
    }, 500)
  }

  hideHeaderTitles() {
    const headers = document.querySelectorAll('div.e-headercelldiv > span')
    const titles = ['Color']
    headers.forEach((header) => {
      if (titles.includes(header.innerHTML)) {
        header.classList.add('hidden-text')
      }
    })
  }

  formatZeroValues(args: any) {
    if (args.column.format === 'C2' && args.value === '') {
      args.value = '00,00 €'
    }
  }

  getCustomAttributes(customAttributes: object) {
    return customAttributes || null
  }

  getTextColorInYesOrNoColumns() {
    const yesOrNoCells = this.$el.querySelectorAll('.e-rowcell.e-templatecell[yesornocolumn="true"]')
    yesOrNoCells.forEach((cell: any) => {
      const text = cell.innerText
      if (text === this.$t('action_buttons.yes')) {
        cell.style.color = vars.success_color
      } else {
        cell.style.color = vars.error_color
      }
    })
  }

  getTextColorInDateColumns() {
    const dateColorCells = this.$el.querySelectorAll('.e-rowcell.e-templatecell[datecolorcolumn="true"]')
    const today = actualDate('YYYY-MM-DD')
    dateColorCells.forEach((cell: any) => {
      const date = changeDateFormat(cell.innerText, 'DD/MM/YYYY', 'YYYY-MM-DD')
      if (date > today) {
        cell.style.color = vars.success_color
      } else if (date < today) {
        cell.style.color = vars.error_color
      } else {
        cell.style.color = vars.warning_color
      }
    })
  }

  onRowSelected(args: RowSelectEventArgs | RowSelectingEventArgs) {
    const { data } = args as any
    this.selectedRegister = data
    const selectedRecords = this.$refs.grid.getSelectedRecords()
    const emittedRecords = selectedRecords.length > 0 ? selectedRecords : this.selectedRegister
    this.$emit('selectedRecords', emittedRecords)
  }

  showActionsColumn(status = true) {
    this.gridElement.getColumns()[0].visible = status
  }

  async pdfExportComplete(args: { promise: Promise<any> }) {
    const event = await args.promise
    const blobURL = URL.createObjectURL(event.blobData)
    window.open(blobURL)
  }

  exportToPDF() {
    const grid = this.gridElement
    const pdfExportProperties: any = {
      pageSize: 'A4',
      pageOrientation: 'Landscape',
      exportType: 'CurrentPage',
      header: {
        fromTop: 0,
        height: 30,
        contents: [
          {
            type: 'Text',
            value: this.title,
            position: { x: 0, y: 10 },
            style: { textBrushColor: '#000000', fontSize: 13 }
          }
        ]
      },
      theme: {
        caption: { font: new PdfTrueTypeFont(mttMilano, 12) },
        header: { font: new PdfTrueTypeFont(mttMilano, 10) },
        record: { font: new PdfTrueTypeFont(mttMilano, 9) },
        footer: { font: new PdfTrueTypeFont(mttMilano, 10) }
      }
    }

    this.showActionsColumn(false)
    grid.pdfExport(pdfExportProperties, null, null, true)
    this.showActionsColumn(true)
  }

  hideScroll() {
    const grid = (this as any).$refs.grid.ej2Instances
    const scrollBar = grid.getContent().querySelector('.e-scrollbar')
    if (scrollBar) {
      const mvblScrollBar = grid.getContent().querySelector('.e-movablescrollbar')
      const mvblChild = grid.getContent().querySelector('.e-movablechild')
      scrollBar.style.display = 'flex'
      if (mvblScrollBar.offsetWidth >= mvblChild.offsetWidth) {
        scrollBar.style.display = 'none'
      }
    }
  }

  /* COLUMNS TEMPLATES */

  actionType() {
    return { template: ActionTypeTemplateComponent }
  }

  documentAttachment() {
    return { template: DocumentsAttachmentTemplateComponent }
  }

  dataToHourTemplate() {
    return { template: DataToHourTemplateComponent }
  }

  iconColumnTemplate() {
    return { template: IconColumnTemplateComponent }
  }

  contextMenuColumnTemplate() {
    return { template: ContextMenuTemplateComponent }
  }

  columnStatusTemplate() {
    return { template: StatusTemplateComponent }
  }

  monitoringDialogRequestColumnTemplate() {
    return { template: MonitoringDialogRequestColumnTemplateComponent }
  }

  monitoringDialogStatusColumnTemplate() {
    return { template: MonitoringDialogStatusColumnTemplateComponent }
  }

  monitoringDialogAuditColumnTemplate() {
    return { template: MonitoringDialogAuditColumnTemplateComponent }
  }

  monitoringDialogRecipientColumnTemplate() {
    return { template: MonitoringDialogRecipientColumnTemplateComponent }
  }

  colorsColumnTemplate() {
    return { template: ColorsColumnTemplateComponent }
  }

  documentType() {
    return { template: DocumentTypeTemplateComponent }
  }

  partialbankdraftTemplate() {
    return { template: PartialbankdraftTemplateComponent }
  }

  createFilterTooltips() {
    const tableHead = this.$el.querySelector('.e-movableheader thead')

    if (!tableHead) {
      return
    }

    const headerCells = tableHead.querySelectorAll('.e-headercell')

    headerCells.forEach((headerCell) => {
      const headerText = headerCell.querySelector('.e-headertext')

      if (!headerText) {
        return
      }

      const filterIcon = headerCell.querySelector('.e-filtermenudiv')

      if (!filterIcon) {
        return
      }

      const headerTextInnerHTML = headerText.innerHTML
      const tooltipText = this.$t('components.grid_table.tooltip.filter_by', {
        headerText: headerTextInnerHTML
      }) as string
      filterIcon.setAttribute('title', tooltipText)
    })
  }
}
</script>

<style lang="scss">
.yes-color {
  color: $success-color;
}

.no-color {
  color: $error-color;
}

.cell-icon {
  @include flex;
}

.e-rowcell.e-gridchkbox.e-templatecell {
  text-align: center;
}
</style>
