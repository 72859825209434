<template lang="pug">

  PrintOrSaveInvoicesFieldsetComponent(
    :stepNumber="stepNumber"
    :legend="fieldsetLegend"
    class="invoices-progress-visualizer-container"
  )
    ul(class="visualizer-list")
     li(
      v-for="(invoice, index) in invoices"
      :key="index"
      class="invoice"
    )
      div(:class="['row-1', , invoice.status]")
        div(class="invoice-info")
          span(:class="[icons.pdf, 'pdf-icon']")
          span(class="invoice-name") {{ invoice.numberInvoice }}
          span(class="invoice-size") {{ sizeText(invoice.size) }}
        span(:class="['icon', infoIcon(invoice.status)]")

      div(:class="['row-2', invoice.status]")
        span(class="progress-info") {{ infoText(invoice.status) }}

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PrintOrSaveInvoicesFieldsetComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesFieldsetComponent.vue'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'
import { Icons } from '@/icons/icons'
import { InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'

@Component({
  components: {
    PrintOrSaveInvoicesFieldsetComponent
  }
})
export default class InvoicesProgressVisualizer extends Vue {
  @Prop({
    required: true
  })
  invoices: []

  stepNumber = PrintOrSaveInvoicesSteps.GENERATION_PROGRESS

  icons = {
    cancel: Icons.CLOSE,
    check: Icons.CHECK,
    lefebvre: Icons.LEFEBVRE,
    pdf: Icons.PDF
  }

  fieldsetLegend = this.$t('components.invoices_progress_visualizer.fieldset_legend')

  infoText(status: string) {
    switch (status) {
      case InvoiceStatus.LOADED:
        return '100%'
      case InvoiceStatus.ERROR:
        return '¡Error inesperado!'
      default:
        return 'Generando documento'
    }
  }

  infoIcon(status: string) {
    const { check, cancel, lefebvre } = this.icons
    switch (status) {
      case InvoiceStatus.LOADED:
        return check
      case InvoiceStatus.ERROR:
        return cancel
      default:
        return lefebvre
    }
  }

  sizeText(size: number) {
    return isNaN(size) ? '' : Intl.NumberFormat('es-ES').format(size) + ' KB'
  }
}
</script>

<style lang="scss" scoped>
.invoices-progress-visualizer-container {
  --invoices-fieldset-height: 100%;
  --invoices-fieldset-padding-right: 20px;
  --spinner-layer-width: 20px;
  --spinner-image-font-size: 10px;

  .visualizer-list {
    @include list;
    font-family: $secondary-font;
    font-size: 13px;
    padding: 2px 20px 0 20px;

    .invoice {
      @include flex($align-items: flex-start, $flex-direction: column);
      margin-bottom: 2px;
      color: $corporate-color;
      padding-bottom: 5px;

      .row-1,
      .row-2 {
        width: 100%;
      }

      .row-1 {
        @include flex($justify-content: space-between);
        height: 24px;

        .pdf-icon {
          font-size: 20px;
          margin-right: 7px;
        }

        .invoice-info {
          padding-top: 20px;

          .invoice-name {
            color: $gray-01;
            margin-right: 8px;
          }

          .invoice-size {
            height: 19px;
            color: $gray-02;
            font-size: 10px;
          }
        }

        .icon {
          font-size: 12px;
        }

        &.loading .icon {
          animation: pulse 2000ms infinite ease-in-out;
        }

        &.loaded .icon {
          color: $success-color;
        }

        &.error {
          background-color: transparent !important;

          .icon {
            font-size: 9px;
            color: $error-color;
          }
        }
      }

      .row-2 {
        @include flex($justify-content: flex-end);
        @include border;
        height: 12px;

        .progress-info {
          font-size: 10px;
          margin-right: 8px;
        }

        &::after {
          content: '';
          width: 12px;
          height: 12px;
          background-color: $corporate-color;
        }

        &.loaded {
          color: $success-color;
          border-color: $success-color;

          &::after {
            background-color: $success-color;
          }
        }

        &.error {
          color: $error-color;
          border-color: $error-color;
          background-color: transparent !important;

          &::after {
            background-color: $error-color;
          }
        }
      }
    }
  }
}
</style>
