<template lang="pug">

  div(class="expedient-economic-data-container")

    div(v-if="renderGrid" class="grid-container")

      GridTableComponent(
        v-if="Object.keys(listConfiguration).length && isGridTableComponentList"
        :itemsData="gridItems"
        :contextMenuItems="contextMenuItemsEconomicData"
        :gridConfiguration="listConfiguration['Config']"
        :listName="listConfiguration['Alias']"
        :context="context"
        :title="texts.gridTitle"
        :toolbarOptions="toolbarOptions"
        :customToolbarContextMenuItems="customToolbarContextMenuItems"
        @otherGridButtonClicked="openForm"
        @openExpedientEconomicDataForm="openFormOnEditMode"
        @showDialog="showDialogComponent"
        @customToolbarContextMenuClick="customToolbarContextMenuClick"
        @gridTableFilter="onGridTableFilter"
        @contextMenuClicked="onContextMenuClicked"
      )

    div(v-else class="form-container")

      TabHeaderComponent(
        @closeForm="onCloseForm"
        @saveForm="onSaveForm"
        @removeItem="showDialogComponent"
        :buttons="buttons"
        :title="getTitle"
      )

      FormGeneratorComponent(
        v-if="formSchema"
        :schema="formSchemaParsed"
        :fieldValues="formDataParsed"
        :context="context"
        @mountedForm="checkIfEconomicDataIsBilled"
        @emit-on-blur-event="fillTextfieldFields"
        @saveFormData="onChangeFormFieldValue"
      )

      SimpleGridTableComponent(
        v-if="showSimpleGrid && formSchema"
        class="simple-grid-table"
        :columns="listConfiguration['Config']"
        :itemsData="simpleGridItems"
        :showGrid="showSimpleGrid"
        :showButtons="false"
        :showHeaderTitle="true"
        :title="getTitle"
        :showFilters="true"
        :allowReorder="true"
        :noResultsCustomMessage="getNoResultsMessage"
        :headerIcon="noResultsIcon"
        :isFrozenColsMode="false"
        @selectedRecords="onSelectedRecords"
      )
        span(class="simple-header-title" slot="header-title") {{ getSimpleGridTitle }}

</template>

<script lang="ts">
import { Vue, Component, Watch, Mixins } from 'vue-property-decorator'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import SimpleGridTableComponent from '@/components/grids/SimpleGridTable/SimpleGridTableComponent.vue'
import TaxesDynamicFormFieldsMixin from '@/mixins/TaxesDynamicFormFields/TaxesDynamicFormFieldsMixin.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import { Icons } from '@/icons/icons'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import {
  ComponentRenderMode,
  ComponentFormFields,
  EconomicDataListAlias,
  SimpleGridImportActionsItem,
  InvoiceExpedientTextfieldInfo
} from '@/components/expedients/ExpedientEconomicData/types/ExpedientEconomicDataComponentTypes'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { CustomDialogComponentName, CustomDialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { LocaleMessage } from 'vue-i18n'
import { ExpedientFields } from '@/views/Expedients/types/ExpedientFieldsTypes'
import { TaxesFormFields } from '@/mixins/TaxesDynamicFormFields/types/TaxesDynamicFormFieldsMixinTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { InvoiceTypes } from '@/store/modules/invoices/invoicesTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { arrayHasItems } from '@/helpers/array'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const configurationModule = ModuleNamespaces.CONFIGURATION
const formsModule = ModuleNamespaces.FORMS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const dialogModule = ModuleNamespaces.DIALOG
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const alertsModule = ModuleNamespaces.ALERTS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const invoicesModule = ModuleNamespaces.INVOICES

@Component({
  components: {
    FormGeneratorComponent,
    GridTableComponent,
    TabHeaderComponent,
    SimpleGridTableComponent
  }
})
export default class ExpedientEconomicDataComponent extends Mixins(
  TaxesDynamicFormFieldsMixin,
  PermissionsMixin,
  GridContextMenuOptionsMixin
) {
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: { activeStages: number; stageId: number }

  @Getter('getSimpleGridItemsWithFormattedDates', { namespace: expedientsModule })
  simpleGridItems: []

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  gridItems: []

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGet: (context: string) => any

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfigurationAction: ({}) => Promise<{}>

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Action('removeEconomicData', { namespace: expedientsModule })
  removeEconomicDataAction: ({}) => Promise<void>

  @Action('saveEconomicData', { namespace: expedientsModule })
  saveEconomicDataAction: ({}) => Promise<void>

  @Action('saveInvoice', { namespace: invoicesModule })
  saveInvoice: ({}) => Promise<string>

  @Action('fetchExpedientEconomicData', { namespace: expedientsModule })
  fetchExpedientEconomicData: ({}) => Promise<[]>

  @Action('fetchSelectedExpedientEconomicData', { namespace: expedientsModule })
  fetchSelectedExpedientEconomicData: ({}) => Promise<{}>

  @Action('fetchMaxNumberInvoice', { namespace: invoicesModule })
  fetchMaxNumberInvoice: ({}) => Promise<number>

  @Action('fetchExpedientImportActionsList', { namespace: expedientsModule })
  fetchExpedientImportActionsList: ({}) => Promise<[]>

  @Action('saveImportedActions', { namespace: expedientsModule })
  saveImportedActions: ({}) => Promise<void>

  @Action('fetchExpedientSummary', { namespace: expedientsModule })
  fetchExpedientSummary: (filtersData: object) => Promise<[]>

  @Action('fetchExpedientEconomicDataPendingList', { namespace: expedientsModule })
  fetchExpedientEconomicDataPendingList: ({}) => Promise<[]>

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => Promise<{}>

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialogAction: ({}: CustomDialogData) => {}

  changedInvoiceExpedientFromTexfield = false

  texts = {
    buttons: {
      close: this.$t('action_buttons.close'),
      remove: this.$t('action_buttons.remove'),
      save: this.$t('action_buttons.save')
    },
    contextMenuItems: {
      invoiceCustomer: this.$t('components.context_menu.invoice_customer'),
      invoiceProforma: this.$t('components.context_menu.invoice_proforma')
    },
    economicData: this.$t('components.expedient_economic_data.economic_data'),
    economicDataBilled: this.$t('components.alerts.economic_data_billed'),
    economicDataSuccess: this.$t('components.alerts.economic_data'),
    gridTitle: this.$t('views.expedient_detail.title'),
    importActionsSimpleGridTitle: this.$t('components.expedient_economic_data.import_actions_grid_title'),
    importedAtionsSuccess: this.$t('components.alerts.imported_expedient_action_success'),
    invoiceCreated: this.$t('components.expedient_economic_data.invoice_created'),
    invoiceExpedientSimpleGridTitle: this.$t('components.expedient_economic_data.invoice_expedient_grid_title'),
    noResultsImportActions: this.$t('components.expedient_economic_data.no_results_import_actions'),
    noResultsInvoiceExpedient: this.$t('components.expedient_economic_data.no_results_invoice_expedient'),
    noCustomerId: this.$t('components.expedient_economic_data.no_customer_id'),
    titles: {
      editEconomicData: this.$t('components.expedient_economic_data.edit_economic_data'),
      importActions: this.$t('components.expedient_economic_data.import_actions'),
      newEconomicData: this.$t('components.expedient_economic_data.new_economic_data')
    },
    tooltips: {
      importActions: this.$t('components.expedient_economic_data.tooltip.import_actions'),
      invoice: this.$t('components.expedient_economic_data.tooltip.invoice'),
      newEconomicData: this.$t('components.expedient_economic_data.tooltip.add_new_economic_data')
    }
  }

  renderGrid = true

  componentRenderMode = ComponentRenderMode.ECONOMIC_DATA_MODE

  formData: any = {}

  get contextMenuItemsEconomicData() {
    const { editItem, lookItem, removeItem, viewActionsDetail } = this.contextMenuOptionsTexts
    const { canSave } = this.viewPermission

    return [
      {
        text: canSave ? editItem : lookItem,
        iconCss: canSave ? Icons.EDIT : Icons.VISIBLE
      },
      {
        text: viewActionsDetail,
        iconCss: Icons.FOLDER_OPEN
      },
      ...(canSave
        ? [
            {
              separator: true
            },
            {
              text: removeItem,
              iconCss: Icons.REMOVE
            }
          ]
        : [])
    ]
  }

  get customToolbarContextMenuItems() {
    return [
      ...(this.checkEntityPermissionsGetter(entity.invoices.customers.type).canSave
        ? [
            {
              text: this.texts.contextMenuItems.invoiceCustomer,
              iconCss: Icons.BILL
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.invoices.proforma.type).canSave
        ? [
            {
              text: this.texts.contextMenuItems.invoiceProforma,
              iconCss: Icons.BILL
            }
          ]
        : [])
    ]
  }

  economicDataInEditionMode = false

  selectedEconomicDataStage = 0

  formSchema = []

  selectedPendingItems: boolean = false

  invoiceExpedientTypeText: string = ''

  @Watch('expedientStageState')
  updateSummary(value: { activeStages: number; stageId: number }) {
    this.getSummary()

    if (!value.activeStages) {
      Vue.set(this.formData, 'idStage', null)
    }
  }

  get userHasPermissionToInvoice(): boolean {
    const invoiceTypes = [entity.invoices.customers.type, entity.invoices.proforma.type]
    return invoiceTypes.some((type) => this.checkEntityPermissionsGetter(type).canSave)
  }

  get toolbarOptions() {
    return [
      {
        id: 'filter',
        prefixIcon: Icons.FILTER,
        text: this.$t('components.grid_table.tooltip.clean_filter'),
        align: 'left',
        cssClass: 'lf-clean-filter-button'
      },
      {
        id: 'search',
        text: 'Search',
        align: 'right'
      },
      {
        id: 'print',
        prefixIcon: Icons.PRINT,
        tooltipText: this.$t('components.grid_table.tooltip.print'),
        align: 'right',
        cssClass: 'first-item'
      },
      {
        id: 'export',
        prefixIcon: Icons.DOWNLOAD,
        tooltipText: this.$t('components.grid_table.tooltip.export'),
        align: 'right'
      },
      ...(this.viewPermission.canSave && this.userHasPermissionToSaveAtLeastOneActionTypeIncludingEmails
        ? [
            {
              id: ComponentRenderMode.IMPORT_ACTIONS_MODE,
              prefixIcon: Icons.CLOCK,
              tooltipText: this.texts.tooltips.importActions,
              align: 'right',
              cssClass: 'e-tbtn-align'
            }
          ]
        : []),
      ...(this.viewPermission.canSave && this.userHasPermissionToInvoice
        ? [
            {
              id: ComponentRenderMode.INVOICE_EXPEDIENT_MODE,
              prefixIcon: Icons.COIN,
              tooltipText: this.texts.tooltips.invoice,
              align: 'right',
              cssClass: 'e-tbtn-align'
            },
            {
              id: ComponentRenderMode.ECONOMIC_DATA_MODE,
              prefixIcon: Icons.ADD,
              tooltipText: this.texts.tooltips.newEconomicData,
              align: 'right'
            }
          ]
        : [])
    ]
  }

  get isGridTableComponentList() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    return !!config.columns
  }

  get selectedExpedientId(): string {
    return this.selectedRegisterIdGet(this.expedientsContext)
  }

  get selectedExpedientNumber(): string {
    return (this as any).selectedRegisterData(this.expedientsContext).idOwnFile
  }

  get showSimpleGrid() {
    const validRenderModes = [ComponentRenderMode.IMPORT_ACTIONS_MODE, ComponentRenderMode.INVOICE_EXPEDIENT_MODE]
    return validRenderModes.includes(this.componentRenderMode as ComponentRenderMode)
  }

  get buttons() {
    const buttons = [
      {
        icon: Icons.REMOVE,
        tooltip: this.texts.buttons.remove,
        action: ActionName.REMOVE,
        show: true,
        hidden: !this.viewPermission.canSave
      },
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.buttons.close,
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.buttons.save,
        class: 'green-color',
        action: ActionName.SAVE,
        show: () => {
          const valid = this.checkIfFormIsValid(this.context)

          if (valid && this.checkSimpleGridItems()) {
            return ComponentRenderMode.ECONOMIC_DATA_MODE !== this.componentRenderMode
              ? this.selectedPendingItems
              : valid
          }
          return false
        },
        hidden: !this.viewPermission.canSave
      }
    ]

    return this.economicDataInEditionMode ? buttons : buttons.filter((button) => button.icon !== Icons.REMOVE)
  }

  get context() {
    return ContextName.EXPEDIENT_ECONOMIC_DATA
  }

  get expedientsContext() {
    return ContextName.EXPEDIENTS
  }

  get getTitle() {
    switch (this.componentRenderMode) {
      case ComponentRenderMode.IMPORT_ACTIONS_MODE:
        return this.texts.titles.importActions
      case ComponentRenderMode.INVOICE_EXPEDIENT_MODE:
        return this.invoiceExpedientTypeText
      case ComponentRenderMode.ECONOMIC_DATA_MODE:
        return this.economicDataInEditionMode ? this.texts.titles.editEconomicData : this.texts.titles.newEconomicData
      default:
        return ''
    }
  }

  get noResultsIcon() {
    switch (this.componentRenderMode) {
      case ComponentRenderMode.IMPORT_ACTIONS_MODE:
        return Icons.CLOCK
      case ComponentRenderMode.INVOICE_EXPEDIENT_MODE:
        return Icons.COIN
    }
  }

  get getSimpleGridTitle() {
    switch (this.componentRenderMode) {
      case ComponentRenderMode.IMPORT_ACTIONS_MODE:
        return this.texts.importActionsSimpleGridTitle
      case ComponentRenderMode.INVOICE_EXPEDIENT_MODE:
        return this.texts.invoiceExpedientSimpleGridTitle
    }
  }

  get getNoResultsMessage() {
    switch (this.componentRenderMode) {
      case ComponentRenderMode.IMPORT_ACTIONS_MODE:
        return this.texts.noResultsImportActions
      case ComponentRenderMode.INVOICE_EXPEDIENT_MODE:
        return this.texts.noResultsInvoiceExpedient
    }
  }

  get formSchemaParsed() {
    const stageField = (this as any).formSchema.find((item: any) => item.id === ExpedientFields.STAGE)
    if (stageField) {
      stageField.hidden = this.expedientStageState && this.expedientStageState.activeStages === 0 ? true : false
    }
    this.checkFormPermissions(this.formSchema, entity.expedients.type)
    return this.formSchema
  }

  get formDataParsed() {
    if (this.economicDataInEditionMode) {
      this.formData.idStage = String(this.selectedEconomicDataStage)
    } else if (this.expedientStageState && this.expedientStageState.stageId) {
      this.formData.idStage = String(this.expedientStageState.stageId)
    }
    return this.formData
  }

  get invoiceInfo() {
    const isProformaInvoice = this.invoiceExpedientTypeText === this.$t('components.context_menu.invoice_proforma')
    return {
      type: isProformaInvoice ? InvoiceTypes.PROFORMA : InvoiceTypes.CUSTOMER,
      url: isProformaInvoice ? URLS.PROFORMA_INVOICES : URLS.CUSTOMER_INVOICES
    }
  }

  async created() {
    this.toolbarOptions.map((item: any) => {
      if (item.id === ComponentRenderMode.INVOICE_EXPEDIENT_MODE) {
        item.id = ActionName.CUSTOM_OPTION
      }
    })
  }

  onSelectedRecords(selectedRecords: SimpleGridImportActionsItem[]) {
    const idCompany = this.formData.idCompany || ''
    const newAddedKey =
      this.componentRenderMode === ComponentRenderMode.IMPORT_ACTIONS_MODE ? 'actions' : 'economicDetails'

    const selectedItems = Array.isArray(selectedRecords)
      ? selectedRecords
          .map((record: SimpleGridImportActionsItem) => {
            return idCompany !== '' && String(record.idCompany) === idCompany ? record.id : null
          })
          .filter((id: any) => id !== null)
      : []

    this.formData[newAddedKey] = selectedItems
    this.selectedPendingItems = this.formData[newAddedKey].length > 0
  }

  clearFormData() {
    this.formData = {}
  }

  showDialogComponent(selectedEconomicDataId: string) {
    trackEvent(TrackerEvents.REMOVE_ECONOMIC_DATA)
    this.formData = { id: selectedEconomicDataId || this.formData.id }
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: this.selectedExpedientNumber,
        text: this.texts.economicData
      }),
      action: this.removeEconomicData
    })
  }

  async removeEconomicData() {
    const info = {
      id: this.formData.id,
      expedientNumber: this.selectedExpedientNumber
    }
    await this.removeEconomicDataAction({ id: info.id, expedientNumber: info.expedientNumber })
    await this.fetchExpedientEconomicData({
      selectedRegisterId: this.selectedExpedientId,
      filter: {
        idStage: this.expedientStageState.stageId
      }
    })
    this.getSummary()

    this.clearFormData()
    this.economicDataInEditionMode = false
    this.renderGrid = true
    this.lastDynamicFields = []
    this.$emit('closeForm')
    this.$emit('enabledTabs')
  }

  selectDiscountValueFormField() {
    const discountFieldset = this.formSchema.find((item: any) => {
      return item.fieldsetId === TaxesFormFields.DISCOUNT_FIELDSET
    }) as any

    if (discountFieldset) {
      return discountFieldset.fields.find((item: any) => {
        return item.id === TaxesFormFields.DISCOUNT_VALUE
      }) as any
    }
  }

  enableOrDisableDiscountValueFormField(selectedType: number | null = null) {
    const discountValueField = this.selectDiscountValueFormField()

    if (selectedType) {
      discountValueField.disabled = false
    } else {
      discountValueField.disabled = true
      discountValueField.fieldType = 'LexonNumericComponent'
      discountValueField.percentage = false
      Vue.set(this.formDataParsed, TaxesFormFields.DISCOUNT_VALUE, null)
    }
  }

  checkDiscountValueFieldType(status: number | null = null) {
    const discountType = status || this.formDataParsed[TaxesFormFields.DISCOUNT_TYPE]

    if (discountType) {
      const discountValueField = this.selectDiscountValueFormField()
      if (discountValueField) {
        if (discountType === 1) {
          discountValueField.fieldType = 'LexonNumericComponent'
          discountValueField.percentage = true
          discountValueField.decimals = 2
          discountValueField.format = 'N'
        } else {
          discountValueField.fieldType = 'LexonCurrencyComponent'
          discountValueField.decimals = 6
          discountValueField.format = '####'
          Vue.delete(discountValueField, 'percentage')
        }
      }
    }
  }

  async onChangeFormFieldValue(schema: [], _formData: object, field: any, value: any) {
    // Grouped field logic in import actions form
    if (this.showSimpleGrid) {
      if (field.id === ComponentFormFields.GROUPED) {
        const hidden: boolean = value === '0'
        const conceptField: any = schema.filter((item: any) => item.id === ComponentFormFields.CONCEPT)[0]
        conceptField.hidden = hidden
        conceptField.validationRules = hidden ? '' : 'required|max:2000'
      }
    }

    // Adding new fields depending on type selected in economic data form
    if (field.id === TaxesFormFields.TYPE) {
      this.addTaxesFields(schema, value, 6)
    }

    // Invoice expedient logic depending on professional selected
    if (
      field.id === ComponentFormFields.COMPANY &&
      !this.executingAPICall &&
      this.componentRenderMode !== ComponentRenderMode.ECONOMIC_DATA_MODE
    ) {
      this.executingAPICall = true

      if (this.componentRenderMode === ComponentRenderMode.IMPORT_ACTIONS_MODE) {
        const filter = {
          idStage: this.formData.idStage || null,
          idCompany: value
        }
        await this.fetchExpedientImportActionsList({ idExpedient: this.selectedExpedientId, filter })
      } else if (this.componentRenderMode === ComponentRenderMode.INVOICE_EXPEDIENT_MODE) {
        const filter = {
          idStage: this.expedientStageState.stageId,
          idCompany: value,
          exercise: this.formData.exercise || null,
          idExpedient: this.selectedExpedientId
        }
        await this.fetchExpedientEconomicDataPendingList({ idExpedient: this.selectedExpedientId, filter })

        if (value) {
          const data = (await this.fetchMaxNumberInvoice({
            invoiceType: this.invoiceInfo.type,
            filter: {
              idCompany: value,
              idExpedient: this.selectedExpedientId
            }
          })) as unknown as InvoiceExpedientTextfieldInfo
          Vue.set(this.formData, ComponentFormFields.EXERCISE, data['exercise'])
          Vue.set(this.formData, ComponentFormFields.SEQUENCE, data['sequence'])
          Vue.set(this.formData, ComponentFormFields.INVOICE_NUMBER, data['nextInvoice'])
        } else {
          Vue.delete(this.formData, ComponentFormFields.EXERCISE)
          Vue.delete(this.formData, ComponentFormFields.SEQUENCE)
          Vue.delete(this.formData, ComponentFormFields.INVOICE_NUMBER)
        }
      }
      this.executingAPICall = false
    }

    // Launch max number invoice API call in some fields
    if (
      (field.id === ComponentFormFields.EXERCISE || field.id === ComponentFormFields.SEQUENCE) &&
      this.formData.idCompany
    ) {
      this.changedInvoiceExpedientFromTexfield = true
    }

    // Changed stage form field
    if (field.id === ComponentFormFields.STAGE && !this.executingAPICall) {
      this.executingAPICall = true
      let filter = {}
      if (this.formData.idCompany) {
        filter = {
          idStage: String(this.formData.idStage),
          idCompany: this.formData.idCompany
        }
      } else {
        filter = {
          idStage: String(this.formData.idStage)
        }
      }

      if (this.componentRenderMode === ComponentRenderMode.IMPORT_ACTIONS_MODE) {
        await this.fetchExpedientImportActionsList({ idExpedient: this.selectedExpedientId, filter })
      }

      if (this.componentRenderMode === ComponentRenderMode.INVOICE_EXPEDIENT_MODE) {
        filter = {
          idStage: String(this.formData.idStage),
          idCompany: this.formData.idCompany,
          exercise: this.formData.exercise || null,
          idExpedient: this.selectedExpedientId
        }
        await this.fetchExpedientEconomicDataPendingList({ idExpedient: this.selectedExpedientId, filter })
      }

      this.executingAPICall = false
    }

    // Charged date field logic when payment type provission selected
    if (field.id === TaxesFormFields.CHARGED) {
      this.addChargedDate(schema, value, 8)
    }

    // discount type LexonNumeric percetage or number
    if (field.id === TaxesFormFields.DISCOUNT_TYPE) {
      this.checkDiscountValueFieldType(value)
      this.enableOrDisableDiscountValueFormField(value)
    }
    this.checkIfEconomicDataIsBilled()
  }

  async fillTextfieldFields() {
    if (this.formData.idCompany && this.changedInvoiceExpedientFromTexfield) {
      const data = (await this.fetchMaxNumberInvoice({
        invoiceType: this.invoiceInfo.type,
        filter: {
          idStage: this.expedientStageState.stageId,
          idCompany: this.formData.idCompany,
          sequence: this.formData.sequence,
          exercise: this.formData.exercise || null,
          idExpedient: this.selectedExpedientId
        }
      })) as unknown as InvoiceExpedientTextfieldInfo
      Vue.set(this.formData, ComponentFormFields.EXERCISE, data['exercise'])
      Vue.set(this.formData, ComponentFormFields.SEQUENCE, data['sequence'])
      Vue.set(this.formData, ComponentFormFields.INVOICE_NUMBER, data['nextInvoice'])
      this.changedInvoiceExpedientFromTexfield = false
    }
  }

  async fetchCurrentViewConfiguration(renderMode: string) {
    await this.fetchCurrentViewConfigurationAction({
      objectType: ConfigurationTypes.VIEW,
      alias: renderMode,
      context: this.context
    })
  }

  fetchListConfigurationDependingOnAlias(renderMode: string) {
    switch (renderMode) {
      case ComponentRenderMode.ECONOMIC_DATA_MODE:
        return this.fetchCurrentListConfiguration(EconomicDataListAlias.ECONOMIC_DATA)
      case ComponentRenderMode.IMPORT_ACTIONS_MODE:
        return this.fetchCurrentListConfiguration(EconomicDataListAlias.IMPORT_ACTIONS)
      case ComponentRenderMode.INVOICE_EXPEDIENT_MODE:
        return this.fetchCurrentListConfiguration(EconomicDataListAlias.INVOICE_EXPEDIENT)
    }
  }

  async onCloseForm() {
    if (this.isGridTableComponentList) {
      await this.fetchCurrentListConfiguration(EconomicDataListAlias.ECONOMIC_DATA)
      await this.fetchExpedientEconomicData({
        selectedRegisterId: this.selectedExpedientId,
        filter: {
          idStage: this.expedientStageState.stageId
        }
      })
    } else {
      this.fetchCurrentListConfiguration(EconomicDataListAlias.ECONOMIC_DATA)
      this.fetchExpedientEconomicData({
        selectedRegisterId: this.selectedExpedientId,
        filter: {
          idStage: this.expedientStageState.stageId
        }
      })
    }
    this.clearFormData()
    this.economicDataInEditionMode = false
    this.componentRenderMode = ComponentRenderMode.ECONOMIC_DATA_MODE
    this.renderGrid = true
    this.lastDynamicFields = []
    this.$emit('closeForm')
    this.$emit('enabledTabs')
  }

  async saveEconomicData() {
    const dataToSave = {
      ...this.formData,
      idExpedient: this.selectedExpedientId
    }
    await this.saveEconomicDataAction(dataToSave)
    this.renderComponentInEconomicDataMode(this.texts.economicDataSuccess)
    this.lastDynamicFields = []
    this.$emit('closeForm')
    this.$emit('enabledTabs')
  }

  async importActions() {
    const filter = {
      ...this.formData,
      idExpedient: this.selectedExpedientId
    }
    await this.saveImportedActions({
      idExpedient: this.selectedExpedientId,
      filter
    })
    this.renderComponentInEconomicDataMode(this.texts.importedAtionsSuccess)
    this.$emit('closeForm')
    this.$emit('enabledTabs')
  }

  async invoiceExpedient() {
    const id = await this.saveInvoice({
      invoiceType: this.invoiceInfo.type,
      filter: {
        idExpedient: this.selectedExpedientId,
        source: this.context,
        ...this.formData
      }
    })

    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${this.invoiceInfo.url}`,
      params: { selectedRegisterId: id }
    })

    window.open(routeData.href, '_blank')

    this.renderComponentInEconomicDataMode(this.texts.invoiceCreated)
    this.$emit('closeForm')
    this.$emit('enabledTabs')
  }

  async renderComponentInEconomicDataMode(successMessage: LocaleMessage) {
    await this.fetchExpedientEconomicData({
      selectedRegisterId: this.selectedExpedientId,
      filter: {
        idStage: this.expedientStageState.stageId
      }
    })
    this.getSummary()
    this.economicDataInEditionMode = false
    this.componentRenderMode = ComponentRenderMode.ECONOMIC_DATA_MODE
    await this.fetchListConfigurationDependingOnAlias(this.componentRenderMode)
    this.clearFormData()
    this.renderGrid = true
    this.showAlert({
      type: AlertsTypes.SUCCESS,
      message: successMessage,
      componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
    })
  }

  onSaveForm() {
    switch (this.componentRenderMode) {
      case ComponentRenderMode.ECONOMIC_DATA_MODE:
        return this.saveEconomicData()
      case ComponentRenderMode.IMPORT_ACTIONS_MODE:
        return this.importActions()
      case ComponentRenderMode.INVOICE_EXPEDIENT_MODE:
        return this.invoiceExpedient()
    }
  }

  trackClickEvent(renderMode: string) {
    if (renderMode === ComponentRenderMode.INVOICE_EXPEDIENT_MODE) {
      trackEvent(TrackerEvents.INVOICE_ECONOMIC_DATA)
    }

    if (renderMode === ComponentRenderMode.ECONOMIC_DATA_MODE) {
      trackEvent(TrackerEvents.CREATE_ECONOMIC_DATA)
    }

    if (renderMode === ComponentRenderMode.IMPORT_ACTIONS_MODE) {
      trackEvent(TrackerEvents.IMPORT_ACTIONS_IN_ECONOMIC_DATA)
    }
  }

  async openForm(renderMode: string) {
    this.trackClickEvent(renderMode)
    if (Object.values(ComponentRenderMode).indexOf(renderMode as any) === -1) {
      return
    }

    if (
      (this as any).selectedRegisterData(this.expedientsContext).idCustomer === null &&
      renderMode === ComponentRenderMode.INVOICE_EXPEDIENT_MODE
    ) {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.texts.noCustomerId
      })
      return
    }

    this.resetTaxesItems()

    if (renderMode !== ComponentRenderMode.ECONOMIC_DATA_MODE) {
      this.selectedPendingItems = false
      await this.fetchListConfigurationDependingOnAlias(renderMode)
      if (renderMode === ComponentRenderMode.IMPORT_ACTIONS_MODE) {
        const filter = {
          idStage: this.expedientStageState.stageId
        }
        await this.fetchExpedientImportActionsList({ idExpedient: this.selectedExpedientId, filter })
      } else if (renderMode === ComponentRenderMode.INVOICE_EXPEDIENT_MODE) {
        const filter = {
          idStage: this.formData.idStage,
          idExpedient: this.selectedExpedientId
        }
        await this.fetchExpedientEconomicDataPendingList({ idExpedient: this.selectedExpedientId, filter })
      }
    } else {
      this.selectedPendingItems = true
    }

    await this.fetchCurrentViewConfiguration(renderMode)
    this.formSchema = this.getCurrentViewConfiguration(this.context)
    this.renderGrid = false
    this.componentRenderMode = renderMode as ComponentRenderMode
    this.$emit('openForm')
    this.$emit('disabledTabs')
  }

  async openFormOnEditMode({ id, idStage }: { id: number; idStage: number }) {
    this.$emit('disabledTabs')
    this.$emit('openForm')
    this.selectedEconomicDataStage = idStage
    this.economicDataInEditionMode = true
    this.componentRenderMode = ComponentRenderMode.ECONOMIC_DATA_MODE
    await this.fetchCurrentViewConfiguration(this.componentRenderMode)
    this.formSchema = this.getCurrentViewConfiguration(this.context)
    this.formData = await this.fetchSelectedExpedientEconomicData({
      selectedRegisterId: this.selectedExpedientId,
      filter: { id }
    })
    this.renderGrid = false

    if (this.formData.billed) {
      this.showAlert({
        type: AlertsTypes.WARNING,
        message: this.texts.economicDataBilled,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })
    }
  }

  checkIfEconomicDataIsBilled() {
    if (
      this.componentRenderMode === ComponentRenderMode.ECONOMIC_DATA_MODE &&
      this.economicDataInEditionMode &&
      this.formData.billed
    ) {
      this.formSchema.forEach((field: any) => {
        field.disabled = true
      })
    }
  }

  customToolbarContextMenuClick(args: any) {
    this.invoiceExpedientTypeText = args.item.text

    this.openForm(ComponentRenderMode.INVOICE_EXPEDIENT_MODE)
  }

  onGridTableFilter(filters: any) {
    this.getSummary(filters)
  }

  async getSummary(filters: any = {}) {
    await this.fetchExpedientSummary({
      idExpedient: this.selectedExpedientId,
      idStage: this.expedientStageState.stageId,
      ...filters
    })
  }

  checkSimpleGridItems() {
    if (ComponentRenderMode.ECONOMIC_DATA_MODE === this.componentRenderMode) {
      return true
    }

    return arrayHasItems(this.simpleGridItems)
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    const { viewActionsDetail } = this.contextMenuOptionsTexts
    if (args.item && args.item.text === viewActionsDetail) {
      this.showCustomDialogAction({
        name: CustomDialogComponentName.VIEW_ECONOMICDATA_ACTIONS,
        styles: {
          maxWidth: '1000px',
          maxHeight: '600px'
        },
        props: {
          selectedRegister
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.expedient-economic-data-container {
  ::v-deep .grid-header {
    display: none;
  }

  ::v-deep .e-grid .e-toolbar {
    border-top: none !important;
  }

  ::v-deep .header-title {
    text-transform: uppercase;
  }

  .form-container {
    padding: 0 10px 10px 10px;
  }

  .simple-grid-table {
    ::v-deep .e-grid {
      @include borders;
      border-top: none;
    }
  }
}
</style>
