<template lang="pug">
  div(class="summary-formula")
    div()
      span(class="summary-text") {{ 'Ganancia: ' }}
      span(class="summary-value") {{returnedValue('Ganancia')}} 
      span() {{ '/ ' }}
      span(class="summary-text") {{ 'Inversión: ' }}
      span(class="summary-value") {{ returnedValue('Inversión') }}
      span() {{ ' = ' }}
      span(class="summary-text") {{ 'RENTABILIDAD: ' }}
      span(
        class="summary-value profitability-value" 
        :class="renderColorStatus(returnedValue('Rentabilidad'))") {{returnedValue('Rentabilidad')}} {{ ' %' }}
    
    div(
      v-for="(items, index) in summaryItemsOutFormula"
    )
      template(
        v-for="(item, index) in items"
      )
        span(class="summary-text") {{ item.label }}{{ ': ' }}
        span(class="summary-value") {{ item.value }}{{ ' ' }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ProfitabilitySummaryItem } from '@/components/Dialog/customDialogs/globalVisionProfitability/types/globalVisionProfitabilityDialogTypes'
import { splitArray } from '@/helpers/array'

@Component
export default class GlobalVisionProfitabilitySummaryFormulaComponent extends Vue {
  @Prop({
    required: true
  })
  summary!: any

  numberElements = 3

  get summaryItems() {
    const _summary: any = []
    this.summary.forEach((summary: ProfitabilitySummaryItem) => (_summary[summary.label] = summary.value))
    return _summary
  }

  get summaryItemsOutFormula() {
    const itemsInFormulaValues = ['Ganancia', 'Inversión', 'Rentabilidad']
    const summaryItemsOutFormula = this.summary.filter(
      (summary: ProfitabilitySummaryItem) => !itemsInFormulaValues.includes(summary.label)
    )
    return splitArray(summaryItemsOutFormula, this.numberElements)
  }

  returnedValue(key: string) {
    return typeof this.summaryItems[key] !== 'undefined' ? this.summaryItems[key] : '0,00 €'
  }

  renderColorStatus(digit: string): string {
    const convertDigitToNumber = parseFloat(digit.replace('.', '').replace(',', '.'))
    if (convertDigitToNumber === 0) {
      return 'corporate-color'
    } else if (convertDigitToNumber < 0) {
      return 'negative-color'
    } else {
      return 'positive-color'
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-formula {
  align-self: center;
  font-family: $secondary-font;
  color: $corporate-color;
  font-size: 14px;
  text-align: center;
  margin: 24px;

  ::v-deep .summary-text {
    font-weight: bold;
  }

  ::v-deep .summary-value {
    color: $gray-02;
    font-size: 15px;

    &.profitability-value {
      font-size: 15px;
      font-weight: 700;

      &.corporate-color {
        color: $corporate-color;
      }

      &.positive-color {
        color: $success-color;
      }

      &.negative-color {
        color: $error-color;
      }
    }
  }
}
</style>
