<template lang="pug"></template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes, DialogButton, DialogButtonTypes } from '@/store/modules/dialog/dialogTypes'
import { TranslateResult } from 'vue-i18n'
import { extend } from 'vee-validate'
import { ContactTypeId } from '@/store/modules/contacts/contactsTypes'
import { customValidatorTypes } from '@/store/modules/forms/formsTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { goToPreviousPage } from '@/helpers/routes'

const formsModule: string = ModuleNamespaces.FORMS
const dialogModule = ModuleNamespaces.DIALOG
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const entitiesModule = ModuleNamespaces.ENTITIES

@Component
export default class DniValidatorMixin extends Vue {
  @Action('contactsValidate', { namespace: formsModule })
  contactsDniValidate: ({}) => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegister: (context: string) => any

  @Getter('getSelectedRelatedData', { namespace: selectedRegisterModule })
  selectedRelatedData: any

  @Getter('getCustomValidateField', { namespace: formsModule })
  getCustomValidateField: (key: string) => any

  @Getter('getSelectedEntityName', { namespace: entitiesModule })
  selectedEntityName: string

  mounted() {
    extend('uniqueDNI', {
      validate: (value: string) => {
        return this.validateUniqueDni(value)
      },
      message: () => {
        return ''
      }
    })
  }

  async validateUniqueDni(value: string): Promise<boolean> {
    const dni: string = this.getCustomValidateField(customValidatorTypes.DNI)

    if (value === dni) {
      return true
    }

    const { id } = this.selectedRegister(entity.contacts.customers.alias)
    const data: any = await this.contactsDniValidate({
      params: {
        id: id || this.selectedRelatedData.id,
        dni: value,
        idType: ContactTypeId.CUSTOMER
      }
    })

    return this.checkIfContinueToCustomerssGrid(data[0])
  }

  checkIfContinueToCustomerssGrid(value: any): boolean {
    if (!value || value.length === 0) {
      return true
    }

    const buttons: DialogButton[] = [
      {
        text: this.$t('action_buttons.no') as TranslateResult,
        style: DialogButtonTypes.SECONDARY,
        action: () => goToPreviousPage()
      },
      {
        text: this.$t('action_buttons.yes') as TranslateResult,
        style: DialogButtonTypes.MAIN,
        type: DialogButtonTypes.CLOSE
      }
    ]

    this.showDialog({
      type: DialogTypes.WARNING,
      message: this.$t('components.dialog.dni_validation', {
        contactType: value.type,
        name: value.name
      }) as TranslateResult,
      buttons
    })
    return true
  }
}
</script>
