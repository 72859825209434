import { Module } from 'vuex'
import { getters } from '@/store/modules/invoices/invoicesGetters'
import { actions } from '@/store/modules/invoices/invoicesActions'
import { mutations } from '@/store/modules/invoices/invoicesMutations'
import { InvoicesState } from '@/store/modules/invoices/invoicesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: InvoicesState = {
  invoices: [],
  totalUnbilled: '',
  totalReceivable: '',
  invoiceType: '',
  invoicesGenerated: []
}

const namespaced: boolean = true

export const invoicesModule: Module<InvoicesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
