<template lang="pug">

  DashboardWidgetResumeType(
    :headerIcon="headerIcon"
    :headerTitle="headerTitle"
    :widgetData="widgetData"
    :titleBorderColor="titleBorderColor"
    :showSpinner="showSpinner"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardWidgetResumeType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetResumeType.vue'
 import { vars } from '@/styles/styleVars'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { DashboardResumeItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetResumeType
  }
})
export default class DashboardUserDailyWorkingTimeWidget extends Vue {
  @Action('fetchDashboardUserDailyWorkingTimeResume', { namespace: dashboardWidgetsModule })
  fetchDashboardUserDailyWorkingTimeResumeAction: () => Promise<void>

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showWidgetSpinner: (context: string) => boolean

  @Getter('getDashboardUserDailyWorkingTimeResume', { namespace: dashboardWidgetsModule })
  userDailyWorkingTimeGetter: DashboardResumeItem[]

  widgetData: any = []

  headerIcon = Icons.HOURGLASS

  headerTitle = this.$t('components.dashboard_widget_resume_type.personal_daily_working_time')

  titleBorderColor = vars.green_09

  get showSpinner() {
    return this.showWidgetSpinner(SpinnerDashboardContext.USER_DAILY_WORKING_TIME_RESUME)
  }

  async created() {
    await this.fetchDashboardUserDailyWorkingTimeResumeAction()
    this.widgetData = this.userDailyWorkingTimeGetter
  }
}
</script>
