<template lang="pug">

  div(:class="['total-item', { 'accordion-item': isAccordionType }, item.alias]")
    div(class="total-label")
      span(:class="[getIcon, 'total-icon']")
      div(class="total-title") {{ item.detail }}
    div(class="total-quantity") {{ item.totalAmount }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TotalItem } from '@/store/modules/billing/billingTypes'
import { Icons } from '@/icons/icons'

@Component
export default class BillingTotalItemComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  item!: TotalItem

  @Prop({
    type: Boolean,
    default: false
  })
  isAccordionType!: boolean

  get getIcon() {
    if (this.isAccordionType) {
      return Icons.TAG
    } else {
      switch (this.item.alias) {
        case 'totalInvoicesTaxBase':
          return Icons.COIN
        case 'totalInvoicesTaxes':
          return Icons.MONEY_LOCALIZATION
        case 'totalInvoicesIRPF':
          return Icons.MONEY_BAG
        case 'totalInvoices':
          return Icons.BILL
        case 'totalInvoicesPending':
          return Icons.HAND_SPHERE
        case 'totalBankDrafstPending':
          return Icons.HAND_SPHERE
        case 'totalBankDraftsToCharge':
          return Icons.BILL
        case 'totalInvoicesCharged':
          return Icons.COIN
        case 'totalBankDraftsToPay':
          return Icons.COIN
        case 'totalInvoicesPaid':
          return Icons.BILL
        default:
          return Icons.CIRCLE_O
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.total-item {
  @include flex($justify-content: space-between);
  height: 50px;
  font-family: $corporate-font-bold;
  color: $white-01;
  padding: 0 33px 0 10px;
  margin-bottom: 2px;

  .total-label {
    @include flex;
    opacity: 0.4;
    text-transform: uppercase;

    .total-icon {
      font-size: 30px;
    }

    .total-title {
      font-size: 12px;
      margin: 0 10px;
    }
  }

  .total-quantity {
    @include ellipsis;
    font-size: 20px;
  }

  &.accordion-item {
    background-color: $gray-04;
    color: $corporate-color;

    .total-label {
      opacity: 1;
    }
  }

  &.totalInvoicesTaxBase,
  &.totalInvoicesCharged,
  &.totalBankDraftsToPay {
    background-color: $blue-02;
  }

  &.totalInvoicesTaxes {
    background-color: $blue-03;
  }

  &.totalInvoicesIRPF {
    background-color: $blue-04;
  }

  &.totalInvoices,
  &.totalBankDraftsToCharge,
  &.totalInvoicesPaid {
    background-color: $blue-09;
  }

  &.totalInvoicesPending,
  &.totalBankDrafstPending {
    background-color: $purple-01;
  }
}
</style>
