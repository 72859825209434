<template lang="pug">

  ul(class="pagination")
    div(class="icons-container")
      li(class="pagination-item")
        button(
          type="button"
          :class="[{'inactive': isInFirstPage}, icons.back, 'icon']"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
        )

      li(class="pagination-item")
        button(
          type="button"
          :class="[{'inactive': isInFirstPage}, icons.angleLeft, 'icon']"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        )

    div(class="numbers-container")
      li(v-for="page in pages" class="pagination-item")
        button(
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          :aria-label="`Go to page number ${page.name}`"
        ) {{ formatPage(page.name) }}

    div(class="icons-container")
      li(class="pagination-item")
        button(
          type="button"
          :class="[{'inactive': isInLastPage}, icons.angleRight, 'icon']"
          @click="onClickNextPage"
          :disabled="isInLastPage"
        )

      li(class="pagination-item")
        button(
          type="button"
          :class="[{'inactive': isInLastPage}, icons.advance, 'icon']"
          @click="onClickLastPage"
          :disabled="isInLastPage"
        )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { formatNumber } from '@/helpers/numeric'

@Component
export default class PaginationComponent extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 3
  })
  maxVisibleButtons!: number

  @Prop({
    type: Number,
    required: true
  })
  totalPages!: number

  @Prop({
    type: Number,
    required: true
  })
  currentPage!: number

  icons = {
    angleLeft: Icons.ANGLE_LEFT,
    angleRight: Icons.ANGLE_RIGHT,
    back: Icons.BACK,
    advance: Icons.ADVANCE
  }

  get startPage() {
    if (this.currentPage === 1) {
      return 1
    }

    if (this.currentPage === this.totalPages && this.totalPages >= this.maxVisibleButtons) {
      return this.totalPages - this.maxVisibleButtons + 1
    }
    return this.currentPage - 1
  }

  get endPage() {
    return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
  }

  get pages() {
    const range = []

    for (let i = this.startPage; i <= this.endPage; i++) {
      range.push({
        name: i,
        isDisabled: i === this.currentPage
      })
    }

    return range
  }

  get isInFirstPage() {
    return this.currentPage === 1
  }

  get isInLastPage() {
    return this.currentPage === this.totalPages
  }

  onClickFirstPage() {
    this.$emit('pageChanged', 1)
  }

  onClickPreviousPage() {
    this.$emit('pageChanged', this.currentPage - 1)
  }

  onClickPage(page: any) {
    this.$emit('pageChanged', page)
  }

  onClickNextPage() {
    this.$emit('pageChanged', this.currentPage + 1)
  }

  onClickLastPage() {
    this.$emit('pageChanged', this.totalPages)
  }

  isPageActive(page: any) {
    return this.currentPage === page
  }

  formatPage(numPage: number) {
    return formatNumber(numPage)
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  @include flex($justify-content: space-between);
  @include list;
  margin: var(--pagination-container-margin, 16px 0 6px);
}

.numbers-container {
  @include flex;
}

.icons-container {
  @include flex;
}

.pagination-item button {
  @include flex;
  color: var(--pagination-btn-color, $blue-04);
  width: 24px;
  cursor: pointer;

  &:not(.icon) {
    @include border($width: 4px);
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: bold;
    padding-top: 2px;
    margin: 0 8px;
    border-color: var(--pagination-btn-not-active-border, $corporate-color);
  }

  &:hover:not(.icon):not(.active) {
    @include border($width: 4px);
    border-color: var(--pagination-btn-hover-color, $blue-04);
  }

  &.active {
    @include border($width: 4px);
    color: var(--pagination-btn-active, $white-01);
    border-color: var(--pagination-btn-active-border, $white-01);
  }

  &.icon {
    color: var(--pagination-icon-color, $white-01);
    font-size: 16px;

    &.inactive {
      cursor: auto;
      color: var(--pagination-icon-inactive-color, $blue-04);
    }
  }
}
</style>
