import { ActionTree } from 'vuex'
import { TasksState, tasksMutationTypes } from '@/store/modules/tasks/tasksTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { TasksService } from '@/services/TasksService'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<TasksState, RootState> = {
  async fetchControlSidebarTasksObject({ commit }, payload = { pageNumber: 1, pageSize: 5 }) {
    try {
      const { pageNumber, pageSize } = payload
      const url = `tasks/my/page/${pageNumber}/page_size/${pageSize}`
      const { data } = await new TasksService().getData(url)
      commit(tasksMutationTypes.FETCH_PAGINATED_TASKS, data)
    } catch (error) {}
  },
  async deleteTask({}, idTask: string) {
    try {
      const url = `/task/${idTask}/delete`
      await new MainService().postData(url)
    } catch (error) {}
  },
  async createTask({}, newTaskObject) {
    try {
      const url = 'task/save'
      await new MainService().postData(url, newTaskObject)
    } catch (error) {}
  }
}
