<template lang="pug">

  footer(class="lefebvre-footer-component")

    section(class="content")
      p(
        v-if="lastAccess"
        class="last-access"
      ) {{ lastAccessText }}
      div(:class="['row-1', addLastAccessClass]")
        img(src="@/assets/images/logos/lefebvre-202x40.png" alt="Lefebvre logo" )
        nav(class="social-networks-container")
          ul(class="networks-list")
            li(
              v-for="(network, index) in socialNetworkLinks"
              :key="`${index}-network`"
              class="network-item"
            )
              a(:class="network.icon" class="network-link" :href="network.url" target="_blank")

      div(class="row-2")
        p ©Lefebvre {{ currentYear }}. {{ $t('views.login.copyright_text') }}
        nav(class="footer-links")
          ul(class="footer-links-list")
            li(v-for="(link, index) in footerLinks" :key="`${index}-link`" class="footer-item")
              a(:href="link.url" class="footer-link" target="_blank") {{ $t(link.name) }}

    div(class="border")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { FooterLink } from '@/components/Footer/types/FooterComponentTypes'
import { externalURLS } from '@/router/routes/urlRoutes'
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter } from 'vuex-class'

const authModule = ModuleNamespaces.AUTH

@Component
export default class FooterComponent extends Vue {
  @Getter('getLastAccess', { namespace: authModule })
  lastAccess: string

  footerLinks: FooterLink[] = [
    {
      name: 'views.login.footer.legal_warning',
      url: externalURLS.legal_warning
    },
    {
      name: 'views.login.footer.privacy_policy',
      url: externalURLS.privacy_policy
    },
    {
      name: 'views.login.footer.cookies_policy',
      url: externalURLS.cookies_policy
    }
  ]

  socialNetworkLinks: FooterLink[] = [
    {
      name: 'Linkedin',
      icon: Icons.LINKEDIN,
      url: externalURLS.linkedin
    },
    {
      name: 'Youtube',
      icon: Icons.YOUTUBE,
      url: externalURLS.youtube
    },
    {
      name: 'Facebook',
      icon: Icons.FACEBOOK,
      url: externalURLS.facebook
    },
    {
      name: 'Twitter',
      icon: Icons.TWITTER,
      url: externalURLS.twitter
    }
  ]

  get currentYear(): number {
    const year = new Date().getFullYear()
    return year
  }

  get lastAccessText() {
    return `${this.$t('views.last_access')} ${this.lastAccess}`
  }

  get addLastAccessClass() {
    return this.lastAccess ? 'has-last-access' : ''
  }
}
</script>

<style scoped lang="scss">
$right-position: 118px;

.lefebvre-footer-component {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 2px 9px rgba(113, 113, 113, 0.25);
}
.last-access {
  font-family: $corporate-font-bold;
  color: $corporate-color;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  margin-bottom: 5px;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 15px;
}
.row-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.social-networks-container {
  margin: 12px 0 7px 0;
}
.networks-list {
  display: flex;
  list-style-type: none;
  padding: 0;
}
.network-item:not(:first-of-type) {
  margin-left: 18px;
}

.network-link {
  text-decoration: none;
  font-size: 27px;
}
.network-link::before {
  color: #d50057;
}
.row-2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: MTTMilano, Lato, Arial, sans-serif;
  font-size: 14px;
  color: #001978;
  line-height: 18px;
}
.footer-links-list {
  display: flex;
  list-style-type: none;
  margin-top: 5px;
  margin-left: 6px;
}
.footer-link {
  text-decoration: none;
  color: #001978;
}
.footer-link:hover {
  text-decoration: underline;
}

.footer-item:not(:first-of-type)::before {
  content: '·';
  margin: 0 5px;
}
.border {
  height: 15px;
  width: 100%;
  background: linear-gradient(90deg, #001a7d 0%, #d50057 100%);
}

@media (min-width: 744px) {
  .content {
    padding: 10px 50px;
  }
  .row-2 {
    flex-direction: row;
    justify-content: center;
  }
}

@media (min-width: 745px) {
  .last-access {
    position: absolute;
    top: 10px;
    right: $right-position;
  }
  .row-1 {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 7px;
    &.has-last-access .social-networks-container {
      position: absolute;
      right: $right-position;
      top: 28px;
    }
  }

  .row-2 {
    justify-content: flex-start;
  }
  .social-networks-container {
    margin: 0;
  }
  .footer-links-list {
    margin-top: 0px;
  }
}

@media (min-width: 800px) {
  .last-access {
    top: 20px;
  }
  .row-1.has-last-access .social-networks-container {
    top: 38px;
  }
  .content {
    padding: 20px 90px;
  }
}

@media (min-width: 1030px) {
  .row-1 .social-networks-container {
    position: absolute;
    right: $right-position;
    top: 38px;
  }

  .row-1.has-last-access .social-networks-container {
    top: 56px;
  }
}
</style>
