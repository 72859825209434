<template lang="pug">
div
  SpinnerLayerComponent(v-if="showSpinner" class="spinner-layer")
  div(v-else)
    TabHeaderComponent(
      @saveForm="onSaveForm"
      @closeForm="onCloseForm"
      @removeItem="onRemoveItem"
      :buttons="buttons"
      :title="title"
      isInTab
    )
    FormGeneratorComponent(
      :schema="formSchema"
      :fieldValues="formData"
      :context="context"
    )
</template>

<script lang="ts">
import { ActionBarButton, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Icons } from '@/icons/icons'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ExpedientStage } from '@/store/modules/expedients/expedientsTypes'
import { Court } from '@/store/modules/expedients/expedientsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ExpedientFields } from '@/views/Expedients/types/ExpedientFieldsTypes'
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { Action, Getter } from 'vuex-class'
import { entity } from '@/store/modules/entities/entitiesTypes'

const configurationModule = ModuleNamespaces.CONFIGURATION
const expedientModule = ModuleNamespaces.EXPEDIENTS
const dialogModule = ModuleNamespaces.DIALOG
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const alertsModule = ModuleNamespaces.ALERTS
const formsModule = ModuleNamespaces.FORMS

@Component({
  components: {
    FormGeneratorComponent: () => import('@/components/forms/FormGenerator/FormGeneratorComponent.vue'),
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue'),
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class ExpedientCourtsForm extends Mixins(PermissionsMixin) {
  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  context!: string

  @Prop({
    type: Number,
    required: true
  })
  expedientId!: number

  @Prop({
    default: null
  })
  formValue!: any

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('deleteExpedientCourt', { namespace: expedientModule })
  delete: ({}) => Promise<boolean>

  @Action('fetchExpedientCourt', { namespace: expedientModule })
  fetchExpedientCourt: ({}) => Promise<Court>

  @Action('saveExpedientCourt', { namespace: expedientModule })
  save: ({}) => Promise<{ exceptionCode?: string; exceptionDesc?: string }>

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: ExpedientStage

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  formSchema = []
  data = {}
  showSpinner = true

  get formData() {
    return this.data
  }

  set formData(value: any) {
    this.data = value
  }

  get editMode() {
    return Boolean(this.formValue)
  }

  get buttons(): ActionBarButton[] {
    const buttons = [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        show: this.checkIfFormIsValid(this.context),
        hidden: !this.viewPermission.canSave
      }
    ]
    if (this.editMode) {
      buttons.unshift({
        icon: Icons.REMOVE,
        tooltip: this.$t('action_buttons.remove'),
        class: '',
        action: ActionName.REMOVE,
        show: true,
        hidden: !this.viewPermission.canSave
      })
    }
    return buttons
  }

  async created() {
    await this.getFormData()
    await this.getFormConfiguration()
    this.showSpinner = false
  }

  @Emit()
  onCloseForm() {
    this.formData = {}
  }

  get formSchemaParse() {
    const schema: any = this.getCurrentViewConfiguration(this.context)
    if (this.expedientStageState!.activeStages === 0) {
      const stageField = schema.find((item: any) => item.id === ExpedientFields.STAGE)
      stageField!.hidden = true
    }
    return schema
  }

  async getFormConfiguration() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: this.context,
      context: this.context
    })
    this.formSchema = this.formSchemaParse
    this.checkFormPermissions(this.formSchema, entity.expedients.type)
  }

  async getFormData() {
    if (this.formValue) {
      this.formData = await this.fetchExpedientCourt({
        selectedRegisterId: this.formValue.id
      })
    }
  }

  async onSaveForm() {
    const { publish } = this.formData
    const response = await this.save({
      ...this.formData,
      idExpedient: this.expedientId,
      public: publish || 0
    })

    if (response && response.exceptionCode) {
      this.showDialog({
        type: DialogTypes.ERROR,
        message: response.exceptionDesc
      })
    } else {
      const message = this.editMode
        ? this.$t('components.alerts.courts_edit')
        : this.$t('components.alerts.courts_save')
      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })
    }
    this.onCloseForm()
  }

  onRemoveItem() {
    const { id, name } = this.formData
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.courts_remove', {
        associateRelated: name
      }),
      action: () => this.removeAction(id, name)
    })
  }

  async removeAction(id: string, name: string) {
    const response = await this.delete(id)
    if (response) {
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.courts_remove_success', {
          associateRelated: name
        })
      })
    }
    this.onCloseForm()
  }
}
</script>
<style lang="scss" scoped>
.spinner-layer {
  --spinner-layer-min-height: 470px;
  width: 100%;
}
</style>
