<template lang="pug">
  div(class="grid-container pivot-table-container")
    header(v-if="showHeader" class="grid-header")
      AlertComponent(
        :whereIsRendered="componentWhereIsRenderedAlertComponent"
      )
      LexonBreadcrumbsComponent(:items="breadcrumbsItems")
      v-spacer
      block header
      ActionsBarComponent(
        :buttons="headerButtons"
        @execute-action="executeAction"
      )
    ejs-pivotview(
      id="pivotview"
      ref="pivotview"
      v-bind="gridProps"
    )
    save-dialog(
      :report="dataSourceSettings"
      :showDialog="showDialog"
      :title="dialogHeader"
      :rename="doRename"
      @closeDialog="closeDialog"
      @renameDialog="renameReport"
      @saveDialog="saveReport"
    )
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import GridPivotTablesMixin from '@/mixins/GridPivotTablesMixin.vue'
import TotalsContainerComponent from '@/components/Totals/TotalsContainerComponent.vue'
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Icons } from '@/icons/icons'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { ActionBarButton, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import LexonBreadcrumbsComponent from '@/components/forms/fields/LexonBreadcrumbs/LexonBreadcrumbsComponent.vue'
import { i18n } from '@/plugins/vue-i18n'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import SaveDialog from '@/views/Reports/dialogs/SaveDialog.vue'

const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const dialogModule = ModuleNamespaces.DIALOG

Vue.use(DropDownListPlugin)

@Component({
  components: {
    AlertComponent,
    TotalsContainerComponent,
    ActionsBarComponent,
    LexonBreadcrumbsComponent,
    SaveDialog
  }
})
export default class GridPivotTableComponent extends mixins(GridPivotTablesMixin) {
  @Prop({
    required: true
  })
  dataSourceSettings!: any

  @Prop({
    type: Function
  })
  fetchSettings: (id: any) => void

  @Prop({
    type: Function
  })
  fetchData: (id: any) => void

  @Prop({
    type: Function
  })
  removeItem: (id: any) => void

  @Prop({
    type: Function
  })
  resetItem: () => void

  @Prop({
    type: Function
  })
  fetchList: () => void

  @Prop({
    type: Function
  })
  saveSettings: (item: any) => void

  @Prop({
    type: Boolean,
    default: true
  })
  showHeader!: boolean

  showFieldList: boolean = true
  // height: string = 'auto
  allowCalculatedField: boolean = true
  allowPdfExport: boolean = true
  allowExcelExport: boolean = true
  isPrimary: boolean = true
  showToolbar: boolean = true
  firstListLoad: boolean = true
  sourceSettings: any = {}
  showDialog: boolean = false
  doRename: boolean = false
  dialogHeader: any = ''
  replacers: any = [
    'columns',
    'filters',
    'formatSettings',
    'rows',
    'sortSettings',
    'type',
    'values',
    'valueSortSettings'
  ]

  tooltipTemplate: any =
    `<div class='pivotTooltipTemplateWrap'><span class='pivotTooltipHeader'>` +
    '${columnHeaders}' +
    `:</span ><span class='pivotTooltipValue'>` +
    '${value}' +
    `</span></div>`

  headerButtons: ActionBarButton[] = [
    {
      icon: Icons.CLOSE,
      tooltip: this.$t('action_buttons.close'),
      class: 'red-color',
      action: ActionName.CLOSE,
      show: true
    }
  ]
  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.GRID_PIVOT_TABLE

  @Action('removeRegister', { namespace: selectedRegisterModule }) removeRegisterAction: ({}) => void

  @Action('showDialog', { namespace: dialogModule }) showDialogAction: ({}) => void

  @Action('setFirstLoad', { namespace: ModuleNamespaces.REPORTS }) setFirstLoad: (status: boolean) => void

  @Mutation('FETCH_REPORTS', { namespace: ModuleNamespaces.REPORTS })
  saveReportList: (reports: any) => void

  @Getter('getFirstLoad', { namespace: ModuleNamespaces.REPORTS }) firstLoad: boolean

  @Watch('$route')
  async onRouteChange(to: any) {
    await this.fetchList()
    await this.fetchSettings(to.params.selectedRegisterId)
    await this.changeToolbarProps()
  }

  @Watch('dataSourceSettings')
  async onDatachange() {
    await this.changeToolbarProps()
  }

  @Watch('itemsList')
  async onListChange() {
    await this.changeToolbarProps()
  }

  reportListTemplate() {
    const fields: any = { text: 'name', value: 'id' }
    const itemsList: any = this.itemsList
    const onChange: any = this.loadReport
    let index = 0
    for (let i = 0; i < itemsList.length; i++) {
      if (this.$route.params.selectedRegisterId === itemsList[i].id) {
        index = i
      }
    }
    return {
      template: Vue.component('listTemplate', {
        template: `<ejs-dropdownlist id='reportsDropdownlist'
                                       :dataSource='items'
                                       :fields='selectedFields'
                                       :change="loadRepo"
                                       :index="selectedIndex">
          </ejs-dropdownlist>`,
        props: {},
        data() {
          return {
            selectedFields: fields,
            items: itemsList,
            selectedIndex: index
          }
        },
        methods: {
          loadRepo(e: any) {
            onChange(e.itemData)
          }
        }
      })
    }
  }

  exportListTemplate() {
    const exportPdf = this.exportToPDF
    const exportExcel = this.exportToExcel
    const exportCSV = this.exportToCSV
    return {
      template: Vue.component('exportListTemplate', {
        template: `<div class="export-menu e-menu-wrapper e-lib e-keyboard" @mouseenter="showOptions(true)" @mouseleave="showOptions(false)">
            <ul id="pivotviewexport_menu" class="e-control e-menu e-lib e-menu-parent" role="menubar" tabindex="-1">
              <li @click="showOptions(true)" class="e-menu-item e-menu-caret-icon" :class="show ? 'e-selected' : ''" role="menuitem" aria-label="" aria-haspopup="true" aria-expanded="true">
                <span class="e-menu-icon lf-icon-send e-icons">
                </span>
                <span class="e-icons e-caret">
                </span>
              </li>
            </ul>
            <div v-if="show" class=" e-menu-popup e-lib e-popup e-control e-keyboard e-popup-open" id="export-options" style="left: 574px; top: 50px; z-index: 1000002;">
              <ul class="e-menu-parent e-ul " role="menu" tabindex="0">
                <li v-for="item in items" :key="item.id" @click="onClick(item)" class="e-menu-item" id="pivotviewgrandtotal" role="menuitem" data-value="null" tabindex="-1" aria-label="Show grand totals">
                  <span class="e-menu-icon e-icons" :class="item.icon">
                  </span>
                  <span class="e-label">
                    {{item.label}}
                  </span>
                </li>
              </ul>
            </div>
          </div>`,
        props: {},
        data() {
          return {
            show: false,
            items: [
              { icon: Icons.PDF, label: 'PDF', id: '1' },
              { icon: Icons.EXCEL, label: 'Excel', id: '2' },
              { icon: Icons.CSV, label: 'CSV', id: '3' }
            ],
            fields: { text: 'Type', iconCss: 'Class', value: 'Id' }
          }
        },
        methods: {
          onClick(e: any) {
            switch (e.label) {
              case 'PDF':
                return exportPdf()
              case 'Excel':
                return exportExcel()
              case 'CSV':
                return exportCSV()
            }
          },
          showOptions(e: boolean) {
            this.show = e
          }
        }
      })
    }
  }

  get breadcrumbsItems() {
    return [
      {
        text: URLS.REPORTS,
        url: `/${URLS.REPORTS}`
      },
      {
        text: this.dataSourceSettings.name
      }
    ]
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
    }
  }

  close() {
    this.$router.push(`/${URLS.REPORTS}`)
  }

  changeToolbarProps() {
    let obj: any = document.getElementById('pivotview')
    if (obj) {
      obj = obj.ej2_instances[0]
      obj.toolbarModule.currentReport = this.dataSourceSettings.name
      if (obj.toolbarModule.reportList) {
        obj.toolbarModule.reportList.itemData = this.dataSourceSettings.name
      }
      if (this.dataSourceSettings.idType === '1' || this.dataSourceSettings.idType === 1) {
        obj.toolbarModule.toolbar.items[9].disabled = false
        obj.toolbarModule.toolbar.items[6].disabled = false
        obj.toolbarModule.toolbar.items[8].disabled = false
      } else {
        obj.toolbarModule.toolbar.items[9].disabled = true
        obj.toolbarModule.toolbar.items[6].disabled = true
        obj.toolbarModule.toolbar.items[8].disabled = true
      }
      delete obj.toolbarModule.toolbar.items[11]
      obj.toolbarModule.toolbar.items.splice(11, 0, {
        template: this.reportListTemplate,
        align: 'Center'
      })
    }
  }

  openSaveDialog() {
    this.dialogHeader = this.$t('components.dialog.save_report')
    this.doRename = false
    this.showDialog = true
  }

  openRenameDialog() {
    this.dialogHeader = this.$t('components.dialog.rename_report')
    this.doRename = true
    this.showDialog = true
  }

  closeDialog() {
    this.showDialog = false
  }

  async saveReport(args: any) {
    if (args.saveAs) {
      this.setFirstLoad(false)
    } else {
      args.name = this.dataSourceSettings.name
      args.reportName = args.name
    }
    if (!this.firstLoad) {
      let item: any = {}
      let obj: any = document.getElementById('pivotview')
      if (obj) {
        obj = JSON.parse(obj.ej2_instances[0].getPersistData()).dataSourceSettings
        delete obj.dataSource
        item = { ...this.dataSourceSettings, config: { ...obj }, ...args }
      } else {
        item = { ...this.dataSourceSettings, ...args }
      }
      if (item.report) {
        item.report = JSON.parse(item.report)
        item.config = {
          ...item.report.dataSourceSettings
        }
      }
      if (item.idType === '0' || item.idType === 0) {
        item.idType = 1
        item.idReportOrigin = item.id
        delete item.id
      } else {
        item.idType = 1
        if (args.saveAs) {
          item.idReportOrigin = item.id
          delete item.id
        }
      }
      item.config.dataSource = []
      delete item.report
      delete item.saveAs
      this.closeDialog()
      await this.saveSettings(item)
      await this.changeToolbarProps()
    }
    this.setFirstLoad(false)
  }

  async fetchReport(args: any) {
    let reportCollection: any[] = []
    const reportList: any = []
    if (this.itemsList) {
      reportCollection = this.itemsList
    }
    reportCollection.map((item: any) => {
      reportList.push(item.name)
    })
    args.reportName = reportList
    await this.changeToolbarProps()
  }

  async loadReport(args: any) {
    this.$router.push(`/${URLS.REPORTS}/${args.id}`)
  }

  async openRemoveReport() {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: this.dataSourceSettings.name,
        text: this.$t('components.dialog.reports_literal')
      }),
      action: this.removeReport
    })
  }

  async removeReport() {
    this.removeRegisterAction({
      parentCategory: this.menuParentCategoryName,
      idSelectedRegister: this.dataSourceSettings.id,
      goToURL: this.$route.name,
      name: this.dataSourceSettings.name,
      dialogText: i18n.t('components.dialog.reports_literal')
    })
    let reportCollection: any[] = []
    if (this.itemsList) {
      reportCollection = this.itemsList
    }
    if (reportCollection.length > 0) {
      this.$router.push(`/${URLS.REPORTS}/${reportCollection[0].id}`)
    } else {
      this.$router.push(`/${URLS.REPORTS}`)
    }
    for (let i = 0; i < reportCollection.length; i++) {
      if (reportCollection[i].reportName === this.dataSourceSettings.reportName) {
        reportCollection.splice(i, 1)
      }
    }
  }

  async renameReport(args: any) {
    let reportCollection: any[] = []
    if (this.itemsList) {
      reportCollection = this.itemsList
    }
    reportCollection.map((item: any) => {
      if (args.name === item.reportName) {
        item.reportName = args.name
      }
    })
    if (this.itemsList) {
      const report = {
        ...this.dataSourceSettings,
        name: args.name,
        reportName: args.name,
        description: args.description,
        sourceData: []
      }
      report.config.dataSource = []
      this.closeDialog()
      await this.saveSettings(report)
      await this.fetchList()
      await this.fetchSettings(report.id)
      await this.changeToolbarProps()
    }
  }

  async newReport() {
    const element: any = document.getElementById('pivotview')
    if (element !== null) {
      const pivotGridObj = element.ej2_instances[0]
      pivotGridObj.setProperties(
        {
          dataSourceSettings: {
            columns: [],
            rows: [],
            values: [],
            filters: []
          }
        },
        false
      )
    }
  }

  async beforeToolbarRender(args: any) {
    args.customToolbar.splice(2, 0, {
      template: this.exportListTemplate,
      align: 'left'
    })

    args.customToolbar[0].prefixIcon = Icons.TABLE_DISPLAY
    args.customToolbar[1].prefixIcon = Icons.ANALYSIS
    args.customToolbar[1].suffixIcon = Icons.ANALYSIS
    args.customToolbar[3].prefixIcon = Icons.TABLE_DISPLAY_1
    args.customToolbar[3].suffixIcon = Icons.TABLE_DISPLAY_1
    args.customToolbar[4].prefixIcon = Icons.TABLE_DISPLAY_2
    args.customToolbar[4].suffixIcon = Icons.TABLE_DISPLAY_2
    args.customToolbar[5].align = 'Center'
    args.customToolbar[5].visible = false
    args.customToolbar[6].align = 'Right'
    args.customToolbar[6].prefixIcon = Icons.SAVE
    args.customToolbar[7].align = 'Right'
    args.customToolbar[7].prefixIcon = Icons.SAVE_AS
    args.customToolbar[7].click = this.openSaveDialog.bind(this)
    args.customToolbar[8].align = 'Right'
    args.customToolbar[8].prefixIcon = Icons.RENAME
    args.customToolbar[8].click = this.openRenameDialog.bind(this)
    args.customToolbar[9].align = 'Right'
    args.customToolbar[9].prefixIcon = Icons.REMOVE
    args.customToolbar[9].click = this.openRemoveReport.bind(this)
    args.customToolbar[10].prefixIcon = Icons.CONFIGURATION
    args.customToolbar[10].align = 'Right'

    args.customToolbar.splice(11, 0, {
      template: this.reportListTemplate,
      align: 'Center'
    })

    if (this.dataSourceSettings.idType === '1' || this.dataSourceSettings.idType === 1) {
      args.customToolbar[9].disabled = false
      args.customToolbar[6].disabled = false
      args.customToolbar[8].disabled = false
    } else {
      args.customToolbar[9].disabled = true
      args.customToolbar[6].disabled = true
      args.customToolbar[8].disabled = true
    }
  }

  get gridProps(): object {
    return {
      ...this.bothGridsSharedProps,
      dataSourceSettings: this.dataSourceSettings.config,
      // height: this.height,
      showToolbar: this.showToolbar,
      toolbar: this.toolbarOptions,
      showFieldList: this.showFieldList,
      saveReport: this.saveReport,
      loadReport: this.loadReport,
      fetchReport: this.fetchReport,
      renameReport: this.renameReport,
      removeReport: this.removeReport,
      newReport: this.newReport,
      chartSettings: this.chartSettings,
      chartTypes: this.chartTypes,
      toolbarRender: this.beforeToolbarRender,
      spinnerTemplate: this.spinnerTemplate,
      locale: this.locale,
      displayOption: this.displayOption,
      allowPdfExport: this.allowPdfExport,
      allowExcelExport: this.allowExcelExport,
      allowNumberFormatting: true
    }
  }

  destroyed() {
    this.resetItem()
  }
}
</script>

<style lang="scss">
.grid-container {
  height: 100%;
  position: relative;

  #pivotview {
    height: 100%;
    #pivotview_grid {
      border: 1px solid $blue-01 !important;
      .e-pivotview {
        .e-valuescontent {
          opacity: 1 !important;
        }
      }

      .e-gridheader {
        color: $white-01;
        .e-movableheader {
          .e-columnheader {
            &:first-child {
              th {
                background-color: $blue-03 !important;
                div,
                span {
                  color: $white-01 !important;
                  font-weight: bold;
                }
              }
            }

            th {
              background-color: $blue-07;

              &.e-stackedheadercell {
                background-color: $blue-06;
              }
              div,
              span {
                color: $blue-01;
                font-weight: bold;
                font-size: 1em;
              }

              &.e-gtot {
                background-color: $blue-01;
                div,
                span {
                  color: $white-01;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }

      .e-gridcontent {
        height: calc(100% - 100px);
        .e-content {
          height: 100% !important;
          display: block !important;
          table {
            /*height: 100%;*/

            tr {
              .e-gtot {
                min-height: 55px;
                background-color: $blue-01;
                &:hover {
                  td {
                    background-color: $blue-01;
                    span {
                      color: $white-01;
                    }
                  }
                }

                span {
                  color: $white-01;
                  font-weight: bold;
                  text-transform: uppercase;
                }
              }
            }
          }

          .e-frozencontent {
            td {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              min-height: 30px;
              background-color: $blue-03;

              &:hover {
                td {
                  background-color: $blue-05;
                  span {
                    color: $white-01;
                  }
                }

                .e-icons {
                  &:before,
                  &:after {
                    color: $blue-01;
                  }
                }
              }

              .e-cellvalue {
                color: $white-01;
                height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-top: 10px;
              }

              .e-icons {
                width: auto !important;

                &:before,
                &:after {
                  color: $white-01;
                }
              }
            }
          }

          .e-movablecontent {
            table {
              tr {
                td {
                  &.e-gtot {
                    background-color: $blue-01;
                    opacity: 1 !important;
                    div,
                    span {
                      color: $white-01;
                    }
                  }
                }

                &.e-frozenhover {
                  .e-gtot {
                    opacity: 0.88 !important;
                    span {
                      color: $white-01 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .e-pivot-toolbar {
      border-top: 5px solid $blue-01;
      border-left: 1px solid $blue-01;
      border-right: 1px solid $blue-01;
      min-height: 65px;
      overflow: visible;
      .e-toolbar-items {
        min-height: 64px;
        background: $white-01 !important;

        .e-toolbar-item {
          .e-btn {
            height: 35px;
            width: 40px !important;

            &:hover {
              border-color: rgba(0, 25, 120, 0.2) !important;
            }

            .e-icons {
              font-size: 1.2em;
              color: $blue-01;
            }
          }

          .e-menu-icon {
            font-size: 1.5em;
            color: $blue-01;
          }

          .e-caret {
            color: $blue-01;
          }
        }

        .e-toolbar-left {
          .e-btn {
            &:hover {
              background: $white-01 !important;
            }
          }
          .e-menu-item {
            &.e-focused,
            &.e-selected {
              background: $white-01 !important;
            }
          }

          .e-toolbar-item {
            .e-tbar-btn {
              background: $white-01;
            }
            .e-menu-wrapper {
              margin-top: -5px;
            }
          }
        }

        .e-toolbar-right {
          background-color: $gray-03;
          border-radius: 50px;
          right: 10px;
          height: 44px;
          margin: 10px 0px;
          padding-left: 3px;
          .e-toolbar-item {
            &.e-overlay {
              background: transparent !important;
            }

            .e-btn {
              border-radius: 50px;
              background-color: $blue-01;
              border: 3px solid $gray-03;
              height: 40px;
              width: 40px !important;

              &:hover {
                border-color: $blue-05 !important;
                .e-icons {
                  color: $blue-05;
                }
              }

              .e-icons {
                color: $white-01;
                margin-top: -4.5px;
              }
            }

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }

        .e-toolbar-center {
          min-height: 35px;
          padding-top: 12px;
          @media (min-width: 1800px) {
            margin-left: 58% !important;
          }

          .e-control-wrapper {
            min-width: 360px !important;
            padding: 5px 10px;
            border: 0.5px solid $gray-03 !important;
          }
        }
      }
    }

    .e-pivotchart {
      background-color: $white-01;
    }
  }

  .e-pivotfieldlist-container {
    .e-title-content {
      color: $blue-01;
      font-size: 1.2em;
    }

    .e-value-field-settings {
      min-width: 350px;

      .e-dlg-header {
        color: $blue-01;
        font-size: 1.4em;
      }

      .e-dlg-closeicon-btn {
        span {
          color: $blue-01;
        }
      }

      .e-dlg-content {
        @include scroll-styles();

        .e-field-name-text-wrapper,
        .e-type-option-text,
        .e-base-field-option-text,
        .e-base-item-option-text {
          width: 100%;
          color: $blue-01;
          font-weight: 600;
          font-size: 1.2em;
        }

        .e-type-option-wrapper,
        .e-base-field-option-wrapper,
        .e-base-item-option-wrapper {
          padding-top: 8px !important;
        }

        input {
          width: calc(100% - 20px) !important;
          border: 1px solid $gray-02;
          padding: 9px 10px;
          float: left;
          margin-top: 4px;

          &:focus {
            border-color: $blue-01 !important;
          }
        }

        .e-input-group {
          border: none;
        }

        .e-input-group-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        .e-control-wrapper {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }

    .e-field-header,
    .e-field-header-container,
    .e-axis-container {
      background-color: $blue-04 !important;
      color: $white-01 !important;
      .e-icons {
        color: $white-01 !important;
        &.e-selected {
          &:before {
            color: $blue-01 !important;
          }
        }
      }
    }

    .e-icons {
      &.e-drag,
      &.e-sort,
      &.e-pv-filter,
      &.e-dropdown-icon,
      &.e-remove {
        color: $blue-01 !important;
        &:hover {
          opacity: 1;
        }
      }
    }

    .e-field-list-tree-outer-div {
      @include scroll-styles();
    }

    .e-frame.e-check {
      background-color: $blue-01 !important;
      color: $white-01 !important;
    }

    .e-layout-footer .e-cancel-btn {
      max-width: 100%;
    }

    .e-footer-content {
      border-top: none !important;
      .e-btn {
        color: $blue-01 !important;
        border: 2px solid $blue-01 !important;
        background-color: $white-01;
        width: 95px;
        height: 40px;
        font-weight: 400;
        float: right;

        &:hover {
          color: $white-01 !important;
          border: 2px solid $blue-01 !important;
          background-color: $blue-01;
        }
      }
    }

    .e-member-editor-dialog {
      .e-dlg-header-content {
        .e-dlg-header {
          color: $blue-01;
          font-size: 22px;
        }

        .e-dlg-closeicon-btn {
          display: none;
        }
      }

      .e-dlg-content {
        .e-tab {
          .e-tab-header {
            .e-indicator {
              background: $blue-01 !important;
            }

            .e-tab-text {
              color: $blue-01 !important;
            }
          }
        }

        .e-selected-tab {
          .e-control-wrapper {
            border: 1px solid $gray-02;
            padding: 5px 10px;
            input {
              border: none !important;
            }
          }
        }
        .e-editor-search-container {
          .e-control-wrapper {
            border: 1px solid $blue-01;
            padding: 0 0 0 10px;
            input {
              border: none !important;
            }
          }

          .e-filter-sort {
            border: 1px solid $blue-01;
            .e-member-sort {
              color: $blue-01;
              &.e-active,
              &:active,
              &:focus,
              &:hover {
                box-shadow: none !important;
                color: $white-01;
                background-color: $blue-01;
              }
            }
          }
        }

        .e-filter-div-content {
          @include scroll-styles();

          .e-filter-option-wrapper-1,
          .e-filter-option-wrapper-2 {
            > div {
              .e-input-group {
                width: calc(100% - 20px) !important;
                border: 1px solid $gray-02 !important;
                padding: 5px 10px;
              }
            }
          }
        }

        .e-list-parent,
        .e-member-editor-container-outer-div {
          @include scroll-styles();
        }
      }

      .e-footer-content {
        .e-clear-filter-button {
          display: none;
        }

        .e-btn {
          color: $gray-02 !important;
          border: 2px solid $gray-02 !important;
          background-color: $white-01;
          width: 95px;
          height: 40px;
          font-weight: 400;
          float: right;

          &:hover,
          &:active,
          &:focus {
            color: $blue-01 !important;
            border: 2px solid $blue-01 !important;
            background-color: $white-01;
          }
        }
      }

      .e-toolbar .e-toolbar-items {
        margin-left: 0px;
      }
    }
  }
}

.e-dlg-overlay {
  background-color: $white-01;
}

.e-reportList-drop {
  border: 1px solid $blue-01;
  padding: 4px !important;
  @include scroll-styles();
}

.e-menu-popup {
  .e-menu-parent {
    &.e-ul {
      border: 1px solid $blue-01;
      padding: 4px !important;
      min-width: 150px;
      font-family: $corporate-font-bold;

      .e-menu-item {
        color: $blue-01;
        font-weight: 500;
        font-size: 14px;

        .e-label,
        .e-menu-icon {
          color: $blue-01;
          font-weight: 500;
          font-size: 14px;
        }

        &.e-separator {
          border-bottom-color: $blue-01;
        }

        &:hover {
          background-color: $blue-01;
          color: $white-01;
          border-bottom: 1px solid $white-01;
          box-shadow: 0 1px 0 $blue-01;
          padding-bottom: 1px;

          .e-label,
          .e-menu-icon {
            color: $white-01 !important;
          }

          .e-checkbox-wrapper {
            .e-frame {
              border-color: $white-01;
            }
          }
        }

        .e-checkbox-wrapper {
          .e-frame {
            border-color: $blue-01;

            &.e-check {
              background-color: $blue-01;
            }
          }
        }
      }
    }
  }
}

.e-tooltip-wrap {
  background-color: $blue-01 !important;
  .pivotTooltipHeader {
    background-color: $blue-01 !important;
  }
}

.e-pivotview div.e-cellvalue {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.e-pivotview-report-dialog,
.e-pivot-error-dialog {
  min-height: 300px;
  border: 2px solid $blue-01;

  .e-dlg-header-content {
    .e-btn {
      span {
        color: $blue-01;
      }
    }

    .e-dlg-header {
      color: $blue-01;
      font-size: 1.9em;
      font-weight: 500;
    }
  }

  .e-dlg-content {
    .e-report-outer {
      .e-pivotview-report-label {
        width: 100%;
        color: $blue-01;
        margin-left: 10px;
        font-weight: 600;
        font-size: 1.05em;
      }

      .e-pivotview-report-input {
        width: calc(100% - 20px) !important;
        border: 1px solid $gray-02;
        padding: 9px 10px;
        float: left;
        margin-top: 4px;

        &:focus {
          border-color: $blue-01 !important;
        }
      }
    }
  }

  .e-footer-content {
    border-top: none !important;
    .e-btn {
      color: $gray-02 !important;
      border: 2px solid $gray-02 !important;
      background-color: $white-01;
      width: 95px;
      height: 40px;
      font-weight: 400;
      float: right;

      &:hover {
        color: $blue-01 !important;
        border: 2px solid $blue-01 !important;
        background-color: $white-01;
      }
    }
  }
}

.e-contextmenu-wrapper {
  #pivotview_PivotFieldListvalueFieldStringContextMenu {
    @include scroll-styles();
    .e-menu-item {
      padding: 0 10px !important;
    }
  }
  .e-menu-item {
    padding: 0 10px !important;
  }
}

.export-menu {
  position: relative;
  #export-options {
    background-color: $white-01;
    left: 0 !important;
    top: 40px !important;
  }
}

#pivotviewexport_menu {
  border-left: 1px solid $blue-01;
  height: 46px;
  margin-top: 6px;

  .e-label {
    margin-left: 3px;
  }

  li {
    &:nth-child(2) {
      display: none;
    }
  }
}
</style>
