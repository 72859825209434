<template lang="pug">
div
  ExpedientCourtsGrid(
    ref="grid"
    :title="title"
    :columns="gridColumns"
    :context="context"
    :listName="listName"
    :expedientId="expedientId"
    @on-open-form="handleOpenForm"
    v-show="!openForm"
  )
  ExpedientCourtsForm(
    :title="formTitle"
    :expedientId="expedientId"
    :context="contextForm"
    :formValue="selectedRegister"
    @on-close-form="onCloseForm"
    v-if="openForm&& contextForm === defaultForm"
  )
  ContactsFormComponent(
    :title="formTitle"
    :context="contextForm"
    :contactType="contactType"
    :contactId="contactId"
    :messages="messages"
    @on-close-form="onCloseForm"
    v-if="openForm && contextForm !== defaultForm"
  )
</template>
<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ExpedientCourtsGrid from './components/ExpedientCourtsGrid.vue'
import ExpedientCourtsForm from './components/ExpedientCourtsForm.vue'
import { ConfigurationAlias } from '@/store/modules/configuration/configurationTypes'
import { Court } from '@/store/modules/expedients/expedientsTypes'
import ContactsFormComponent from '@/components/contacts/forms/ContactsFormComponent.vue'

@Component({
  components: {
    ExpedientCourtsGrid,
    ExpedientCourtsForm,
    ContactsFormComponent
  }
})
export default class ExpedientCourtsComponent extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: String,
    default: ''
  })
  columns!: string

  @Prop({
    type: Number,
    required: true
  })
  expedientId!: number

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  defaultForm = ConfigurationAlias.EXPEDIENT_COURTS_FORM
  contextForm: string = ConfigurationAlias.EXPEDIENT_COURTS_FORM
  context = ConfigurationAlias.EXPEDIENT_COURTS
  openForm = false
  gridColumns = this.columns
  selectedRegister: Court | null = null
  contactType = 'courts'
  contactId = 0
  messages = {
    edit: this.$t('components.alerts.courts_edit')
  }

  $refs!: {
    grid: InstanceType<typeof ExpedientCourtsGrid>
  }

  get formTitle() {
    if (this.selectedRegister) {
      return this.$t('forms.courts.edit_title', { name: this.selectedRegister.name })
    }
    return this.title
  }

  handleOpenForm({ selectedRegister, formType }: { selectedRegister: Court | null; formType: string }) {
    this.$emit('openForm')
    this.$emit('disabledTabs')
    this.selectedRegister = selectedRegister
    if (selectedRegister && selectedRegister.idCourt) {
      this.contactId = Number(selectedRegister!.idCourt)
    }
    this.contextForm = formType
    this.openForm = true
  }

  async onCloseForm() {
    this.$emit('closeForm')
    this.$emit('enabledTabs')
    this.openForm = false
    this.$refs!.grid.refresh()
  }
}
</script>
