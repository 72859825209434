<template lang="pug">

  div(class="toolbar")
    ul(class="toolbar-list")
      //- items that triggers ControlSidebarComponent
      li(
          v-for="(item, index) in getItems.controlSidebarTriggerItems"
          :key="`triggerItem-${index}`"
          :class="['toolbar-item', 'open-control-sidebar', getClasses(item.name)]"
          @click.stop="openControlSidebar(item.name)"
        )
        a(class="icon-style" v-show="item.icon")
          span(:class="[item.icon,'open-control-sidebar']")
          span(v-show="item.name === 'user'" :class="['user-name', 'open-control-sidebar']") {{ userName }}
        div(v-if="item.name === 'alerts'" :class="['number-of-items-info', 'open-control-sidebar']") {{ alertsObject.pagination.totalRecs || '0' }}
        div(v-if="item.name === 'tasks'" :class="['number-of-items-info', 'open-control-sidebar']") {{ tasksObject.pagination.totalRecs || '0' }}

      //- Other icons
      div(class="other-links")
        li(
            v-for="(item, index) in getItems.others"
            :key="`other-${index}`"
            class="toolbar-item"
            @click="makeAction(item.name)"
          )
          span(class="icon-style" :class="item.icon")

    SupportServiceDialogComponent(
      :showDialog="showSupportServiceDialog"
      @hide-dialog="toggleSupportServiceDialog"
    )

</template>

<script lang="ts">
import { Component, Emit, Watch, Mixins } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ControlSidebarAlertsObject } from '@/store/modules/appointments/appointmentsTypes'
import { ControlSidebarTasksObject } from '@/store/modules/tasks/tasksTypes'
import { ItemsNames } from '@/components/Navigation/AppBarToolbarItems/types/AppBarToolbarItemsComponentTypes'
import SupportServiceDialogComponent from '@/components/SupportServiceDialog/SupportServiceDialogComponent.vue'
import { hasPermission } from '@/helpers/security'
import { SecurityCapacities, SecurityVerbs } from '@/store/modules/security/securityTypes'
import { NavigationItems } from '@/components/Navigation/NavigationTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { resetTracker } from '@/plugins/tracker'

const authModule = ModuleNamespaces.AUTH
const appointmetsModule = ModuleNamespaces.APPOINTMENTS
const tasksModule = ModuleNamespaces.TASKS

@Component({
  components: {
    SupportServiceDialogComponent
  }
})
export default class AppBarToolbarItemsComponent extends Mixins(PermissionsMixin) {
  @Getter('getUserName', { namespace: authModule }) userName: string
  @Getter('getNavisionId', { namespace: authModule }) navisionId: string
  @Getter('getControlSidebarAlertsObject', { namespace: appointmetsModule }) alertsObject: ControlSidebarAlertsObject
  @Getter('getControlSidebarTasksObject', { namespace: tasksModule }) tasksObject: ControlSidebarTasksObject
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean

  @Action('logout', { namespace: authModule }) logout: () => {}

  currentUrl: string = ''

  showSupportServiceDialog: boolean = false

  get items() {
    return {
      controlSidebarTriggerItems: [
        {
          name: ItemsNames.TASKS,
          icon: Icons.TASKS
        },
        ...(this.userHasPermissionToViewAgenda
          ? [
              {
                name: ItemsNames.ALERTS,
                icon: Icons.ALERT
              }
            ]
          : []),
        {
          name: ItemsNames.USER,
          icon: Icons.USER
        }
      ],
      others: [
        ...(!this.isPortalUser
          ? [
              {
                name: ItemsNames.HELP,
                icon: Icons.HELP
              }
            ]
          : []),
        {
          name: ItemsNames.CLOSE,
          icon: Icons.CLOSE_SESSION
        }
      ]
    }
  }

  @Watch('$route')
  encodeUrl() {
    this.currentUrl = encodeURIComponent(document.location.href)
  }

  @Emit()
  openControlSidebar(itemName: string) {
    return itemName
  }

  get getItems() {
    const filteredItems = JSON.parse(JSON.stringify(this.items))

    if (!hasPermission(SecurityVerbs.VIEW, SecurityCapacities.TASK)) {
      filteredItems.controlSidebarTriggerItems = filteredItems.controlSidebarTriggerItems.filter(
        (item: any) => item.name !== NavigationItems.TASKS
      )
    }
    if (!hasPermission(SecurityVerbs.VIEW, SecurityCapacities.ALERTS)) {
      filteredItems.controlSidebarTriggerItems = filteredItems.controlSidebarTriggerItems.filter(
        (item: any) => item.name !== NavigationItems.ALERTS
      )
    }

    return filteredItems
  }

  makeAction(item: string): void {
    if (item === ItemsNames.CLOSE) {
      resetTracker()
      this.logout()
    } else if (item === ItemsNames.HELP) {
      this.toggleSupportServiceDialog()
    }
  }

  getClasses(itemName: string) {
    return itemName === 'alerts' || itemName === 'tasks' ? 'number-of-items-container' : ''
  }

  toggleSupportServiceDialog() {
    this.showSupportServiceDialog = !this.showSupportServiceDialog
  }
}
</script>

<style lang="scss" scoped>
.icon-style {
  @include icon-style;
}

.toolbar-list {
  @include flex;
  height: $app-bar-height;
  list-style-type: none;
  padding: 0;

  .toolbar-item {
    @include flex;
    height: $app-bar-height;
    cursor: pointer;
  }

  .number-of-items-container {
    position: relative;

    .number-of-items-info {
      @include flex;
      @include borders($width: 2px, $color: $corporate-color);
      width: 48px;
      height: 26px;
      font-size: 14px;
      font-weight: bold;
      color: $corporate-color;
      border-radius: 12px;
    }
  }

  .icon-style.fa-user-circle {
    padding-right: 4px;
  }

  .other-links {
    @include flex;
  }

  .user-name {
    display: inline-block;
    font-family: $corporate-font-bold;
    font-size: 12px;
    color: $corporate-color;
    margin-left: 5px;
    padding-top: 5px;
    text-transform: uppercase;
  }
}

@include untilDesktop {
  .toolbar-list {
    .user-name {
      display: none;
    }
  }
}

@include untilTablet {
  .toolbar-list {
    margin-right: 0;
  }
}

@include tabletAndMore {
  .toolbar-list {
    margin-right: 0;
  }
}

@include desktopLarge {
  .other-links {
    margin: 0 17px 0 25px;
  }
}
</style>
