<template lang="pug">

  div(class="dashboard-widget-selector-container")
    button(
      :title="this.texts.tooltip"
      :class="['configuration-icon', configurationIcon, { 'disabled': selectConfigurationButtonDisabled }]"
      @click="openContextMenu"
    )
    ejs-contextmenu(
      id="widget-selector-contextmenu"
      class="widget-selector-contextmenu"
      :items="clonedWidgets"
      :beforeOpen="beforeOpen"
      :beforeClose="beforeClose"
    )

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { EJ2Instance } from '@syncfusion/ej2-kanban'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { createCheckBox } from '@syncfusion/ej2-buttons'
import { createElement, closest } from '@syncfusion/ej2-base'
import { BeforeOpenCloseMenuEventArgs } from '@syncfusion/ej2-vue-navigations'
import { DashboardWidgetItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { DashboardWidgetStatus } from '@/components/widgets/DashboardWidgets/types/DashboardWidgetTypes'

@Component
export default class DashboardWidgetSelector extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  widgets!: DashboardWidgetItem[]

  @Prop({
    type: Boolean
  })
  allowDraggingProp!: boolean

  texts = {
    applyBtn: this.$t('action_buttons.apply') as string,
    blockDragAndDropButton: this.$t('components.dashboard_widget_selector.block_button') as string,
    unblockDragAndDropButton: this.$t('components.dashboard_widget_selector.unblock_button') as string,
    cancelBtn: this.$t('action_buttons.cancel') as string,
    selectAllCheckbox: this.$t('components.dashboard_widget_selector.default') as string,
    tooltip: this.$t('components.dashboard_widget_selector.title') as string
  }

  clonedWidgets: DashboardWidgetItem[] | null = null

  selectedOptions: number[] = []

  selectConfigurationButtonDisabled = true

  configurationIcon = Icons.CONFIGURATION

  @Watch('widgets', { immediate: true })
  setclonedWidgets(widgets: DashboardWidgetItem[]) {
    if (widgets && widgets.length) {
      const clonedWidgets: DashboardWidgetItem[] = JSON.parse(JSON.stringify(widgets))
      const widgetsWithPermission = clonedWidgets.filter(
        (widget) => widget.visible !== DashboardWidgetStatus.WIDGET_WITHOUT_PERMISSION
      )
      this.clonedWidgets = widgetsWithPermission.sort((a, b) => (a.text > b.text ? 1 : -1))
      this.selectConfigurationButtonDisabled = false
    }
  }

  get computedButtonText() {
    return this.allowDraggingProp ? this.texts.blockDragAndDropButton : this.texts.unblockDragAndDropButton
  }

  async blockDragAndDrop() {
    this.$emit('blockDragAndDrop', !this.allowDraggingProp)
    await this.$nextTick()
    this.checkBlockButtonText()
  }

  checkBlockButtonText() {
    const blockButton = document.querySelector('.block-drag-and-drop-container .block-btn')
    if (blockButton) {
      blockButton.innerHTML = this.computedButtonText
    }
  }

  openContextMenu(event: PointerEvent) {
    const btn = event.target as HTMLElement
    const rect = btn.getBoundingClientRect()
    const contextMenu = document.getElementById('widget-selector-contextmenu')

    if (null !== contextMenu) {
      ;(contextMenu as EJ2Instance).ej2_instances[0].open(rect.top + 40, rect.left + 40)
    }
  }

  async saveSelectedItems() {
    this.$emit('saveSelectedWidgets', this.widgets)
  }

  selectAll() {
    const checkBoxAll = document.getElementById('select-all-widgets')
    let checked = false
    if (checkBoxAll) {
      checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')!.classList.toggle('e-check')
      checked = checkBoxAll.querySelector('.e-checkbox-wrapper .e-check') !== null
    }
    const checkBoxes = document.querySelectorAll('.e-checkbox-wrapper .e-frame')
    checkBoxes.forEach((checkbox: any) => {
      if (checkbox.parentNode.id !== 'select-all-widgets') {
        if (checked && !checkbox.classList.contains('e-check')) {
          checkbox.classList.add('e-check')
        } else if (!checked && checkbox.classList.contains('e-check')) {
          checkbox.classList.remove('e-check')
        }
      }
    })

    this.widgets!.map((item) => {
      item.visible = checked ? DashboardWidgetStatus.SELECTED_WIDGET : DashboardWidgetStatus.NO_SELECTED_WIDGET
      return true
    })
  }

  updateSelectAllCheck(event: any) {
    const checkBoxAll = document.getElementById('select-all-widgets')
    if (!checkBoxAll) {
      return
    }

    if (event.target.classList.contains('e-menu-item')) {
      return
    }

    if (this.allWidgetsSelected(event)) {
      if (!checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')!.classList.contains('e-check')) {
        checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')!.classList.add('e-check')
      }
    } else {
      if (checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')!.classList.contains('e-check')) {
        checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')!.classList.remove('e-check')
      }
    }
  }

  allWidgetsSelected(_event: any): boolean {
    let selected = true
    const parentNode = _event.target.parentNode
    const target = parentNode.querySelector('.e-frame') || parentNode.parentNode.querySelector('.e-frame')

    if (target && target.classList.contains('e-check')) {
      // El propio elemento se ha deseleccioando (ya que todavía tiene e-check), así que devolvemos false
      return false
    }

    target.itsMe = true

    const selectedElem: NodeList = document.querySelectorAll(
      '#widget-items-container > .e-menu-item > .e-checkbox-wrapper > .e-frame '
    )

    selectedElem.forEach((item: Node, _key: number) => {
      if (!(item as any).itsMe && !(item as any).classList.contains('e-check')) {
        selected = false
      }
      if ((item as any).itsMe) {
        delete (item as any).itsMe
      }
    })

    return selected
  }

  createNewItemsDOMElement(args: BeforeOpenCloseMenuEventArgs) {
    const itemsContainer = createElement('div', {
      className: 'items-container',
      attrs: { id: 'widget-items-container' }
    })
    args.items.forEach((item: any) => {
      const itemFinal = createElement('span', { className: 'e-menu-item', attrs: { id: item.id } })
      const check = createCheckBox(createElement, false, {
        label: item.text,
        checked: item.visible
      })
      itemFinal.appendChild(check)
      itemFinal.addEventListener('click', (event) => {
        itemFinal.classList.add('item-selected')
        this.updateSelectAllCheck(event)
      })

      if (item.visible) {
        const mainNode = itemFinal.closest('items-container')
        if (mainNode) {
          mainNode.classList.add('selected')
        }
      }
      itemsContainer.appendChild(itemFinal)
    })

    return itemsContainer
  }

  createSelectAllDOMElement() {
    const itemsContainer = createElement('div', { className: 'select-all-container' })
    const itemFinal = createElement('span', { className: 'e-menu-item', attrs: { id: 'select-all-widgets' } })
    const checked =
      this.widgets !== null && !this.widgets.some((item) => item.visible === DashboardWidgetStatus.NO_SELECTED_WIDGET)
    const check = createCheckBox(createElement, false, {
      label: this.$t('action_buttons.select_all').toString(),
      checked
    })
    itemFinal.appendChild(check)
    itemFinal.addEventListener('click', () => {
      this.selectAll()
    })
    itemsContainer.appendChild(itemFinal)

    return itemsContainer
  }

  createButtonsDOMElement() {
    const buttonsContainer = createElement('div', { className: 'buttons-container e-menu-item' })
    const cancelButton = createElement('button', { className: 'cancel-btn', innerHTML: this.texts.cancelBtn })
    const selectButton = createElement('button', { className: 'select-btn', innerHTML: this.texts.applyBtn })
    selectButton.addEventListener('click', () => {
      this.saveSelectedItems()
    })
    buttonsContainer.appendChild(cancelButton)
    buttonsContainer.appendChild(selectButton)

    return buttonsContainer
  }

  createBlockDragAndDropButton() {
    const blockStatusClassName = this.allowDraggingProp ? '' : 'blocked'
    const buttonContainer = createElement('div', { className: `block-drag-and-drop-container ${blockStatusClassName}` })
    const button = createElement('button', { className: 'block-btn', innerHTML: this.computedButtonText })
    button.addEventListener('click', () => {
      this.blockDragAndDrop()
    })
    buttonContainer.appendChild(button)

    return buttonContainer
  }

  beforeOpen(args: BeforeOpenCloseMenuEventArgs) {
    args.element.appendChild(this.createBlockDragAndDropButton())
    args.element.appendChild(this.createSelectAllDOMElement())
    args.element.appendChild(this.createNewItemsDOMElement(args))
    args.element.appendChild(this.createButtonsDOMElement())

    const mainNodeList = document.querySelectorAll('#widget-items-container .e-menu-item')

    if (mainNodeList === null) {
      return null
    }

    mainNodeList.forEach((item: Element) => {
      if (item.querySelector('.e-check')) {
        item.classList.add('selected')
      }
    })
  }

  checkOrUncheckItem(id: string, status: 0 | 1) {
    this.widgets!.forEach((item) => {
      if (item.id === id) {
        item.visible = status
      }
    })
  }

  checkIfClickedBlockDragAndDropBtn(args: any) {
    const clickedBlockBtn: boolean = args.event.target.classList.contains('block-btn')

    // Le añadimos o quitamos la clase 'blocked' para darle los estilos al botón
    if (clickedBlockBtn) {
      const parentContainer = args.event.target.closest('.block-drag-and-drop-container')
      parentContainer.classList.toggle('blocked')
    }

    return clickedBlockBtn
  }

  beforeClose(args: any) {
    if (
      (args.event.target.closest('.e-menu-item') &&
        !args.event.target.classList.contains('cancel-btn') &&
        !args.event.target.classList.contains('select-btn')) ||
      this.checkIfClickedBlockDragAndDropBtn(args)
    ) {
      args.cancel = true
      const selectedElem = args.element.querySelectorAll('.item-selected')
      selectedElem.forEach((_elem: HTMLElement, index: number) => {
        const ele = selectedElem[index]
        ele.classList.remove('item-selected')
      })
      const checkbox = closest(args.event.target, '.e-checkbox-wrapper')
      const frame = checkbox && checkbox.querySelector('.e-frame')

      if (selectedElem[0] === undefined) {
        return
      }

      const selectedElemId = selectedElem[0].id
      const parent = selectedElem[0].closest('.e-menu-item')

      if (checkbox && frame && frame.classList.contains('e-check')) {
        frame.classList.remove('e-check')
        const index = this.selectedOptions.indexOf(Number(selectedElemId))
        if (index > -1) {
          this.selectedOptions.splice(index, 1)
        }
        this.checkOrUncheckItem(selectedElemId, DashboardWidgetStatus.NO_SELECTED_WIDGET)
        parent.classList.remove('selected')
      } else if (checkbox && frame) {
        frame.classList.add('e-check')
        this.selectedOptions.push(Number(selectedElemId))
        this.checkOrUncheckItem(selectedElemId, 1)
        parent.classList.add('selected')
      }
    } else {
      args.element.removeChild(args.element.querySelector('.block-drag-and-drop-container'))
      args.element.removeChild(args.element.querySelector('.select-all-container'))
      args.element.removeChild(args.element.querySelector('.buttons-container'))
      args.element.removeChild(args.element.querySelector('.items-container'))
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-widget-selector-container {
  @include flex($justify-content: flex-end);
  @include border($style: dashed);
  width: 100%;
  height: 40px;

  .configuration-icon {
    @include flex;
    @include interactive-round-button;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.widget-selector-contextmenu {
  width: 300px !important;
  padding: 0 0 10px !important;

  > .e-menu-item:not(.buttons-container):not(.block-drag-and-drop-container) {
    display: none !important;
  }

  .select-all-container {
    border-bottom: 1px solid $gray-03;
    border-top: 1px solid $gray-03;
    .e-check {
      width: 18px;
      &::before {
        content: '\e932' !important;
        font-size: 21px;
        top: -3px;
        left: -3px;
      }
    }
    .e-selectall.e-uncheck {
      width: 20px;
    }
  }

  .select-all-container,
  .items-container {
    @include scroll-styles;
    max-height: 310px;
    overflow-y: auto;
    margin-bottom: 10px;

    .e-menu-item {
      padding: 0 !important;
      padding-left: 10px !important;
    }
  }

  .items-container {
    .e-menu-item {
      &.selected {
        background-color: $blue-07;
      }
    }
  }

  .e-checkbox-wrapper {
    width: 100%;

    .e-label {
      color: $corporate-color !important;
    }
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-stop,
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: $corporate-color;
    border-color: transparent;
    color: $white-01;
  }

  .e-css.e-checkbox-wrapper {
    @include flex($justify-content: flex-start);
    width: 100% !important;
    height: 100%;
  }

  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-stop::before,
  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-check::before {
    font-family: $lf-icons-font;
    content: '\e92e';
  }

  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-stop::before {
    content: '';
  }

  .e-checkbox-wrapper:hover .e-frame.e-check,
  .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: $corporate-color;
  }

  .buttons-container.e-menu-item {
    @include border($direction: top);
    position: sticky;
    bottom: 0;
    justify-content: space-between !important;
    background-color: $white-01 !important;
    padding: 10px 10px 0 10px !important;

    .cancel-btn {
      @include secondary-action-button--rectangle;
      margin: 0;
      width: 50%;
    }

    .select-btn {
      @include main-action-button--rectangle;
      width: 50%;
    }
  }

  .block-drag-and-drop-container {
    @include flex;
    @include borders($width: 2px);
    margin: 10px;

    .block-btn {
      @include flex;
      width: 100%;
      height: 40px;
      font-family: $corporate-font-bold;
      font-size: 12px;
      text-transform: uppercase;

      &::after {
        font-family: $lf-icons-font;
        font-size: 18px;
        content: '\ea09';
        margin-left: 10px;
      }
    }

    &.blocked {
      background-color: $pink-01;
      border-color: $pink-01;

      .block-btn {
        color: $white-01;

        &::after {
          content: '\e993';
        }
      }
    }
  }
}
</style>
