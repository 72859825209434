<template lang="pug">

  div(v-if="renderedInfo" class="swimlane-template-container")
    span(:class="renderedInfo.class") {{ renderedInfo.abbreviation }}
    span(class="swimlane-text") {{ renderedInfo.text }}

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { checkActionIcon, checkActionText } from '@/store/modules/actions/actionsTypes'
import { KanbanGroupBy } from '@/components/LexonKanban/types/LexonKanbanTypes'
import { Icons } from '@/icons/icons'

@Component
export default class LexonKanbanSwimlaneTemplate extends Vue {
  groupBy: string = ''

  keyField: string = ''

  checkActionIconHelper = checkActionIcon

  checkActionTextHelper = checkActionText

  get renderedInfo() {
    switch (this.groupBy) {
      case KanbanGroupBy.START_DATE:
        return {
          abbreviation: '',
          class: [Icons.CALENDAR, 'action-icon'],
          text: this.keyField
        }
      case KanbanGroupBy.RESPONSIBLE:
        return {
          abbreviation: this.renderInitials(),
          class: 'initials',
          text: this.keyField
        }
      case KanbanGroupBy.ACTION_TYPE:
        return {
          abbreviation: '',
          class: [Icons.CALENDAR, 'action-icon'],
          text: this.$t(this.checkActionTextHelper(this.keyField))
        }
    }
  }

  mounted() {
    this.groupBy = (this as any).$parent.cssClass
    this.keyField = (this as any).data.keyField
  }

  renderInitials() {
    if (this.keyField) {
      const initials = (this as any).data.keyField
        .replace(/[^a-zA-Z- ]/g, '')
        .match(/\b\w/g)
        .slice(0, 2)
        .join('')

      return initials.toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.swimlane-template-container {
  @include flex;
  font-size: 13px;
  margin-left: 4px;

  .initials {
    @include flex;
    width: 30px;
    height: 30px;
    font-family: $corporate-font-medium;
    color: $blue-04;
    background-color: $blue-07;
    border-radius: 50%;
    margin-right: 8px;
  }

  .action-icon {
    font-size: 20px;
    color: $corporate-color;
    margin-right: 10px;
  }

  .swimlane-text {
    font-family: $secondary-font;
    color: $gray-01;
  }
}
</style>
