<template lang="pug">
  div( id="customers-table-status")
    span( :class="getTextColor(getText)") {{ getText }}

</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'columnStatusTemplate'
})
export default class StatusTemplateComponent extends Vue {
  data: any

  get getText() {
    return (this as any).data.statePortalText || (this as any).data.state
  }

  getTextColor(status: string): string {
    switch (status) {
      case 'Activado':
        return 'green status'
      case 'Realizada':
        return 'green status'
      case 'Bloqueado':
        return 'red status'
      case 'Planificada':
        return 'red status'
      case 'Pendiente':
        return 'status'
      case 'Celebrada':
        return 'green status'
      default:
        return ''
    }
  }
}
</script>
