<template lang="pug">

  div(class="actions-container")
    button(
      :class="[icons.arrowDown, 'arrow', { disabled: index + 1 >= fieldsNumber }]"
      @click.stop="index + 1 >= fieldsNumber ?  false : moveDown(index)"
    )
    button(
      :class="[icons.arrowUp, 'arrow', { disabled: index === 0 }]"
      @click.stop="index === 0 ? false : moveUp(index)"
    )
    button(
      :class="[icons.remove, 'round-btn', { disabled: disableDelete }]"
      @click.stop="removeField(index)"
    )
    button(
      v-if="item.idCustomFieldType !== numerationCode"
      :class="[icons.configuration, 'round-btn']"
      @click.stop="editField(item, index)"
    )

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { NumerationPatternTypes } from '@/components/forms/fields/LexonNumeration/types/LexonNumerationComponentTypes'
import { CustomField, customFieldTypesEnum } from '@/store/modules/customFields/customFieldsTypes'

@Component
export default class CustomFieldActionButtonsComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  index!: number

  @Prop({
    type: Object,
    required: true
  })
  item!: CustomField

  @Prop({
    type: Number,
    required: true
  })
  fieldsNumber!: number

  get numerationCode() {
    return customFieldTypesEnum.NUMERATION
  }

  get disableDelete() {
    return this.item.idNumerationType && this.item.idNumerationType === NumerationPatternTypes.CODE
  }

  icons = {
    arrowDown: Icons.ARROW_DOWN,
    arrowUp: Icons.ARROW_UP,
    configuration: Icons.CONFIGURATION,
    remove: Icons.REMOVE
  }

  @Emit()
  moveUp(index: number) {
    return index
  }

  @Emit()
  moveDown(index: number) {
    return index
  }

  @Emit()
  removeField(index: number) {
    return index
  }

  @Emit()
  editField(item: CustomField, index: number) {
    return { index, item }
  }
}
</script>

<style scoped lang="scss" scoped>
.actions-container {
  @include flex;
  align-self: flex-start;
  width: 136px;
  height: 40px;
  background-color: $gray-06;
  margin-top: 24px;
  margin-left: 10px;
  padding-left: 6px;
  border-radius: 20px;

  .arrow {
    @include flex;
    width: 28px;
    height: 28px;
    color: $corporate-color;
    border-radius: 50%;

    &:not(.disabled):hover {
      background-color: $blue-06;
    }

    &.disabled {
      cursor: inherit;
    }
  }

  .round-btn {
    @include interactive-round-button($size: 28px);

    &::before {
      position: relative;
      font-size: 15px;
      top: -1.5px;
    }
  }

  button.disabled {
    color: $blue-05;
  }
}
</style>
