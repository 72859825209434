<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { mixins } from 'vue-class-component'
import ActionsMixin from '@/mixins/ActionsMixin.vue'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import TabsView from '@/views/Tabs/TabsView.vue'
import { setBasePathByActionType } from '@/store/modules/actions/actionsTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS

@Component({
  mixins: [ActionsMixin]
})
export default class ActionDetailView extends mixins(LexnetMixin, TabsView) {
  @Prop({
    type: String
  })
  actionType!: string

  @Action('saveSelectedActionType', { namespace: actionsModule })
  selectedActionType: (type: string) => void

  @Getter('getSelectedActionType', { namespace: actionsModule })
  getSelectedActionType: string

  @Mutation('RESET_CHRONOMETER_NEW_ACTION_INFO', { namespace: actionsModule })
  resetChronometerNewActionInfoMutation: () => void

  extraButtons = [
    {
      icon: Icons.LEXNET,
      tooltip: this.$t('components.context_menu.open_lexnet'),
      action: ActionName.OPEN_IN_LEXNET,
      show: true
    }
  ]

  async onCreated() {
    this.selectedActionType(this.actionType)
    if (entity.actions.lexnet.type === this.entityType || entity.actions.emails.type === this.entityType) {
      await this.fetchSelectedRegisterData({
        endpoint: this.endpoints(this.permissionsEntity).get,
        context: this.getContext
      })
    }

    if (entity.actions.lexnet.type !== this.entityType) {
      this.extraButtons = []
    }
  }

  destroyed() {
    this.resetChronometerNewActionInfoMutation()
  }

  executeExtraActions(actionName: string): void {
    if (ActionName.OPEN_IN_LEXNET === actionName) {
      this.openInLexnet(this.selectedRegisterData(this.getContext))
    }
  }

  onBeforeSetComponentProperties(component: any, _tab: any, _tabIndex: number) {
    component.basePath = setBasePathByActionType(this.getSelectedActionType)
  }
}
</script>
