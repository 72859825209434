<template lang="pug">

  transition(name="fade" appear)
    div(
      v-if="showAlert && whereIsRendered === alert.componentWhereIsRendered"
      :class="[ getType, 'alert-container', { 'render-at-the-bottom': renderAtTheBottom, 'login-view': renderAtLoginView  } ]"
    )
      div(class="alert-text-container")
        span(:class="[ getIcon, 'alert-icon' ]")
        span(class="alert-text" v-html="alert.message")
      span(class="lf-icon-close close-icon" @click.stop="closeAlertAction")


</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertsData, AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Icons } from '@/icons/icons'

const namespace: string = ModuleNamespaces.ALERTS

@Component
export default class AlertComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  whereIsRendered!: string

  @Prop({
    type: Number,
    default: 5000
  })
  durationTime!: number

  @Getter('showAlert', { namespace }) showAlert: boolean
  @Getter('getAlertData', { namespace }) alert: AlertsData

  @Action('closeAlert', { namespace }) closeAlertAction: () => {}

  get getType() {
    switch (this.alert.type) {
      case AlertsTypes.INFO:
        return AlertsTypes.INFO
      case AlertsTypes.SUCCESS:
        return AlertsTypes.SUCCESS
      case AlertsTypes.ERROR:
        return AlertsTypes.ERROR
      case AlertsTypes.WARNING:
        return AlertsTypes.WARNING
    }
  }

  get getIcon() {
    switch (this.alert.type) {
      case AlertsTypes.INFO:
        return Icons.INFO
      case AlertsTypes.SUCCESS:
        return Icons.CHECK_ROUND
      case AlertsTypes.ERROR:
        return Icons.CLOSE_ROUND
      case AlertsTypes.WARNING:
        return Icons.WARNING
    }
  }

  get renderAtTheBottom() {
    return this.whereIsRendered === ComponentWhereIsRendered.TABS_COMPONENT
  }

  get renderAtLoginView() {
    return this.whereIsRendered === ComponentWhereIsRendered.LOGIN_VIEW
  }

  created() {
    this.closeAlert()
  }

  updated() {
    this.closeAlert()
  }

  closeAlert() {
    if (this.durationTime) {
      setTimeout(() => {
        this.closeAlertAction()
      }, this.durationTime)
    }
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.alert-container {
  @include flex($justify-content: space-between);
  width: 100%;
  height: 50px;
  color: $white-01;
  padding: 0 20px;
  box-shadow: 0px 0px 7px 0px rgba(86, 86, 86, 0.5);
  z-index: 99;

  &.render-at-the-bottom {
    position: inherit;
  }

  &:not(.render-at-the-bottom) {
    position: absolute;
    top: 0;
  }

  &.login-view {
    width: 65%;
    height: auto;
    top: 9%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .close-icon {
      align-self: start;
      margin-top: 16px;
    }

    .alert-text-container {
      align-items: flex-start;
      padding: 14px 0;

      .alert-icon {
        font-size: 20px;
      }

      .alert-text {
        margin: 0 20px;
      }
    }
  }

  &.info-text {
    background-color: $corporate-color;
  }

  &.error-text {
    background-color: $error-color;
  }

  &.success-text {
    background-color: $success-color;
  }

  &.warning-text {
    background-color: $warning-color;
  }

  .alert-text-container {
    @include flex;

    .alert-icon {
      font-size: 30px;
      opacity: 0.5;
    }

    .alert-text {
      font-family: $corporate-font;
      font-size: 14px;
      margin-left: 25px;

      ::v-deep a {
        font-family: $corporate-font;
        font-size: 14px;
        font-weight: bold;
        color: $white-01;
        text-decoration: none;
      }
    }
  }

  .close-icon {
    cursor: pointer;
  }
}
</style>
