<template lang="pug">
div
  GridTable(
    v-if="Object.keys(listConfiguration).length && Object.keys(serverSideData).length > 0 && !showSpinnerLayer"
    :itemsData="serverSideData"
    :itemsDataExport="serverSideDataExport"
    :gridConfiguration="listConfiguration['Config']"
    :title="title"
    :toolbarOptions="toolbarOptions"
    :contextMenuItems="contextMenu"
    :listName="listConfiguration['Alias']"
    :useServerPagination="useServerPagination"
    :recordClick="onCellClicked"
    @cellClicked="onCellClicked"
    @contextMenuBeforeOpen="onContextMenuBeforeOpen"
    @contextMenuClicked="onContextMenuClicked"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    @toolbarClicked="onToolbarClicked"
    :context="actionContext"
    :archivedFilter="archivedSelectedOption"
  )

  ejs-contextmenu(
    id="expedientActionsContextMenuComponent"
    class="context-menu-component"
    :items="actionsContextMenu"
    :select="onActionAddClick"
  )

  ejs-contextmenu(
    :id="idActionsArchivedContextMenu"
    class="context-menu-component e-contextmenu-archived"
    :items="actionsArchivedContextMenuItems"
    :select="onActionsArchivedClick"
  )

</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { ActionTypes, ActionMenu } from '@/store/modules/actions/actionsTypes'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'
import ActionsMixin from '@/mixins/ActionsMixin.vue'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const actionsModule: string = ModuleNamespaces.ACTIONS
const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    GridTable
  }
})
export default class AllActionsView extends Mixins(ActionsMixin, ListViewMixin, LexnetMixin, ArchivedFilterMixin) {
  @Prop({
    type: String
  })
  listNames!: ListNames

  @Prop({
    type: String
  })
  actionMenu!: ActionMenu

  @Action('fetchActions', { namespace: actionsModule })
  fetchActions: ({}) => []

  @Action('saveSelectedActionType', { namespace: actionsModule })
  selectedActionType: (type: string) => void

  @Action('rememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserCustomConfigurationList: (flag: boolean) => {}

  @Getter('getLastListConfiguration', { namespace: configurationModule })
  lastListConfiguration: object

  actionDescription = ''

  idActionsArchivedContextMenu = 'actionsArchivedContextMenuComponent'

  useServerPagination = true

  serverSideData: DataResult | object = []

  serverSideDataExport: object = []

  get title() {
    switch (this.actionType) {
      case ActionTypes.ALL:
        return this.$t('views.actions_all.title')
      case ActionTypes.CALLS:
        return this.$t('views.actions_calls.title')
      case ActionTypes.MEETINGS:
        return this.$t('views.actions_meetings.title')
      case ActionTypes.PROCEDURES:
        return this.$t('views.actions_procedures.title')
      case ActionTypes.OTHERS:
        return this.$t('views.actions_others.title')
      case ActionTypes.LEXNET:
        return this.$t('views.actions_lexnet.title')
      case ActionTypes.EMAILS:
        return this.$t('views.actions_emails.title')
    }
    return ''
  }

  async created() {
    try {
      await this.fetchCurrentListConfiguration(this.listNames)
      const config = JSON.parse((this as any).listConfiguration['Config'])
      this.archivedSelectedOption = config?.archived || 0
      await this.loadActionsData()
      this.initializeArchivedSelectedOption({ selectedOption: 0, contextMenu: this.idActionsArchivedContextMenu })
      this.selectedActionType(this.actionType)
      this.saveSelectedEntityName(this.actionMenu)
      this.hideSpinnerLayerAction()
    } catch (error) {}
  }

  async loadActionsData() {
    if (this.useServerPagination) {
      const jsonConf = this.parseUserConfig(this.actionType)
      await this.fetchActions({ ActionType: this.actionType, json: jsonConf })
      this.serverSideData = this.formatDataPaginated(this.actionsData)
    } else {
      const jsonConf = {
        idType: this.actionType,
        archived: this.archivedSelectedOption,
        search: (this as any).lastListConfiguration.searchSettings
      }
      await this.fetchActions({ ActionType: this.actionType, json: JSON.stringify(jsonConf) })
      this.serverSideData = this.formatData(this.actionsData)
    }
  }

  parseUserConfig(idType: string) {
    let pageSettings
    let sortSettings
    let searchSettings
    let filterSettings
    const sortColumns: any = []
    const jsonConfig: any = {}

    if (Object.keys(this.lastListConfiguration).length) {
      pageSettings = (this as any).lastListConfiguration.pageSettings
      sortSettings = (this as any).lastListConfiguration.sortSettings
      searchSettings = (this as any).lastListConfiguration.searchSettings
      filterSettings = (this as any).lastListConfiguration.filterSettings
      jsonConfig['search'] = searchSettings
    } else {
      pageSettings = JSON.parse((this as any).listConfiguration['Config'])['pageSettings']
      sortSettings = JSON.parse((this as any).listConfiguration['Config'])['sortSettings']
      searchSettings = JSON.parse((this as any).listConfiguration['Config'])['searchSettings']
      filterSettings = JSON.parse((this as any).listConfiguration['Config'])['filterSettings']
    }
    jsonConfig['idType'] = idType
    jsonConfig['archived'] = this.archivedSelectedOption
    jsonConfig['pageSize'] = pageSettings?.pageSize ? pageSettings.pageSize : 10
    jsonConfig['page'] = this.rememberUserConfig && pageSettings ? pageSettings.currentPage : 0
    jsonConfig['filter'] = this.rememberUserConfig ? filterSettings : []
    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach((sortColumn: any) => {
        const column: any = {}
        column['column'] = sortColumn.field
        column['order'] = sortColumn.direction === 'Ascending' ? 'asc' : 'desc'
        sortColumns.push(column)
      })
    }
    jsonConfig['columnOrder'] = sortColumns

    if (searchSettings !== undefined && this.rememberUserConfig) {
      jsonConfig['search'] = searchSettings
    }

    return JSON.stringify(jsonConfig)
  }

  async gridActionChange(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['idType'] = this.actionType
    ssp['archived'] = this.archivedSelectedOption
    await this.fetchActions({ ActionType: this.actionType, json: JSON.stringify(ssp) })
    this.serverSideData = this.formatDataPaginated(this.actionsData)
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['idType'] = this.actionType
    ssp['archived'] = this.archivedSelectedOption
    await this.fetchActions({ ActionType: this.actionType, json: JSON.stringify(ssp) })
    this.serverSideDataExport = this.formatData(this.actionsData)
  }

  async onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'add') {
      this.trackClickAdd()
      args.cancel = true
      if (this.actionType === ActionTypes.ALL) {
        this.toggleContextMenu(args.originalEvent)
      } else if (this.actionType === ActionTypes.LEXNET) {
        this.openLexnetInNewWindow()
      } else {
        await this.openAction(this.actionType as ActionTypes)
      }
    } else if (target && target.id === 'archived') {
      args.cancel = true
      this.toggleArchivedContextMenu(args.originalEvent, this.idActionsArchivedContextMenu)
    }
  }

  trackClickAdd() {
    trackEvent(TrackerEvents.CREATE_ACTION)
    if (this.actionType === ActionTypes.EMAILS) {
      trackEvent(TrackerEvents.CREATE_MAIL_ACTION)
    }
  }

  async onActionsArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idActionsArchivedContextMenu)
    this.rememberUserCustomConfigurationList(true)
    await this.loadActionsData()
  }

  async onActionAddClick(args: any) {
    if (args.item.actionType === ActionTypes.EMAILS) {
      trackEvent(TrackerEvents.CREATE_MAIL_ACTION)
    }
    if (args.item.actionType === ActionTypes.LEXNET) {
      this.openLexnetInNewWindow()
      return
    }
    await this.openAction(args.item.actionType)
  }

  async openAction(actionType: ActionTypes, selectedRegisterId = '', target = '_self') {
    let routeData: {} | null = null
    const selectedRegisterParam = selectedRegisterId || this.$t('views.selected_register.new').toString()
    switch (actionType) {
      case ActionTypes.CALLS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.CALLS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.MEETINGS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.MEETINGS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.PROCEDURES:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.PROCEDURES}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.OTHERS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.LEXNET:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
          params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
        })
        break
      case ActionTypes.EMAILS:
        if (selectedRegisterId) {
          routeData = this.$router.resolve({
            name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
            params: { selectedRegisterId: selectedRegisterParam, from: URLS.ACTION_ALL }
          })
        } else {
          routeData = this.$router.resolve({ name: `${URLS.EMAIL}-${URLS.NEW}` })
          target = '_blank'
        }
        break
    }
    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item) {
      if (
        args.item.text === this.$t('components.context_menu.edit') ||
        args.item.text === this.$t('components.context_menu.look')
      ) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        args.cancel = true
        await this.openAction(selectedRegister.typeId, selectedRegister.actionId)
      }
      if (args.item.text === this.$t('components.context_menu.open_window')) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        args.cancel = true
        await this.openAction(selectedRegister.typeId, selectedRegister.actionId, '_blank')
      }
      if (
        args.item.text === this.$t('components.context_menu.open') ||
        args.item.text === this.$t('components.context_menu.open_lf_mail')
      ) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        trackEvent(TrackerEvents.OPEN_LEFEBVRE_MAIL)
        args.cancel = true
        let url = `/${URLS.EMAIL}/new/action`
        const target = '_blank'
        if (this.contextMenuSelectedRegister) {
          url = `/${URLS.EMAIL}/edit/${this.contextMenuSelectedRegister.actionId}`
        }
        window.open(`${url}`, target)
      }
      if (args.item.text === this.$t('components.context_menu.open_lexnet')) {
        trackEvent(TrackerEvents.EDIT_ACTION)
        args.cancel = true
        this.openInLexnet(selectedRegister)
      }
    }
  }

  async onCellClicked(selectedRegister: any, cancel: boolean) {
    if (!cancel) {
      trackEvent(TrackerEvents.EDIT_ACTION)
      cancel = true
      await this.openAction(selectedRegister.typeId, selectedRegister.actionId)
    }
  }
}
</script>
