<template lang="pug">

    WidgetBaseComponent(
      ref="widget"
      class="next-events-widget"
      :headerIcon="icons.headerIcon"
      :loadedData="allDataLoaded"
    )
      //- HEADER
      template(v-slot:header)
        div(class="header-bar")
          h2 {{ expedientGlobalVisionAppointmentsGetter.title }}
          button(
            v-if="userHasPermissionToSaveAtLeastOneActionType"
            :class="[icons.addButton, 'add-button']"
            @click="openContextMenuComponent"
          )
        ejs-contextmenu(
          ref="contextMenuComponent"
          :items="contextMenuItems"
          :select="onContextMenuItemClicked"
        )

      //- BODY
      template(v-slot:body)
        EventContainerComponent(
          v-if="expedientGlobalVisionAppointmentsGetter.body.length"
          :events="expedientGlobalVisionAppointmentsGetter.body"
          @fetchEvents="fetchEvents"
        )
        //- No events
        div(v-else class="no-events")
          span {{ noEventsText }}

      //-FOOTER
      template(v-slot:footer)
        div(class="footer-data-container")
          span {{ `${expedientGlobalVisionAppointmentsGetter.footer.label}: ` }}
          span(class="footer-total-number") {{ expedientGlobalVisionAppointmentsGetter.footer.totalNumber }}

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import EventContainerComponent from '@/components/events/EventContainer/EventContainerComponent.vue'
import { Icons } from '@/icons/icons'
import { NextEventsWidgetData } from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidgetTypes'
import { ActionTypes } from '@/store/modules/actions/actionsTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { TabNames } from '@/store/modules/tabs/tabsTypes'
import { TranslateResult } from 'vue-i18n'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'

const appointmentsModule: string = ModuleNamespaces.APPOINTMENTS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    EventContainerComponent,
    WidgetBaseComponent
  }
})
export default class ExpedientNextEventsWidget extends Mixins(PermissionsMixin) {
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getExpedientGlobalVisionAppointments', { namespace: appointmentsModule })
  expedientGlobalVisionAppointmentsGetter: () => NextEventsWidgetData

  @Mutation('RESET_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS', { namespace: appointmentsModule })
  resetWidgetMutation: () => void

  @Action('fetchExpedientGlobalVisionAppointments', { namespace: appointmentsModule })
  fetchExpedientGlobalVisionAppointmentsAction: ({
    idExpedient,
    source
  }: {
    idExpedient: number
    source: string
  }) => NextEventsWidgetData

  $refs!: {
    contextMenuComponent: any
    widget: any
  }

  icons = {
    actionCall: Icons.CALL,
    actionMeeting: Icons.MEETING,
    actionOther: Icons.OTHERS_F,
    actionProcedure: Icons.PROCEDURES,
    addButton: Icons.ADD,
    headerIcon: Icons.CALENDAR
  }

  get contextMenuItems() {
    return [
      ...(this.checkEntityPermissionsGetter(entity.actions.calls.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.calls'),
              iconCss: this.icons.actionCall,
              actionType: ActionTypes.CALLS
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.meetings.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.meetings'),
              iconCss: this.icons.actionMeeting,
              actionType: ActionTypes.MEETINGS
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.procedures.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.procedures'),
              iconCss: this.icons.actionProcedure,
              actionType: ActionTypes.PROCEDURES
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.others.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.others_f'),
              iconCss: this.icons.actionOther,
              actionType: ActionTypes.OTHERS
            }
          ]
        : [])
    ]
  }

  eventContainerHeight = 0

  noEventsText: TranslateResult = this.$t('components.widgets.expedient_next_events.no_events')

  contexts = {
    expedientSchedule: ContextName.EXPEDIENT_SCHEDULE_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  get selectedExpedientId(): number {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get allDataLoaded(): boolean {
    return !!(
      this.expedientGlobalVisionAppointmentsGetter && Object.keys(this.expedientGlobalVisionAppointmentsGetter).length
    )
  }

  created() {
    setTimeout(() => {
      this.fetchEvents()
    })
  }

  beforeDestroy() {
    this.resetWidgetMutation()
  }

  async fetchEvents() {
    await this.fetchExpedientGlobalVisionAppointmentsAction({
      idExpedient: this.selectedExpedientId as unknown as number,
      source: this.contexts.expedientSchedule
    })
  }

  openContextMenuComponent(args: any): void {
    const rect = args.target.getBoundingClientRect()
    const top = rect.top + window.scrollY + 20
    const left = rect.left - 150
    this.$refs.contextMenuComponent.open(top, left)
  }

  onContextMenuItemClicked(args: any): void {
    const actionType = args.item.actionType

    this.$router.push({
      name: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
      params: { selectedRegisterId: this.selectedRegisterId(this.contexts.expedientContext) },
      query: {
        tab: TabNames.ACTIONS,
        action: actionType
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.next-events-widget {
  .header-bar {
    @include flex($justify-content: space-between);
    width: 100%;

    .add-button {
      @include interactive-round-button;
      min-height: 35px;
      min-width: 35px;
    }
  }

  .footer-data-container {
    width: 100%;
    text-align: right;
    padding-right: 3%;

    .footer-total-number {
      display: inline-block;
      font-size: 18px;
      padding-left: 2px;
    }
  }

  .no-events {
    @include flex;
    font-family: $secondary-font;
    color: $corporate-color;
    font-weight: bold;
    width: 100%;
    margin: 34px 10px;
  }
}
</style>
