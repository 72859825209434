<template lang="pug">
extends ../../views/Tabs/tabsView.pug
block header
  StageSelectorComponent(
    v-if="isExpedientsDetailView && formLoaded && !isPortalUser"
    :context="context"
    :entityId="entityId"
    :disabled="disableStageSelector"
    :permissions="checkEntityPermissionsGetter(this.entityType)"
  )
block tabHeader
  ExpedientDetailHeaderComponent(
    :title="expedientTitle"
    :selectedTabInfo="selectedTabInfo"
  )
  ejs-contextmenu(
    id="contextMenuSendEmail"
    ref="contextMenuSendEmail"
    class="context-menu-component e-contextmenu-send-mail"
    :items="expedientsMailSelector"
    :select="onExpedientsMailClick"
  )
  ejs-contextmenu(
    id="contextMenuSendEmailNoPortal"
    ref="contextMenuSendEmailNoPortal"
    class="context-menu-component e-contextmenu-send-mail"
    :items="expedientsMailSelectorNoPortal"
    :select="onExpedientsMailClick"
    :beforeItemRender="beforeItemRender"
  )
</template>
<script lang="ts">
import TabsView from '@/views/Tabs/TabsView.vue'
import store from '@/store/store'
import { Component, Provide, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { Action, Getter, Mutation } from 'vuex-class'
import { ExpedientFields, ExpedientSendEmail } from '@/views/Expedients/types/ExpedientFieldsTypes'
import {
  ConfigurationAlias,
  ConfigurationElements,
  ConfigurationTypes
} from '@/store/modules/configuration/configurationTypes'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ContextName, ModuleNamespaces, ValidationRules } from '@/store/types/storeGlobalTypes'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import ExpedientDetailHeaderComponent from '@/components/expedients/ExpedientDetailHeader/ExpedientDetailHeaderComponent.vue'
import StageSelectorComponent from '@/components/StageSelector/StageSelectorComponent.vue'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import TaxesDynamicFormFieldsMixin from '@/mixins/TaxesDynamicFormFields/TaxesDynamicFormFieldsMixin.vue'
import { Tab, TabNames } from '@/store/modules/tabs/tabsTypes'
import { endpoints as endpointsTypes } from '@/store/modules/endpoint/endpointTypes'
import { CustomDialogComponentName } from '@/store/modules/dialog/dialogTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const alertsModule: string = ModuleNamespaces.ALERTS
const authModule = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const formsModule: string = ModuleNamespaces.FORMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    ExpedientDetailHeaderComponent,
    StageSelectorComponent
  }
})
export default class ExpedientDetailView extends mixins(TabsView, TaxesDynamicFormFieldsMixin) {
  @Provide()
  showTabSelectorComponent = true

  @Provide()
  saveOnBeforeOpenTabSelector = true

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void
  @Action('fetchExpedientMaxCode', { namespace: expedientsModule })
  expedientMaxCode: ({}) => Promise<void>
  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => void
  @Action('fetchRelatedSelectedRegister', { namespace: selectedRegisterModule })
  fetchCustomerData: ({}) => {}

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => Promise<void>
  @Mutation('SHOW_OR_HIDE_ONE_TAB', { namespace: configurationModule })
  showOrHidePeriodicBillingTab: ({
    context,
    tabName,
    status
  }: {
    context: string
    tabName: string
    status: boolean
  }) => void
  @Mutation('ENABLE_OR_DISABLE_ONE_TAB', { namespace: configurationModule })
  enableOrDisablePeriodicBillingTab: ({
    context,
    tabName,
    status
  }: {
    context: string
    tabName: string
    status: boolean
  }) => void
  @Mutation('SET_EDITING_PERIODIC_BILLING', { namespace: configurationModule })
  setEditingPeriodicBillingFlag: () => void
  @Mutation('RESET_EDITING_PERIODIC_BILLING', { namespace: configurationModule })
  resetEditingPeriodicBillingFlag: () => void

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: []
  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object
  @Getter('getSelectedRegisterPeriodicBillingData', { namespace: selectedRegisterModule })
  periodicBillingData: {}
  @Getter('getIsPortalUser', { namespace: authModule })
  isPortalUser: boolean
  @Getter('isEditingPeriodicBilling', { namespace: configurationModule })
  isEditingPeriodicBilling: boolean
  @Getter('getPeriodicBillingVisibleTabStatus', { namespace: configurationModule })
  getPeriodicBillingVisibleTabStatus: boolean
  @Getter('getPeriodicBillingEnabledTabStatus', { namespace: configurationModule })
  getPeriodicBillingEnabledTabStatus: boolean
  @Getter('getSelectedRelatedData', { namespace: selectedRegisterModule })
  getCustomerData: any

  isSavingForm = false

  newForm = false

  disableStageSelector = false

  pendingBillingContext: string = ContextName.PERIODIC_BILLING_TAB

  triggerPeriodicBillingAlert = false

  expedientContext = ContextName.EXPEDIENTS

  expedientsMailSelector = [
    {
      text: this.$t('navigation.drawer-menu-items.send_mail_extern'),
      iconCss: Icons.NEWSN,
      actionType: ExpedientSendEmail.CLIENT
    },
    {
      separator: true
    },
    {
      text: this.$t('navigation.drawer-menu-items.send_mail_intern'),
      iconCss: Icons.NEWSN,
      actionType: ExpedientSendEmail.INTERN
    }
  ]

  expedientsMailSelectorNoPortal = [
    {
      text: this.$t('navigation.drawer-menu-items.send_mail_extern'),
      iconCss: Icons.WARNING,
      id: 'lf-disable-context-menu-send-notification'
    },
    {
      separator: true
    },
    {
      text: this.$t('navigation.drawer-menu-items.send_mail_intern'),
      iconCss: Icons.NEWSN,
      actionType: ExpedientSendEmail.INTERN
    }
  ]

  contextMenuSendEmail: any = null
  contextMenuSendEmailNoPortal: any = null

  // $refs!: {
  //   tabsComponent: InstanceType<typeof TabsComponent>
  // }

  @Watch('duplicateExpedientId')
  async onDuplicateExpedient() {
    await this.saveSelectedRegisterId({ id: this.duplicateExpedientId, context: this.expedientContext })
  }

  get buttons() {
    const { canDelete, canSave } = this.checkEntityPermissionsGetter(this.entityType)

    const sendEmail = {
      icon: Icons.SEND_EMAIL,
      tooltip: this.$t('action_buttons.send_notification'),
      action: ActionName.SEND_EMAIL,
      class: 'lf-mail-class-selector',
      show:
        this.showRelatedForm(this.getContext) ||
        (!this.checkIfFormIsValid(this.getContext) && this.selectedTabInfo.objectType === ConfigurationElements.FORM) ||
        !this.buttonsEnabled
          ? false
          : true
    }

    const saveButton = {
      icon: Icons.CHECK,
      tooltip: this.$t('action_buttons.save'),
      class: 'green-color',
      action: ActionName.SAVE,
      show:
        this.showRelatedForm(this.getContext) ||
        (!this.checkIfFormIsValid(this.getContext) &&
          this.selectedTabInfo.objectType !== ConfigurationElements.EXPEDIENT_INSURERS &&
          !this.checkIfFormIsValid(this.pendingBillingContext)) ||
        !this.buttonsEnabled
          ? false
          : true,
      hidden: !canSave
    }

    const closeButton = {
      icon: Icons.CLOSE,
      tooltip: this.$t('action_buttons.close'),
      class: 'red-color',
      action: ActionName.CLOSE,
      show: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true
    }

    const removeButton = {
      icon: Icons.REMOVE,
      tooltip: this.$t('action_buttons.remove'),
      action: ActionName.REMOVE,
      show: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true,
      hidden: !canDelete
    }

    const duplicateButton = {
      icon: Icons.DOCUMENTS,
      tooltip: this.$t('action_buttons.duplicate_expedient'),
      action: ActionName.DUPLICATE_EXPEDIENT,
      show: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true,
      hidden: !canSave
    }

    if (this.isPortalUser) {
      return [closeButton]
    }

    if (this.existSelectedRegister) {
      return [duplicateButton, sendEmail, removeButton, closeButton, saveButton]
    }

    return [closeButton, saveButton]
  }

  get currentUser() {
    return (store.state as any).authModule.authData.user
  }

  mounted() {
    this.resetEditingPeriodicBillingFlag()
    this.contextMenuSendEmail = this.$refs.contextMenuSendEmail
    this.contextMenuSendEmailNoPortal = this.$refs.contextMenuSendEmailNoPortal
  }

  async created() {
    this.saveMenuParentCategoryName(ParentCategory.EXPEDIENTS)
  }

  async onInitForm(params: any) {
    const { canSave } = this.checkEntityPermissionsGetter(this.entityType)
    const { schema, formData, isNewForm } = params
    this.disableStageSelector = false
    if (isNewForm) {
      this.disableStageSelector = true
      this.newForm = true
      formData.idUser = [String(this.currentUser.id)]
      // lexon v4
      // formData.idUserTitular = [String(this.currentUser.id)]
      // lexon v3
      if ('idUserTitular' in formData) {
        formData.idUserTitular = [String(this.currentUser.id)]
      }
      const filter = { idUser: this.currentUser.id }
      formData.idOwnFile = await this.expedientMaxCode({ filter })
    }

    const subjectField = schema.find((item: any) => {
      return item.id === ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID
    })

    if (subjectField && canSave) {
      subjectField.disabled =
        'undefined' === typeof formData[ExpedientFields.EXPEDIENT_TYPE_ID] ||
        null === formData[ExpedientFields.EXPEDIENT_TYPE_ID]

      subjectField.filteredValue = formData[ExpedientFields.EXPEDIENT_TYPE_ID]
        ? formData[ExpedientFields.EXPEDIENT_TYPE_ID].toString()
        : formData[ExpedientFields.EXPEDIENT_TYPE_ID]
    }

    this.formLoaded = true
  }

  beforeSetInitialTab() {
    const expedient: any = this.selectedRegisterData(this.context)
    const periodicBillingSwitch = expedient[ExpedientFields.PERIODIC_BILLING]

    const showPeriodicBillingTab: boolean = periodicBillingSwitch && Object.keys(this.periodicBillingData).length !== 0

    this.showOrHidePeriodicBillingTab({
      context: this.context,
      tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
      status: showPeriodicBillingTab
    })

    if (showPeriodicBillingTab) {
      this.enableOrDisablePeriodicBillingTab({
        context: this.context,
        tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
        status: true
      })
    }

    this.formLoaded = true
  }

  async onExpedientGlobalVisionMounted() {
    if (this.showSpinnerLayer) {
      this.hideSpinnerLayerAction()
    }
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.context).type).get,
      context: this.context
    })
    this.beforeSetInitialTab()
  }

  checkIfIdCustomerFieldIsRequired(schema: any, periodicBillingSwitchValue: number) {
    const customerFactField = schema.find((item: any) => {
      return item.id === ExpedientFields.CUSTOMER_FACT
    })

    if (periodicBillingSwitchValue) {
      customerFactField.validationRules = ValidationRules.REQUIRED
    } else {
      customerFactField.validationRules = null
      delete customerFactField[ValidationRules.VALIDATION_RULES]
    }
  }

  onFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (this.isSavingForm) {
      return
    }

    const { canSave } = this.checkEntityPermissionsGetter(this.entityType)

    if (ExpedientFields.ARCHIVED === field.id) {
      const hidden: boolean = '0' === value
      schema
        .filter((item: any) => {
          return item.id === ExpedientFields.ARCHIVE_DATE || item.id === ExpedientFields.ARCHIVE_REFERENCE
        })
        .map((item: any) => {
          if (!this.isPortalUser) {
            item.hidden = hidden
            item.disable = hidden && canSave
          } else {
            item.hidden = false
          }
        })

      // FILEREF FIELD LOGIC
      const archived: boolean = '1' === value
      if (archived && !formData.fileReference) {
        this.checkArchivedMaxCode(formData)
      }
    }
    if (ExpedientFields.USER_ID === field.id) {
      this.checkMaxCode(formData)
    }

    if (ExpedientFields.EXPEDIENT_TYPE_ID === field.id) {
      const subjectField = schema.find((item: any) => {
        return item.id === ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID
      })
      subjectField.filterField = field.filterField
      subjectField.filteredValue = value
      subjectField.disabled = value === undefined
      this.$set(formData, ExpedientFields.EXPEDIENT_TYPE_SUBJECT_ID, null)
      this.checkMaxCode(formData)
    }

    if (ExpedientFields.CUSTOMER_FACT === field.id && !this.isPortalUser) {
      if (formData[ExpedientFields.PERIODIC_BILLING]) {
        this.enableOrDisablePeriodicBillingTab({
          context: this.context,
          tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
          status: true
        })
      }
      this.checkMaxCode(formData)
    }

    if (ExpedientFields.PERIODIC_BILLING === field.id && !this.isPortalUser) {
      this.setEditingPeriodicBillingFlag()

      const valueToNumber = Number(value)

      this.checkIfIdCustomerFieldIsRequired(schema, valueToNumber)

      if (this.periodicBillingData && Object.keys(this.periodicBillingData).length) {
        if (!valueToNumber) {
          this.triggerPeriodicBillingAlert = true
        }
        this.showOrHidePeriodicBillingTab({
          context: this.context,
          tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
          status: valueToNumber ? true : false
        })
        if (valueToNumber) {
          this.enableOrDisablePeriodicBillingTab({
            context: this.context,
            tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
            status: true
          })
        }
      } else {
        this.triggerPeriodicBillingAlert = true
        this.showOrHidePeriodicBillingTab({
          context: this.context,
          tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
          status: valueToNumber ? true : false
        })
      }
      if (this.triggerPeriodicBillingAlert) {
        const alertData = this.getAlertData(value)
        this.showAlert({
          type: alertData.type,
          message: alertData.message,
          componentWhereIsRendered: ComponentWhereIsRendered.TABS_COMPONENT
        })
      }
    }
  }

  getAlertData(value: string) {
    const valueToNumber = Number(value)
    return {
      type: valueToNumber ? AlertsTypes.INFO : AlertsTypes.WARNING,
      message: valueToNumber
        ? this.$t('components.alerts.periodic_billing_activated')
        : this.$t('components.alerts.periodic_billing_deactivated')
    }
  }

  async save(redirectToGridTable = true, resolve: any = null, idObjectType = '') {
    const { canSave } = this.checkEntityPermissionsGetter(this.entityType)

    this.resetEditingPeriodicBillingFlag()

    if (canSave) {
      this.isSavingForm = true

      const formData: any = this.getFormData

      this.prepareFormData(formData)

      if (Object.keys(formData).length !== 0) {
        formData[ExpedientFields.ACTIVE_STAGES] = (this as any).expedientStageState[ExpedientFields.ACTIVE_STAGES]
        if (!formData[ExpedientFields.TAGS] && idObjectType !== ConfigurationElements.EXPEDIENT_INSURERS) {
          formData[ExpedientFields.TAGS] = []
        }

        if (typeof formData[ExpedientFields.USER_ID] === 'string') {
          formData[ExpedientFields.USER_ID] = [formData[ExpedientFields.USER_ID]]
        }

        if (!this.isPortalUser) {
          await this.saveRegisterFormData({
            endpoint: this.endpoints(this.entity(this.getContext).type).save,
            idSelectedRegister: this.entity(this.getContext).id,
            goToURL: redirectToGridTable ? this.menuName : null,
            context: this.getContext
          })
          this.showDashboardSpinnerMutation(SpinnerDashboardContext.EXPEDIENTS_COUNTER_CHART)
        }
      } else if (redirectToGridTable) {
        this.$router.push({ name: this.menuName })
      }
    }

    this.isSavingForm = false
    this.disableStageSelector = false
    this.newForm = false

    if (!redirectToGridTable) {
      this.setPeriodicBillingVisibility()
    }

    if (resolve) {
      resolve()
    }
  }

  async checkArchivedMaxCode(formData: any) {
    const filter = {
      idUser: formData.idUser[0],
      idExpedientType: formData.idExpedientType,
      idCustomer: formData.customerfact && formData.customerfact.id ? formData.customerfact.id : null,
      archived: 1
    }
    const maxCode: any = await this.expedientMaxCode({ filter })
    if (maxCode !== '') {
      formData.fileReference = maxCode
    }
  }

  async checkMaxCode(formData: any) {
    if (this.newForm) {
      const filter = {
        idUser: formData.idUser[0],
        idExpedientType: formData.idExpedientType,
        idCustomer: formData.customerfact && formData.customerfact.id ? formData.customerfact.id : null
      }
      const maxCode: any = await this.expedientMaxCode({ filter })
      if (maxCode !== '') {
        formData.idOwnFile = maxCode
      }
    }
  }

  async onAfterSaveTabsVisibility(currentTab: Tab) {
    // Obtener el tabComponent
    const tabComponent: any = this.$refs.tabsComponent

    if (!tabComponent) {
      return
    }

    // Apagar el renderizado de los componentes
    tabComponent.setEverythingLoaded(false)

    // Pedir la configuración reactiva
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.EXPEDIENT,
      context: ContextName.EXPEDIENTS
    })

    // Obtener el í­ndice de la nueva configuración
    let index = this.getCurrentViewTabs(this.context).findIndex((tab: Tab) => tab.name === currentTab!.name)

    if (index < 0) {
      // La pestaña actual no es visible
      index = this.getCurrentViewTabs(this.context).findIndex((tab: Tab) => tab.name === TabNames.EXPEDIENT)
    }

    // Situar la pestaña actual en el nuevo í­ndice
    tabComponent.setSelectedTabIndex(index)
    tabComponent.setSelectedCurrentTabIndex(index)

    // Establecer la pestaña actual
    const tab: Tab = this.getCurrentViewTabs(this.context)[index]
    tabComponent.setCurrentTab(tab)

    this.$nextTick()

    // Encender el renderizado de los componentes
    tabComponent.setEverythingLoaded(true)

    // Mostrar / ocultar la pestaña de igualas
    this.setPeriodicBillingVisibility()
  }

  // Control sobre la visiblidad de la pestaña de igualas una vez guardada la configuración de pestañas
  async setPeriodicBillingVisibility() {
    // Si está editando un expediente
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.context).type).get,
      context: this.context
    })
    let expedient = this.selectedRegisterData(ContextName.EXPEDIENTS)

    if (!expedient || Object.keys(expedient).length <= 0) {
      // Si está creando un expediente
      expedient = this.getFormData
    }

    if (!expedient) {
      return
    }

    const enabled: boolean = (expedient as any).periodicBillingSwitch && (expedient as any).periodicBillingSwitch === 1

    this.showOrHidePeriodicBillingTab({
      context: ContextName.EXPEDIENTS,
      tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
      status: enabled
    })

    if (enabled) {
      this.enableOrDisablePeriodicBillingTab({
        context: ContextName.EXPEDIENTS,
        tabName: TabNames.EXPEDIENT_PERIODIC_BILLING,
        status: enabled
      })
    }
  }

  async onExpedientsMailClick(args: any) {
    switch (args.item.actionType) {
      case ExpedientSendEmail.CLIENT:
        this.sendEmailDialog()
        break
      case ExpedientSendEmail.INTERN:
        this.showCustomDialog({
          name: CustomDialogComponentName.NOTIFICATION_INTERN,
          props: {
            id: this.selectedRegisterId(this.context),
            name: this.getSelectedRegisterData.description,
            context: this.context
          }
        })
        break
      default:
        break
    }
  }

  async openContextMailMenu() {
    const formData: any = this.getFormData
    await this.save(false)
    let { idCustomer } = this.selectedRegisterData(this.getContext)
    let customerData = null
    if (formData && formData.customerfact) {
      idCustomer = formData.customerfact.id
    } else if (formData && formData.customerfact === null) {
      idCustomer = null
    }
    if (idCustomer) {
      const contactEndpoint = (endpointsTypes as any).contactsGeneric
      const endpointContactGet = String.format(
        contactEndpoint.get,
        ConfigurationAlias.CONTACT_CUSTOMERS,
        formData.customerfact ? formData.customerfact.id : idCustomer
      )
      await this.fetchCustomerData({
        url: endpointContactGet,
        filterData: { source: 'checkPortal' }
      })
      customerData = this.getCustomerData
    }

    const rect = (document as any).getElementsByClassName('lf-mail-class-selector')[0].getBoundingClientRect()
    const top = rect.top + window.scrollY + 48
    const left = rect.left + 100

    if (customerData && customerData.idPortal) {
      this.contextMenuSendEmail.open(top, left)
    } else {
      this.contextMenuSendEmailNoPortal.open(top, left)
    }
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        trackEvent(TrackerEvents.REMOVE_EXPEDIENT)
        return this.remove()
      case ActionName.DUPLICATE_EXPEDIENT:
        trackEvent(TrackerEvents.DUPLICATE_EXPEDIENT)
        return this.duplicateExpedient()
      case ActionName.SEND_EMAIL:
        return this.openContextMailMenu()
    }
    this.executeExtraActions(actionName)
  }

  beforeItemRender(args: any) {
    if (args.element.id === 'lf-disable-context-menu-send-notification') {
      args.element.title = this.$t('navigation.drawer-menu-items.send_mail_extern_tooltip')
    }
  }
}
</script>
<style lang="scss">
.context-menu-component {
  #lf-disable-context-menu-send-notification {
    cursor: default;
    color: $gray-02;
  }
}
</style>
