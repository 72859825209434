<template lang="pug">

  GridTable(
    v-if="Object.keys(listConfiguration).length && contactsData && !showSpinnerLayer"
    :itemsData="contactsData"
    :gridConfiguration="listConfiguration['Config']"
    :title="$t('views.contacts_opponent_lawyers.title')"
    :toolbarOptions="toolbarOptions"
    :contextMenuItems="contextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ContactTypes } from '@/store/modules/contacts/contactsTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ListNames } from '@/store/modules/configuration/configurationTypes'

@Component({
  components: {
    GridTable
  }
})
export default class OpponentLawyersView extends mixins(ListViewMixin) {
  get context() {
    return ContextName.CONTACT_OPPONENT_LAWYERS
  }

  async created() {
    await this.loadContactViewsData(ContactTypes.OPPONENT_LAWYERS, ListNames.OPPONENT_LAWYERS)
    this.hideSpinnerLayerAction()
  }
}
</script>
