<template lang="pug">

  v-dialog(
    v-if="showDialog"
    v-model="showDialog"
    max-width="910px"
    content-class="print-or-save-invoices-dialog"
    persistent
  )
    //- persistent
    template
      //- HEADER
      v-card(class="print-or-save-dialog-container")
        header(class="header")
          v-card-title
            span {{ titleDialog }}
          span(
            :class="[closeIcon , 'close-icon']"
            @click.stop="closeDialog"
          )
        SpinnerLayerComponent(v-if="showSpinner" class="spinner-layer" :loadingText="$t('components.spinner.generating_document')")
        template(v-else)
          //- BODY
          v-card-text(class="wizard-container")
            //- STEPS 1 AND 2
            template(v-if="currentStep === steps.selectInvoices")
              InvoicesTemplateSelectorComponent(
                :idEntityType="idEntityType"
                @templateSelectedInvoice="templateSelectedInvoice"
              )

              InvoiceConfigurationComponent(
                :idEntityType="idEntityType"
                :selectedInvoicesLength="invoiceGenerate.length"
                :disableConfiguration="disableConfiguration"
                @configButtons="configButtons"
              )

            //- STEP 3
            template(v-if="currentStep === steps.generationProgress")
              InvoicesProgressVisualizer(
                :invoices="invoiceGenerate"
              )

            //- STEP 4
            template(v-if="this.currentStep === this.steps.finalResumeList")
              InvoicesFinalResumeList(
                :invoices="invoiceGenerate"
              )

          //- ACTION BUTTONS
          v-card-actions(class="actions-footer")
          PrintOrSaveInvoicesDialogActionsComponent(
            :currentStep="currentStep"
            :disabledGenerate="disabledGenerate"
            @eventButton="actionsButtons"
          )

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import {
  PrintInvoiceEvents,
  PrintOrSaveInvoicesSteps,
  TranslationTexts
} from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import InvoiceConfigurationComponent from '@/components/billing/PrintOrSaveInvoicesDialog/InvoiceConfigurationComponent.vue'
import InvoicesProgressVisualizer from '@/components/billing/PrintOrSaveInvoicesDialog/InvoicesProgressVisualizer.vue'
import InvoicesTemplateSelectorComponent from '@/components/billing/PrintOrSaveInvoicesDialog/InvoicesTemplateSelectorComponent.vue'
import InvoicesFinalResumeList from '@/components/billing/PrintOrSaveInvoicesDialog/InvoicesFinalResumeList.vue'
import PrintOrSaveInvoicesDialogActionsComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesDialogActionsComponent.vue'
import { generateInvoice } from '@/helpers/invoice'
import { Action, Getter, Mutation } from 'vuex-class'
import { ITemplate } from '@/store/modules/template/templateTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { InvoiceGenerated } from '@/store/modules/invoices/invoicesTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { InvoiceEntityId } from '@/general/entityIds'
const invoicesModule = ModuleNamespaces.INVOICES
const fileManagerModule = ModuleNamespaces.FILE_MANAGER
const authModule = ModuleNamespaces.AUTH
const alertsModule = ModuleNamespaces.ALERTS

@Component({
  components: {
    InvoiceConfigurationComponent,
    InvoicesProgressVisualizer,
    InvoicesTemplateSelectorComponent,
    InvoicesFinalResumeList,
    PrintOrSaveInvoicesDialogActionsComponent,
    SpinnerLayerComponent
  }
})
export default class PrintOrSaveInvoicesDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  showDialog!: boolean

  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  @Getter('getInvoiceGenerateAll', { namespace: invoicesModule })
  invoiceGenerate: InvoiceGenerated[]

  @Getter('getInvoiceGenerateOK', { namespace: invoicesModule })
  invoiceGenerateOK: boolean

  @Getter('getInvoiceGenerateReady', { namespace: invoicesModule })
  invoiceGenerateReady: boolean

  @Getter('getInvoiceGenerateError', { namespace: invoicesModule })
  invoiceGenerateError: boolean

  @Getter('getInvoiceGenerateErrorAll', { namespace: invoicesModule })
  invoiceGenerateErrorAll: boolean

  @Action('mergePdf', { namespace: invoicesModule })
  mergePdf: () => {}

  @Action('deleteDocument', { namespace: fileManagerModule })
  deleteDocument: (idDocument: number) => void

  @Mutation('RESET_GENERATE_INVOICES', { namespace: invoicesModule })
  resetInvoices: () => void

  @Action('cancelPendingRequests', { namespace: authModule })
  cancelPendingRequests: () => {}

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  actions: { print: string; save: string } = { print: '1', save: '0' }

  printDocument: boolean = true
  saveDocument: boolean = false
  showSpinner = false

  templateSelected: ITemplate | null = null

  currentStep = PrintOrSaveInvoicesSteps.SELECT_INVOICES

  steps = {
    configureOptions: PrintOrSaveInvoicesSteps.CONFIGURE_OPTIONS,
    generationProgress: PrintOrSaveInvoicesSteps.GENERATION_PROGRESS,
    finalResumeList: PrintOrSaveInvoicesSteps.FINAL_RESUME_LIST,
    selectInvoices: PrintOrSaveInvoicesSteps.SELECT_INVOICES
  }

  closeIcon = Icons.CLOSE

  texts: TranslationTexts = {
    title: {
      [InvoiceEntityId.CUSTOMER_INVOICES]: this.$t('components.print_or_save_invoices_dialog.title_client'),
      [InvoiceEntityId.PROFORMA_INVOICES]: this.$t('components.print_or_save_invoices_dialog.title_proforma'),
      [InvoiceEntityId.PROVIDER_INVOICES]: this.$t('components.print_or_save_invoices_dialog.title_provider')
    }
  }

  @Watch('showDialog')
  async checkIfBlockAppPointerEvents() {
    await this.$nextTick()
    const app = document.querySelector('.v-application')
    const dialog = document.querySelector('.v-dialog__content.v-dialog__content--active')
    if (app && dialog) {
      app.classList.add('print-or-save-invoice-dialog')
      dialog.classList.add('print-or-save-invoice-dialog')
    } else {
      app!.classList.remove('print-or-save-invoice-dialog')
    }
  }

  @Watch('invoiceGenerateReady')
  hasError(isReady: boolean) {
    if (isReady && this.invoiceGenerateError) {
      this.currentStep = this.steps.finalResumeList
    }
  }

  @Watch('invoiceGenerateOK')
  async getReady(isOK: boolean) {
    // descargamos el pdf si todo esta ok y si esta el check de imprimir
    if (isOK && this.printDocument) {
      await this.downloadPDF()
    }
    this.showAlert({
      type: AlertsTypes.SUCCESS,
      message: this.$t('components.template_generator.invoice_generated_ok'),
      componentWhereIsRendered: ComponentWhereIsRendered.GRID_TABLE
    })
    this.closeDialog()
  }

  get titleDialog() {
    return this.texts.title[this.idEntityType]
  }

  get disableConfiguration() {
    return !Boolean(this.templateSelected)
  }

  get disabledGenerate() {
    if (!Boolean(this.templateSelected)) {
      return true
    }
    return !((Boolean(this.templateSelected) && this.saveDocument) || this.printDocument)
  }

  get disabledDownload() {
    return false
  }

  async downloadPDF() {
    this.showSpinner = true
    await this.mergePdf()
    this.showSpinner = false
  }

  closeDialog() {
    this.currentStep = this.steps.selectInvoices
    this.$emit('closePrintOrSaveInvoiceDialog')
    this.templateSelected = null
    this.resetInvoices()
  }

  async cancelButton() {
    this.cancelPendingRequests()
    if (this.invoiceGenerate.length > 0) {
      this.invoiceGenerate.forEach(async ({ idDocument }) => {
        if (idDocument) {
          await this.deleteDocument(idDocument)
        }
      })
    }
    this.closeDialog()
  }

  printOrSaveInvoices() {
    this.currentStep = this.steps.generationProgress
    this.invoiceGenerate.forEach((invoice) => {
      generateInvoice(
        this.templateSelected,
        invoice.idEntity,
        invoice.idEntityType,
        this.saveDocument,
        this.printDocument
      )
    })
  }

  configButtons(actions: any) {
    const { print, save } = actions
    this.printDocument = Boolean(Number(print))
    this.saveDocument = Boolean(Number(save))
  }

  templateSelectedInvoice(templateSelected: ITemplate) {
    this.templateSelected = templateSelected
  }

  finish() {
    if (this.invoiceGenerateErrorAll) {
      this.finishedWithErrors()
    } else {
      this.finishedOk()
    }
    this.closeDialog()
  }

  finishedWithErrors() {
    this.showAlert({
      type: AlertsTypes.ERROR,
      message: this.$t('components.template_generator.invoice_generated_error'),
      componentWhereIsRendered: ComponentWhereIsRendered.GRID_TABLE
    })
  }

  async finishedOk() {
    if (this.printDocument) {
      await this.downloadPDF()
    }
    this.showAlert({
      type: AlertsTypes.SUCCESS,
      message: this.$t('components.template_generator.invoice_generated_ok'),
      componentWhereIsRendered: ComponentWhereIsRendered.GRID_TABLE
    })
  }

  destroyed() {
    this.templateSelected = null
    this.resetInvoices()
  }

  actionsButtons(args: { event: string }) {
    const { event } = args
    switch (event) {
      case PrintInvoiceEvents.CLOSE:
        this.closeDialog()
        break
      case PrintInvoiceEvents.GENERATE:
        this.printOrSaveInvoices()
        break
      case PrintInvoiceEvents.CANCEL:
        this.cancelButton()
        break
      case PrintInvoiceEvents.FINISH:
        this.finish()
        break
    }
  }
}
</script>

<style lang="scss" scoped>
.print-or-save-dialog-container {
  padding: 20px;

  .header {
    @include flex($justify-content: space-between);
    color: $corporate-color;
    margin-bottom: 20px;

    .v-card__title {
      font-family: $corporate-font;
      font-size: 24px;
      padding: 0;
    }

    .close-icon {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .wizard-container {
    @include flex($justify-content: space-between);
    padding: 0;
  }

  .actions-footer {
    padding: 0;
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
.v-application.print-or-save-invoice-dialog {
  pointer-events: none;
}

.v-dialog__content.v-dialog__content--active.print-or-save-invoice-dialog {
  background-color: $dialog-background-color;
}
</style>
