import { createElement } from '@syncfusion/ej2-base'
import { MultiSelect } from '@syncfusion/ej2-dropdowns'
import { MainService } from '@/services/MainService'
import { i18n } from '@/plugins/vue-i18n'
import { lastFilters } from '@/components/grids/CustomFilters/sharedVariables'
import { IFilter } from '@syncfusion/ej2-grids'
import { Grid } from '@syncfusion/ej2-vue-grids'

const filterMultiSelectSource: any = []
const filterMultiSelectItems: any = []
const isLoadedFlags: string[] = []

let _gridInstance: Grid
let multiSelectInstance: MultiSelect

const init = (gridInstance: Grid) => {
  _gridInstance = gridInstance
}

const customMultiSelectFilter: IFilter = {
  ui: {
    create: (args: any) => {
      const divFilters: HTMLElement | null = args.dialogObj.element.querySelector(
        '.e-flm_optrdiv'
      ) as HTMLElement | null

      if (divFilters) {
        divFilters.style.display = 'none'
      }

      const flValInput = createElement('div', { className: 'lf_custom-multi-select-filter' })
      args.target.appendChild(flValInput)

      const includeEmptyFilterOption = Boolean(
        args.column.customAttributes && args.column.customAttributes.includeEmptyFilterOption
      )

      multiSelectInstance = new MultiSelect({
        cssClass: 'custom-multiselect',
        dataSource: checkIfRenderEmptyOption(includeEmptyFilterOption, args),
        fields: {
          value: filterMultiSelectSource[args.column.field] ? filterMultiSelectSource[args.column.field].value : '',
          text: filterMultiSelectSource[args.column.field] ? filterMultiSelectSource[args.column.field].text : ''
        },
        filterType: 'Contains',
        ignoreAccent: true,
        itemTemplate: customItemTemplate(),
        mode: 'CheckBox',
        placeholder: i18n.t('components.grid_table.enter_search_multiselect').toString(),
        popupWidth: '217px',
        selectAllText: i18n.t('action_buttons.select_all').toString(),
        showSelectAll: true,
        unSelectAllText: i18n.t('action_buttons.select_all').toString()
      })

      multiSelectInstance.appendTo(flValInput)

      const divDropDownList: HTMLElement | null = args.dialogObj.element.querySelector(
        '.e-flmenu-valuediv'
      ) as HTMLElement | null

      if (divDropDownList) {
        divDropDownList.style.padding = '0'
      }
    },
    write: (args: any) => {
      if (lastFilters.length > 1) {
        multiSelectInstance.value = lastFilters
          .filter((obj: any) => obj.field === args.column.field)
          .map((obj: any) => obj.value)
      } else {
        multiSelectInstance.value = args.filteredValue ? [args.filteredValue] : []
      }
    },
    read: (args: any) => {
      _gridInstance.clearFiltering([args.column.field])
      args.fltrObj.filterByColumn(args.column.field, args.operator, multiSelectInstance.value)
    }
  }
}

const checkIfRenderEmptyOption = (includeEmptyFilterOption: boolean, args: any) => {
  const emptyItem = { id: '-1', name: i18n.t('components.grid_table.empty') }
  const items = filterMultiSelectItems[args.column.field]
  return includeEmptyFilterOption ? [emptyItem, ...items] : items
}

const customItemTemplate = () => {
  return `
    <div class="multiselect-item" title="\${name}">
      \${if(color)}
        <div style="background-color: \${color}"></div>
      \${/if}
      <span class="item-text">\${name}</span>
    </div>
  `
}

const setFilterMultiSelect = (columnConf: any) => {
  const { field, filterModuleSource } = columnConf

  filterMultiSelectSource[field] = filterModuleSource
  if (filterMultiSelectSource[field] && !isLoadedFlags.includes(field)) {
    const { endpoint } = filterMultiSelectSource[field]
    fetchFilterMultiSelectItemsByEndpoint(endpoint, field)
    isLoadedFlags.push(field)
  }

  return customMultiSelectFilter
}

const fetchFilterMultiSelectItemsByEndpoint = (endpoint: any, field: any) => {
  filterMultiSelectItems[field] = []
  if (endpoint) {
    new MainService().getData(endpoint).then(async (data: any) => {
      filterMultiSelectItems[field] = data.data
    })
  } else {
    filterMultiSelectItems[field] = filterMultiSelectSource[field].values || []
  }
}

export const resetLoadedCustomMultiselectFlags = (fields: string | string[]) => {
  const resetFlag = (field: string) => {
    const index = isLoadedFlags.indexOf(field)
    if (index !== -1) {
      isLoadedFlags.splice(index, 1)
    }
  }

  if (Array.isArray(fields)) {
    fields.forEach(resetFlag)
  } else {
    resetFlag(fields)
  }
}

export const getCustomMultiSelectFilter = (columnConf: any, gridInstance: Grid) => {
  init(gridInstance)
  return setFilterMultiSelect(columnConf)
}
