export interface Expedient {
  idFile: string | null
  description: string
  nig: string
}

export interface SummaryItem {
  alias: string
  detail: string
  totalAmount: string
}

export interface ActionsSummaryItem {
  alias: string
  detail: string
  total: string
}

export interface StageStatus {
  activeStages: number
  stageId: number
}

export interface ExpedientStage {
  activeStages: number
  stageId: number
}

export interface SectionToDuplicate {
  name: string
  id: number
}

interface ProfitabilitySummaryItem {
  label: string
  value: string
}

interface ProfitabilityTableHeaderItem {
  title: string
  key: string
  type: 'string' | 'currency'
}

interface ProfitabilityTableSummaryItem {
  key: string
  value: string
}

interface ProfitabilityTable {
  headers: ProfitabilityTableHeaderItem[]
  items: []
  name: string
  summary: ProfitabilityTableSummaryItem[]
}

export interface ProfitabilityDetail {
  summary: ProfitabilitySummaryItem[]
  tables: ProfitabilityTable[]
}

export interface ExpedientsState {
  actionsSummary: ActionsSummaryItem[]
  duplicateExpedientId: number | null
  economicDataSimpleGridItems: []
  expedientProfitabilityDetail: ProfitabilityDetail | {}
  expedients: Expedient[]
  lastExpedientSaved: number | null
  lastListExpedientFilter: []
  sectionsToDuplicate: SectionToDuplicate[]
  selectedExpedientInsurer: {}
  stageStatus: StageStatus[]
  stageStatusForm: string
  summary: SummaryItem[]
  taxesItems: []
  total: string
  expedientCustomerFact: null | ExpedientCustomerFact
}

export interface ExpedientCustomerFact {
  email: string
  id: number
  name: string
}

export const expedientsMutationTypes = {
  FETCH_EXPEDIENTS: 'FETCH_EXPEDIENTS',
  FETCH_EXPEDIENT_ACTIONS_SUMMARY: 'FETCH_EXPEDIENT_ACTIONS_SUMMARY',
  FETCH_EXPEDIENT_ECONOMIC_DATA: 'FETCH_EXPEDIENT_ECONOMIC_DATA',
  FETCH_EXPEDIENT_SECTIONS_TO_DUPLICATE: 'FETCH_EXPEDIENT_SECTIONS_TO_DUPLICATE',
  FETCH_EXPEDIENT_STAGE_STATE: 'FETCH_EXPEDIENT_STAGE_STATE',
  FETCH_EXPEDIENT_STAGE_STATE_FORM: 'FETCH_EXPEDIENT_STAGE_STATE_FORM',
  FETCH_EXPEDIENT_SUMMARY: 'FETCH_EXPEDIENT_SUMMARY',
  FETCH_TOTAL_NUMBER_OF_EXPEDIENTS: 'FETCH_TOTAL_NUMBER_OF_EXPEDIENTS',
  LAST_EXPEDIENT_SAVED: 'LAST_EXPEDIENT_SAVED',
  RESET_EXPEDIENT_ACTIONS_SUMMARY: 'RESET_EXPEDIENT_ACTIONS_SUMMARY',
  RESET_EXPEDIENT_PROFITABILITY_DETAIL: 'RESET_EXPEDIENT_PROFITABILITY_DETAIL',
  RESET_EXPEDIENT_SUMMARY: 'RESET_EXPEDIENT_SUMMARY',
  RESET_SELECTED_EXPEDIENT_INSURER: 'RESET_SELECTED_EXPEDIENT_INSURER',
  RESET_TAXES_ITEMS: 'RESET_TAXES_ITEMS',
  SET_DUPLICATE_EXPEDIENT_ID: 'SET_DUPLICATE_EXPEDIENT_ID',
  SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS: 'SET_ECONOMIC_DATA_SIMPLE_GRID_ITEMS',
  SET_EXPEDIENT_PROFITABILITY_DETAIL: 'SET_EXPEDIENT_PROFITABILITY_DETAIL',
  SET_LAST_LISTEXPEDIENT_FILTER: 'SET_LAST_LISTEXPEDIENT_FILTER',
  SET_SELECTED_EXPEDIENT_INSURER: 'SET_SELECTED_EXPEDIENT_INSURER',
  SET_TAXES_ITEMS: 'SET_TAXES_ITEMS',
  SAVE_EXPEDIENT_CUSTOMER_FACT: 'SAVE_EXPEDIENT_CUSTOMER_FACT',
  RESET_EXPEDIENT_CUSTOMER_FACT: 'RESET_EXPEDIENT_CUSTOMER_FACT'
}

export interface Court {
  email: string
  id: string
  idCourt: string
  idExpedient: string
  idStage: string
  mobilePhone: string
  name: string
  notes: string
  numberAppeal: string
  numberExecution: string
  phone: string
  province: string
  publish: string
  publishText: string
  stage: string
  town: string
  writNumber: string
}

export interface IntervenerType {
  id: string
  name: string
  type: string
}

export interface Intervener {
  id: string
  name: string
  alias: string
  idIntervener: string
  idIntervenerType: string
  intervenerType: IntervenerType
}
