<template lang="pug">

  div(:class="[breakpointClass, 'lexon-button-container']")
    button(
      @click.stop="lexonButtonEvent"
      :class="['lexon-button', { 'disabled': disabled }]"
    ) {{ getText }}

</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class LexonButtonComponent extends Vue {
  @Prop({
    type: String
  })
  text!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Emit()
  lexonButtonEvent() {}

  get getText() {
    return this.text ? this.text : this.$t('components.lexon_button.default')
  }

  get breakpointClass() {
    return String(this.$vuetify.breakpoint.name)
  }
}
</script>

<style lang="scss" scoped>
.lexon-button-container.form-element {
  width: 100%;
  margin-bottom: 16px;

  &.lg .lexon-button,
  &.xl .lexon-button {
    position: relative;
    top: 9px;
  }

  .lexon-button {
    @include main-action-button--rectangle;
    width: 100%;
    margin-left: 0;
  }
}

.fieldset .lexon-button-container.form-element {
  &.xl .lexon-button {
    top: 18px;
  }
}
</style>
