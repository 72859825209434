interface RelatedDataType {
  [index: string]: object
}

interface ShowRelatedFormType {
  [index: string]: boolean
}

interface FormIsValidType {
  [index: string]: boolean
}

interface CustomValidateField {
  [index: string]: string
}

export interface FormsState {
  formIsValid: FormIsValidType
  registerFormData: object
  showRelatedForm: ShowRelatedFormType
  showAditionalForm: ShowRelatedFormType
  validateFormFlag: boolean
  loadingFormData: boolean
  relatedData: RelatedDataType
  customValidateField: CustomValidateField
}

export const formsMutationTypes = {
  CHANGE_RELATED_FORM_STATUS: 'CHANGE_RELATED_FORM_STATUS',
  CLEAR_FORM_IS_VALID: 'CLEAR_FORM_IS_VALID',
  PREPARE_REGISTER_FORM_DATA: 'PREPARE_REGISTER_FORM_DATA',
  RESET_FORM_IS_VALID: 'RESET_FORM_IS_VALID',
  RESET_REGISTER_FORM_DATA: 'RESET_REGISTER_FORM_DATA',
  RESET_RELATED_DATA: 'RESET_RELATED_DATA',
  RESET_CUSTOM_VALIDATE_FIELD: 'RESET_CUSTOM_VALIDATE_FIELD',
  SAVE_NEW_REGISTER: 'SAVE_NEW_REGISTER',
  SET_FORM_IS_VALID: 'SET_FORM_IS_VALID',
  SET_LOADING_FORM_DATA: 'SET_LOADING_FORM_DATA',
  SET_RELATED_DATA: 'SET_RELATED_DATA',
  SET_CUSTOM_VALIDATE_FIELD: 'SET_CUSTOM_VALIDATE_FIELD',
  VALIDATE_FORM: 'VALIDATE_FORM'
}

export const customValidatorTypes = {
  DNI: 'dni'
}
