<template lang="pug">

ValidationProvider(
  ref="numericValidator"
  :rules="validationRules"
  :name="validatorName"
  :vid="validatorName"
  immediate
  v-slot="{ errors }"
  tag="div"
)
  div(
    :class="[{ 'error-styles': errors[0] }, { 'disabled-field': disabled },'lf-numeric-container', percentage?'lf-percentage-container':'']"
  )
    div(class="label-container")
      span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ label }}
      span(
        v-if="showInfo"
        :class="[icons.info, 'label-icon']"
      )
    ejs-numerictextbox(
      ref="numericTextBox"
      :id="id"
      :name="name"
      v-model="innerValue"
      :showSpinButton="false"
      :showClearButton="true"
      :placeholder="placeholder"
      :format="format"
      :decimals="decimals"
      :min="minValue"
      :max="maxValue"
      :validateDecimalOnType="true"
      :class="[{ 'error-styles': errors[0] }, 'lf-numeric-input']"
      @blur="emitValueOnBlur"
      :created="onTextBoxCreated"
      :destroyed="onTextBoxDestroyed"
    )

    div(class="messages-container")
      span(v-if="errors.length" class="alert-message") {{ errors[0] }}
      span(v-else class="help-message") {{ helpText }}

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { NumericTextBoxPlugin } from '@syncfusion/ej2-vue-inputs'
import { ValidationProvider } from 'vee-validate'
import { Icons } from '@/icons/icons'
import NumericTextBoxClearButtonMixin from '@/mixins/NumericTextBoxClearButtonMixin.vue'
import { mixins } from 'vue-class-component'

Vue.use(NumericTextBoxPlugin)

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonNumericComponent extends mixins(NumericTextBoxClearButtonMixin) {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showInfo!: boolean

  @Prop({
    type: [Number, String]
  })
  value!: number

  @Prop({
    type: String
  })
  id!: string

  @Prop({
    type: String
  })
  name!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: String,
    default: '####'
  })
  format!: string

  @Prop({
    type: Number,
    default: 0
  })
  decimals!: number

  @Prop({
    type: Boolean,
    default: false
  })
  percentage!: boolean

  @Prop({
    type: String
  })
  validatorName!: string

  @Prop({
    type: Number,
    default: null
  })
  min!: number

  @Prop({
    type: Number,
    default: null
  })
  max!: number

  @Prop({
    type: Number,
    default: null
  })
  defaultValue!: number | null

  $refs!: {
    numericValidator: InstanceType<typeof ValidationProvider>
  }

  icons = {
    info: Icons.INFO_FULL
  }

  innerValue: any = this.defaultValue

  @Watch('innerValue')
  changedInnerValue(newVal: string) {
    this.validateField()
    this.emitValue(newVal)
  }

  @Watch('value')
  changedValue(value: string) {
    this.innerValue = value
  }

  @Emit()
  emitValue(value: any) {
    return this.$emit('input', value)
  }

  @Emit()
  emitValueOnBlur(event: any) {
    if (event.value) {
      return this.$emit('blur', event.value)
    }
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }

  get minValue() {
    if (this.percentage) {
      return 1
    }
    return this.min ? this.min : null
  }

  get maxValue() {
    if (this.percentage) {
      return 100
    }
    return this.max ? this.max : 9999999
  }

  validateField() {
    if (this.$refs.numericValidator) {
      return this.$refs.numericValidator.validate()
    }
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-numeric-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  width: 100%;

  .label-container {
    @include flex;
    height: 18px;
    color: $corporate-color;

    .label-text {
      @include label-text;
      position: relative;

      &:after {
        position: absolute;
        right: -4px;
      }
    }

    .label-icon {
      font-size: 15px;
    }
  }

  ::v-deep .e-control-wrapper.e-numeric.e-input-group {
    @include textfield-styles;
    position: relative;
    margin-bottom: 0;
    color: $gray-01 !important;
    border-color: $gray-03 !important;

    &:hover {
      border-color: $corporate-color !important;
    }

    .lf-numeric-input {
      width: 100%;

      &::selection {
        background-color: $selection-color !important;
        color: $gray-01 !important;
      }

      &::placeholder {
        font-size: 12px;
        color: $gray-02;
      }
    }

    &.e-input-focus {
      @include borders;
      background-color: transparent;
      border: 1px solid $gray-03 !important;
    }

    .e-clear-icon::before {
      font-family: $lf-icons-font;
      font-size: 20px;
      color: $corporate-color;
      content: '\e935';
    }
  }

  &.disabled-field {
    pointer-events: none;

    .label-container .label-text {
      color: $disabled-color;

      &::after {
        color: $disabled-color;
      }
    }

    ::v-deep .e-input-group.e-control-wrapper.e-numeric {
      border-color: $gray-03 !important;
      background-color: $gray-04;

      .lf-numeric-input {
        color: $disabled-color;
      }

      .lf-numeric-input::placeholder {
        color: $disabled-color;
      }

      &::after {
        color: $disabled-color;
      }

      &:hover {
        border-color: $error-color !important;
        background-color: $red-07;
      }
    }

    .help-message {
      color: $disabled-color;
    }
  }

  &.error-styles {
    .label-container .label-text {
      color: $error-color;
    }

    ::v-deep .e-input-group.e-control-wrapper.e-numeric {
      border-color: $error-color !important;
      background-color: $red-07;

      .e-clear-icon::before {
        color: $error-color;
      }

      .lf-numeric-input {
        color: $error-color;
      }

      .lf-numeric-input::placeholder {
        font-size: 12px;
        color: $error-color;
      }

      &::after {
        color: $error-color;
      }

      &:hover {
        border-color: $error-color !important;
        background-color: $red-07;
      }
    }
  }
}
.lf-numeric-container.lf-percentage-container {
  ::v-deep .e-control-wrapper.e-numeric.e-input-group {
    &::after {
      font-family: $lf-icons-font;
      font-size: 20px;
      content: '\e9b7';
      position: absolute;
      top: 5px;
      left: calc(100% - 28px);
      color: $corporate-color;
    }
    .e-clear-icon::before {
      margin-right: 22px;
    }
  }
}
</style>
