import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { hasPermission } from '@/helpers/security'
import { onAccessTokenRefreshed } from '@/plugins/axios'
import { SecurityCapacities, SecurityVerbs } from '@/store/modules/security/securityTypes'
import { AuthData } from '@/store/modules/auth/authTypes'

export const authenticate = async (authData: AuthData): Promise<void> => {
  onAccessTokenRefreshed(authData.accessToken)
  await store.dispatch(`${ ModuleNamespaces.AUTH }/setAuthDataAction`, authData)
}

export const loadInitConfig = async (): Promise<void> => {
  await store.dispatch(`${ ModuleNamespaces.MENUS }/fetchConfigurationMenu`)
  await store.dispatch(`${ ModuleNamespaces.MENUS }/fetchSiteMenu`)
  await store.dispatch(`${ ModuleNamespaces.CONFIGURATION }/fetchUserProfileConfiguration`)
  await store.dispatch(`${ ModuleNamespaces.EXPEDIENTS }/fetchExpedientSectionsToDuplicate`)
  if (hasPermission(SecurityVerbs.VIEW, SecurityCapacities.TASK)) {
    await store.dispatch(`${ ModuleNamespaces.TASKS }/fetchControlSidebarTasksObject`)
  }
}

export const reloadApp = (path: string): void => {
  window.location.href = path
}
