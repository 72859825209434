<template lang="pug">

section(v-if="allLoaded" class="dashboard-container")

  DashboardWidgetSelectorComponent(
    :widgets="widgets"
    :allowDraggingProp="allowDragAndDrop"
    @saveSelectedWidgets="onSaveSelectedWidgets"
    @blockDragAndDrop="onBlockDragAndDrop"
  )

  DashboardWidgetsContainerComponent(
    :widgets="widgets"
    :showSpinner="showSpinner"
    :allowDraggingProp="allowDragAndDrop"
     @saveWidgetsPositions="onSaveWidgetsPositions"
  )

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import store from '@/store/store'
import DashboardWidgetsContainerComponent from '@/components/widgets/DashboardWidgets/DashboardWidgetsContainerComponent.vue'
import DashboardWidgetSelectorComponent from '@/components/widgets/DashboardWidgets/DashboardWidgetSelectorComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { from, next, to } from '@/router/types/routerTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { hasPermission } from '@/helpers/security'
import { SecurityCapacities, SecurityVerbs } from '@/store/modules/security/securityTypes'
import { UserType } from '@/store/modules/auth/authTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextMenuPlugin } from '@syncfusion/ej2-vue-navigations'
import {
  DashboardWidgetItem,
  DashboardWidgetsUserConfiguration
} from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { ListNames } from '@/store/modules/configuration/configurationTypes'

Vue.use(ContextMenuPlugin)

const authModule: string = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const menusModule: string = ModuleNamespaces.MENUS
const spinnerModule: string = ModuleNamespaces.SPINNER
const tasksModule: string = ModuleNamespaces.TASKS

@Component({
  components: {
    DashboardWidgetsContainerComponent,
    DashboardWidgetSelectorComponent
  }
})
export default class DashboardView extends Vue {
  @Action('fetchDashboardWidgetsConfiguration', { namespace: dashboardWidgetsModule })
  fetchCurrentWidgetsConfigurationAction: (id: string) => Promise<DashboardWidgetsUserConfiguration>

  @Action('saveUserWidgetsCongiguration', { namespace: dashboardWidgetsModule })
  saveWidgetsConfiguration: ({}) => void

  @Mutation('RESET_DASHBOARD_WIDGET_SPINNERS', { namespace: spinnerModule })
  resetDashboardWidgetSpinners: () => void

  @Mutation('RESET_ALL_DASHBOARD_WIDGET_SPINNERS', { namespace: spinnerModule })
  resetAllDashboardWidgetSpinners: () => void

  @Getter('getDashboardWidgetsConfiguration', { namespace: dashboardWidgetsModule })
  widgetsConfigurationGetter: any

  @Getter('getDashboardWidgetsConfigurationLoadedFlag', { namespace: dashboardWidgetsModule })
  widgetsConfigurationLoadedFlagGetter: boolean

  @Getter('getUserId', { namespace: authModule })
  userId: string

  allLoaded = false

  widgets: DashboardWidgetItem[] = []

  newWidgetsConfig: DashboardWidgetItem[] = []

  showSpinner: boolean = true

  allowDragAndDrop: boolean | null = null

  @Watch('allLoaded')
  checkIfHideSpinnerLayer(value: boolean) {
    if (value) {
      store.dispatch(`${spinnerModule}/hideSpinnerLayer`)
    }
  }

  get parseSavedData(): DashboardWidgetsUserConfiguration {
    return {
      widgets: this.newWidgetsConfig.length ? this.newWidgetsConfig : this.widgets,
      userSettings: {
        allowDragging: Boolean(this.allowDragAndDrop)
      }
    }
  }

  get newConfiguration() {
    return {
      IdUser: this.userId,
      Alias: ListNames.DASHBOARD_WIDGETS,
      Config: this.parseSavedData
    }
  }

  async beforeRouteEnter(to: to, _from: from, next: next<DashboardView>) {
    localStorage.removeItem('pre-auth-data')

    await store.dispatch(`${configurationModule}/fetchUserProfileConfiguration`)

    if (store.getters[`${ModuleNamespaces.MENUS}/getConfigurationMenu`].length === 0) {
      await store.dispatch(`${menusModule}/fetchConfigurationMenu`)
    }

    if (store.getters[`${ModuleNamespaces.MENUS}/getSiteMenu`].length === 0) {
      await store.dispatch(`${menusModule}/fetchSiteMenu`)
    }

    if (store.getters[`${ModuleNamespaces.EXPEDIENTS}/getExpedientSectionsToDuplicate`].length === 0) {
      store.dispatch(`${expedientsModule}/fetchExpedientSectionsToDuplicate`)
    }

    if (hasPermission(SecurityVerbs.VIEW, SecurityCapacities.TASK)) {
      store.dispatch(`${tasksModule}/fetchControlSidebarTasksObject`)
    }

    const userType: UserType = store.getters[`${ModuleNamespaces.AUTH}/getUserType`]
    if (UserType.LEXON_DMS === userType) {
      if (to.name === URLS.DASHBOARD) {
        next(`/${URLS.REPOSITORY}`)
      }
    }

    if (store.getters[`${ModuleNamespaces.AUTH}/getIsPortalUser`] && to.name === URLS.DASHBOARD) {
      next(`/${URLS.EXPEDIENTS}`)
    }

    next()
  }

  beforeDestroy() {
    // Reseteamos estos widgets porque siempre tienen que pedir datos al pasar por el dashboard
    this.resetDashboardWidgetSpinners()
  }

  created() {
    this.fetchAndRenderWidgets()
  }

  mounted() {
    this.allLoaded = true
  }

  async fetchAndRenderWidgets() {
    if (!this.widgetsConfigurationLoadedFlagGetter) {
      await this.fetchCurrentWidgetsConfigurationAction(ListNames.DASHBOARD_WIDGETS)
    }
    const widgetsConfiguration: DashboardWidgetsUserConfiguration = JSON.parse(
      this.widgetsConfigurationGetter['Config']
    )
    const { widgets, userSettings } = widgetsConfiguration
    this.widgets = widgets
    this.allowDragAndDrop = userSettings.allowDragging
    this.showSpinner = false
  }

  async onSaveSelectedWidgets(widgetsConfig: DashboardWidgetItem[]) {
    this.showSpinner = true
    this.newWidgetsConfig = widgetsConfig
    await this.saveWidgetsConfiguration(this.newConfiguration)
    this.resetAllDashboardWidgetSpinners()
    this.fetchAndRenderWidgets()
  }

  async onSaveWidgetsPositions(widgetsConfig: DashboardWidgetItem[]) {
    this.newWidgetsConfig = widgetsConfig
    await this.saveWidgetsConfiguration(this.newConfiguration)
  }

  onBlockDragAndDrop(allowDragging: boolean) {
    this.allowDragAndDrop = allowDragging
    this.saveWidgetsConfiguration(this.newConfiguration)
  }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 167px 30px 15px;
}

@include tabletAndMore {
  .dashboard-container {
    padding-top: $app-bar-height + 15px;
    grid-gap: 40px 29px;
  }
}

@include desktopLarge {
  .dashboard-container {
    grid-template-columns: 49% 49%;
  }

  .info-resume {
    padding-left: 20px;
  }
}
</style>
