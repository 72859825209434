<template lang="pug">
  ValidationProvider(
    :rules="validationRules"
    v-slot="{ errors }"
    tag="div"
    :vid="$attrs.id"
    )
    div(
      :class="[{ 'error-styles': errors[0] },'lf-switch-container', { 'disabled-field': disabled, 'lf-custom-switch-style': customStyle }]"
    )
      div(v-if="customStyle" class="lf-custom-switch-style-container")
        span(:class="[ customStyle.icon, 'lf-custom-switch-style-icon' ]")
        span(class="lf-custom-switch-style-text" v-html="customStyle.text")
      v-spacer(v-if="customStyle")
      div
        div(v-if="label" class="lf-switch label-container" @click="emitClickEvent")
          v-switch(
            v-bind="$attrs"
            v-model="innerValue"
            :id="id"
            :label="label"
            :disabled="disabled"
            :class="innerValue ? 'on' : 'off'"
            @change="emitValue"
          )
            template(v-slot:append)
              span(v-if="innerValue" @click="toggleValue" :class="icons.switchOn")
              span(v-else @click="toggleValue" :class="icons.switchOff")
          span(
            v-if="requiredField"
            class="required-field") *
          v-tooltip(
              v-if="showInfo"
              top
              color="primary"
              dark
            )
            template(v-slot:activator="{ on, attrs }")
              span(v-bind="attrs" v-on="on" :class="[icons.info, 'label-icon']")
            span(class="show-info-message") {{ showInfo }}
        div(class="messages-container")
          span(v-if="errors.length" class="alert-message") {{ errors[0] }}
          span(v-else class="help-message") {{ helpText }}
</template>

<script lang="ts">
import { Getter } from 'vuex-class'
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Icons } from '@/icons/icons'

const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonSwitchComponent extends Vue {
  @Getter('getCustomConfigText', { namespace: configurationModule })
  getCustomConfigText: string

  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String
  })
  showInfo!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: String,
    required: true
  })
  id!: string

  @Prop({
    type: [String, Number, Boolean],
    default: ''
  })
  value!: string | number | boolean

  @Prop({
    type: Boolean,
    default: false
  })
  fullWidth!: boolean

  @Prop({
    type: String,
    default: 'text'
  })
  type!: string

  @Prop({
    type: [Boolean, Object],
    default: false
  })
  customStyle!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  returnBoolean!: boolean

  icons = {
    clearIcon: Icons.CLOSE_ROUND,
    info: Icons.INFO_FULL,
    switchOn: Icons.SWITCH_ON,
    switchOff: Icons.SWITCH_OFF
  }

  innerValue: boolean = false

  @Emit()
  emitValue(value: any) {
    const val: string = value ? '1' : '0'
    return this.$emit('input', val)
  }

  @Emit()
  emitClickEvent() {
    return this.innerValue
  }

  @Watch('value')
  changedVal() {
    this.changeValue()
  }

  @Watch('innerValue')
  changedValue(newVal: boolean) {
    const val: string = newVal ? '1' : '0'
    const finalValue = this.returnBoolean ? Boolean(Number(val)) : val
    this.$emit('input', finalValue)
  }

  @Watch('customConfigText')
  changedCustomConfigTextValue(newVal: boolean) {
    ;(this as any).customStyle.text = newVal
  }

  get customConfigText() {
    return this.getCustomConfigText
  }

  toggleValue() {
    this.innerValue = !this.innerValue
  }

  created() {
    this.changeValue()
    if (this.customStyle !== false) {
      this.checkCustomStyleText()
    }
  }
  checkCustomStyleText() {
    if (this.getCustomConfigText !== '') {
      ;(this as any).customStyle.text = this.customConfigText
    }
  }
  changeValue() {
    let value = this.value
    if ('string' === typeof this.value) {
      value = Number(this.value)
    }
    this.innerValue = !!value
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }
}
</script>

<style lang="scss" scoped>
$text-color: $gray-01;

.lf-switch-container {
  @include flex($justify-content: flex-start);
  @include textfield-messages;
  width: 100%;

  ::v-deep .v-label {
    @include label-text;
  }

  ::v-deep .v-input--selection-controls {
    flex-direction: row-reverse;
    align-items: center;
  }

  ::v-deep .v-input--selection-controls__input {
    display: none;
  }

  ::v-deep .v-messages {
    display: none;
  }

  ::v-deep .v-input__append-outer {
    font-size: 40px;
    margin: 0px;
    cursor: pointer;
  }

  ::v-deep .v-input__slot {
    margin: 0;
  }

  ::v-deep .lf-icon-switch-left {
    color: $gray-02;
  }

  &.disabled-field {
    pointer-events: none;

    .label-container .required-field,
    .label-container .label-text,
    .messages-container .help-messag,
    .lf-icon-switch-left,
    ::v-deep .v-label--is-disabled {
      color: $gray-02;
    }

    .lf-icon-switch-right {
      color: $blue-05;
    }
  }
  &.lf-custom-switch-style {
    height: 75px;
    background-color: $blue-07;
    font-family: $corporate-font-bold;
    font-size: 12px;
    color: $blue-04;
    padding: 0 10% 0 12px;
    margin-bottom: 20px;

    .lf-custom-switch-style-container {
      @include flex;
      margin-right: 10px;

      .lf-custom-switch-style-icon {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  &.error-styles {
    .label-container .label-text {
      color: $error-color;
    }
  }

  .on ::v-deep .v-label--is-disabled {
    color: $blue-05;
  }
}

.compressed .lf-switch-container ::v-deep .messages-container {
  display: none;
}
</style>
