<template lang="pug">
  div(
    class="no-results-container"
    :class="[withBordersClass, verticalAlignCenterClass]"
  )
    span(:class="[noResultsIcon, 'icon']")
    div(class="no-results-text")
      p(class="first-paragraph") {{ noResultsFirstText }}
      p(class="second-paragraph") {{ noResultsSecondText }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'

@Component
export default class NoResultsComponent extends Vue {
  @Prop({
    type: String,
    default: Icons.CLOSE_SEARCH
  })
  noResultsIcon!: string

  @Prop({
    type: String,
    default: i18n.t('components.no_results.no_search_results_text')
  })
  noResultsFirstText!: string

  @Prop({
    type: String,
    default: i18n.t('components.no_results.try_again')
  })
  noResultsSecondText!: string

  @Prop({
    type: Boolean,
    default: true
  })
  withBorders!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  verticalAlignCenter!: boolean

  get withBordersClass() {
    return this.withBorders ? 'with-borders' : ''
  }

  get verticalAlignCenterClass() {
    return this.verticalAlignCenter ? 'vertical-align-center' : ''
  }
}
</script>

<style lang="scss" scoped>
.no-results-container {
  @include flex($flex-direction: column);
  color: $blue-05;
  padding: 80px 10px 120px;
  margin-top: 2px;

  &.with-borders {
    @include borders;
    @include border($direction: top, $width: 6px);
  }

  &.vertical-align-center {
    height: 99%;
  }

  .icon {
    font-size: 100px;
  }

  .no-results-text {
    @include flex($flex-direction: column);
    font-family: $corporate-font;

    .first-paragraph {
      font-size: 21px;
      line-height: 24px;
      margin: 10px 0 6px;
    }

    .second-paragraph {
      font-size: 15px;
      line-height: 24px;
    }
  }
}
</style>
