import { MutationTree } from 'vuex'
import { DashboardWidgetsState } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

export const mutations: MutationTree<DashboardWidgetsState> = {
  SET_DASHBOARD_WIDGETS_CONFIGURATION(state, config) {
    state.dashboardWidgetsConfiguration = config
  },
  SET_DASHBOARD_WIDGETS_CONFIGURATION_LOADED_FLAG(state, status) {
    state.dashboardWidgetsConfigurationLoadedFlag = status
  },
  SET_DASHBOARD_LAST_EXPEDIENTS_LIST(state, expedients) {
    state.dashboardLastExpedientsList = expedients
  },
  SET_DASHBOARD_LAST_CUSTOMERS_LIST(state, customers) {
    state.dashboardLastCustomersList = customers
  },
  SET_DASHBOARD_EXPEDIENT_RESUME(state, resume) {
    state.dashboardExpedientResume = resume
  },
  SET_DASHBOARD_EXPEDIENTS_COUNTER_CHART(state, data) {
    state.dashboardExpedientsCounterChart = data
  },
  SET_DASHBOARD_RECENT_ACTIVITY(state, data) {
    state.dashboardRecentActivityItems = data
  },
  SET_DASHBOARD_LAST_ACTIONS_LIST(state, actions) {
    state.dashboardLastActionsList = actions
  },
  SET_DASHBOARD_CUSTOMER_RESUME(state, resume) {
    state.dashboardCustomerResume = resume
  },
  SET_DASHBOARD_CUSTOMERS_COUNTER_CHART(state, data) {
    state.dashboardCustomersCounterChart = data
  },
  SET_DASHBOARD_LAST_DOCUMENTS_LIST(state, documents) {
    state.dashboardLastDocumentsList = documents
  },
  SET_DASHBOARD_INACTIVE_EXPEDIENTS(state, expedients) {
    state.dashboardInactiveExpedientsList = expedients
  },
  SET_DASHBOARD_UNPAID_INVOICES_LIST(state, invoices) {
    state.dashboardUnpaidInvoicesList = invoices
  },
  SET_DASHBOARD_AMOUNT_OUTSTANDING_RESUME(state, resume) {
    state.dashboardAmountOutstandingResume = resume
  },
  SET_DASHBOARD_INVOICED_AMOUNTS_RESUME(state, resume) {
    state.dashboardInvoicedAmountsResume = resume
  },
  SET_DASHBOARD_OFFICE_DAILY_WORKING_TIME_RESUME(state, resume) {
    state.dashboardOfficeDailyWorkingTimeResume = resume
  },
  SET_DASHBOARD_OFFICE_WEEKLY_WORKING_TIME_RESUME(state, resume) {
    state.dashboardOfficeWeeklyWorkingTimeResume = resume
  },
  SET_DASHBOARD_OFFICE_MONTHLY_WORKING_TIME_RESUME(state, resume) {
    state.dashboardOfficeMonthlyWorkingTimeResume = resume
  },
  SET_DASHBOARD_USER_DAILY_WORKING_TIME_RESUME(state, resume) {
    state.dashboardUserDailyWorkingTimeResume = resume
  },
  SET_DASHBOARD_USER_WEEKLY_WORKING_TIME_RESUME(state, resume) {
    state.dashboardUserWeeklyWorkingTimeResume = resume
  },
  SET_DASHBOARD_USER_MONTHLY_WORKING_TIME_RESUME(state, resume) {
    state.dashboardUserMonthlyWorkingTimeResume = resume
  }
}
