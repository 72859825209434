export interface AlertsState {
  showAlert: boolean
  alertData: AlertsData | null
}

export interface AlertsData {
  type: string
  message: string
  componentWhereIsRendered: string
}

export enum AlertsTypes {
  ERROR = 'error-text',
  INFO = 'info-text',
  SUCCESS = 'success-text',
  WARNING = 'warning-text'
}

export enum ComponentWhereIsRendered {
  GRID_PIVOT_TABLE = 'GridPivotTableComponent',
  GRID_TABLE = 'GridTableComponent',
  SIMPLE_GRID_TABLE = 'SimpleGridTableComponent',
  LOGIN_VIEW = 'LoginView',
  PERMISSIONS = 'LexonPermissions',
  TABS_COMPONENT = 'TabsComponent',
  TABS_VIEW = 'TabsView'
}

export const alertsMutationTypes = {
  SHOW_ALERT: 'SHOW_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT'
}
