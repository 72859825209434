// https://docs.mixpanel.com/docs/tracking/javascript-quickstart
import mixpanel from 'mixpanel-browser'
import Vue, { VueConstructor } from 'vue'
import { getTrackUser } from './userService'
import { Tracker } from './types'

const initMixpanel = {
  debug: process.env.NODE_ENV === 'development',
  track_pageview: false,
  persistence: 'localStorage'
}

const tracker: Tracker = {
  init: (token: string, config = {}) => {
    if (tracker.checkMixPanel()) {
      mixpanel.init(token, config)
    }
  },
  trackEvent: (event: string, properties: object = {}) => {
    if (tracker.checkMixPanel()) {
      mixpanel.track(event, properties)
    }
  },
  // trackError: (event: string, error: string | unknown, information: string | object | unknown = '') => {
  //   if (tracker.checkMixPanel()) {
  //     const fullError: ErrorInfo = {
  //       message: error instanceof Error ? error.message : error,
  //       name: error instanceof Error ? error.name : '',
  //       traces: error instanceof Error ? error.stack : ''
  //     }
  //     const errorData: ErrorData = {
  //       error: fullError,
  //       info: information
  //     }
  //     // mixpanel.track(TrackerEvents.ERROR, { event, errorData })
  //   }
  // },
  register: () => {
    if (tracker.checkMixPanel()) {
      const user = getTrackUser()
      mixpanel.identify(user.id)
      mixpanel.people.set_once(user)
      mixpanel.register(user)
    }
  },
  reset: () => {
    if (tracker.checkMixPanel()) {
      mixpanel.track('logout')
      mixpanel.reset()
    }
  },
  pageView: (properties: object = {}) => {
    if (tracker.checkMixPanel()) {
      // mixpanel.track(TrackerEvents.VIEW_PAGE, properties)
      mixpanel.track_pageview(properties)
    }
  },
  checkMixPanel: () => {
    return Boolean(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN)
  }
}

const trackerPlugin = {
  install: (vue: VueConstructor) => {
    tracker.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN, initMixpanel)
    vue.prototype.$tracker = tracker
  }
}
export default trackerPlugin

export function trackEvent(event: string, properties: object = {}) {
  Vue.prototype.$tracker.trackEvent(event, properties)
}
export function trackUser() {
  Vue.prototype.$tracker.register()
}
export function resetTracker() {
  Vue.prototype.$tracker.reset()
}
export function trackPageView(properties: object = {}) {
  Vue.prototype.$tracker.pageView(properties)
}
