<template lang="pug">

  WidgetBaseComponent(
    class="expedient-summary-widget"
    :headerIcon="headerIcon"
    :loadedData="allDataLoaded"
  )
    //- HEADER
    template(v-slot:header)
      h2 {{ expedientSummaryData.title }}

    //- BODY
    template(v-slot:body)
      section(class="items-container")
        div(v-for="(item, index) in expedientSummaryData.body" :key="index" class="item")
          span(class="item-label") {{ `${item.label}:` }}
          div(class="item-data") {{ item.data }}

    //- FOOTER
    template(v-slot:footer)
      div(v-for="(item, index) in expedientSummaryData.footer" class="footer-icons-container")
        span(:class="[item.icon, 'footer-icon']")
        div(class="footer-data")
          label {{ item.label }}
          span {{ item.data }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'

const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES

@Component({
  components: {
    WidgetBaseComponent
  }
})
export default class ExpedientSummaryWidget extends Vue {
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterDataAction: ({}) => Promise<{}>

  @Mutation('REMOVE_SELECTED_REGISTER_DATA_CONTEXT', { namespace: selectedRegisterModule })
  removeSelectedRegisterDataContextMutation: (context: string) => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterDataGetter: (context: string) => {}
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint
  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  headerIcon = Icons.EXPEDIENT

  contexts = {
    expedientSummary: ContextName.EXPEDIENT_SUMMARY_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get expedientSummaryData() {
    return (this as any).selectedRegisterDataGetter(this.contexts.expedientSummary)[this.contexts.expedientSummary]
  }

  get allDataLoaded() {
    return !!(this.expedientSummaryData && Object.keys(this.expedientSummaryData).length)
  }

  async created() {
    this.fetchSummaryInfo()
  }

  beforeDestroy() {
    this.removeSelectedRegisterDataContextMutation(this.contexts.expedientSummary)
  }

  fetchSummaryInfo() {
    const endpoint: string = this.endpoints(this.entity(this.contexts.expedientContext).type).get

    this.fetchSelectedRegisterDataAction({
      endpoint,
      context: this.contexts.expedientSummary,
      post: {
        idExpedient: this.selectedExpedientId,
        source: this.contexts.expedientSummary
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.expedient-summary-widget {
  .items-container {
    @include flex($flex-direction: column, $justify-content: flex-start);
    width: 100%;
    height: 100%;
    padding: 0 1.6%;

    .item {
      @include flex($justify-content: flex-start);
      width: 100%;
      height: 16%;

      .item-label {
        min-width: 70px;
        font-size: 14px;
        font-weight: bold;
        color: $corporate-color;
        padding-bottom: 6.8px;
      }

      .item-data {
        @include border;
        @include ellipsis;
        width: 100%;
        min-height: 27px;
        font-size: 14px;
        margin: 0 14px 0 20px;
      }
    }
  }

  .footer-icons-container {
    @include flex;

    .footer-icon {
      font-size: 18px;
    }

    .footer-data {
      @include flex($flex-direction: column, $align-items: flex-start);
      font-family: $corporate-font-bold;
      margin-left: 8px;
    }
  }
}
</style>
