<script lang="ts">
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { createToken } from '@/helpers/helpers'
import { http } from '@/plugins/axios'
import { authLexonToken } from '@/store/modules/auth/authTypes'
import { MainService } from '@/services/MainService'
import { LexnetEscritoId } from '@/components/Lexnet/types/LexnetTypes'

const authModule = ModuleNamespaces.AUTH

@Component
export default class LexnetMixin extends Vue {
  @Getter('getUserId', { namespace: authModule }) idUser: number
  @Getter('getCompanyId', { namespace: authModule }) idCompany: number

  token: string = ''
  refreshToken: string = ''

  async openInLexnet(selectedRegister: any) {
    await this.getLexNetToken()
    this.openEditLexnetApp(selectedRegister.idLexnet, false)
  }

  openLexnetApp(sameWindow: boolean = true) {
    const url = `${process.env.VUE_APP_LEXNET_URL}${this.token}&refreshToken=${this.refreshToken}`
    if (sameWindow) {
      window.location.replace(url)
    } else {
      window.open(url, '_blank')
    }
  }

  openNewEscritoLexnetApp(escritoType: number, docId: number) {
    const url = `${process.env.VUE_APP_LEXNET_NEW_ESCRITO_URL}${this.token}&idTipoEscrito=${escritoType}&idDocument=${docId}&refreshToken=${this.refreshToken}`
    window.open(url, '_blank')
  }

  openEditLexnetApp(idLexnet: number, sameWindow: boolean = true) {
    const url = `${process.env.VUE_APP_LEXNET_EDIT_URL}${idLexnet}/?token=${this.token}&refreshToken=${this.refreshToken}`
    if (sameWindow) {
      window.location.replace(url)
    } else {
      window.open(url, '_blank')
    }
  }

  async getLexNetToken() {
    const salt = process.env.VUE_APP_SALT
    const lexonToken = createToken(salt)
    const url = `/lexnet/token/${this.idUser}/${this.idCompany}/get`

    http.defaults.headers.common[authLexonToken] = lexonToken

    const { data } = await new MainService().getData(url)

    if (data && data.accessToken) {
      this.token = data.accessToken
      this.refreshToken = data.refreshToken
    }

    delete http.defaults.headers.common[authLexonToken]
  }

  getLexnetIdFromName(typeName: string) {
    return LexnetEscritoId[(typeName as any).toUpperCase()]
  }
}
</script>
