<template lang="pug">
div
  SpinnerLayerComponent(v-if="showSpinner" class="spinner-layer")
  div(v-else)
    TabHeaderComponent(
      @saveForm="onSaveForm"
      @closeForm="onCloseForm"
      :buttons="buttons"
      :title="title"
      isInTab
    )
    FormGeneratorComponent(
      :schema="formSchema"
      :fieldValues="formData"
      :context="context"
    )
</template>

<script lang="ts">
import { ActionBarButton, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Icons } from '@/icons/icons'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Prop, Component, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const configurationModule = ModuleNamespaces.CONFIGURATION
const contactsModule = ModuleNamespaces.CONTACTS
const alertsModule = ModuleNamespaces.ALERTS
const formsModule = ModuleNamespaces.FORMS

@Component({
  components: {
    FormGeneratorComponent: () => import('@/components/forms/FormGenerator/FormGeneratorComponent.vue'),
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue'),
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class ContactsFormComponent extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  context!: string

  @Prop({
    type: String,
    default: ''
  })
  contactType!: string

  @Prop({
    type: Number,
    default: 0
  })
  contactId!: number

  @Prop({
    default: {}
  })
  messages!: { edit: string }

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('fetchContact', { namespace: contactsModule })
  fetchContact: ({}) => Promise<{}>

  @Action('saveContact', { namespace: contactsModule })
  save: ({}) => Promise<{}>

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  formSchema = []
  data = {}
  showSpinner = true

  get buttons(): ActionBarButton[] {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        show: this.checkIfFormIsValid(this.context)
      }
    ]
  }

  get formData() {
    return this.data
  }

  set formData(value: any) {
    this.data = value
  }

  @Emit()
  onCloseForm() {
    this.formData = {}
  }

  async created() {
    await this.getFormData()
    await this.getFormConfiguration()
    this.showSpinner = false
  }

  async getFormConfiguration() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: this.context,
      context: this.context
    })
    this.formSchema = this.getCurrentViewConfiguration(this.context)
  }

  async getFormData() {
    if (this.contactId !== 0) {
      this.formData = await this.fetchContact({
        contactType: this.contactType,
        params: {
          idContact: this.contactId
        }
      })
    }
  }

  async onSaveForm() {
    const data = await this.save({
      contactType: this.contactType,
      formData: this.formData
    })
    if (data) {
      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message: this.messages.edit,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      })
    }
    this.onCloseForm()
  }
}
</script>
<style lang="scss" scoped>
.spinner-layer {
  --spinner-layer-min-height: 470px;
  width: 100%;
}
</style>
