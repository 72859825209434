import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'
import { URLS } from '@/router/routes/urlRoutes'

export interface Action {
  actionId: string
}

export interface RelatedAction {
  actionId: string
  economicDataId: string
  dateTimeAt: string
  duration: string
  economicEstimate: string
  subject: string
  type: string
  invoiced: boolean
}

export interface RelatedActionSummary {
  duration: string
  economicEstimate: string
}

export interface RelatedActionResponse {
  actionList: RelatedAction []
  summary: RelatedActionSummary
}

export interface File {
  canEdit: number
  fileId: number
  idOwnFile: string
}

export enum ActionMenu {
  ALL = 'all',
  CALLS = 'calls',
  EMAILS = 'emails',
  MEETINGS = 'meetings',
  OTHERS = 'others',
  LEXNET = 'lexnet',
  PROCEDURES = 'procedures'
}

export enum ActionBasePath {
  ALL = 'documentos/actuaciones',
  CALLS = 'documentos/actuaciones/llamadas',
  EMAILS = 'documentos/actuaciones/emails',
  MEETINGS = 'documentos/actuaciones/reuniones',
  LEXNET = 'documentos/actuaciones/lexnet',
  OTHERS = 'documentos/actuaciones/otras',
  PROCEDURES = 'documentos/actuaciones/tramites'
}

export enum ActionTypes {
  ALL = 'ALL',
  CALLS = 'LLAM',
  EMAILS = 'EMAIL',
  MEETINGS = 'REUN',
  LEXNET = 'LEXN',
  OTHERS = 'OTRA',
  PROCEDURES = 'TRAM'
}

export const objectActionTypes = [
  {
    id: 1,
    status: i18n.t('components.lexon_kanban.columns.planned')
  },
  {
    id: 2,
    status: i18n.t('components.lexon_kanban.columns.initiated')
  },
  {
    id: 3,
    status: i18n.t('components.lexon_kanban.columns.out_of_time')
  },
  {
    id: 4,
    status: i18n.t('components.lexon_kanban.columns.done')
  },
  {
    id: 5,
    status: i18n.t('components.lexon_kanban.columns.cancelled')
  },
  {
    id: 6,
    status: i18n.t('components.lexon_kanban.columns.pending_resolution')
  }
]

export enum ActionRelationTypes {
  CUSTOMERS = 'CLI',
  EXPEDIENT = 'EXP'
}

export const actionClassificationTypes = {
  CLI: 'customers',
  NOT: 'notaries',
  CON: 'opponents',
  ABOC: 'opponent_lawyers',
  PROC: 'own_attorneys',
  PROCC: 'opponent_attorneys',
  OTR: 'others',
  ABO: 'own_lawyers',
  EXP: 'expedients',
  JUZ: 'courts'
}

export interface ActionsState {
  action: object | null
  actions: ListResult[] | null
  selectedActionType: string
  chronometerNewActionInfo: ChronometerNewActionInfo
}

export interface ListResult {
  data: object
  pagination: ListPagination
}

export interface ListPagination {
  numPage: number
  totalRecs: number
}

export interface ChronometerNewActionInfo {
  start: string
  end: string
  duration: number
}

export interface MoveExpedientActionRequest {
  fromExpedientId: number
  toExpedientId: number
  actionIdLot: number[]
}

export interface AssociateEconomicDataToActionRequest {
  actionIdLot: number[],
  economicDataId: number
}

export const actionsMutationTypes = {
  FETCH_ACTION: 'FETCH_ACTION',
  FETCH_ACTIONS: 'FETCH_ACTIONS',
  FETCH_ACTION_RELATIONS: 'FETCH_ACTION_RELATIONS',
  RESET_ACTIONS: 'RESET_ACTIONS',
  RESET_CHRONOMETER_NEW_ACTION_INFO: 'RESET_CHRONOMETER_NEW_ACTION_INFO',
  SAVE_SELECTED_ACTION_TYPE: 'SAVE_SELECTED_ACTION_TYPE',
  SET_CHRONOMETER_NEW_ACTION_INFO: 'SET_CHRONOMETER_NEW_ACTION_INFO'
}

export const setBasePathByActionType = (actionType: string) => {
  switch (actionType) {
    case ActionTypes.CALLS:
      return ActionBasePath.CALLS
    case ActionTypes.EMAILS:
      return ActionBasePath.EMAILS
    case ActionTypes.MEETINGS:
      return ActionBasePath.MEETINGS
    case ActionTypes.LEXNET:
      return ActionBasePath.LEXNET
    case ActionTypes.OTHERS:
      return ActionBasePath.OTHERS
    case ActionTypes.PROCEDURES:
      return ActionBasePath.PROCEDURES
  }
}

export const getActionRouteName = (actionType: string) => {
  switch (actionType) {
    case ActionTypes.CALLS:
      return `${URLS.ACTIONS}-${URLS.CALLS}`
    case ActionTypes.MEETINGS:
      return `${URLS.ACTIONS}-${URLS.MEETINGS}`
    case ActionTypes.PROCEDURES:
      return `${URLS.ACTIONS}-${URLS.PROCEDURES}`
    case ActionTypes.OTHERS:
      return `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`
    case ActionTypes.LEXNET:
      return `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`
    case ActionTypes.EMAILS:
      return `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`
  }
}

export const checkActionIcon = (type: string) => {
  switch (type) {
    case ActionTypes.CALLS:
      return Icons.CALL
    case ActionTypes.LEXNET:
      return Icons.LEXNET
    case ActionTypes.MEETINGS:
      return Icons.MEETING
    case ActionTypes.PROCEDURES:
      return Icons.FORMALITY
    case ActionTypes.OTHERS:
      return Icons.OTHERS_F
    case ActionTypes.EMAILS:
      return Icons.EMAIL
  }
}

export const checkActionText = (value: string) => {
  switch (value) {
    case ActionTypes.OTHERS:
      return 'components.dashboard_list.other'
    case ActionTypes.EMAILS:
      return 'components.dashboard_list.email'
    case ActionTypes.PROCEDURES:
      return 'components.dashboard_list.formality'
    case ActionTypes.LEXNET:
      return 'components.dashboard_list.lexnet'
    case ActionTypes.MEETINGS:
      return 'components.dashboard_list.meeting'
    case ActionTypes.CALLS:
      return 'components.dashboard_list.call'
    default:
      return value
  }
}
