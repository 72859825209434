<template lang="pug">

  section(
    v-if="!showSpinnerLayer"
    :class="['custom-fields-container', breakpointClass]"
  )

    SpinnerLayerComponent(v-if="loadingData" class="spinner-layer")

    template(v-else)
      ShowOrHideButtonComponent(
        class="preview-button"
        :show="showFormPreview"
        :texts="formPreviewTexts"
        @toggle="toggleShowOrHide"
      )

      div(class="entity-type-selector")
        EntityTypeSelectorComponent(
          class="entity-type-selector"
          v-if="isContactsType")

      ActionsBarComponent(
        :buttons="buttons"
        @execute-action="executeAction"
        class="action-buttons"
      )

      template(v-if="showFormPreview")
        div(class="custom-fields-preview")
          CustomFieldsPreviewComponent(
            :schema="config.customFields"
            :context="context"
          )

      template(v-else)
        CustomFieldTypeSelectorComponent(
          class="custom-fields-selector"
          :items="getCustomFieldsTypes"
          @emit-clicked-item="openFormDialog"
        )

        CustomFieldsPlaygroundComponent(class="custom-fields-playground")

        CustomFieldsFormDialogComponent(
          v-if="formSchema"
          :showFormDialog="showFormDialog"
          :typeOfFieldToRenderInForm="typeOfFieldToRenderInForm"
          :schema="formSchema"
          :formData="customFieldFormData"
          @dialog-event="dialogEvents"
        )

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import CustomFieldsFormDialogComponent from '@/components/customFields/CustomFieldsFormDialog/CustomFieldsFormDialogComponent.vue'
import CustomFieldsPlaygroundComponent from '@/components/customFields/customFieldsPlayground/CustomFieldsPlayground/CustomFieldsPlaygroundComponent.vue'
import CustomFieldTypeSelectorComponent from '@/components/customFields/CustomFieldTypeSelector/CustomFieldTypeSelectorComponent.vue'
import EntityTypeSelectorComponent from '@/components/customFields/EntityTypeSelector/EntityTypeSelectorComponent.vue'
import CustomFieldsPreviewComponent from '@/components/customFields/CustomFieldsPreview/CustomFieldsPreviewComponent.vue'
import ShowOrHideButtonComponent from '@/components/ShowOrHideButton/ShowOrHideButtonComponent.vue'
import { ShowOrHideTexts } from '@/components/ShowOrHideButton/types/ShowOrHideButtonComponentTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ActionBarButton, ActionBarButtonMode } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import {
  CustomFieldsConfig,
  CustomFieldTypes,
  customFieldTypesEnum,
  DialogEvent,
  CustomField,
  selectFieldTypes
} from '@/store/modules/customFields/customFieldsTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

const alertsModule: string = ModuleNamespaces.ALERTS
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS
const dialogModule: string = ModuleNamespaces.DIALOG
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    ActionsBarComponent,
    CustomFieldsFormDialogComponent,
    CustomFieldsPlaygroundComponent,
    CustomFieldTypeSelectorComponent,
    EntityTypeSelectorComponent,
    CustomFieldsPreviewComponent,
    ShowOrHideButtonComponent,
    SpinnerLayerComponent
  }
})
export default class CustomFieldsContainerComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  idEntityType: number

  @Prop({
    type: String,
    default: ComponentWhereIsRendered.TABS_VIEW
  })
  alertWhereIsReview!: string

  @Getter('getCustomFieldsConfig', { namespace: customFieldsModule })
  config: CustomFieldsConfig
  @Getter('getCustomFieldsOriginalConfig', { namespace: customFieldsModule })
  originalConfig: CustomFieldsConfig
  @Getter('getCustomFieldsTypes', { namespace: customFieldsModule })
  getCustomFieldsTypes: CustomFieldTypes[]
  @Getter('launchCustomFieldFormDialogFlagStatus', { namespace: customFieldsModule })
  launchFormDialogFlagStatus: () => boolean
  @Getter('getCustomFieldFormData', { namespace: customFieldsModule })
  customFieldFormData: any
  @Getter('getCustomFieldSelectedData', { namespace: customFieldsModule })
  customFieldSelectedData: any

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []
  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  @Mutation('RESET_CURRENT_VIEW_CONFIGURATION', { namespace: configurationModule })
  resetFormConfiguration: (context: string) => void
  @Mutation('ADD_CUSTOM_FIELD', { namespace: customFieldsModule })
  addCustomFieldAction: (customFieldInfo: object) => void
  @Mutation('EDIT_CUSTOM_FIELD', { namespace: customFieldsModule })
  editCustomFieldAction: (customFieldInfo: object) => void
  @Mutation('DISCARD_CHANGES_CUSTOM_FIELDS_CONFIG', { namespace: customFieldsModule })
  discardConfigurationChanges: () => void

  @Action('fetchCustomFieldsConfig', { namespace: customFieldsModule })
  fetchConfig: (idEntityType: number) => Promise<void>
  @Action('fetchCustomFieldsTypes', { namespace: customFieldsModule })
  fetchCustomFieldsTypes: () => Promise<void>
  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>
  @Action('saveCustomFieldsConfig', { namespace: customFieldsModule })
  saveConfig: (customFieldConfig: CustomFieldsConfig) => Promise<boolean>
  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => void
  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => void

  showFormPreview: boolean = false

  showFormDialog: boolean = false

  loadingData: boolean = true

  typeOfFieldToRenderInForm: string = ''

  context: string = ContextName.CUSTOM_FIELDS

  idCustomFieldType: number = 0

  @Watch('launchFormDialogFlagStatus')
  editCustomField() {
    const item = this.getFormDialogType(this.customFieldSelectedData.idCustomFieldType) as CustomFieldTypes
    this.openFormDialog(item)
  }

  get formPreviewTexts(): ShowOrHideTexts {
    return {
      show: this.$t('components.custom_fields_container.show_preview_form') as string,
      hide: this.$t('components.custom_fields_container.hide_preview_form') as string
    }
  }

  get buttons(): ActionBarButton[] {
    return [
      {
        action: ActionName.DISCARD,
        tooltip: this.$vuetify.breakpoint.mdAndDown
          ? this.$t('action_buttons.discard')
          : this.$t('action_buttons.discard_configuration'),
        class: 'secondary-color',
        mode: ActionBarButtonMode.SQUARE,
        show: this.hasConfigChanges
      },
      {
        action: ActionName.SAVE,
        tooltip: this.$vuetify.breakpoint.mdAndDown
          ? this.$t('action_buttons.save')
          : this.$t('action_buttons.save_configuration'),
        class: 'main-color',
        mode: ActionBarButtonMode.SQUARE,
        show: this.hasConfigChanges
      }
    ]
  }

  get breakpointClass(): string {
    return String(this.$vuetify.breakpoint.name)
  }

  get formSchema() {
    const schema = this.getCurrentViewConfiguration(this.context)
    if (schema) {
      schema.map((item: any) => {
        if (item.id === selectFieldTypes.GROUPING) {
          item.propItems = this.getGroupList()
        } else if (item.id === selectFieldTypes.FIELDS) {
          item.propItems = this.getFieldList()
        }
      })
      return schema
    }
  }

  get hasConfigChanges() {
    return JSON.stringify(this.config) !== JSON.stringify(this.originalConfig)
  }

  get isContactsType(): boolean {
    return entity.contacts.type === this.idEntityType
  }

  mounted() {
    this.initialization()
  }

  async initialization(): Promise<void> {
    try {
      await this.fetchCustomFieldsTypes()
      await this.fetchConfig(this.idEntityType)
    } finally {
      this.loadingData = false
    }
  }

  getFieldList(): object {
    const customFields: CustomField[] = this.config.customFields
    const fieldList: object[] = []
    customFields.forEach((item: any) => {
      if (customFieldTypesEnum.GROUP === item.idCustomFieldType && item.fields) {
        item.fields.forEach((field: any) => (field.parentFieldset = item.label))
        fieldList.push(...item.fields)
      } else if (customFieldTypesEnum.GROUP !== item.idCustomFieldType) {
        delete item.parentFieldset
        fieldList.push(item)
      }
    })
    return fieldList.map((item: any) => {
      return {
        id: item.id,
        uuid: item.uuid,
        name: item.parentFieldset ? `${item.label} - ${item.parentFieldset}` : item.label
      }
    })
  }

  getGroupList(): object {
    const customFields: CustomField[] = this.config.customFields
    return customFields
      .filter((item: any) => customFieldTypesEnum.GROUP === item.idCustomFieldType)
      .map((item: any) => {
        return {
          id: item.id,
          uuid: item.uuid,
          name: item.label
        }
      })
  }

  toggleShowOrHide(): void {
    this.showFormPreview = !this.showFormPreview
  }

  executeAction(action: string): void {
    switch (action) {
      case ActionName.SAVE:
        if (this.hasConfigChanges) {
          this.saveConfiguration()
        }
        break
      case ActionName.DISCARD:
        if (this.hasConfigChanges) {
          this.discardConfigurationQuestion()
        }
        break
    }
  }

  async saveConfiguration(): Promise<void> {
    const result: boolean = await this.saveConfig(this.config)
    if (result) {
      this.showAlertResult(this.$t('components.custom_fields_container.save_config_ok'), AlertsTypes.SUCCESS)
    } else {
      this.showAlertResult(this.$t('components.custom_fields_container.save_config_ko'), AlertsTypes.ERROR)
    }
  }

  discardConfigurationQuestion() {
    this.showDialog({
      type: DialogTypes.WARNING,
      message: this.$t('components.custom_fields_container.discard_changes_question'),
      action: this.discardChanges
    })
  }

  async discardChanges() {
    await this.discardConfigurationChanges()
    this.showAlertResult(this.$t('components.custom_fields_container.discard_changes_ok'), AlertsTypes.INFO)
  }

  closeFormDialog(): void {
    this.idCustomFieldType = 0
    document.body.style.position = 'initial'
    this.showFormDialog = false
    this.resetFormConfiguration(this.context)
    this.typeOfFieldToRenderInForm = ''
  }

  dialogEvents(event: DialogEvent): void {
    switch (event.action) {
      case ActionName.CLOSE:
        this.closeFormDialog()
        break
      case ActionName.SAVE:
        this.addCustomFieldAction({
          formData: this.customFieldFormData,
          idCustomFieldType: this.idCustomFieldType
        })
        this.showDialog({
          type: DialogTypes.SUCCESS,
          message: this.customFieldFormData.grouping
            ? this.$t('components.custom_fields_container.add_custom_field_success_text_group')
            : this.$t('components.custom_fields_container.add_custom_field_success_text_list')
        })
        this.closeFormDialog()
        break

      case ActionName.EDIT:
        this.editCustomFieldAction({
          formData: event.formData
        })
        this.showDialog({
          type: DialogTypes.SUCCESS,
          message: this.$t('components.custom_fields_container.edit_custom_field_success')
        })
        this.closeFormDialog()
    }
  }

  openFormDialog(item: CustomFieldTypes): void {
    this.idCustomFieldType = Number(item.id)
    this.getDialogForm(item)
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    this.showFormDialog = true
  }

  async getDialogForm(item: CustomFieldTypes): Promise<void> {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: item.formAlias,
      context: this.context
    })
    this.typeOfFieldToRenderInForm = item.description
  }

  getFormDialogType(idCustomFieldType: string) {
    return this.getCustomFieldsTypes.find((item: any) => item.id === String(idCustomFieldType))
  }

  showAlertResult(message: any, alertType: AlertsTypes) {
    this.showAlert({
      type: alertType,
      message,
      componentWhereIsRendered: this.alertWhereIsReview
    })
  }
}
</script>

<style lang="scss" scoped>
.custom-fields-container {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  height: 100%;
  padding: 10px;

  .spinner-layer {
    --spinner-layer-min-height: 470px;
    width: 100%;
  }

  .preview-button {
    width: 100%;
    height: 30px;
    justify-content: flex-end;
  }

  .custom-fields-preview {
    width: 100%;
  }

  ::v-deep .show-or-hide-button {
    margin: 0;
  }

  .entity-type-selector {
    flex-grow: 2;
    height: 30px;
  }

  .action-buttons {
    width: 100%;
    order: 1;

    ::v-deep button {
      width: 50%;
    }

    ::v-deep .secondary-color {
      margin-left: 0 !important;
    }
  }

  .custom-fields-selector {
    margin: 0;
  }

  .custom-fields-playground {
    width: 100%;
    padding: 4px;
    margin: 10px 0;
  }

  &.sm {
    .preview-button {
      width: 175px;
    }

    .entity-type-selector {
      order: -1;
    }
  }

  &.md,
  &.lg,
  &.xl {
    .preview-button {
      width: 175px;
      height: 40px;
    }

    .entity-type-selector {
      height: 40px;
      padding-top: 4px;
      order: -1;
    }

    .action-buttons {
      order: 0;
      margin: 0 0 10px 10px;
    }

    .custom-fields-selector {
      width: 300px;
      margin-right: 20px;
    }

    .custom-fields-playground {
      width: 544px;
      flex-grow: 2;
      margin: 0;
    }
  }

  &.md {
    .entity-type-selector {
      min-width: 400px;
    }

    .action-buttons {
      width: 240px;
    }
  }

  &.lg {
    .entity-type-selector {
      width: 41%;
    }

    .action-buttons {
      width: 30%;
      min-width: 480px;
    }
  }

  &.xl {
    .entity-type-selector {
      width: 60%;
    }

    .action-buttons {
      width: 28.2%;
    }
  }

  ::v-deep .no-changes {
    cursor: default;
  }
}
</style>
