import { extend } from 'vee-validate'
import { i18n } from '@/plugins/vue-i18n'
import store from '@/store/store'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'

let regexOk: boolean = true

const isUniqueCode = async (code: string) => {
  const params = {
    id: store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](ContextName.MAINTENANCE),
    code
  }
  const regexPattern = new RegExp('^[A-Za-z0-9_-]+$')
  regexOk = regexPattern.test(code)
  if (!regexOk) {
    return false
  }
  if (code.length > 3) {
    const response = await store.dispatch(`${ModuleNamespaces.ACTIONS}/isUniqueCode`, params)
    return response[0].data === 'true'
  }
  return false
}

extend('uniqueCode', {
  validate: isUniqueCode,
  message: (_, values) => {
    if (!regexOk) {
      return i18n.t('validations.invalid_characters') as string
    } else if (values._value_.length > 3) {
      return i18n.t('validations.code_already_exist') as string
    } else {
      return i18n.t('validations.greater_than_three') as string
    }
  }
})
