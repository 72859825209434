<template lang="pug">

  div(v-if="showDialog" class="dialog-container")
    div(class="dialog-background")
    div(class="dialog-message-container")
      header(:class="['header-container', getType]")
        span(:class="['header-icon', getIcon]")
        span(class="header-title") {{ title }}
        v-spacer
        span(
          @click.stop="dialog.onCloseCustomAction ? closeDialogAndMakeAnAction() : checkCloseDialog()"
          class="lf-icon-close close-icon"
        )
      div(class="message-container")
        p(class="message" v-html="dialog.message")
      div(class="actions-container")
        template(v-if="!buttons")
          button(
            v-if="checkIfShowSecondaryButton"
            @click.stop="checkCloseDialog"
            class="button secondary-action-button"
          ) {{ secondaryButtonText }}
          button(
            @click.stop="makeAction"
            class="button main-action-button"
          ) {{ mainButtonText }}
        template(v-else)
          button(
            v-for="(button, index) in buttons"
            :key="index"
            @click.stop="getAction(button)"
            :class="`button ${button.style}`"
          ) {{ button.text }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogData, DialogTypes, DialogButtonTypes, DialogButton } from '@/store/modules/dialog/dialogTypes'
import { Icons } from '@/icons/icons'
import { TranslateResult } from 'vue-i18n'

const dialogModule: string = ModuleNamespaces.DIALOG
const errorsModule: string = ModuleNamespaces.ERROR

@Component
export default class DialogComponent extends Vue {
  @Getter('showDialog', { namespace: dialogModule })
  showDialog: boolean
  @Getter('getDialogData', { namespace: dialogModule })
  dialog: DialogData

  @Action('closeDialog', { namespace: dialogModule })
  closeDialog: () => {}
  @Action('clearEndpointError', { namespace: errorsModule })
  clearEndpointError: () => {}

  get mainButtonText() {
    let text: TranslateResult = ''
    if (this.dialog.mainButtonText) {
      text = this.dialog.mainButtonText
    } else if (this.dialog.type === DialogTypes.INFO || this.dialog.type === DialogTypes.CUSTOM_DIALOG) {
      text = this.$t('action_buttons.yes')
    } else {
      text = this.$t('action_buttons.accept')
    }
    return text
  }

  get secondaryButtonText() {
    let text: TranslateResult = ''
    if (this.dialog.secondaryButtonText) {
      text = this.$t(`${this.dialog.secondaryButtonText}`)
    } else if (this.dialog.type === DialogTypes.INFO || this.dialog.type === DialogTypes.CUSTOM_DIALOG) {
      text = this.$t('action_buttons.no')
    } else {
      text = this.$t('action_buttons.cancel')
    }
    return text
  }

  get checkIfShowSecondaryButton() {
    const { type, hideSecondaryButton } = this.dialog
    if (hideSecondaryButton) {
      return false
    } else if (type === DialogTypes.INFO || type === DialogTypes.WARNING || type === DialogTypes.CUSTOM_DIALOG) {
      return true
    }
    return false
  }

  get getType() {
    switch (this.dialog.type) {
      case DialogTypes.INFO:
        return DialogTypes.INFO
      case DialogTypes.SUCCESS:
        return DialogTypes.SUCCESS
      case DialogTypes.ERROR:
        return DialogTypes.ERROR
      case DialogTypes.WARNING:
        return DialogTypes.WARNING
      case DialogTypes.CUSTOM_DIALOG:
        return DialogTypes.CUSTOM_DIALOG
    }
  }

  get getIcon() {
    switch (this.dialog.type) {
      case DialogTypes.INFO:
        return Icons.INFO
      case DialogTypes.SUCCESS:
        return Icons.CHECK_ROUND
      case DialogTypes.ERROR:
        return Icons.CLOSE_ROUND
      case DialogTypes.WARNING:
        return Icons.WARNING
      case DialogTypes.CUSTOM_DIALOG:
        return this.dialog.icon
    }
  }

  get title() {
    switch (this.dialog.type) {
      case DialogTypes.INFO:
        return this.$t('components.dialog.type.info')
      case DialogTypes.SUCCESS:
        return this.$t('components.dialog.type.success')
      case DialogTypes.ERROR:
        return this.$t('components.dialog.type.error')
      case DialogTypes.WARNING:
        return this.$t('components.dialog.type.warning')
      case DialogTypes.CUSTOM_DIALOG:
        return this.dialog.title
    }
  }

  get buttons() {
    return this.dialog.buttons
  }

  mounted() {
    document.body.appendChild(this.$el)
  }

  makeAction() {
    if (this.dialog.action) {
      this.dialog.action()
    }
    this.checkCloseDialog()
  }

  closeDialogAndMakeAnAction() {
    if (this.dialog.type === DialogTypes.ERROR) {
      this.clearEndpointError()
    }

    if (this.dialog.onCloseCustomAction) {
      this.dialog.onCloseCustomAction()
      this.closeDialog()
    }
  }

  checkCloseDialog() {
    if (this.dialog.type === DialogTypes.ERROR) {
      this.clearEndpointError()
    }

    this.closeDialog()
  }

  getAction(button: DialogButton) {
    if (button.type === DialogButtonTypes.CLOSE) {
      this.checkCloseDialog()
    } else if (button.action) {
      button.action()
      this.checkCloseDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  @include flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 99999;

  .dialog-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: $dialog-background-color;
  }

  .dialog-message-container {
    position: absolute;
    width: 700px;
    min-height: 250px;
    font-family: $corporate-font;
    box-shadow: 0px 0px 7px 0px rgba(65, 65, 65, 0.7);

    .header-container {
      @include flex;
      width: 700px;
      color: $white-01;
      padding: 29px 27px;

      &.info-text,
      &.custom-dialog-text {
        background-color: $corporate-color;
      }

      &.error-text {
        background-color: $error-color;
      }

      &.success-text {
        background-color: $success-color;
      }

      &.warning-text {
        background-color: $warning-color;
      }

      .header-icon {
        font-size: 50px;
      }

      .close-icon {
        @include flex;
        width: 35px;
        height: 35px;
        cursor: pointer;
      }

      .header-title {
        font-size: 22px;
        margin-left: 24px;
        text-transform: capitalize;
      }
    }

    .message-container {
      background-color: $white-01;

      .message {
        font-size: 18px;
        color: $blue-03;
        margin: 0;
        padding: 20px 65px 35px 100px;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .actions-container {
      @include flex($justify-content: flex-end);
      background-color: $white-01;
      padding: 0 25px 25px;

      .main-action-button {
        @include main-action-button--rectangle;
      }

      .secondary-action-button {
        @include secondary-action-button--rectangle;
      }

      .button {
        min-width: 60px;
      }
    }
  }
}
</style>
