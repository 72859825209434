<template lang="pug">

  transition(name="fade" appear)
    div(class="custom-fields-empty-message-container")
      div(:class="['empty-message-icon', icon]")
      div(class="empty-message-title") {{ texts.title }}
      div(class="empty-message-text") {{ texts.text }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class CustomFieldsEmptyMessageComponent extends Vue {
  icon = Icons.NEWS

  get texts() {
    return {
      text: this.$t('components.custom_fields_tab.text_message'),
      title: this.$t('components.custom_fields_tab.title_message')
    }
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.custom-fields-empty-message-container {
  @include flex($flex-direction: column);
  width: 100%;
  color: $blue-04;
  font: $corporate-font;
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 100px;

  .empty-message-icon {
    font-size: 6em;
    color: $blue-06;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .empty-message-title {
    font-size: 20px;
  }

  .empty-message-text {
    font-size: 16px;
  }
}
</style>
