<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const dialogModule: string = ModuleNamespaces.DIALOG
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component
export default class GridToolbarOptionsMixin extends mixins(PortalUserMixin, PermissionsMixin) {
  @Getter('getSelectedIdEntityType', { namespace: entitiesModule })
  selectedIdEntityType: number

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: () => {}

  toolbarOptionsTooltipTexts = {
    add: this.$t('components.grid_table.tooltip.new'),
    archived: this.$t('components.grid_table.tooltip.archived'),
    exportText: this.$t('components.grid_table.tooltip.export'),
    filter: this.$t('components.grid_table.tooltip.clean_filter'),
    print: this.$t('components.grid_table.tooltip.print')
  }

  get toolbarOptions() {
    const { add, archived, exportText, filter, print } = this.toolbarOptionsTooltipTexts
    return [
      {
        id: 'filter',
        prefixIcon: Icons.FILTER,
        text: filter,
        align: 'left',
        cssClass: 'lf-clean-filter-button'
      },
      {
        id: 'search',
        text: 'Search',
        align: 'right'
      },
      ...(!this.isPortalUser
        ? [
            {
              id: 'archived',
              prefixIcon: Icons.REPORTS_T,
              tooltipText: archived,
              align: 'right',
              cssClass: 'lf-gray-btn'
            }
          ]
        : []),
      {
        id: 'print',
        prefixIcon: Icons.PRINT,
        tooltipText: print,
        align: 'right'
      },
      {
        id: 'export',
        prefixIcon: Icons.DOWNLOAD,
        tooltipText: exportText,
        align: 'right'
      },
      ...(this.showAddNewToolbarButton
        ? [
            {
              id: 'add',
              prefixIcon: Icons.ADD,
              tooltipText: add,
              align: 'right'
            }
          ]
        : [])
    ]
  }

  get showAddNewToolbarButton() {
    return !this.isPortalUser && this.viewPermission.canSave
  }

  get toolbarOptionsWithoutAddNewButton() {
    return this.toolbarOptions.filter((item) => item.id !== 'add')
  }

  getToolbarOptions(options?: any) {
    let filterToolbarOptions
    if (options) {
      filterToolbarOptions = this.toolbarOptions.filter((item: any) => {
        return options.find((i: any) => item.id === i.toLowerCase())
      })
    }
    return filterToolbarOptions || this.toolbarOptions
  }
}
</script>
