<template lang="pug">

  div(v-if="showDialog" class="dialog-container" @click.stop="checkIfHideDialog")
    div(class="dialog-message-container")

      header(class="dialog-header")
        img(class="lf-logo" src="@/assets/images/logos/lefebvre-ij-143x50.png" alt="Lefebvre intelligence logo")
        span(@click.stop="hideDialog" :class="[icons.close, 'icon-close']")

      section(class="cards-section")
        h2(class="section-title") {{ texts.title }}
        div(class="cards-container")
          //- Telephone assistance
          div(class="card-wrapper")
            article(class="card")
              span(:class="[icons.call, 'card-icon']")
              h3(class="card-title center-text")  {{ texts.telephoneAssistance.title }}
              p(class="card-text center-text") {{ texts.telephoneAssistance.info }}
              span(class="center-text phone") {{ texts.telephoneAssistance.phone }}
              span(class="center-text phone") {{ texts.telephoneAssistance.fax }}
              span(class="center-text timetable") {{ texts.telephoneAssistance.timetable }}

          //- Email assistance
          div(class="card-wrapper")
            article(class="card")
              span(:class="[icons.newsletter, 'card-icon']")
              h3(class="card-title center-text") {{ texts.emailAssistance.title }}
              p(class="card-text center-text") {{ texts.emailAssistance.info }}
              br
              span(class="center-text email") {{ texts.emailAssistance.email }}
              a(:href="urls.helpCenterEmail" class="widget-btn") {{ texts.emailAssistance.btnText }}

          //- Help center widgets
          div(class="card-wrapper")
            article(class="card help-center")
              span(:class="[icons.support, 'card-icon']")
              h3(class="card-title center-text") {{ texts.helpCenter.title }}
              ul(class="help-widgets-container")
                li(
                  v-for="(widget, index) of helpWidgets"
                  :key="index"
                  class="help-widget"
                )
                  a(:href="widget.url" target="_blank" class="help-widget-link")
                    span(:class="['widget-icon', widget.icon]")
                    span {{ widget.text }}

      a(:href="urls.helpCenter" target="blank" class="main-action-btn") {{ texts.mainBtn }}
</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { externalURLS } from '@/router/routes/urlRoutes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const authModule = ModuleNamespaces.AUTH

@Component
export default class SupportServiceDialogComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  showDialog!: boolean

  @Getter('getEncryptedIdProUser', { namespace: authModule }) encryptedIdProUser: string
  @Getter('getNavisionId', { namespace: authModule }) navisionId: string

  @Action('encryptIdProUser', { namespace: authModule }) encryptIdProUser: () => void

  icons = {
    call: Icons.CALL,
    close: Icons.CLOSE_ROUND,
    newsletter: Icons.NEWSLETTER,
    support: Icons.SUPPORT
  }

  currentUrl: string = ''

  texts = {
    title: this.$t('components.dialog.support_service.title'),
    mainBtn: this.$t('components.dialog.support_service.main_btn'),
    telephoneAssistance: {
      fax: this.$t('components.dialog.support_service.telephone_assistance.fax'),
      info: this.$t('components.dialog.support_service.telephone_assistance.info'),
      phone: this.$t('components.dialog.support_service.telephone_assistance.phone'),
      timetable: this.$t('components.dialog.support_service.telephone_assistance.timetable'),
      title: this.$t('components.dialog.support_service.telephone_assistance.title')
    },
    emailAssistance: {
      btnText: this.$t('components.dialog.support_service.email_assistance.btn_text'),
      email: this.$t('components.dialog.support_service.email_assistance.email'),
      info: this.$t('components.dialog.support_service.email_assistance.info'),
      title: this.$t('components.dialog.support_service.email_assistance.title')
    },
    helpCenter: {
      courses: this.$t('components.dialog.support_service.help_center.courses'),
      frecuentQuestions: this.$t('components.dialog.support_service.help_center.frequent questions'),
      manuals: this.$t('components.dialog.support_service.help_center.manuals'),
      title: this.$t('components.dialog.support_service.help_center.title'),
      videos: this.$t('components.dialog.support_service.help_center.videos')
    }
  }

  @Emit()
  hideDialog() {
    return false
  }

  @Watch('showDialog')
  blockBodyScroll(value: boolean) {
    const body = document.querySelector('body')

    if (value) {
      body!.classList.add('dialog-open')
    } else {
      body!.classList.remove('dialog-open')
    }
  }

  @Watch('$route')
  encodeUrl() {
    this.currentUrl = encodeURIComponent(document.location.href)
  }

  get urls() {
    return {
      courses: `${externalURLS.courses}${this.encryptedIdProUser}`,
      faqs: `${externalURLS.faqs}${this.encryptedIdProUser}`,
      helpCenterEmail: externalURLS.help_center_email,
      manuals: `${externalURLS.manuals}${this.encryptedIdProUser}`,
      helpCenter: `${externalURLS.help_center}${this.encryptedIdProUser}`,
      videos: `${externalURLS.videos}${this.encryptedIdProUser}`
    }
  }

  get helpWidgets() {
    return [
      {
        icon: Icons.QUESTION,
        text: this.texts.helpCenter.frecuentQuestions,
        url: this.urls.faqs
      },
      {
        icon: Icons.OTHERS_F,
        text: this.texts.helpCenter.courses,
        url: this.urls.courses
      },
      {
        icon: Icons.VIDEO,
        text: this.texts.helpCenter.videos,
        url: this.urls.videos
      },
      {
        icon: Icons.DOCUMENTATION,
        text: this.texts.helpCenter.manuals,
        url: this.urls.manuals
      }
    ]
  }

  async created() {
    await this.encryptIdProUser()
  }

  checkIfHideDialog($event: Event) {
    const elementClicked = $event.target as HTMLElement

    if (elementClicked.classList.contains('dialog-container')) {
      this.hideDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.center-text {
  text-align: center;
}

.dialog-container {
  @include flex($align-items: baseline);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;

  .dialog-message-container {
    @include flex($flex-direction: column);
    position: absolute;
    background-color: $white-01;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    margin: 0.5rem;
    max-width: 400px;

    .dialog-header {
      @include flex;
      align-self: flex-end;
      padding: 1rem 1.5rem;
    }

    .icon-close {
      align-self: baseline;
      font-size: 18px;
      color: $blue-04;
      margin-left: 28px;
      cursor: pointer;

      &:hover {
        color: $corporate-color;
      }
    }

    .cards-section {
      @include flex($flex-direction: column);
      padding: 1rem 1.5rem;

      .section-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: $corporate-color;
        font-family: $corporate-font-medium;
        align-self: start;
        margin-bottom: 8px;
      }

      .cards-container {
        @include flex;
        flex-wrap: wrap;
      }

      .card-wrapper {
        margin-bottom: 1rem;
        max-width: 100%;
        width: 100%;

        .widget-btn {
          @include main-action-button--rectangle;
          width: 130px;
          text-decoration: none;
          margin-top: 6px;
          margin-left: 0;
          text-align: center;
        }
      }

      .card {
        @include flex($flex-direction: column, $justify-content: flex-start);
        height: 327px;
        color: $corporate-color;
        padding: 24px 8px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        font-family: $corporate-font-medium;
        font-size: 16px;

        &.help-center {
          color: $white-01;
          background-color: $corporate-color;

          .help-widgets-container {
            @include border($direction: top, $color: $white-01);
            @include flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 10px;
            padding-left: 0;

            .help-widget {
              @include borders($color: $blue-04);
              @include flex($flex-direction: column);
              width: 47%;
              height: 82px;
              margin: 5px 3px;
              cursor: pointer;

              &:hover {
                background-color: $blue-04;
              }

              .widget-icon {
                font-size: 25px;
                margin-bottom: 0.5rem;
              }

              .help-widget-link {
                @include flex($flex-direction: column);
                color: $white-01;
                font-size: 10px;
                height: 100%;
                text-decoration: none;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .card-icon {
        font-size: 50px;
      }

      .card-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 6px;
      }
      .card-text {
        color: $blue-04;
        margin: 10px 0;
      }

      .phone,
      .email {
        font-size: 17px;
        margin-bottom: 4px;
      }

      .email {
        margin-bottom: 13px;
      }

      .timetable {
        font-size: 14px;
        color: #565656;
      }
    }

    .main-action-btn {
      @include main-action-button--rectangle($main-color: $white-01, $secondary-color: $corporate-color);
      margin: 1rem 0;
      width: 230px;
      text-align: center;
      text-decoration: none;

      &:hover {
        border-color: $corporate-color;
      }
    }
  }
}

@media (min-width: 576px) {
  .dialog-container {
    .dialog-message-container {
      margin: 1.75rem auto;
    }
  }
}

@media (min-width: 992px) {
  .dialog-container {
    .dialog-message-container {
      max-width: 800px;

      .cards-section {
        .section-title {
          padding-left: 0.5rem;
        }

        .card-wrapper {
          padding: 0 0.5rem;
          max-width: 33.3333%;
        }
      }
    }
  }
}

@media (min-width: 1180px) {
  .dialog-container {
    align-items: center;

    .dialog-message-container {
      max-width: 1000px;
    }
  }
}
</style>

<style>
body.dialog-open {
  overflow: hidden;
  height: 100vh;
}
</style>
