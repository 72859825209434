<template lang="pug">

    div(class="no-results-container")
      span(:class="[icon, 'no-result-icon']")
      p {{ noResultsText  }}

</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'

@Component
export default class DashboardNoResultsComponen extends Vue {
  @Prop({
    type: String,
    default: Icons.ANALYSIS
  })
  icon!: string

  noResultsText = this.$t('components.widgets.no_results_text')
}
</script>

<style lang="scss" scoped>
.no-results-container {
  @include flex($flex-direction: column);
  height: 100%;
  min-height: 15vw;
  font-family: $corporate-font-medium;
  font-size: 0.85vw;
  color: $blue-05;
  padding-bottom: 5vw;

  .no-result-icon {
    font-size: 3vw;
    margin: 31px 0 15px 0;
  }
}
</style>
