<template lang="pug">

  div(class="html-table-container")
    h2(v-if="tableData.name" class="table-name") {{ tableData.name }}
    table

      //- HEADERS
      thead
        tr(class="table-head-row")
          th(
            v-for="header in tableData.headers"
            :class="['head-item', {'button-col': header.type === ItemTypeEnum.BUTTON }]"
          ) {{ header.title }}

      //- BODY
      tbody
        tr(
          v-for="(item, itemIndex) in tableData.items"
          :key="`${itemIndex}-item`"
          :class="['body-row', {'disabled-item': item.disabled}]"
        )
          td(
            v-for="(header, headerIndex) in tableData.headers"
            :key="`${headerIndex}-header`"
            class="body-item"
          )
            span(v-if="header.type === ItemTypeEnum.STRING") {{ item.disabled && headerIndex !== 0 ? '' : item[header.key] }}
            span(v-if="header.type === ItemTypeEnum.BUTTON && item.buttons === true")
              button(@click.stop="buttonCallback(item)")
                span(:class="[ buttonIcon, 'icon' ]")

        //- SUMMARY
        tr(v-if="tableData.summary" class="summary-row")
          td(class="total-cell") {{ this.totalText }}
          td(
            v-for="(summary, summaryIndex) in parsedSummary"
            class="summary-item"
          )
            span {{ summary.value  }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ItemTypeEnum, TableStructure, TableSummary } from './types/HTMLTableComponentTypes'

@Component({
  computed: {
    ItemTypeEnum() {
      return ItemTypeEnum
    }
  }
})
export default class HTMLTableComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  tableData!: TableStructure

  @Prop({
    type: Function
  })
  buttonCallback!: (item: any) => void

  @Prop({
    type: String
  })
  buttonIcon!: string

  totalText = this.$t('components.HTML_table.total')

  get parsedSummary() {
    const parsed: any[] = []

    this.tableData.headers.forEach((header, index) => {
      if (index !== 0) {
        const item: TableSummary = {
          key: header.key,
          value: '',
          type: header.type
        }
        this.tableData.summary.forEach((summary) => {
          if (header.key === summary.key) {
            item.value = summary.value
          }
        })
        parsed.push(item)
      }
    })
    return parsed
  }
}
</script>

<style lang="scss" scoped>
.html-table-container {
  $cellPadding: 14px;

  .table-name {
    font-family: $corporate-font;
    color: $corporate-color;
    font-size: 16px;
    margin-bottom: 10px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  table {
    width: 100%;
    font-family: $secondary-font;
    font-size: 12px;
    color: $gray-02;
    border-collapse: collapse;

    tr {
      line-height: 28px;
    }

    .table-head-row {
      @include border;

      .head-item {
        color: $gray-01;
        font-weight: bold;
        text-align: right;
        width: 200px;

        &:first-of-type {
          text-align: left;
          padding-left: $cellPadding;
        }

        &:last-of-type {
          padding-right: $cellPadding;
        }
      }
      .head-item.button-col {
        width: 30px;
      }
    }

    .body-row {
      &.disabled-item .body-item {
        color: $gray-02;
      }
    }

    .body-item:first-of-type {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .body-item,
    .summary-item {
      &:first-of-type {
        color: $corporate-color;
        padding-left: $cellPadding;
      }

      &:last-of-type {
        padding-right: $cellPadding;
      }

      &:not(:first-of-type) {
        text-align: right;
      }
    }

    .summary-row {
      background-color: $gray-04;

      .total-cell {
        color: $corporate-color;
        text-transform: uppercase;
        padding-left: $cellPadding;
      }

      &:last-of-type {
        padding-right: $cellPadding;
      }
    }

    .currency-symbol {
      padding-left: 3px;
    }
  }

  ~ .html-table-container {
    margin-top: 20px;
  }
}
</style>
