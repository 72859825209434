export interface Customer {
  IBANNumber: string | null
  UID: string | null
  accountingAccount: string | null
  address: string | null
  birthDay: string | null
  broughtBy: string | null
  canDelete: string | null
  canEdit: string | null
  charged: string | null
  country: string | null
  day1: string | null
  day2: string | null
  day3: string | null
  daysBetweenExpiration: string | null
  daysFirtsExpiration: string | null
  dni: string | null
  email: string | null
  emailPortal: string | null
  expirationsNumber: null
  fax: string | null
  id: number | null
  idCountry: string | null
  idEntry: string | null
  idFolder: string | null
  idPortal: string | null
  idProvince: string | null
  idpaymentType: string | null
  manualExpiration: string | null
  mobilePhone: string | null
  name: string | null
  namePortal: string | null
  notes: string | null
  paymentType: string | null
  phone: string | null
  postalCode: string | null
  province: string | null
  reference: string | null
  secretKey: string | null
  statePortal: string | null
  statePortalText: string | null
  town: string | null
  userPortal: string | null
  web: string | null
}

export interface CustomersState {
  total: string
}

export const customersMutationTypes = {
  FETCH_TOTAL_NUMBER_OF_CUSTOMERS: 'FETCH_TOTAL_NUMBER_OF_CUSTOMERS'
}
