<template lang="pug">

    div(:class="['stage-selector-wrapper', { 'form-layer-is-open': formLayerIsOpen, 'show-select': showSelect }]")

      button(
        @click="toggleShowSelect"
        :class="['stage-icon', icons.stage]"
        :disabled="disabled"
      )

      div(class="stage-text" v-if="!showSelect") {{ texts.stageText }}

      transition(name="expand")
        v-select(
          ref="stageSelect"
          v-if="showSelect"
          v-model="selectedStage"
          :items="filteredItems"
          @click="clearSearch"
          class="stage-selector"
          :append-icon="icons.arrowDown"
          :clear-icon="icons.clearIcon"
          hide-details
          :menu-props="{offsetY: true}"
          attach
          dense
          clearable
          item-text="stage"
          item-value="id"
          :placeholder="texts.selectAll"
          return-object
          v-on:change="setState"
        )

          template(v-slot:prepend-item)
            div(v-if="!formLayerIsOpen" :class="itemsContainerClass")
              div(class="search-field-and-add-button-container")
                div(class="search-field-container")
                  input(class="search-field" v-model="searchStage")
                  div(
                    class="search-icon-container"
                    @click="clearSearch"
                  )
                    span(:class="['search-icon', searchIconRendered]")
                button(
                  v-if="canSave"
                  :class="['add-new-button', icons.addNew]"
                  @click.stop="openForm"
                )
              div(
                :class="['select-all', { 'is-portal': isPortalUser }]"
                @click.stop="selectAllTheStages"
              ) {{ texts.selectAll }}
            div(
              v-else
              class="form-container"
            )
              div(class="form-header")
                span(class="form-title") {{ formTitle }}
                v-spacer
                ActionsBarComponent(
                  :buttons="buttons"
                  @execute-action="executeAction"
                  class="action-buttons"
                )
              div(class="form-body")
                LexonTextFieldComponent(
                  v-model="formData.stage"
                  :label="texts.stageNameLabel"
                  :id="texts.stageNameLabel"
                  :name="texts.stageNameLabel"
                  type="text"
                  :maxlength="maxNumberOfCharactersInStageNameField"
                  :validationRules="`required|max:${maxNumberOfCharactersInStageNameField}`"
                )
                LexonTextAreaComponent(
                  v-model="formData.notes"
                  :label="texts.stageNotesLabel"
                  :id="texts.stageNotesLabel"
                  :name="texts.stageNotesLabel"
                  :maxlength="maxNumberOfCharactersInNotesField"
                  :validationRules="`max:${maxNumberOfCharactersInNotesField}`"
                )
                LexonSwitchComponent(
                  v-model="formData.current"
                  :label="texts.stageCurrentLabel"
                  :id="texts.stageCurrentLabel"
                  :name="texts.stageCurrentLabel"
                )


          template(v-slot:item="{ item }")
            span(v-if="formLayerIsOpen")
            div(v-else class="item-container")
              span(
                v-if="canSave"
                :class="[icons.edit, 'edit-icon']"
                @click.stop="openForm(false, item)"
              )
              span(class="stage-item") {{ item.stage }}
              span(v-if="item.current" class="stage-current lf-icon-connect")

          template(v-slot:no-data)
            p(:class="[{ 'no-data__hide': !searchStage }, 'no-results-message']") {{ $t('components.no_results.no_search_results_text') }}

</template>

<script lang="ts">
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import LexonTextAreaComponent from '@/components/forms/fields/LexonTextArea/LexonTextAreaComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import { Action, Getter } from 'vuex-class'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MainService } from '@/services/MainService'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'

const alertsModule = ModuleNamespaces.ALERTS
const authModule = ModuleNamespaces.AUTH
const endpointModule: string = ModuleNamespaces.ENDPOINT
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    ActionsBarComponent,
    LexonSwitchComponent,
    LexonTextFieldComponent,
    LexonTextAreaComponent
  }
})
export default class StageSelectorComponent extends Vue {
  @Prop({
    type: String
  })
  context!: string

  @Prop({
    type: Number,
    required: true
  })
  entityId!: number

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  @Prop({
    type: Object,
    default: { canSave: 0, canDelete: 0 }
  })
  permissions: PermissionVerbs

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}
  @Action('saveExpedientStage', { namespace: expedientsModule })
  saveExpedientStage: ({}) => void
  @Action('setExpedientStageState', { namespace: expedientsModule })
  setExpedientStageState: ({}) => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any
  @Getter('getLastExpedientSaved', { namespace: expedientsModule }) lastExpedientSaved: number
  @Getter('getEndpoints', { namespace: endpointModule }) endpoints: (entityType: number) => Endpoint
  @Getter('getEntity', { namespace: entitiesModule }) entity: (context: string) => Entity
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean
  @Getter('getCompanyDefaultStage', { namespace: authModule })
  hasCompanyDefaultStage: boolean

  $refs!: {
    stageSelect: any
  }

  items: object[] = []

  showSelect: boolean = false

  selectedStage: object | undefined | null = null
  selectedALL: boolean = false
  activeStage: number = 0

  searchStage: string = ''

  formData = {
    id: 0,
    idExpedient: this.entityId,
    stage: '',
    notes: '',
    current: 0
  }

  maxNumberOfCharactersInStageNameField: number = 90
  maxNumberOfCharactersInNotesField: number = 300

  icons = {
    addNew: Icons.ADD,
    arrowDown: Icons.ANGLE_DOWN,
    clearIcon: Icons.CLOSE_ROUND,
    clearSearchIcon: Icons.CLOSE_SEARCH,
    edit: Icons.EDIT,
    searchIcon: Icons.SEARCH,
    stage: Icons.STAGE
  }

  texts = {
    addStage: this.$t('components.stage_selector.add_stage'),
    closeButton: this.$t('action_buttons.close'),
    editStage: this.$t('components.stage_selector.edit_stage'),
    saveButton: this.$t('action_buttons.save'),
    selectAll: this.$t('components.stage_selector.select_all'),
    stageNameLabel: this.$t('components.stage_selector.stage_name_label'),
    stageNotesLabel: this.$t('components.stage_selector.stage_notes_label'),
    stageCurrentLabel: this.$t('components.stage_selector.stage_current_label'),
    stageText: this.$t('components.stage_selector.stage_text'),
    saveStageSuccess: this.$t('components.stage_selector.save_stage_success')
  }

  formLayerIsOpen: boolean = false

  isAddNewStageformMode: boolean = true

  get formTitle() {
    return this.isAddNewStageformMode ? this.texts.addStage : this.texts.editStage
  }

  get searchIconRendered() {
    return this.searchStage.length ? this.icons.clearSearchIcon : this.icons.searchIcon
  }

  get itemsContainerClass(): string {
    return this.items.length ? 'sticky sticky-border' : 'sticky'
  }

  get buttons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.closeButton,
        class: 'red-color',
        action: ActionName.CLOSE,
        show: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.saveButton,
        class: 'green-color',
        action: ActionName.SAVE,
        show:
          (this.formData.stage &&
          this.formData.stage.length &&
          this.formData.stage.length <= this.maxNumberOfCharactersInStageNameField
            ? true
            : false) &&
          (this.formData.notes && this.formData.notes.length
            ? this.formData.notes.length <= this.maxNumberOfCharactersInNotesField
              ? true
              : false
            : true)
      }
    ]
  }

  get filteredItems() {
    const lowerSearch = this.searchStage.toLowerCase()

    let filteredItems: object[] = []
    if (this.items) {
      filteredItems = this.items.filter((item: any) => item.stage.toLowerCase().includes(lowerSearch))
      if (this.selectedStage) {
        const selectedStageHidden = {
          ...this.selectedStage,
          disabled: true
        }
        filteredItems.push(selectedStageHidden)
      }
    }

    return filteredItems
  }

  get canSave() {
    return !this.isPortalUser && this.permissions.canSave
  }

  toggleShowSelect() {
    this.showSelect = !this.showSelect
    this.showSelect ? (this.activeStage = 1) : (this.activeStage = 0)
    if (this.selectedRegisterData(this.context)) {
      this.updateExpedientRegister()
    }
    this.setState()
  }

  async updateExpedientRegister() {
    const url = this.endpoints(this.entity(this.context).type).save

    const data = {
      id: this.selectedRegisterData(this.context).idExpedient,
      activeStages: this.activeStage
    }
    await new MainService().postData(url, data)
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.closeForm()
      case ActionName.SAVE:
        return this.saveNewStage()
    }
  }

  clearSearch() {
    this.searchStage = ''
  }

  async saveNewStage() {
    if (this.formData.idExpedient === 0) {
      this.formData.idExpedient = this.selectedRegisterData(this.context).idExpedient
    }
    await this.saveExpedientStage(this.formData)
    if (this.formData.current && this.items) {
      this.items.map((item: any) => {
        if (+item.current === 1) {
          item.current = 0
        }
      })
    }
    if (this.isAddNewStageformMode) {
      this.formData.id = this.lastExpedientSaved
      this.items.push(this.formData)
    } else {
      this.items.map((item: any) => {
        if (item.id === this.formData.id) {
          item.stage = this.formData.stage
          item.notes = this.formData.notes
          item.current = Number(this.formData.current)
        }
      })
    }

    this.closeForm()
    this.showAlert({
      type: AlertsTypes.SUCCESS,
      message: this.texts.saveStageSuccess,
      componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
    })
  }

  selectAllTheStages() {
    this.selectedALL = true
    this.selectedStage = null
    this.searchStage = ''
    this.setState()
    this.$refs.stageSelect.isMenuActive = false
  }

  getSearchFieldValue(e: Event) {
    const searchFieldEvent = e.target as any
    this.selectedStage = searchFieldEvent!.value
  }

  openForm(isAddNewMode: boolean = true, item: any = null) {
    this.isAddNewStageformMode = isAddNewMode
    this.formLayerIsOpen = true
    if (item) {
      this.formData.id = item.id
      this.formData.stage = item.stage
      this.formData.notes = item.notes
      this.formData.current = item.current
    }
  }

  closeForm() {
    this.isAddNewStageformMode = false
    this.formLayerIsOpen = false
    this.formData = {
      id: 0,
      idExpedient: this.selectedRegisterData(this.context).idExpedient,
      stage: '',
      notes: '',
      current: 0
    }
  }

  toggle() {
    this.formLayerIsOpen = !this.formLayerIsOpen
  }

  setState() {
    let selectedStageId = 0
    if (this.selectedStage) {
      selectedStageId = (this.selectedStage as any).id
    }
    const data = {
      activeStages: this.activeStage,
      stageId: this.activeStage ? selectedStageId : 0
    }
    
    this.setExpedientStageState(data)
  }

  created() {
    this.items = this.selectedRegisterData(this.context).stages || []

    const current = this.items.find((item: any) => 1 === item.current && this.hasCompanyDefaultStage && !this.selectedALL )
    if(current) {
      this.selectedStage = current
      this.setState()
    }

    if (this.selectedRegisterData(this.context).activeStages === 1) {
      this.activeStage = 1
      this.showSelect = true
    } else {
      this.showSelect = false
    }

    this.setState()
  }
}
</script>

<style lang="scss" scoped>
.stage-selector-wrapper {
  @include flex;
  min-width: 50px;
  height: 48px;
  background-color: $gray-06;
  border-radius: 24px;
  padding: 0 10px 0 6px;
  margin-right: 6px;

  &.show-select {
    @include borders($width: 4px, $color: $blue-06);
    background-color: $blue-04;
    padding: 0 10px 0 4px;

    .stage-icon {
      background: $corporate-color;
    }
  }

  .stage-text {
    color: $white-01;
    text-transform: uppercase;
    margin-left: 6px;
  }

  ::v-deep .v-select-list {
    padding-bottom: 8px !important;
  }

  ::v-deep .v-input__append-inner .v-icon {
    margin-top: 2px !important;
  }

  .item-container {
    @include flex($align-items: flex-start);
  }

  .stage-item {
    color: $gray-01;
    font-size: 14px;
    padding-top: 5px;
    padding-right: 20px;
    word-break: break-all;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .stage-current {
    background-color: $blue-01;
    height: 26px;
    width: 26px;
    position: absolute;
    right: 5px;
    border-radius: 50px;
    color: $white-01;
    padding-top: 5px;
    padding-left: 5px;
  }

  &.form-layer-is-open {
    ::v-deep .v-menu__content {
      min-height: 391px;
    }

    ::v-deep .v-list-item.v-list-item--link {
      display: none;
    }

    ::v-deep .lf-switch-container .v-input__slot {
      top: -6px;
    }
  }

  .stage-icon {
    @include flex;
    width: 35px;
    height: 35px;
    color: $white-01;
    font-size: 20px;
    background-color: $gray-05;
    border-radius: 50%;

    &::before {
      transform: translate(-1px);
    }
  }

  .stage-selector {
    max-width: 300px;
    height: 40px;
    background: $white-01;
    margin: 0 3px;
    padding: 0;
    transform-origin: 0 100%;

    &.v-select--is-menu-active {
      @include borders;
      height: 41px;
      background-color: $blue-07;

      ::v-deep .v-list-item {
        min-height: 42px;
        max-height: 152px;
        height: auto;

        &::before {
          opacity: 0;
        }

        &:hover {
          background: $blue-07;

          .stage-item {
            color: $corporate-color;
          }
        }
      }
    }

    ::v-deep .v-list-item--disabled {
      display: none;
    }

    .edit-icon {
      color: $corporate-color;
      font-size: 22px;
      margin-top: 3px;
      margin-right: 12px;
    }

    ::v-deep .v-input__slot {
      &::before,
      &::after {
        display: none;
      }

      .v-select__slot {
        min-width: 300px;
        height: 40px;
        padding: 0 10px;

        .v-icon {
          font-size: 20px;
        }

        .v-input__append-inner {
          margin-top: 7px;
        }

        .v-select__selection {
          color: $gray-01;
          font-size: 16px;
        }
      }
    }

    ::v-deep .v-menu__content {
      box-shadow: none;
      border-radius: 0;

      .select-all {
        @include flex($justify-content: 'start');
        padding: 2px 0 0 40px;
        height: 36px;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          background-color: $blue-07;
        }

        &.is-portal {
          padding-left: 10px;
        }
      }
    }

    .sticky {
      position: sticky;
      background-color: $white-01;
      top: 0;
      padding-top: 1px;
      z-index: 99;
    }

    .sticky-border {
      border-bottom: 1px solid $blue-01;
    }

    ::v-deep .search-field-and-add-button-container {
      @include flex;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 8px 8px;
      margin: 10px 0 0 0;
      .v-icon {
        font-size: 300px;
      }

      .search-field-container {
        @include borders;
        @include flex;
        height: 42px;
        padding: 0 8px;
        flex-grow: 1;

        &:focus-within,
        &:hover {
          background-color: $blue-07;

          .search-field::placeholder {
            color: $blue-04;
          }
        }

        .search-field {
          padding-right: 8px;
          color: $corporate-color;
          pointer-events: all;

          &::placeholder {
            font-size: 12px;
            font-family: $secondary-font;
            color: $gray-02;
          }
        }

        .search-icon-container {
          @include flex;
          @include border($direction: left);
          height: 32px;
          font-size: 20px;
          padding: 2px 0 2px 8px;
          cursor: pointer;
        }
      }

      .add-new-button {
        @include interactive-round-button;
        margin: 0 2px 0 6px;
      }
    }
  }

  .form-container {
    margin: 8px 0 0 0;

    .form-header {
      @include flex;
      @include border($color: $gray-03);
      padding: 0 4px 6px 8px;

      .form-title {
        font-family: $corporate-font-bold;
        font-size: 12px;
        color: $gray-02;
        text-transform: uppercase;
      }
    }

    .form-body {
      padding: 10px 8px;
    }
  }
}

.expand-enter-active {
  animation: expand 0.2s;
}

.expand-leave-active {
  animation: expand 0.2s reverse;
}

@-webkit-keyframes expand {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes expand {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@include mobile {
  .stage-selector-wrapper {
    margin-top: 20px;
    order: 3;
  }
}

::v-deep .v-menu__content.menuable__content__active .no-results-message.no-data__hide {
  display: none;
}
</style>
