export interface Task {
  confidential: string
  dueDate: string
  endDate: string
  ended: string
  id: string
  idCategory: string | null
  idEntry: null
  idUser: string
  notes: string | null
  startDate: string
  subject: string
}

export interface CreatedNewTask {
  confidential: string
  id: string
  startDate: string
  subject: string
}

export interface ControlSidebarTasksObject {
  data: Task[]
  pagination: {
    numPage: number
    totalRecs: string
  }
}

export interface TasksState {
  controlSidebarTasksObject: ControlSidebarTasksObject
}

export const tasksMutationTypes = {
  FETCH_PAGINATED_TASKS: 'FETCH_PAGINATED_TASKS'
}
