<template lang="pug">
  div
    GridTable(
      v-if="renderGrid && !showSpinnerLayer"
      :itemsData="isPortalUser ? listItems : billingData"
      :gridConfiguration="listConfig"
      :listName="listName"
      :title="$t('views.billing_proforma_invoices.title')"
      :toolbarOptions="toolbarOptions"
      :contextMenuItems="isPortalUser ? [printButton] : invoiceContextMenuItems"
      :context="context"
      :totals="totals"
      :archivedFilter="archivedSelectedOption"
      :disabledGridCellClicked="getDisableGridProperty"
      :activeOnClickCell="selectedOnlyOneInvoice"
      :checkboxSelectColumn="hasCompanyTemplateInvoice"
      :frozenColumns="isPortalUser ? 1 : 3"
      @contextMenuClicked="onContextMenuClicked"
      @contextMenuBeforeOpen="onContextMenuBeforeOpen"
      @gridTableFilter="onGridTableFilter"
      @toolbarClicked="onToolbarClicked"
      @selectedRecords="onSelectedRecords"
    )

    ejs-contextmenu(
      :id="idContextMenu"
      class="context-menu-component e-contextmenu-archived"
      :items="invoicesArchivedContextMenuItemsParsed"
      :select="onInvoicesArchivedClick"
    )

    PrintOrSaveInvoicesDialog(
      :showDialog="showTemplateDialog"
      :idEntityType="idEntityType"
      @closePrintOrSaveInvoiceDialog="closeTemplateDialog"
      @generateTemplateDialog="generateTemplateDialog"
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { URLS } from '@/router/routes/urlRoutes'
import PrintOrSaveInvoicesDialog from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesDialog.vue'
import { UserType } from '@/store/modules/auth/authTypes'
import { InvoiceEntityId } from '@/general/entityIds'
import { MAX_SELECTED_INVOICE_PRINT } from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import { InvoiceGenerated } from '@/store/modules/invoices/invoicesTypes'
import { MenuItem, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const billingModule: string = ModuleNamespaces.BILLING
const invoicesModule = ModuleNamespaces.INVOICES
const alertsModule = ModuleNamespaces.ALERTS
const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    GridTable,
    PrintOrSaveInvoicesDialog
  }
})
export default class ProformaInvoicesView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin,
  PortalUserMixin
) {
  @Action('fetchBillingData', { namespace: billingModule })
  fetchBillingData: (contactType: {}) => []

  @Action('fetchTotals', { namespace: billingModule })
  fetchTotals: (source: string) => []

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Action('convertProformaToCustomerInvoice', { namespace: invoicesModule })
  convertProformaToCustomerInvoice: (idInvoice: number) => Promise<string>

  @Action('fetchInvoices', { namespace: invoicesModule })
  fetchPortalInvoices: (filter: {}) => []

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Mutation('ADD_GENERATE_INVOICE', { namespace: invoicesModule })
  addGenerateInvoice: (selectedInvoices: InvoiceGenerated[]) => void

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.proforma.type
  gridFilters: any = {}
  selectedInvoices = []

  contextMenuItemsText = {
    openNewWindow: this.$t('components.context_menu.open_window'),
    print: this.$t('action_buttons.print'),
    printOrSave: this.$t('action_buttons.print_or_save'),
    rectifyCustomerInvoice: this.$t('components.context_menu.rectify_customer_invoice')
  }

  get context() {
    return ContextName.BILLING
  }

  get listConfig() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const index = config.columns.findIndex(({ editType }: any) => editType === 'booleanedit')
    if (index && index !== -1) {
      config.columns[index].visible = this.hasCompanyTemplateInvoice
    }
    return JSON.stringify(config)
  }

  get listName() {
    return (this as any).listConfiguration['Alias']
  }

  get invoiceContextMenuItems() {
    const items = this.contextMenuItemsDefault
    const getPrintContextMenuItems = this.getPrintContextMenuItems(items)
    const { canSave } = this.checkEntityPermissionsGetter(InvoiceEntityId.PROFORMA_INVOICES)

    if (!!canSave) {
      getPrintContextMenuItems.splice(2, 0, {
        iconCss: Icons.COIN,
        text: this.$t('action_buttons.convert_to_customer_invoice')
      })
    }
    // Cambia el literal del menu por defecto de IMPRIMIR a IMPRIMIR/GUARDAR
    this.changePrintContextMenuLiteral(getPrintContextMenuItems)

    return this.getPrintContextMenuItems(items)
  }

  get filters() {
    const filters = {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }

    return this.isPortalUser ? { ...filters, ...this.portalFilters } : filters
  }

  get selectedOnlyOneInvoice() {
    return this.selectedInvoices.length <= 1
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.PROFORMA_INVOICES)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.PROFORMA_INVOICES)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchPortalInvoices({
        archived: this.invoicesDefaulArchivedOption,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.CUSTOMER_PROFORMA_INVOICES
      })
    } else {
      await this.fetchBillingData({
        billingType: ListNames.PROFORMA_INVOICES,
        filter: { archived: this.archivedSelectedOption }
      })
    }
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (
      args.item.text === this.contextMenuItemsText.printOrSave ||
      (this.isPortalUser && args.item.text === this.contextMenuItemsText.openNewWindow)
    ) {
      if (this.selectedInvoices.length > MAX_SELECTED_INVOICE_PRINT) {
        this.showDialog({
          type: DialogTypes.WARNING,
          hideSecondaryButton: true,
          message: this.$t('components.dialog.exceeded_number_of_selected_invoices', {
            selectedInvoicesLength: this.selectedInvoices.length,
            maxInvoiceSelected: MAX_SELECTED_INVOICE_PRINT
          })
        })
        args.cancel = true
        return
      }
      if (this.hasCompanyTemplateInvoice) {
        this.idEntity = selectedRegister.id
        this.showTemplateDialog = true
        const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
          return {
            idEntity: idInvoice,
            idEntityType: this.idEntityType,
            numberInvoice,
            status: InvoiceStatus.LOADING
          }
        })
        this.addGenerateInvoice(selectedInvoices)
      } else {
        this.printInvoiceOld(selectedRegister.id, entity.invoices.proforma.alias)
      }
    } else if (args.item.text === this.$t('action_buttons.convert_to_customer_invoice')) {
      this.convertToCustomerInvoice(selectedRegister.id)
    }
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  onContextMenuBeforeOpen(component: any, grid: any, selectedRegister: any) {
    const disabledBtn: boolean = !Boolean(selectedRegister.billed)
    component.ej2Instances.enableItems([this.$t('action_buttons.convert_to_customer_invoice')], disabledBtn)
    grid.enableItems([this.$t('action_buttons.convert_to_customer_invoice')], disabledBtn)

    grid.items.forEach((item: MenuItemModel) => {
      const enable = !(item.text !== this.contextMenuItemsText.printOrSave && !this.selectedOnlyOneInvoice)
      component.enableItems([item.text], enable)
      grid.enableItems([item.text], enable)
    })
  }

  convertToCustomerInvoice(id: number) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.associated_invoice'),
      action: () => this.convertingToCustomerInvoice(id)
    })
  }

  async convertingToCustomerInvoice(id: number) {
    try {
      const newCustomerInvoiceId = await this.convertProformaToCustomerInvoice(id)

      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message: this.$t('components.alerts.associated_invoice_success'),
        componentWhereIsRendered: ComponentWhereIsRendered.GRID_TABLE
      })

      const routeData = this.$router.resolve({
        name: `${URLS.BILLING}-${URLS.CUSTOMER_INVOICES}`,
        params: { selectedRegisterId: newCustomerInvoiceId }
      })

      window.open(routeData.href, '_blank')

      await this.fetchBillingData(ListNames.PROFORMA_INVOICES)
    } catch (error) {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.assocciated_invoice_error')
      })
    }
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchTotals({
      source: ListNames.PROFORMA_INVOICES,
      ...this.filters
    })
  }

  onToolbarClicked(args: any) {
    trackEvent(TrackerEvents.CREATE_INVOICE)
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  changePrintContextMenuLiteral(contextMenuItems: MenuItem[]) {
    contextMenuItems.forEach((option: MenuItem) => {
      if (option.text === this.contextMenuItemsText.print) {
        option.text = this.contextMenuItemsText.printOrSave as string
      }
    })
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.fetchGridItems()
    await this.fetchTotals({ source: ListNames.PROFORMA_INVOICES, ...this.filters })
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedInvoices = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }
}
</script>
