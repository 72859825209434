import { ActionTree } from 'vuex'
import {
  DashboardWidgetsState,
  dashboardWidgetsMutationTypes
} from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { SpinnerDashboardContext, spinnerMutationTypes } from '@/store/modules/spinner/spinnerTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<DashboardWidgetsState, RootState> = {
  // DASHBOARD WIDGETS CONFIGURATION
  async fetchDashboardWidgetsConfiguration({ commit, rootGetters }, alias) {
    try {
      const userId = rootGetters[`${ModuleNamespaces.AUTH}/getUserId`]
      const url = `configuration/user/lists/${userId}/${alias}/get`
      const response = await new MainService().getData(url)
      const { data } = response
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_WIDGETS_CONFIGURATION, data[0])
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_WIDGETS_CONFIGURATION_LOADED_FLAG, true)
    } catch (error) {}
  },
  // SAVE CONFIGURATION
  async saveUserWidgetsCongiguration({ commit }, config) {
    try {
      const url = 'configuration/user/lists/save'
      await new MainService().postData(url, config)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_WIDGETS_CONFIGURATION_LOADED_FLAG, false)
    } catch (error) {}
  },
  // LAST EXPEDIENTS WIDGET
  async fetchDashboardLastExpedientsList({ commit }) {
    try {
      const filter = {
        archived: 0,
        order: 'ts',
        page: 1,
        pageNumber: 1,
        pageSize: 10,
        source: 'dashboard',
        typeOrder: 'desc'
      }
      const url = 'expedients/dashboard'
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_LAST_EXPEDIENTS_LIST, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.LAST_EXPEDIENTS_LIST,
        { root: true }
      )
    }
  },
  // LAST CUSTOMERS WIDGET
  async fetchDashboardLastCustomers({ commit }) {
    try {
      const url = 'contact/customers'
      const filter = {
        page: 1,
        pageSize: 10,
        pageNumber: 1,
        source: 'dashboard',
        order: 'FechaAlta',
        typeOrder: 'desc'
      }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_LAST_CUSTOMERS_LIST, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.LAST_CUSTOMERS_LIST,
        { root: true }
      )
    }
  },
  // EXPEDIENTS RESUME WIDGET
  async fetchDashboardExpedientsResume({ commit }) {
    try {
      const url = 'expedients/dashboardWidget/get'
      const { data } = await new MainService().postData(url)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_EXPEDIENT_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.EXPEDIENTS_RESUME,
        { root: true }
      )
    }
  },
  // EXPEDIENTS COUNTER CHART WIDGET
  async fetchDashboardExpedientsCounterDataChart({ commit }) {
    try {
      const url = 'expedients/dashboardChart/get'
      const { data } = await new MainService().postData(url)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_EXPEDIENTS_COUNTER_CHART, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.EXPEDIENTS_COUNTER_CHART,
        { root: true }
      )
    }
  },
  // RECENT ACTIVITY WIDGET
  async fetchDashboardRecentActivity({ commit }) {
    try {
      const url = 'audits/activity/get'
      const filter = { source: 'dashboardWidget' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_RECENT_ACTIVITY, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.RECENT_ACTIVITY,
        { root: true }
      )
    }
  },
  // LAST ACTIONS LIST WIDGET
  async fetchDashboardLastActionsList({ commit }) {
    try {
      const url = '/actions/get'
      const filter = {
        source: 'dashboard',
        page: 1,
        pageSize: 10,
        columnOrder: [
          {
            column: 'ts',
            order: 'desc'
          }
        ]
      }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_LAST_ACTIONS_LIST, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.LAST_ACTIONS_LIST,
        { root: true }
      )
    }
  },
  // CUSTOMERS RESUME WIDGET
  async fetchDashboardCustomersResume({ commit }) {
    try {
      const url = 'contact/customers/get'
      const filter = { source: 'dashboardResume' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_CUSTOMER_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.CUSTOMERS_RESUME,
        { root: true }
      )
    }
  },
  // CUSTOMERS COUNTER CHART
  async fetchDashboardCustomersCounterDataChart({ commit }) {
    try {
      const url = 'contact/customers/get'
      const filter = { source: 'dashboardChart' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_CUSTOMERS_COUNTER_CHART, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.CUSTOMERS_COUNTER_CHART,
        { root: true }
      )
    }
  },
  // LAST DOCUMENTS WIDGET
  async fetchDashboardLastDocumentsList({ commit }) {
    try {
      const url = 'repository/documents/all'
      const filter = { source: 'dashboardWidget' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_LAST_DOCUMENTS_LIST, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.LAST_DOCUMENTS_LIST,
        { root: true }
      )
    }
  },
  // INACTIVE EXPEDIENTS WIDGET
  async fetchDashboardInactiveExpedients({ commit }) {
    try {
      const url = 'expedients/dashboard'
      const filter = {
        archived: 0,
        order: 'DiasInactivos',
        page: 1,
        pageNumber: 1,
        pageSize: 10,
        source: 'dashboard',
        typeOrder: 'desc'
      }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_INACTIVE_EXPEDIENTS, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.INACTIVE_EXPEDIENTS_LIST,
        { root: true }
      )
    }
  },
  // UNPAID INVOICES LIST WIDGET
  async fetchDashboardUnpaidInvoices({ commit }) {
    try {
      const url = 'invoices/customers/list'
      const filter = { source: 'dashboardWidgetImp' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_UNPAID_INVOICES_LIST, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.UNPAID_INVOICES_LIST,
        { root: true }
      )
    }
  },
  // AMOUNT OUTSTANDING RESUME WIDGET
  async fetchDashboardAmountOutstandingResume({ commit }) {
    try {
      const url = 'invoices/customers/list'
      const filter = { source: 'dashboardWidgetSinFacturar' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_AMOUNT_OUTSTANDING_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.AMOUNTS_OUTSTANDING_RESUME,
        { root: true }
      )
    }
  },
  // INVOICED AMOUNTS RESUME WIDGET
  async fetchDashboardInvoicedAmountsResume({ commit }) {
    try {
      const url = 'invoices/customers/list'
      const filter = { source: 'dashboardWidget' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_INVOICED_AMOUNTS_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.INVOICED_AMOUNTS_RESUME,
        { root: true }
      )
    }
  },
  // OFFICE DAILY WORKING TIME RESUME WIDGET
  async fetchDashboardOfficeDailyWorkingTimeResume({ commit }) {
    try {
      const url = 'actions/get'
      const filter = { source: 'dashboardWidgetDaily' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_OFFICE_DAILY_WORKING_TIME_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.OFFICE_DAILY_WORKING_TIME_RESUME,
        { root: true }
      )
    }
  },
  // OFFICE WEEKLY WORKING TIME RESUME WIDGET
  async fetchDashboardOfficeWeeklyWorkingTimeResume({ commit }) {
    try {
      const url = 'actions/get'
      const filter = { source: 'dashboardWidgetWeekly' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_OFFICE_WEEKLY_WORKING_TIME_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.OFFICE_WEEKLY_WORKING_TIME_RESUME,
        { root: true }
      )
    }
  },
  // OFFICE MONTHLY WORKING TIME RESUME WIDGET
  async fetchDashboardOfficeMonthlyWorkingTimeResume({ commit }) {
    try {
      const url = 'actions/get'
      const filter = { source: 'dashboardWidgetMonthly' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_OFFICE_MONTHLY_WORKING_TIME_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.OFFICE_MONTHLY_WORKING_TIME_RESUME,
        { root: true }
      )
    }
  },
  // USER DAILY WORKING TIME RESUME WIDGET
  async fetchDashboardUserDailyWorkingTimeResume({ commit }) {
    try {
      const url = 'actions/get'
      const filter = { source: 'dashboardWidgetPropiasDaily' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_USER_DAILY_WORKING_TIME_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.USER_DAILY_WORKING_TIME_RESUME,
        { root: true }
      )
    }
  },
  // USER WEEKLT WORKING TIME RESUME WIDGET
  async fetchDashboardUserWeeklyWorkingTimeResume({ commit }) {
    try {
      const url = 'actions/get'
      const filter = { source: 'dashboardWidgetPropiasWeekly' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_USER_WEEKLY_WORKING_TIME_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.USER_WEEKLY_WORKING_TIME_RESUME,
        { root: true }
      )
    }
  },
  // USER MONTHLY WORKING TIME RESUME WIDGET
  async fetchDashboardUserMonthlyWorkingTimeResume({ commit }) {
    try {
      const url = 'actions/get'
      const filter = { source: 'dashboardWidgetPropiasMonthly' }
      const { data } = await new MainService().postData(url, filter)
      commit(dashboardWidgetsMutationTypes.SET_DASHBOARD_USER_MONTHLY_WORKING_TIME_RESUME, data)
    } catch (error) {
    } finally {
      commit(
        `${ModuleNamespaces.SPINNER}/${spinnerMutationTypes.HIDE_DASHBOARD_WIDGET_SPINNER}`,
        SpinnerDashboardContext.USER_MONTHLY_WORKING_TIME_RESUME,
        { root: true }
      )
    }
  }
}
