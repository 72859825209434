<template lang="pug">

  div(class="profitability-item-container")
    span(:class="['data', renderProfitabilityColorStatus]") {{ renderDigit }} %
    LexonTooltipComponent(
      :icon="tooltipIcon"
      :message="tooltipText"
    )

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'
import { Icons } from '@/icons/icons'

@Component({
  components: {
    LexonTooltipComponent
  }
})
export default class ExpedientProfitabilityWarningTooltipComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  itemData!: string

  get profitabilityIsNotEqualToZero() {
    return this.itemData !== '0.00'
  }

  get renderDigit() {
    return this.profitabilityIsNotEqualToZero ? this.itemData : '0'
  }

  get renderProfitabilityColorStatus(): string {
    const convertDigitToNumber = parseFloat(this.itemData.replace('.', '').replace(',', '.'))
    if (convertDigitToNumber === 0) {
      return 'corporate-color'
    } else if (convertDigitToNumber < 0) {
      return 'negative-color'
    } else {
      return 'positive-color'
    }
  }

  tooltipText = this.$t('components.tooltip.profitability_global_vision_warning')

  tooltipIcon = Icons.WARNING
}
</script>

<style lang="scss" scoped>
.profitability-item-container {
  display: inline-block;

  .data {
    font-weight: bold;
    font-size: 15px;
    margin-left: 4px;

    &.positive-color {
      color: $success-color;
    }

    &.negative-color {
      color: $error-color;
    }
  }
}
</style>
