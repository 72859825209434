<template lang="pug">

  DashboardWidgetListType(
    class="dashboard-widget"
    v-bind="unpaidInvoicesDashboardListProps"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { DashboardListProps, DashboardInvoice } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import DashboardWidgetListType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetListType.vue'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'
import { Icons } from '@/icons/icons'

const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS
const spinnersModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardWidgetListType
  }
})
export default class DashboardUnpaidInvoicesListWidget extends Vue {
  @Action('fetchDashboardUnpaidInvoices', { namespace: dashboardWidgetsModule })
  fetchDashboardUnpaidInvoicesAction: () => Promise<void>

  @Getter('getDashboardUnpaidInvoicesList', { namespace: dashboardWidgetsModule })
  unpaidInvoicesGetter: DashboardInvoice[]

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showSpinner: (context: string) => boolean

  get unpaidInvoicesDashboardListProps(): DashboardListProps {
    return {
      items: this.unpaidInvoicesGetter,
      entity: entity.invoices.alias,
      headers: [
        'components.dashboard_widget_list_type.invoices.headers.invoiceDate',
        'components.dashboard_widget_list_type.invoices.headers.description',
        'components.dashboard_widget_list_type.invoices.headers.customer',
        'components.dashboard_widget_list_type.invoices.headers.totalInvoice'
      ],
      headerText: 'components.dashboard_widget_list_type.invoices.unpaid_invoices',
      url: `${URLS.BILLING}/${URLS.CUSTOMER_INVOICES}`,
      showSpinner: this.showSpinner(SpinnerDashboardContext.UNPAID_INVOICES_LIST),
      noResultIcon: Icons.BILLING,
      goToAllText: this.$t('components.dashboard_widget_list_type.invoices.go_to_all_text') as string,
      columnClasses: ['', '', '', 'lf-alignRight']
    }
  }

  created() {
    // Hace la petición si ha guardado alguna factura. Utiliza el estado del spinner como un flag
    if (this.showSpinner(SpinnerDashboardContext.UNPAID_INVOICES_LIST)) {
      this.fetchDashboardUnpaidInvoicesAction()
    }
  }
}
</script>

<style lang="scss" scoped>
$custom-width: 110px;

.dashboard-widget {
  ::v-deep .table-header tr.table-row {
    &:last-child th {
      border-bottom-color: $invoice-color !important;
    }

    .header-cell {
      &:last-of-type,
      &:first-of-type {
        width: $custom-width;
      }
    }
  }

  ::v-deep td {
    &:last-of-type,
    &:first-of-type {
      width: $custom-width;
    }

    &.lf-alignRight {
      text-align: right;
      padding-right: 6px !important;
    }
  }
}
</style>
