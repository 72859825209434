<template lang="pug">
GridTableComponent(
  ref="grid"
  :itemsData="gridData"
  :gridConfiguration="columns"
  :title="title"
  :toolbarOptions="toolbarOptions"
  :contextMenuItems="contextMenuItemsCourts"
  :listName="listName"
  :context="context"
  :showHeader="false"
  isInTab
  @contextMenuClicked="onContextMenuClicked"
  @contextMenuBeforeOpen="onContextMenuBeforeOpen"
  @cellClicked="onCellClicked"
  @toolbarClicked="onToolbarClicked"
)
</template>

<script lang="ts">
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import { Icons } from '@/icons/icons'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { ConfigurationAlias } from '@/store/modules/configuration/configurationTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { Court } from '@/store/modules/expedients/expedientsTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

const expedientModule = ModuleNamespaces.EXPEDIENTS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const expedientsModule = ModuleNamespaces.EXPEDIENTS

@Component({
  components: {
    GridTableComponent
  }
})
export default class ExpedientCourtsGrid extends Mixins(
  GridToolbarOptionsMixin,
  PermissionsMixin,
  GridContextMenuOptionsMixin
) {
  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: String,
    default: ''
  })
  columns!: string

  @Prop({
    type: String,
    default: ''
  })
  context!: string

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: Number,
    required: true
  })
  expedientId!: number

  @Action('deleteExpedientCourt', { namespace: expedientModule })
  delete: ({}) => Promise<boolean>

  @Action('fetchExpedientsCourt', { namespace: expedientModule })
  fecthCourt: ({}) => Promise<void>

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  gridData: []

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: []

  openForm: boolean = false
  entityType = 20

  $refs!: {
    grid: InstanceType<typeof GridTableComponent>
  }

  get editCourtContextMenuItem() {
    return {
      text: this.contextMenuOptionsTexts.editCourt,
      iconCss: Icons.EDIT
    }
  }

  @Emit()
  onOpenForm(selectedRegister: Court | null = null, formType: string = ConfigurationAlias.EXPEDIENT_COURTS_FORM) {
    return { selectedRegister, formType }
  }

  get contextMenuItemsCourts() {
    const { editItem, lookItem, openWindow, removeItem } = this.contextMenuOptionsTexts
    const { canSave } = this.viewPermission
    return [
      {
        text: canSave ? editItem : lookItem,
        iconCss: canSave ? Icons.EDIT : Icons.VISIBLE
      },
      {
        text: this.$t('components.context_menu.edit_court'),
        iconCss: Icons.EDIT
      },
      {
        text: openWindow,
        iconCss: Icons.OPEN_WINDOW
      },
      ...(canSave
        ? [
            {
              separator: true
            },
            {
              text: removeItem,
              iconCss: Icons.REMOVE,
              action: this.showDialog
            }
          ]
        : [])
    ]
  }

  onContextMenuClicked(args: any, selectedRegister: Court) {
    const { editItem, editCourt, lookItem, openWindow, removeItem } = this.contextMenuOptionsTexts
    if (args.item.text === editItem || args.item.text === lookItem) {
      this.onOpenForm(selectedRegister)
    } else if (args.item.text === editCourt) {
      this.onOpenForm(selectedRegister, ConfigurationAlias.COURT_FORM)
    } else if (args.item.text === removeItem) {
      this.onRemove(selectedRegister)
    } else if (args.item.text === openWindow) {
      this.onOpenNewWindow(selectedRegister)
    }
    args.cancel = true
  }

  renderContextMenuEditCourtItem(canSave: number, contextMenuGridObject: ContextMenuComponent, contextMenuModule: any) {
    const { editCourt, openWindow } = this.contextMenuOptionsTexts
    if (canSave) {
      if (!contextMenuGridObject.items.find((item: MenuItemModel) => item.text === editCourt)) {
        contextMenuGridObject.insertBefore([this.editCourtContextMenuItem], openWindow, false)
        contextMenuModule.insertBefore([this.editCourtContextMenuItem], openWindow, false)
      }
    } else {
      contextMenuGridObject.removeItems([editCourt])
      contextMenuModule.removeItems([editCourt])
    }
  }

  onContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: ContextMenuComponent) {
    const selectedContactTypePermission = this.checkEntityPermissionsGetter(entity.contacts.courts.type)
    const { canSave } = selectedContactTypePermission
    this.renderContextMenuEditCourtItem(canSave, contextMenuGridObject, contextMenuModule)
  }

  onToolbarClicked(args: any) {
    if ('add' === args.item.id) {
      this.onOpenForm()
      args.cancel = true
    }
  }

  onCellClicked(selectedRegister: Court) {
    this.onOpenForm(selectedRegister)
  }

  async onRemove(selectedRegister: Court) {
    const { id, name } = selectedRegister

    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.courts_remove', {
        associateRelated: name
      }),
      action: () => this.removeAction(id, name)
    })
  }

  async removeAction(id: string, name: string) {
    const response = await this.delete(id)
    if (response) {
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.courts_remove_success', {
          associateRelated: name
        })
      })
    }
    this.refresh()
  }

  onOpenNewWindow(selectedRegister: Court) {
    const routeData = this.$router.resolve({
      name: `${URLS.CONTACTS}-${URLS.COURTS}`,
      params: { selectedRegisterId: selectedRegister.idCourt }
    })
    window.open(routeData.href, '_blank')
  }

  async refresh() {
    await this.fecthCourt({
      selectedRegisterId: this.expedientId,
      ...(this.expedientStageState && { stage: this.expedientStageState })
    })
    this.$refs.grid.refresh()
  }
}
</script>
