<template lang="pug">
div
  div(
    v-show="isLoading"
    class="loading")
    SpinnerLayerComponent(
      class="spinner-layer"
      :loadingText="spinnerText"
    )
  v-radio-group(
    v-if="tree.length > 0"
    v-model="templateSelected"
    :key="componentKey"
    @change="getTemplate"
  )
    v-treeview(
      v-show="!isLoading"
      ref="treeView"
      dense
      selected-color="#001978"

      :items="tree"
      item-text="name"
      item-disabled="locked"
      :open="itemsOpenend"
    )

      template(v-if="!isLoading" v-slot:label="{ item }")
        span(
          v-if="item.isDirectory"
          class="is-directory"
          @click="onClickDirectory(item)"
        ) {{item.name}}
          span(
            v-if="directoryIsEmpty(item)"
          ) {{ ' ('+ texts.emptyFolder +')' }}
        span(
          v-else
        ) {{item.name}}
      template(v-if="!isLoading" v-slot:prepend="{ item }")
        SpinnerLayerComponent(
          v-if="item.loading"
          class="spinner-layer-item"
        )
        span(
          v-if="item.isDirectory && !item.loading"
          :class="directoryIcon(item.id)"
          @click="onClickDirectory(item)"
        )
        div(
          v-else
          class="e-filemanager"
        )
          v-radio(
            v-if="!item.loading"
            class="radio-tree"
            :value="item"
            :on-icon="icons.on"
            :off-icon="icons.off"
          )
  div(v-else class="no-result-dialog")
    NoResultsComponent(
      v-if="!isLoading"
      :noResultsIcon="icons.document"
      :noResultsFirstText="texts.templatesNotFound"
      :noResultsSecondText="texts.templatesFromSettings"
      :withBorders="false"
      :verticalAlignCenter="true"
    )
</template>

<script lang="ts">
import { ITemplate, TemplateTree } from '@/store/modules/template/templateTypes'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { Icons } from '@/icons/icons'
import NoResultsComponent from '@/components/search/NoResults/NoResultsComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const templateModule = ModuleNamespaces.TEMPLATE

@Component({
  components: {
    SpinnerLayerComponent,
    NoResultsComponent
  }
})
export default class TemplateSelectorTreeComponent extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  prop!: string

  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  @Prop({
    type: Boolean,
    default: false
  })
  loading!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  generateTemplate!: boolean

  @Getter('getTemplateTree', { namespace: templateModule })
  tree: () => ITemplate[]

  @Getter('getTemplateTreeEntityType', { namespace: templateModule })
  treeEntityType: number

  @Action('fetchTemplateTree', { namespace: templateModule })
  fetchTemplateTree: ({}) => TemplateTree[]

  @Mutation('RESET_TEMPLATE_TREE', { namespace: templateModule })
  resetTemplateTree: () => void

  @Mutation('SET_TEMPLATE_TREE_ENTITY_TYPE', { namespace: templateModule })
  setTreeEntityType: (idEntityType: number) => void

  texts = {
    templatesNotFound: this.$t('components.file_manager.dialogs.templates_not_found'),
    generatingTemplate: this.$t('components.file_manager.dialogs.generating_document'),
    emptyFolder: this.$t('components.file_manager.advises.empty_folder'),
    templatesFromSettings: this.$t('components.file_manager.dialogs.templates_from_settings')
  }

  icons = {
    document: Icons.TEMPLATE,
    folder: Icons.FOLDER,
    folderOpen: Icons.FOLDER_OPEN,
    on: Icons.RADIO_ACTIVE,
    off: Icons.CIRCLE_O,
    close: Icons.CLOSE
  }
  componentKey: number = 0

  isLoading: boolean = true

  templateSelected: ITemplate | null = null
  itemsOpenend: number[] = []
  generatingTemplate: boolean = false

  get spinnerText() {
    const { generatingTemplate } = this.texts
    return this.generatingTemplate ? generatingTemplate : ''
  }

  async created() {
    if (this.idEntityType !== this.treeEntityType) {
      this.setTreeEntityType(this.idEntityType)
      await this.getTemplateTree()
    }
    this.isLoading = this.loading
    this.generatingTemplate = this.generateTemplate
  }

  async getTemplateTree() {
    const json = {
      idEntityType: this.idEntityType
    }
    await this.fetchTemplateTree(json)
  }

  updateComponent() {
    return (this.componentKey += 1)
  }

  async onClickDirectory(item: any) {
    const updateOpenItems = this.updateOpenItems(item.id)
    if (!updateOpenItems) {
      return false
    }

    this.spinnerTreeItem(item, true)

    await this.addChildrenTreeItems(item).then(() => {
      this.spinnerTreeItem(item, false)
    })
  }

  updateOpenItems(itemId: number) {
    if (!this.directoryIsOpen(itemId)) {
      this.addInitiallyOpenItems(itemId)
      return true
    } else {
      this.removeInitiallyOpenItems(itemId)
      return false
    }
  }

  spinnerTreeItem(item: any, loading: boolean) {
    item.loading = loading
    this.updateComponent()
  }

  async addChildrenTreeItems(item: any) {
    const childrenUndefined = typeof item.children === 'undefined'
    let data = !childrenUndefined ? item.children : []

    if (childrenUndefined) {
      data = await this.fetchTemplateTree({
        idEntityType: this.idEntityType,
        id: item.id,
        getData: true
      })
    }

    item.children = data || []
    item.locked = data.length <= 0
  }

  directoryIsOpen(itemId: number) {
    return this.itemsOpenend.includes(itemId)
  }

  directoryIcon(itemId: number) {
    return this.directoryIsOpen(itemId) ? this.icons.folderOpen : this.icons.folder
  }

  directoryIsEmpty(item: any) {
    return this.directoryIsOpen(item.id) && typeof item.children !== 'undefined' && item.children.length <= 0
  }

  addInitiallyOpenItems(itemId: number) {
    this.itemsOpenend.push(itemId)
  }

  removeInitiallyOpenItems(itemId: number) {
    this.itemsOpenend = this.itemsOpenend.filter((_itemId) => _itemId !== itemId)
  }

  @Emit('templateSelected')
  getTemplate() {
    return this.templateSelected
  }

  destroyed() {
    this.templateSelected = null
    this.isLoading = true
    this.generatingTemplate = true
  }
}
</script>

<style lang="scss" scoped>
.is-directory,
[class*='lf-icon-folder'] {
  color: $corporate-color;
  margin-right: 15px;
  cursor: pointer;
  span {
    font-size: 11px;
  }
}
.v-treeview-node--disabled {
  span {
    color: $gray-02;
  }
}
.v-treeview-node__toggle {
  width: 20px;
  &::before {
    display: none;
  }
}
::v-deep .spinner-layer-item {
  margin-left: 0px;
  .spinner-lefebvre {
    margin-right: 9px;
    width: $spinner-xs;
    height: $spinner-xs;
    border-width: 2px;
  }
}
::v-deep .v-treeview-node__level {
  width: 20px;
}
::v-deep .v-treeview-node__root {
  padding-left: 0;
  min-height: 25px;
}
::v-deep .v-icon--link {
  font-size: 12px;
  color: $corporate-color;
}
::v-deep .v-treeview-node__prepend {
  font-family: $lf-icons-font;
  color: $corporate-color;
  margin-right: 0px;
  .e-filemanager {
    span {
      background-image: none;
    }
    .radio-tree {
      position: relative;
      float: left;
      .v-input--selection-controls__ripple {
        height: 22px;
        width: 23px;
        left: -7px;
        top: calc(50% - 18px);
      }
      .v-input--selection-controls__input {
        .lf-icon-step-first {
          color: $corporate-color;
        }
      }
      .v-icon {
        font-size: 18px;
      }
    }
  }
}
::v-deep .uploaded-fail,
::v-deep .invalid {
  color: $red-01;
  margin-left: 10px;
}

::v-deep .spinner-layer {
  --spinner-layer-min-height: 32%;
  margin-top: 28%;

  .spinner-lefebvre {
    width: $spinner-md;
    height: $spinner-md;
  }
}

::v-deep .uploaded-ok {
  margin-left: 10px;
  color: $green-01;
}

::v-deep .v-treeview-node__label {
  font-family: $gray-01;
  flex: none;
  font-size: 13px;
}

::v-deep .v-icon.v-icon::after {
  background-color: transparent;
}

::v-deep .v-treeview-node__append {
  display: flex;
  margin-left: 10px;
  font-style: italic;
  font-size: 11px;
  color: $gray-01;
}

::v-deep .no-result-dialog {
  min-height: 100%;
  display: inline;
  text-align: center;
  .no-result-container {
    padding: 60px 10px 60px;
  }
}
</style>
