<template lang="pug">
  div(
    :class="getClasses"
    )
    header(class="grid-header")
      AlertComponent(
        :whereIsRendered="componentWhereIsRenderedAlertComponent"
      )
      span(class="grid-title") {{ title }}
    div(class="file-manager-alone")
      FileManager(
        :basePath="basePath"
        :idEntityTypeBase="idEntityTypeBase"
        :context="context"
        mainFolderDescription="Documentos"
        :initialBasePath="initialBasePath"
        :initialPath="initialPath"
        height="650"
        :canDropFilesOnMainFolder="false"
        :canCreateFoldersOnMainFolder="false"
        :permissionsEntity="idEntityTypeBase"
      )

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import FileManager from '@/components/FileManager/FileManagerComponent.vue'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter } from 'vuex-class'
import { UserType } from '@/store/modules/auth/authTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'

const authModule: string = ModuleNamespaces.AUTH
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    AlertComponent,
    FileManager
  }
})
export default class RepositoryView extends Mixins(PermissionsMixin) {
  @Getter('getUserType', { namespace: authModule })
  userType: number

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  context: string = ContextName.REPOSITORY
  title: string = this.$t('views.repository.title').toString()
  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.TABS_VIEW
  idEntityTypeBase = entity.documents.type

  get initialBasePath(): string {
    if (this.$route.query.basePath) {
      const basePath: string = this.$route.query.basePath.toString()
      return atob(decodeURI(basePath))
    }
    return '/'
  }

  get initialPath(): string {
    if (this.$route.query.path) {
      const path: string = this.$route.query.path.toString()
      return atob(decodeURI(path))
    }
    return '/'
  }

  get basePath(): string {
    switch (this.userType) {
      case UserType.LEXON:
      case UserType.PORTAL:
        return 'documentos'
      case UserType.LEXON_DMS:
        return 'documentos/general'
    }
    return 'documentos'
  }

  get getClasses() {
    return [
      { 'lexon-view-container': !this.showSpinnerLayer },
      { 'repository-hidden': this.showSpinnerLayer },
      'repository-container'
    ]
  }
}
</script>

<style lang="scss" scoped>
.repository-container {
  font-family: $corporate-font;

  &.repository-hidden {
    position: absolute;
    opacity: 0;
  }

  .grid-header {
    @include flex($justify-content: flex-start);
    @include border($style: dashed);
    position: relative;
    height: 59px;
    margin: 0 0 2px;

    .grid-title {
      display: inline-block;
      font-size: 20px;
      color: $corporate-color;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  ::v-deep .e-filemanager .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
    right: 1px !important;
  }

  ::v-deep .e-toolbar-items {
    border-left: 1px solid $corporate-color;
    border-right: 1px solid $corporate-color;
  }

  ::v-deep #file-manager_toolbar {
    border-top: 6px solid $corporate-color !important;
  }

  ::v-deep .document-form {
    border: 1px solid $corporate-color;
    border-top: 5px solid $corporate-color;
    background-color: $white-01;
    padding-top: 10px;
  }

  ::v-deep .grid-title {
    font-size: 15px !important;
    margin-bottom: 0px !important;
  }

  /*
    ::v-deep .file-manager-alone {
      border: 1px solid #001978;
      border-top: 6px solid #001978;
      height: 70px;
    }
   */
}
</style>
