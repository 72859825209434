import { i18n } from '@/plugins/vue-i18n'

export const URLS = {
  ACTIONS: i18n.t('routes.actions').toString(),
  ACTIONS_PANEL: i18n.t('routes.actions_panel').toString(),
  ACTION_ALL: i18n.t('routes.actions_all').toString(),
  ACTION_CALLS: i18n.t('routes.actions_calls').toString(),
  ACTION_EMAILS: i18n.t('routes.actions_emails').toString(),
  ACTION_LEXNET: i18n.t('routes.actions_lexnet').toString(),
  ACTION_MEETINGS: i18n.t('routes.actions_meetings').toString(),
  ACTION_OTHERS: i18n.t('routes.actions_others').toString(),
  ACTION_PROCEDURES: i18n.t('routes.actions_procedures').toString(),
  AGENCIES: i18n.t('routes.agencies').toString(),
  AGENDA: i18n.t('routes.agenda').toString(),
  ANOTHER_APPLICATIONS: i18n.t('routes.another_applications').toString(),
  AUDITS: i18n.t('routes.audits').toString(),
  BASE_TITLE: 'LEX-ON',
  BILLING: i18n.t('routes.billing').toString(),
  CALCULATORS: i18n.t('routes.calculators').toString(),
  CALLS: i18n.t('routes.calls').toString(),
  CONTACTS: i18n.t('routes.contacts').toString(),
  COURTS: i18n.t('routes.courts').toString(),
  CUSTOMERS: i18n.t('routes.customers').toString(),
  CUSTOMER_BAKDRAFTS: i18n.t('routes.customer_bankdrafts').toString(),
  CUSTOMER_INVOICES: i18n.t('routes.customer_invoices').toString(),
  DASHBOARD: i18n.t('routes.dashboard').toString(),
  DOUBLE_OPTIN: i18n.t('routes.double_optin').toString(),
  EDIT: i18n.t('routes.edit').toString(),
  EMAIL: i18n.t('routes.email').toString(),
  EXPEDIENT: i18n.t('routes.expedient').toString(),
  EXPEDIENTS: i18n.t('routes.expedients').toString(),
  EXTERNAL_LOGOUT: 'external-logout',
  FILE_MANAGER_GET: 'file-manager/get-data',
  INSURERS: i18n.t('routes.insurers').toString(),
  LEXNET: 'lexnet-app',
  LOGIN: 'login',
  MAINTENANCE: i18n.t('routes.maintenance').toString(),
  MAINTENANCE_ACTIONS: i18n.t('routes.maintenance_actions').toString(),
  MAINTENANCE_CONTACTS: i18n.t('routes.maintenance_contacts').toString(),
  MAINTENANCE_CUSTOMER_PORTAL: i18n.t('routes.maintenance_customer_portal').toString(),
  MAINTENANCE_DOCUMENTS: i18n.t('routes.maintenance_documents').toString(),
  MAINTENANCE_EXPEDIENTS: i18n.t('routes.maintenance_expedients').toString(),
  MAINTENANCE_INVOICES: i18n.t('routes.maintenance_invoices').toString(),
  MAINTENANCE_USERS: i18n.t('routes.maintenance_users').toString(),
  MEETINGS: i18n.t('routes.meetings').toString(),
  NAVISION_LOGIN: 'navision-login',
  NEW: i18n.t('routes.new').toString(),
  NOTARIES: i18n.t('routes.notaries').toString(),
  NOT_FOUND_PAGE: 'error',
  ONLINE: i18n.t('routes.online').toString(),
  OPPONENTS: i18n.t('routes.opponents').toString(),
  OPPONENT_ATTORNEYS: i18n.t('routes.opponent_attorneys').toString(),
  OPPONENT_LAWYERS: i18n.t('routes.opponent_lawyers').toString(),
  ORDER_FORMS: i18n.t('routes.order_forms').toString(),
  OTHERS: i18n.t('routes.others').toString(),
  OWN_ATTORNEYS: i18n.t('routes.own_attorneys').toString(),
  OWN_LAWYERS: i18n.t('routes.own_lawyers').toString(),
  PERMISSIONS: i18n.t('routes.permissions').toString(),
  PROCEDURES: i18n.t('routes.procedures').toString(),
  PROFORMA_INVOICES: i18n.t('routes.proforma_invoices').toString(),
  PROVIDERS: i18n.t('routes.providers').toString(),
  PROVIDER_BANKDRAFTS: i18n.t('routes.provider_bankdrafts').toString(),
  PROVIDER_INVOICES: i18n.t('routes.provider_invoices').toString(),
  PROVISIONS: i18n.t('routes.provisions').toString(),
  REMEMBER_PASSWORD: i18n.t('routes.remember_password').toString(),
  REPORT: i18n.t('routes.report').toString(),
  REPORTS: i18n.t('routes.reports').toString(),
  REPORTS_LIST: i18n.t('routes.reports_list').toString(),
  REPORT_DETAIL: i18n.t('routes.report_detail').toString(),
  REPOSITORY: i18n.t('routes.repository').toString(),
  REPOSITORY_CLIENTS: 'documentos/clientes',
  SEARCH: i18n.t('routes.search').toString(),
  SELECT_COMPANY: i18n.t('routes.select_company').toString(),
  SSO_PAYMENTS: i18n.t('routes.sso_payments').toString(),
  SSO_UNPAID: i18n.t('routes.sso_unpaid').toString(),
  TABS_VIEW: 'tabs-view'
}

export const externalURLS = {
  cookies_policy: 'https://lefebvre.es/politica-de-cookies/',
  courses: 'https://centrodeayuda.lefebvre.es/cursos/?idEntrada=',
  facebook: 'https://www.facebook.com/lefebvreelderecho',
  faqs: 'https://centrodeayuda.lefebvre.es/preguntas-frecuentes/?idEntrada=',
  help_center: 'https://centrodeayuda.lefebvre.es/?idEntrada=',
  help_center_email: 'mailto:clientes@lefebvre.es',
  legal_warning: 'https://lefebvre.es/aviso-legal/',
  linkedin: 'https://www.linkedin.com/company/lefebvre_es',
  manuals: 'https://centrodeayuda.lefebvre.es/manuales/?idEntrada=',
  news: 'http://centrodeayuda.elderecho.com/es/SitePages/Novedades.aspx?idEntrada=',
  privacy_policy: 'https://lefebvre.es/politica-privacidad#',
  shop: 'https://www.efl.es',
  twitter: 'https://twitter.com/Lefebvre_ES',
  videos: 'https://centrodeayuda.lefebvre.es/videos/?idEntrada=',
  youtube: 'https://www.youtube.com/channel/UCAxbOiNpUotcbZIRL3IxEDg'
}

export const joomlaURLS = {
  agenda: 'index.php?option=com_wgagenda&view=agenda&Itemid=105'
}
