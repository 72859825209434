<template lang="pug">

  div(
    class="dialog-actions-container"
  )
    button(
      @click.stop="eventButton(printInvoiceEvents.CANCEL)"
      v-if="renderCancelButton"
      class="secondary-action-button"
      :title="texts.cancel"
    ) {{ texts.cancel }}
    button(
      v-if="renderPrimaryButton"
      @click.stop="eventButton(printInvoiceEvents.GENERATE)"
      class="main-action-button"
      :disabled="disabledGenerate"
      :title="texts.generate"
    ) {{texts.generate}}
    button(
      v-if="renderFinishButton"
      @click.stop="eventButton(printInvoiceEvents.FINISH)"
      class="secondary-action-button"
      :title="texts.finish"
      :disabled="disabledDownload"
    ) {{ texts.finish }}

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import {
  PrintOrSaveInvoicesSteps,
  PrintInvoiceEvents
} from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'

@Component
export default class PrintOrSaveInvoicesDialogActionsComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  currentStep!: number

  @Prop({
    type: Boolean,
    default: false
  })
  disabledDownload!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  disabledGenerate!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showDownloadButton!: boolean

  texts = {
    cancel: this.$t('action_buttons.cancel'),
    finish: this.$t('action_buttons.finish'),
    generate: this.$t('action_buttons.generate'),
    download: this.$t('action_buttons.downloadPDF')
  }

  printInvoiceEvents = PrintInvoiceEvents

  get renderPrimaryButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.SELECT_INVOICES
  }

  get renderDownloadButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.GENERATION_PROGRESS && this.showDownloadButton
  }

  get renderFinishButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.FINAL_RESUME_LIST
  }

  get renderCancelButton() {
    return [PrintOrSaveInvoicesSteps.SELECT_INVOICES, PrintOrSaveInvoicesSteps.GENERATION_PROGRESS].includes(
      this.currentStep
    )
  }

  @Emit('eventButton')
  eventButton(event: string) {
    return { event }
  }
}
</script>

<style lang="scss" scoped>
.dialog-actions-container {
  @include flex($justify-content: flex-end);
  width: 100%;

  ::v-deep .main-action-button {
    @include main-action-button--rectangle;
  }

  ::v-deep .secondary-action-button {
    @include secondary-action-button--rectangle;
  }
}
</style>
