<template lang="pug">
div(class="partial-bankdraft-template")
  section(class="detail-section")
    ul(
        v-for="item in partialbankdraftList"
        :key="item.id"
        class="detail-list"
        @click="(e) => onClick(e, item)"
        @contextmenu="(e) => onRightClick(e, item)"
      )
      li(
        class="lf-icon-kebab-menu context-menu-launcher context-menu-container"
        @click="(e) => onRightClick(e, item)"
      )
      li(class="detail-item")
        span(class="detail-text") {{ texts.paymentDate }}:
        span(class="detail-number") {{ item.chargeDate }}
      li(class="detail-item")
        span(class="detail-text") {{ texts.amount }}:
        span(class="detail-number") {{ formatAmount(item.amount) }} €
      li(class="detail-item-description")
        span(class="detail-text") {{ texts.observations }}:
        span(class="detail-number") {{ item.notes }}

    ejs-contextmenu(
      id="partilBankdraftContextMenu"
      ref="partilBankdraftContextMenu"
      class="context-menu-component"
      :items="partialbankdraftContextMenuItems"
      :select="onContextMenuClickItem"
      :beforeClose="onContextMenuBeforeClose"
    )
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { Partialbankdraft } from '@/store/modules/invoices/invoicesTypes'
import { Icons } from '@/icons/icons'
import { formatNumber } from '@/helpers/numeric'

@Component({
  name: 'partialbankdraftTemplate'
})
export default class PartialbankdraftTemplateComponent extends Vue {
  data: any

  contextMenu: any = null
  partialbankdraftSelected: Partialbankdraft

  texts = {
    contextMenuItems: {
      edit: this.$t('components.context_menu.edit'),
      remove: this.$t('components.context_menu.remove')
    },
    paymentDate: this.$t('components.partial_bankdraft_template.payment_date'),
    amount: this.$t('components.partial_bankdraft_template.amount'),
    observations: this.$t('components.partial_bankdraft_template.observations')
  }

  get partialbankdraftContextMenuItems() {
    return [
      {
        text: this.texts.contextMenuItems.edit,
        iconCss: Icons.EDIT
      },
      {
        separator: true
      },
      {
        text: this.texts.contextMenuItems.remove,
        iconCss: Icons.REMOVE
      }
    ]
  }

  get partialbankdraftList(): Partialbankdraft[] {
    return this.data.partialBankdraft as Partialbankdraft[]
  }

  mounted() {
    this.contextMenu = this.$refs.partilBankdraftContextMenu
  }

  onContextMenuClickItem(arg: any) {
    if (arg === undefined || arg.item === undefined) {
      return
    }
    const partialbankdraft = this.partialbankdraftSelected
    switch (arg.item.text) {
      case this.texts.contextMenuItems.edit:
        this.$root.$emit('partialbankdraftContextMenuEditClick', partialbankdraft)
        break
      case this.texts.contextMenuItems.remove:
        this.$root.$emit('partialbankdraftContextMenuRemoveClick', partialbankdraft)
        break
    }
  }

  onClick(e: any, item: Partialbankdraft) {
    if (e.target?.classList.contains('context-menu-container')) {
      return
    }
    this.$root.$emit('partialbankdraftRowClick', item)
  }

  onRightClick(event: MouseEvent, item: Partialbankdraft) {
    const { clientY, clientX } = event
    this.openCustomToolbarContextMenuComponent(clientY, clientX)
    this.partialbankdraftSelected = item
    this.$root.$emit('partialbankdraftContextMenuOpen', item)
  }

  openCustomToolbarContextMenuComponent(clientY: number, clientX: number) {
    this.contextMenu.open(clientY + window.scrollY, clientX)
  }

  onContextMenuBeforeClose() {
    this.$root.$emit('partialbankdraftContextMenuClose')
  }

  formatAmount(amount: number) {
    return formatNumber(amount, 2, 'de-DE')
  }
}
</script>

<style lang="scss" scoped>
.partial-bankdraft-template {
  ::v-deep .detail-section {
    @include flex($flex-direction: column);
    font-family: $corporate-font;

    .context-menu-container {
      width: 25px;
      cursor: pointer;
    }
    .detail-list {
      @include flex($justify-content: flex-start);
      @include list;
      height: 40px;
      width: 100%;
      cursor: pointer;

      &:not(:first-of-type) {
        border-top: 1px solid $gray-09;
      }

      .detail-item {
        @include flex($justify-content: flex-start);
        width: 20%;
        height: 100%;
        padding-left: 1%;
      }

      .detail-item-description {
        @include flex($justify-content: flex-start);
        width: 56%;
        height: 100%;
        padding-left: 0%;
      }

      .detail-text {
        font-size: 14px;
        color: $corporate-color;
      }

      .detail-number {
        font-size: 14px;
        font-weight: 400;
        margin-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
