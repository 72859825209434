<template lang="pug">
div(v-show="showGrid"
    :class="['grid-container', { 'empty-grid': totalRegistersShownInTable === 0, 'is-portal': isPortalUser , 'is-in-tab': isInTab }]")
  header(v-if="showHeader" class="grid-header")
    AlertComponent(
      :whereIsRendered="componentWhereIsRenderedAlertComponent"
    )
    span(class="grid-title" v-html="title")
    template(v-if="totals && totals.length")
      v-spacer
      ShowOrHideTotalsButtonComponent(
        :showTotals="showTotals"
        @toggle-totals="toggleTotals"
      )

  div(class="top-slot")
    slot(name="top")
      TotalsContainerComponent(
        v-if="totals && totals.length && showTotals"
        :items="totals"
      )

  ejs-grid(
    ref="grid"
    :class="['grid-table', checkIfToolbarIsHidden, checkIfClickCellDisabled]"
    v-bind="gridProps"
  )
    e-columns
      e-column(
        v-for="(column, index) in parsedColumns"
        :key="`column-${index}`"
        :allowFiltering="column.allowFiltering"
        :allowReordering="column.allowReordering"
        :allowResizing="column.allowResizing"
        :allowSorting="column.allowSorting"
        :field="column.field"
        :isPrimaryKey="column.isPrimaryKey ? true : false"
        :filter="parsedFilter(column)"
        :filterModule="column.filterModule"
        :foreignKeyField="column.foreignKeyField"
        :format="column.format"
        :headerTextAlign="column.headerTextAlign"
        :headerText="column.headerText ? column.headerText : column.columnHeader"
        :minWidth="column.minWidth"
        :showInColumnChooser="column.showInColumnChooser"
        :template="getTemplate(column.templateName)"
        :customAttributes="getCustomAttributes(column.customAttributes)"
        :type="column.type"
        :visible="column.visible"
        :width="column.width"
        :textAlign="column.textAlign"
        :maxWidth="column.maxWidth"
      )

  ejs-contextmenu(
    id="contextMenuComponent"
    ref="contextMenuComponent"
    class="context-menu-component"
    :items="renderedContextMenuItems"
    :select="contextMenuClickedItem"
    :beforeOpen="onContextMenuBeforeOpen"
  )

  ejs-contextmenu(
    id="customToolbarContextMenuComponent"
    ref="customToolbarContextMenuComponent"
    class="custom-toolbar-context-menu-component"
    :items="customToolbarContextMenuItems"
    :select="customToolbarContextMenuClickedItem"
  )

  OpenFileComponent(
    ref="openFileComponent"
  )
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import GridTablesMixin from '@/mixins/GridTablesMixin.vue'
import TotalsContainerComponent from '@/components/Totals/TotalsContainerComponent.vue'
import ShowOrHideTotalsButtonComponent from '@/components/Totals/ShowOrHideTotalsButtonComponent.vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextMenuItem } from '@/store/modules/contextMenu/contextMenuTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import { DialogTypes, CustomDialogComponentName } from '@/store/modules/dialog/dialogTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { LocaleMessage } from 'vue-i18n'
import { i18n } from '@/plugins/vue-i18n'
import { changeDateFormat, addTimeToDate } from '@/helpers/dateTime'
import { FilterColumns, SearchSettings } from '@/components/grids/GridTable/types/GridTableTypes'
import { DropDownListPlugin, MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns'
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns'
import { goToDocumentRoute } from '@/helpers/file'
import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import OpenFileComponent from '@/components/FileManager/OpenFileComponent.vue'
import { DocumentPathInfo } from '@/store/modules/fileManager/fileManagerTypes'
import { createEntityByType } from '@/store/modules/entities/entitiesActions'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { openLefebvrePdfViewer } from '@/helpers/pdfViewer'
import {
  getCustomFiler,
  getCustomMultiSelectFilter,
  getCustomFilerDuration,
  getCustomFilterDate,
  getCustomBooleanFilter,
  setLastFilters,
  removeLastFilters,
  dateValue,
  setDateValue,
  parsedFilterSettingsFlag,
  setParsedFilterSettingsFlag
} from '@/components/grids/CustomFilters'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

Vue.use(DateRangePickerPlugin)
Vue.use(DropDownListPlugin)
MultiSelect.Inject(CheckBoxSelection)
Vue.use(MultiSelectPlugin)

const contextMenuModule = ModuleNamespaces.CONTEXT_MENU
const configurationModule = ModuleNamespaces.CONFIGURATION
const authModule = ModuleNamespaces.AUTH
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const formsModule = ModuleNamespaces.FORMS

@Component({
  components: {
    AlertComponent,
    ShowOrHideTotalsButtonComponent,
    TotalsContainerComponent,
    OpenFileComponent
  }
})
export default class GridTableComponent extends mixins(GridTablesMixin) {
  @Prop({
    type: [Array, Object],
    required: false
  })
  itemsDataExport!: object

  @Prop({
    type: Array,
    required: false
  })
  customToolbarContextMenuItems!: ContextMenuItem[]

  @Prop({
    type: Array
  })
  totals!: object[]

  @Prop({
    type: Number
  })
  archivedFilter!: number

  @Prop({
    type: String,
    required: true
  })
  gridConfiguration!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showHeader!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showColumnChooser!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  allowGrouping!: boolean

  @Prop({
    type: String,
    required: true
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  useServerPagination!: boolean

  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: String
  })
  maintenanceType!: string

  @Prop({
    type: Boolean,
    default: true
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  haveRelatedForm!: boolean

  @Prop({
    type: String
  })
  onClick!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabledContextMenuBeforeOpen!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  disabledGridCellClicked!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  saveOnClickedCell!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  checkboxSelectColumn!: boolean

  @Prop({
    type: String,
    required: false
  })
  customNoResultText!: string

  @Prop({
    type: String,
    required: false
  })
  secondaryNoResultText!: string

  @Prop({
    type: String
  })
  noResultCustomIcon!: string

  @Getter('getUserId', { namespace: authModule }) userId: string

  @Getter('getRememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserConfig: boolean

  @Getter('getLastListConfiguration', { namespace: configurationModule })
  lastListConfiguration: object

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdFromModule: (context: string) => any

  @Getter('showRelatedForm', { namespace: formsModule }) showRelatedForm: (context: string) => boolean

  @Action('setItemsDependingOnWhichGridTableWeWantToShow', { namespace: contextMenuModule })
  setContextMenuItems: ([]) => void

  @Action('removeRegister', { namespace: selectedRegisterModule }) removeRegisterAction: ({}) => void

  @Action('saveUserCustomConfigurationList', { namespace: configurationModule })
  saveListConfiguration: ({}) => void

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => void

  // TODO - Eliminar y tipar en grid tables mixin
  // $refs!: {
  //   grid: any,
  //   contextMenuComponent: any,
  //   customToolbarContextMenuComponent: any,
  //   openFileComponent: any
  // }

  gridInstance: any = null
  contextMenuComponent: any = null
  customToolbarContextMenuComponent: any = null

  showTotals = true

  enableLazyLoading = false

  enableStickyHeader = false

  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.GRID_TABLE

  dataIsGrouped = false

  searchingText = false

  icon: string = Icons.SEARCH

  searchSettings: SearchSettings = {
    ignoreAccent: true,
    key: ''
  }

  searchButton: HTMLElement | null = null

  searchField: HTMLInputElement | null = null

  cleanInputSearchFieldIcon: HTMLInputElement | null = null

  totalRegistersShownInTable: number | null = null

  filterButtonActive = false

  lastState: any = {
    skip: 0,
    take: this.parsedPagination.pageSize,
    sorted: this.parsedSortSettings.columns
  }

  @Watch('itemsData')
  onDatachange() {
    this.getTotalOfCurrentRegistersShownInTable()
  }

  @Watch('lastListConfiguration')
  lastListConfigurationChange(data: any) {
    if (data.filterSettings) {
      setLastFilters(data.filterSettings)
    }
  }

  @Watch('filterButtonActive')
  onFilterChange() {
    const filterBtn = this.$el.querySelector('.lf-clean-filter-button')
    const iconBtn = this.$el.querySelector('.lf-clean-filter-button .e-btn-icon')
    if (filterBtn && iconBtn) {
      if (this.filterButtonActive) {
        filterBtn.classList.add('active')
        iconBtn.classList.remove(Icons.FILTER)
        iconBtn.classList.add(Icons.FILTER_DELETE)
      } else {
        filterBtn.classList.remove('active')
        iconBtn.classList.remove(Icons.FILTER_DELETE)
        iconBtn.classList.add(Icons.FILTER)
      }
    }
  }

  @Watch('dataIsGrouped')
  async disableOrEnableToolbarItems() {
    await this.$nextTick()
    const toolbarModule = (this as any).$refs.grid.ej2Instances.toolbarModule
    if (this.dataIsGrouped) {
      toolbarModule.enableItems(['print', 'export'], false)
    } else {
      toolbarModule.enableItems(['print', 'export'], true)
    }
    setTimeout(() => {
      this.createFilterTooltips()
    }, 200)
  }

  @Watch('itemsDataExport')
  exportExcelServerSide() {
    const grid = this.gridElement
    const title = this.excelExportedFileName || this.title
    const excelExportProperties = {
      fileName: `${title}.xlsx`,
      dataSource: this.itemsDataExport
    }
    this.showActionsColumn(false)
    grid.excelExport(excelExportProperties)
  }

  @Watch('totalRegistersShownInTable')
  onTotalRegistersChanged(total: number) {
    const frozenContent = this.$el.querySelector('.e-frozencontent') as HTMLElement
    const movableContent = this.$el.querySelector('.e-movablecontent') as HTMLElement
    if (total === 0) {
      const noRegistersContainer = document.createElement('div')
      const tableContent = this.$el.querySelector('.e-content')
      noRegistersContainer.classList.add('no-registers-container')

      if (this.searchingText) {
        noRegistersContainer.innerHTML = `
          <span class="${Icons.CLOSE_SEARCH} no-registers-icon"></span>
          <span class="text">${this.$t('components.no_results.no_search_results_text')}</span>
          <span class="text try-again">${this.$t('components.no_results.try_again')}</span>
        `
      } else {
        let isAddButtonActiveText = ''
        this.toolbarOptionsParsed.map((item: any) => {
          if (item.id === 'add') {
            isAddButtonActiveText = `<span class="text try-again">${this.$t('components.no_results.add_one')}</span>`
          }
        })
        const icon = this.noResultCustomIcon ?? Icons.CONTACTS
        noRegistersContainer.innerHTML = `
          <span class="${icon} no-registers-icon"></span>
          <span class="text">${this.noResultText}</span>
          ${this.checkIfRenderSecondaryNoResultText()}
          ${isAddButtonActiveText}
        `
      }
      tableContent?.prepend(noRegistersContainer)
      if (frozenContent && movableContent) {
        frozenContent.style.display = 'none'
        movableContent.style.display = 'none'
      }
    } else {
      if (frozenContent && movableContent) {
        frozenContent.style.display = 'inherit'
        movableContent.style.display = 'inherit'
      }

      const noRegistersContainer = this.$el.querySelector('.no-registers-container')
      if (noRegistersContainer) {
        noRegistersContainer.remove()
      }
    }
  }

  checkIfRenderSecondaryNoResultText() {
    return this.secondaryNoResultText ? `<span class="secondary-text">${this.secondaryNoResultText}</span>` : ''
  }

  @Emit('gridTableFilter')
  filterDataEvent() {
    const search = this.gridInstance?.searchSettings?.key
    const filter = this.getFilterOptions()
    return { filter, search }
  }

  @Emit('rectifyCustomerInvoiceEvent')
  emitRectifyCustomerInvoiceEvent() {
    return this.selectedRegister.id
  }

  get selectionOptions() {
    if (this.checkboxSelectColumn) {
      return {
        persistSelection: true,
        enableToggle: true,
        checkboxOnly: true
      }
    }
    return {}
  }

  get noResultText() {
    return this.customNoResultText || this.$t('components.no_results.no_data_text')
  }

  get temporaryRouteName() {
    return [
      URLS.AGENCIES,
      URLS.COURTS,
      URLS.CUSTOMERS,
      URLS.CUSTOMER_BAKDRAFTS,
      URLS.CUSTOMER_INVOICES,
      URLS.EXPEDIENTS,
      URLS.INSURERS,
      URLS.MAINTENANCE,
      URLS.MAINTENANCE_ACTIONS,
      URLS.MAINTENANCE_CONTACTS,
      URLS.MAINTENANCE_DOCUMENTS,
      URLS.MAINTENANCE_EXPEDIENTS,
      URLS.MAINTENANCE_INVOICES,
      URLS.NOTARIES,
      URLS.OPPONENTS,
      URLS.OPPONENT_ATTORNEYS,
      URLS.OPPONENT_LAWYERS,
      URLS.ORDER_FORMS,
      URLS.OTHERS,
      URLS.OWN_ATTORNEYS,
      URLS.OWN_LAWYERS,
      URLS.PROFORMA_INVOICES,
      URLS.PROVIDERS,
      URLS.PROVIDER_BANKDRAFTS,
      URLS.PROVIDER_INVOICES,
      URLS.PROVISIONS,
      URLS.REPORTS,
      URLS.SEARCH
    ].includes(this.$route.name!)
  }

  get isContactType() {
    return  [
      ContextName.CONTACT_AGENCIES,
      ContextName.CONTACT_COURTS,
      ContextName.CONTACT_CUSTOMERS,
      ContextName.CONTACT_INSURERS,
      ContextName.CONTACT_NOTARIES,
      ContextName.CONTACT_OPPONENTS,
      ContextName.CONTACT_OPPONENT_ATTORNEYS,
      ContextName.CONTACT_OPPONENT_LAWYERS,
      ContextName.CONTACT_OTHERS,
      ContextName.CONTACT_OWN_ATTORNEYS,
      ContextName.CONTACT_OWN_LAWYERS,
      ContextName.CONTACT_PROVIDERS,
    ].includes(this.context as ContextName)
  }

  get isExpedientDetailView() {
    return this.$route.name === `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`
  }

  get isExpedientView() {
    return this.$route.name === `${URLS.EXPEDIENTS}`
  }

  get menuName() {
    return this.$route.path.split('/')[1]
  }

  get gridConf() {
    return JSON.parse(this.gridConfiguration)
  }

  get parsedColumns() {
    return this.gridConf.columns
  }

  get parsedPagination() {
    const pageSettings = this.gridConf.pageSettings
    if (!this.rememberUserConfig) {
      pageSettings.currentPage = 1
    }
    return pageSettings
  }

  get parsedSortSettings() {
    return JSON.parse(this.gridConfiguration)['sortSettings']
      ? { columns: JSON.parse(this.gridConfiguration)['sortSettings'] }
      : {}
  }

  get parsedSearchSettings() {
    if (JSON.parse(this.gridConfiguration)['searchSettings'] && this.rememberUserConfig) {
      this.searchSettings.key = JSON.parse(this.gridConfiguration)['searchSettings']
    }
    return this.searchSettings
  }

  get parsedFilterSettings() {
    const filterSettingUser = JSON.parse(this.gridConfiguration)['filterSettings']

    if (filterSettingUser && filterSettingUser.length > 0 && this.rememberUserConfig) {
      const newFilter: any = []
      filterSettingUser.forEach((item: any) => {
        const filter: FilterColumns = {
          field: item.field,
          operator: item.operator,
          predicate: item.predicate,
          value: item.value
        }

        newFilter.push(filter)
      })
      this.filterSettings.columns = newFilter
      setParsedFilterSettingsFlag(true)
      this.filterButtonActive = true
    }

    return this.filterSettings
  }

  get parsedGroupSettings() {
    if (!this.allowGrouping) {
      return null
    }

    return { enableLazyLoading: this.enableLazyLoading }
  }

  get selectedRegisterInfo() {
    let id = ''
    let name = ''
    let dialogText: LocaleMessage = ''

    switch (this.menuParentCategoryName) {
      case ParentCategory.CONTACTS:
        id = this.selectedRegisterId
        ;(name = this.selectedRegister.name), (dialogText = this.$t('components.dialog.contacts_literal'))
        break
      case ParentCategory.ACTIONS:
        id = this.selectedRegisterId
        name = this.selectedRegister.subject
        dialogText = this.$t('components.dialog.actions_literal')
        break
      case ParentCategory.EXPEDIENTS:
        id = this.selectedRegisterId
        name = this.selectedRegister.description
        dialogText = this.$t('components.dialog.expedients_literal')
        break
      case ParentCategory.REPORTS:
        id = this.selectedRegisterId
        name = this.selectedRegister.name
        dialogText = this.$t('components.dialog.reports_literal')
        break
      case ParentCategory.BILLING:
        id = this.selectedRegisterId
        name = this.selectedRegister.numberInvoice || this.selectedRegister.description
        dialogText = this.getInvoicesLiteralName
        break
      case ParentCategory.USERS:
        id = this.selectedRegisterId
        name = this.selectedRegister.name
        dialogText = i18n.t('components.dialog.users_literal')
        break
    }
    return {
      id,
      name,
      dialogText
    }
  }

  get getInvoicesLiteralName() {
    switch (this.listName) {
      case ListNames.CUSTOMER_BANKDRAFTS:
        return this.$t('components.dialog.customers_bankdraft_literal')
      case ListNames.ORDER_FORMS:
        return this.$t('components.dialog.order_form_literal')
      case ListNames.PROVIDER_BANKDRAFTS:
        return this.$t('components.dialog.providers_bankdraft_literal')
      case ListNames.PROVISIONS:
        return this.$t('components.dialog.provisions_literal')
      default:
        return this.$t('components.dialog.invoices_literal')
    }
  }

  get toolbarOptionsParsed() {
    const toolbarItems: any = this.toolbarOptions

    if (this.showColumnChooser) {
      if (!toolbarItems.find((item: any) => item.text === 'ColumnChooser')) {
        toolbarItems.push({
          text: 'ColumnChooser',
          prefixIcon: Icons.OPTIONS
        })
      }
    }

    const archievedFilterContext = [
      ContextName.ACTION,
      ContextName.BILLING,
      ContextName.BILLING_ORDER_FORMS,
      ContextName.BILLING_PROVISIONS,
      ContextName.EXPEDIENTS
    ]

    if (!(archievedFilterContext as any).includes(this.context) || this.isInTab) {
      toolbarItems.map((item: any, index: number) => {
        if (item.id === 'archived') {
          toolbarItems.splice(index, 1)
        }
      })
    }
    return toolbarItems
  }

  get gridProps(): object {
    return {
      ...this.bothGridsSharedProps,
      actionBegin: this.actionBegin,
      actionComplete: this.gridActionComplete,
      actionFailure: this.gridActionFailure,
      allowGrouping: false, // this.allowGrouping,
      allowTextWrap: false,
      columnDrop: this.saveUserPreferences,
      contextMenuClick: this.contextMenuClickedItem,
      contextMenuOpen: this.onContextMenuBeforeOpen,
      dataStateChange: this.onDataStateChange,
      enableStickyHeader: this.enableStickyHeader,
      filterSettings: this.parsedFilterSettings,
      groupSettings: this.parsedGroupSettings,
      pageSettings: this.parsedPagination,
      recordClick: this.onCellClicked,
      refresh: this.refresh,
      resizeStop: this.saveUserPreferences,
      searchSettings: this.parsedSearchSettings,
      contextMenuItems: this.renderedContextMenuItems,
      showColumnChooser: this.showColumnChooser,
      sortSettings: this.parsedSortSettings,
      toolbar: this.toolbarOptions.length ? this.toolbarOptionsParsed : null,
      toolbarClick: this.clickHandler,
      dataBound: this.dataBound,
      selectionSettings: this.selectionOptions
    }
  }

  get selectedRegisterId() {
    const { actionId, id, idExpedient, idInvoice } = this.selectedRegister
    return actionId || id || idExpedient || idInvoice
  }

  get getSelectedEntityName() {
    switch (this.menuParentCategoryName) {
      case ParentCategory.ACTIONS:
        return 'action'
      case ParentCategory.BILLING:
        return this.listName
      default:
        return this.selectedEntityName
    }
  }

  get checkIfClickCellDisabled() {
    return this.disabledGridCellClicked ? 'cell-click-disabled' : ''
  }

  created() {
    this.setContextMenuItems(this.contextMenuItems)
    if (!this.rememberUserConfig) {
      removeLastFilters()
    }
  }

  beforeDestroy() {
    this.gridInstance.enableStickyHeader = false
    this.removeListeners()
  }

  mounted() {
    this.gridInstance = (this as any).$refs.grid.ej2Instances
    this.gridElement = this.$refs.grid
    this.contextMenuComponent = this.$refs.contextMenuComponent
    this.customToolbarContextMenuComponent = this.$refs.customToolbarContextMenuComponent

    this.addPagerListener()
    this.getTotalOfCurrentRegistersShownInTable()
    this.setGroups()

    this.filterDataEvent()
    this.createFilterTooltips()

    setTimeout(() => {
      this.searchFieldBehaviours()
      this.showOrHideClearButton()
      this.enableStickyHeader = true
    })
  }

  updated() {
    setTimeout(() => {
      if (this.showGrid) {
        this.searchFieldBehaviours()
      }
    })

    if (!this.searchingText) {
      this.calculateScrollbarsWidth()
    }
  }

  refresh() {
    this.$refs.grid.refresh()
  }

  onDataStateChange(state: any) {
    if (this.useServerPagination) {
      if (
        state.action.requestType !== 'stringfilterrequest' &&
        state.action.requestType !== 'refresh' &&
        state.action.requestType !== 'filtering'
      ) {
        if (state.action.requestType === 'searching') {
          state.skip = 0
          this.gridInstance.pageSettings.currentPage = 1
        }
        this.getServerSideData(state)
      } else if (state.action.requestType === 'refresh' && parsedFilterSettingsFlag) {
        this.getServerSideData(state)
        setParsedFilterSettingsFlag(false)
      } else if (state.action.requestType === 'filtering') {
        state.skip = 0
        this.gridInstance.pageSettings.currentPage = 1
        this.getServerSideData(state)
      }
    }
  }

  getServerSideData(state: any) {
    this.lastState = state
    const pFilter = this.getFilter(state)
    this.$emit('gridActionChange', JSON.stringify(pFilter))
  }

  getFilter(state: any) {
    const pFilter: any = {}
    state.skip === 0 ? (pFilter['page'] = 0) : (pFilter['page'] = (state.skip + state.take) / state.take)
    pFilter['pageSize'] = state.take

    const sort = state.sorted
    if (sort) {
      const sortColumns: any = []
      sort.reverse().forEach((sortColumn: any) => {
        const column: any = {}
        if (sortColumn.field) {
          column['column'] = sortColumn.field
        } else {
          column['column'] = sortColumn.name
        }
        column['order'] = sortColumn.direction.toLowerCase() === 'ascending' ? 'asc' : 'desc'
        sortColumns.push(column)
      })
      pFilter['columnOrder'] = sortColumns
    }

    if (state.where) {
      const filterObject = state.where[0].predicates
      const filterColumns: any = []
      let filterColumnsEach: any = []

      filterObject.forEach((filterColumn: any) => {
        if (filterColumn.isComplex) {
          if (filterColumn.predicates[0].operator === 'greaterthan') {
            filterColumn.predicates[0].operator = 'equal'
          } else if (filterColumn.predicates[0].operator === 'lessthanorequal') {
            filterColumn.predicates[0].operator = 'notequal'
          }
          filterColumnsEach = filterColumn.predicates
        } else {
          filterColumnsEach.push(filterColumn)
        }

        filterColumnsEach.forEach((item: any) => {
          const column: any = {}
          column['field'] = item.field
          column['operator'] = item.operator
          const columnProps = this.parsedColumns.filter((i: any) => i.field === i.field)

          if (
            columnProps.type === 'date' ||
            columnProps.type === 'dateTime' ||
            columnProps.type === 'datetime' ||
            columnProps.type === 'dateEPOCH'
          ) {
            column['value'] = changeDateFormat(item.value, '', 'YYYY-MM-DD')
          } else {
            column['value'] = item.value
          }
          if (column['value'] !== '') {
            filterColumns.push(column)
          }
        })
      })
      pFilter['filter'] = filterColumns
    }

    const searchFilter = state.search
    if (searchFilter) {
      pFilter['search'] = searchFilter[0].key
    }
    return pFilter
  }

  parsedFilter(columnConf: any) {
    switch (columnConf.filterModule) {
      case 'customFiler':
        return getCustomFiler()
      case 'customFilerDuration':
        return getCustomFilerDuration()
      case 'customMultiSelectFilter':
        return getCustomMultiSelectFilter(columnConf, this.gridInstance)
      case 'customFilterDate':
        return getCustomFilterDate(this.gridInstance, this.gridElement)
      case 'customBooleanFilter':
        return getCustomBooleanFilter(columnConf)
    }
    if (columnConf.isCheckboxType) {
      return this.checkboxFilter
    } else {
      return ''
    }
  }

  toggleTotals() {
    this.showTotals = !this.showTotals
  }

  selectedRegisterRouteData(registerId: number | null = null) {
    const routeName = this.$route.name as string
    return {
      name: `${this.menuName}-${routeName}`,
      params: {
        selectedMenuEntity: this.menuName,
        submenuName: routeName,
        selectedRegisterId: registerId || (this.$t('views.selected_register.new') as string)
      }
    }
  }

  onCellClicked(args: any) {
    this.selectedRegister = args.rowData
    const currentEntity: Entity = this.getCurrentEntity()

    if (0 === args.cellIndex) {
      this.gridInstance.selectRow(args.row.rowIndex)
      this.openContextMenuComponent(args)
      return
    }

    if (this.activeOnClickCell) {
      if (args && args.column.type === 'checkbox') {
        return
      }

      if ((this.isPortalUser && this.isInTab) || this.disabledGridCellClicked) {
        args.cancel = true
      }

      if (!this.isPortalUser) {
        this.saveUserPreferences()
      }

      if (this.onClick) {
        ;(this as any)[this.onClick]()
        return
      }

      this.$emit('cellClicked', this.selectedRegister, args.cancel)
      if (!args.cancel) {
        if (this.haveRelatedForm) {
          this.$emit('gridAction', {
            type: 'edit',
            register: this.selectedRegister,
            registerId: this.selectedRegisterId
          })
        } else if (this.temporaryRouteName) {
          this.$router.push(currentEntity.getRoute())
        } else if (this.isExpedientDetailView) {
          const { id, idStage } = this.selectedRegister
          this.$emit('openExpedientEconomicDataForm', { id, idStage })
        }
      }
    }
    this.trackClickEdit()
  }

  trackClickEdit() {
    if ( this.$route.name === URLS.CUSTOMER_INVOICES) {
      trackEvent(TrackerEvents.EDIT_INVOICE)
    }

    if ( this.$route.name === URLS.PROFORMA_INVOICES) {
      trackEvent(TrackerEvents.EDIT_INVOICE)
    }

    if (this.isContactType) {
      trackEvent(TrackerEvents.EDIT_CONTACT)
    }

    if (this.$route.name === URLS.EXPEDIENTS) {
      trackEvent(TrackerEvents.EDIT_EXPEDIENT)
    }
  }

  async resizeStop() {
    this.saveUserPreferences()
    this.$nextTick(() => {
      this.hideScroll()
    })
  }

  getCurrentEntity(): Entity {
    const idEntityType: number = this.selectedRegister.idEntityType
      ? Number(this.selectedRegister.idEntityType)
      : this.$route.meta?.entityType

    return this.getEntity(idEntityType, Number(this.selectedRegisterId))
  }

  openContextMenuComponent(args: any) {
    const rect = args.target.getBoundingClientRect()
    const top = rect.top + window.scrollY
    const left = rect.left + 15
    this.contextMenuComponent.open(top, left)
  }

  openCustomToolbarContextMenuComponent(toolBar: any) {
    const btn: null | HTMLElement = document.getElementById(toolBar.item.id)
    if (btn !== null) {
      const rect: ClientRect | DOMRect = btn.getBoundingClientRect()
      const top = rect.top + window.scrollY + 50
      const left = rect.left + 20
      this.customToolbarContextMenuComponent.open(top, left)
    }
  }

  onContextMenuBeforeOpen(args: any) {
    if ((this.isPortalUser && this.isInTab) || this.disabledContextMenuBeforeOpen) {
      args.cancel = true
    }

    const contextMenuGridObject = this.gridInstance.contextMenuModule.contextMenu

    this.$emit('contextMenuBeforeOpen', this.contextMenuComponent, contextMenuGridObject, this.selectedRegister)

    if (args.cancel) {
      return
    }

    if (args.column === null) {
      args.cancel = true
      return
    }

    if (args.rowInfo) {
      this.gridInstance.selectRow(args.rowInfo.row.rowIndex)
    }

    this.saveSelectedRegisterId({
      id: this.selectedRegisterId,
      context: this.context
    })

    const { idEntityType, idType } = this.selectedRegister
    const { items } = args
    const contextMenuItems = idEntityType ? this.parsedItemsByPermissions(idEntityType, items) : items

    if (idType) {
      const canDeleteReport = idType === '0' ? false : true
      this.contextMenuComponent.enableItems([this.$t('components.context_menu.remove')], !!canDeleteReport)
      contextMenuGridObject.enableItems([this.$t('components.context_menu.remove')], !!canDeleteReport)
    }

    this.contextMenuComponent.enableItems(contextMenuItems, false)
    contextMenuGridObject.enableItems(contextMenuItems, false)
  }

  actionBegin(e: any) {
    const actions = ['searching', 'filtering', 'grouping', 'refresh']

    if ('clearFilter' === e.action && 'customFilterDate' === e.currentFilterColumn.filterModule) {
      delete dateValue[e.currentFilterColumn.field]
    }

    if (actions.includes(e.requestType)) {
      if (e.action === 'filter' || (e.requestType === 'refresh' && parsedFilterSettingsFlag)) {
        this.filterButtonActive = true
      } else if (e.action === 'clearFilter' && this.gridInstance.filterSettings.columns.length === 0) {
        this.filterButtonActive = false
      }
      this.saveUserPreferences()
      this.gridInstance.pageSettings.currentPage = 1
    }

    if (e.requestType === 'filtersearchbegin') {
      this.hideAddCurrentSelectionToFilterOption()
      e.operator = 'contains'
    }

    if (e.requestType === 'grouping') {
      this.frozenCols = 0
      this.enableLazyLoading = true
      this.enableStickyHeader = false
      this.dataIsGrouped = true
    }

    if (e.requestType === 'searching') {
      this.filterDataEvent()
    }

    if (e.requestType === 'filtering') {
      const columnFilter = this.gridInstance
        .getColumns()
        .find((item: any) => item.field === e.currentFilteringColumn && item.filterModule === 'customFilterDate')

      if (columnFilter) {
        this.filterButtonActive = true

        const checkIfFilterExist = this.gridInstance.filterSettings.columns.find(
          (item: any) => item.field === e.currentFilteringColumn && 'lessthanorequal' === item.operator
        )

        if (!checkIfFilterExist) {
          e.columns.push({
            predicate: 'and',
            field: e.currentFilteringColumn,
            operator: 'lessthanorequal',
            value: addTimeToDate(
              dateValue[e.currentFilteringColumn][1],
              1440 + new Date().getTimezoneOffset(),
              'minutes'
            )
          })
        }
      }

      this.getTotalOfCurrentRegistersShownInTable()
      this.filterDataEvent()
    }
  }

  hideAddCurrentSelectionToFilterOption() {
    setTimeout(() => {
      const searchInputContainer = this.$el.querySelector('.e-searchcontainer')
      if (searchInputContainer) {
        const filterOptionContainer = searchInputContainer.querySelectorAll('.e-ftrchk')
        filterOptionContainer.forEach((option) => {
          const optionText = option.querySelector('.e-checkboxfiltertext')
          if (optionText && optionText.textContent === 'Add current selection to filter') {
            ;(option as HTMLElement).style.display = 'none'
          }
        })
      }
    }, 2)
  }

  getTemplate(templateName: string) {
    return templateName ? (this as any)[templateName] : false
  }

  addPagerListener() {
    const pager = this.$el.querySelector('.e-pagercontainer')

    if (pager) {
      pager.addEventListener('click', () => {
        this.getTotalOfCurrentRegistersShownInTable()
      })
    }
  }

  gridActionComplete(args: { requestType: string; rows: string | any[]; searchString: string; tableName: string }) {
    const gridActions = ['paging', 'sorting']

    const otherActions = ['columnstate', 'ungrouping']

    if (args.requestType === 'searching' && args.searchString.length) {
      this.icon = Icons.CLOSE_SEARCH
      this.changeSearchFieldIcon()
    }

    if (args.requestType === 'ungrouping') {
      const agrupationChips = this.$el.querySelectorAll('.e-groupheadercell')

      if (!agrupationChips.length) {
        this.gridInstance.columns.map((column: any, index: number) => {
          if (0 === index) {
            column.visible = true
            Vue.set(this.gridInstance.columns, index, column)
          }
        })
        this.enableLazyLoading = false
        this.dataIsGrouped = false
        setTimeout(() => {
          this.frozenCols = 1
          this.enableStickyHeader = true
        }, 200)
      }
    }

    if (args.requestType === 'grouping') {
      this.enableStickyHeader = true
    }

    if (
      (gridActions.includes(args.requestType) && args.tableName === 'movable') ||
      otherActions.includes(args.requestType)
    ) {
      this.saveUserPreferences()
    }

    if (args.requestType === 'columnstate') {
      this.hideScroll()
    }

    this.getTextColorInYesOrNoColumns()
    this.getTextColorInDateColumns()
    this.hideHeaderTitles()
    this.createFilterTooltips()
  }

  gridActionFailure() {
    this.enableStickyHeader = true
  }

  saveUserPreferences() {
    if (!this.saveOnClickedCell) {
      return false
    }

    this.$nextTick(() => {
      const gridColumns = this.gridInstance.columns

      gridColumns.forEach((column: any) => {
        if (column.template) {
          column.templateName = column.template().template.extendOptions.name
        }

        if (column.headerText) {
          column.columnHeader = column.headerText
        }

        column.filter === '' ? (column.isCheckboxType = false) : (column.isCheckboxType = true)

        const parsedColumn = this.parsedColumns.find((pColumn: any) => pColumn.field === column.field)

        if (parsedColumn) {
          column.filterBooleanItems = parsedColumn.filterBooleanItems
          column.filterModuleSource = parsedColumn.filterModuleSource
        }
      })

      const gridPageSettings = this.gridInstance.pageSettings.properties

      const sortOptions = []

      for (const column of this.gridInstance.properties.sortSettings.properties.columns) {
        if (column.properties) {
          sortOptions.push({ field: column.properties.field, direction: column.properties.direction })
        }
      }

      const searchKey = this.gridInstance.searchSettings.key

      const filterOptions: any = this.getFilterOptions()

      const groupSettings = {
        columns: [],
        enableLazyLoading: false
      }

      if (this.gridInstance.groupSettings.columns.length) {
        groupSettings.columns = this.gridInstance.groupSettings.columns
        groupSettings.enableLazyLoading = true
      }

      const gridConfig = {
        columns: gridColumns,
        pageSettings: gridPageSettings,
        sortSettings: sortOptions,
        searchSettings: searchKey,
        filterSettings: filterOptions,
        groupSettings
      }

      if (this.archivedFilter >= 0) {
        ;(gridConfig as any)['archived'] = this.archivedFilter
      }

      const dataToSend = {
        IdUser: this.userId,
        Alias: this.listName,
        Config: gridConfig
      }

      this.saveListConfiguration(dataToSend)
    })
  }

  getFilterOptions() {
    const gridColumns = this.gridInstance.columns
    const filterOptions: any = []

    this.gridInstance.filterSettings.columns.forEach((item: any) => {
      if (item.field) {
        const col = gridColumns.find((i: any) => i.field === item.field)
        if (col) {
          const filterOpt: FilterColumns = {
            field: item.field,
            operator: item.operator,
            predicate: item.predicate,
            value: item.value
          }
          filterOptions.push(filterOpt)
        }
      }
    })

    return filterOptions
  }

  removeListeners(): void {
    if (this.searchButton) {
      this.searchButton.removeEventListener('click', this.showOrHideClearButton)
    }

    if (this.searchField) {
      this.searchField.removeEventListener('keypress', this.searchOnEnterKeyBehaviour)
    }

    if (this.searchField) {
      this.searchField.removeEventListener('click', this.cleanInputSearchField)
    }
  }

  async removeRegister() {
    // TODO: Set component properties on router and call to new removeRegisterAction method (fetchRemoveRegister)
    const data: any = {
      parentCategory: this.menuParentCategoryName,
      selectedEntityName: this.getSelectedEntityName,
      idSelectedRegister: this.selectedRegisterInfo.id,
      goToURL: this.$route.name,
      name: this.selectedRegisterInfo.name,
      dialogText: this.selectedRegisterInfo.dialogText
    }
    if (this.maintenanceType) {
      data.selectedEntityName = this.maintenanceType
      data.idSelectedRegister = this.selectedRegisterIdFromModule(this.context)
    }

    await this.removeRegisterAction(data)
    if (this.useServerPagination) {
      this.lastState.take = this.gridInstance.pageSettings.pageSize
      this.getServerSideData(this.lastState)
    }
  }

  contextMenuClickedItem(args: any) {
    this.saveUserPreferences()
    this.$emit('contextMenuClicked', args, this.selectedRegister)
    if (args.cancel) {
      return
    }

    if (args.item) {
      const currentEntity: Entity = this.getCurrentEntity()

      switch (args.item.text) {
        case this.$t('components.context_menu.edit'):
        case this.$t('components.context_menu.look'):
          if (this.haveRelatedForm) {
            this.$emit('gridAction', { type: 'edit', register: this.selectedRegister })
          } else if (this.temporaryRouteName) {
            this.trackClickEdit()
            this.$router.push(currentEntity.getRoute())
          } else if (this.isExpedientDetailView) {
            const { id, idStage } = this.selectedRegister
            this.$emit('openExpedientEconomicDataForm', { id, idStage })
          }
          break
        case this.$t('components.context_menu.remove'):
          this.trackClickRemove()
          if (this.isExpedientDetailView) {
            this.$emit('showDialog', this.selectedRegisterId)
          } else if (this.haveRelatedForm) {
            this.$emit('gridAction', { type: 'remove', register: this.selectedRegister })
          } else {
            args.item.action({
              type: DialogTypes.INFO,
              message: this.$t('components.dialog.remove_register_text', {
                register: this.selectedRegisterInfo.name,
                text: this.isExpedientDetailView
                  ? this.$t('components.expedient_economic_data.economic_data')
                  : this.selectedRegisterInfo.dialogText
              }),
              action: this.removeRegister
            })
          }
          break
        case this.$t('components.context_menu.open_window'):
          this.trackClickEdit()
          if (this.temporaryRouteName) {
            if (this.isPortalUser && this.context !== ContextName.EXPEDIENTS) {
              return
            }
            window.open(currentEntity.getRoute(), '_blank')
          }
          break

        case this.$t('components.context_menu.see'):
          this.openFile()
          break
        case this.$t('components.context_menu.download'):
          this.downloadFile()
          break
        case this.$t('components.context_menu.go_to'):
          this.goToDocumentRoute()
          break
        case this.$t('components.context_menu.rectify_customer_invoice'):
          args.item.action({
            type: DialogTypes.INFO,
            message: this.$t('components.dialog.rectify_customer_invoice_text', {
              register: this.selectedRegisterInfo.name
            }),
            action: this.emitRectifyCustomerInvoiceEvent
          })
          break
        case this.$t('action_buttons.duplicate_expedient'):
          trackEvent(TrackerEvents.DUPLICATE_EXPEDIENT)
          args.item.action({
            name: CustomDialogComponentName.DUPLICATE_EXPEDIENT,
            props: {
              id: this.selectedRegisterId,
              name: this.selectedRegisterInfo.name
            }
          })
          break
        case this.$t('components.context_menu.see_details'):
          this.$emit('see-details', this.selectedRegister)
          break
        case this.$t('components.context_menu.download_original_document'):
          this.downloadFileById(this.selectedRegister.idDocumentOriginal)
          break
      }
    }
  }

  trackClickRemove() {
    if (this.context === ContextName.ACTION) {
      trackEvent(TrackerEvents.REMOVE_ACTION)
    }

    if (this.$route.name === URLS.EXPEDIENTS) {
      trackEvent(TrackerEvents.REMOVE_EXPEDIENT)
    }

    if (this.context === ContextName.EXPEDIENT_ECONOMIC_DATA) {
      trackEvent(TrackerEvents.REMOVE_ECONOMIC_DATA)
    }
  }

  async goToDocumentRoute() {
    const pathInfo: DocumentPathInfo = await this.fetchDocumentPathInfoAction(this.selectedRegister.id)
    const route = goToDocumentRoute(this.selectedRegister, pathInfo) // helper/file
    window.open(route, '_blank')
  }

  openFile() {
    this.processDocument(this.selectedRegister)
  }

  downloadFile() {
    this.processDocument(this.selectedRegister, true)
  }

  downloadFileById(id: number) {
    this.selectedRegister.id = id
    this.processDocument(this.selectedRegister, true)
  }

  processDocument(document: any, download = false) {
    const file = {
      id: document.id,
      originalFileName: document.initialFile
    }

    const openFileComponent: any = this.$refs.openFileComponent
    if (openFileComponent) {
      if (download) {
        openFileComponent.downloadFile(file)
      } else {
        if (file.originalFileName.split('.').pop().toLowerCase() === 'pdf') {
          openLefebvrePdfViewer(file.id)
        } else {
          openFileComponent.openFile(file)
        }
      }
    }
  }

  getEntity(entityType: number, entityId = 0): Entity {
    return createEntityByType(entityType, entityId)
  }

  customToolbarContextMenuClickedItem(args: any) {
    this.$emit('customToolbarContextMenuClick', args)
  }

  showOrHideClearButton(): void {
    const text: string | null = this.searchField ? this.searchField!.value : ''
    const hasText = text !== ''

    if (hasText && this.icon === Icons.SEARCH) {
      this.searchingText = true
      this.gridElement.search(text)
      this.icon = Icons.CLOSE_SEARCH
      this.changeSearchFieldIcon()
    } else if (!hasText || (hasText && this.icon === Icons.CLOSE_SEARCH)) {
      this.searchingText = false
      this.clearSearch()
      this.icon = Icons.SEARCH
      this.changeSearchFieldIcon()
    }
    this.getTotalOfCurrentRegistersShownInTable()
  }

  searchOnEnterKeyBehaviour(e: any): void {
    const key = e.which || e.keyCode
    const text: string | null = this.searchField!.value

    if (key === 13) {
      const hasText = text !== ''

      this.searchingText = hasText
      this.gridElement.search(text)
      this.icon = hasText ? Icons.CLOSE_SEARCH : Icons.SEARCH
      this.changeSearchFieldIcon()
    }
    this.getTotalOfCurrentRegistersShownInTable()
  }

  addListenersToShowOrHideClearButton(): void {
    this.searchButton = this.$el.querySelector('.e-search .e-input-group-icon:not(.e-sicon)')
    this.searchField = this.$el.querySelector('.e-search .e-input')

    if (this.searchButton) {
      this.searchButton.addEventListener('click', this.showOrHideClearButton)
    }

    if (this.searchField) {
      this.searchField.addEventListener('keypress', this.searchOnEnterKeyBehaviour)
    }
  }

  addListenerToCleanSearchIcon(): void {
    this.cleanInputSearchFieldIcon = this.$el.querySelector('.e-sicon')

    if (this.cleanInputSearchFieldIcon) {
      this.cleanInputSearchFieldIcon.addEventListener('click', this.cleanInputSearchField)
    }
  }

  cleanInputSearchField() {
    if (this.searchField && this.searchField.value.length) {
      this.searchingText = false
      this.clearSearch()
      this.icon = Icons.SEARCH
      this.searchField.value = ''
      if (this.cleanInputSearchFieldIcon) {
        this.cleanInputSearchFieldIcon.classList.remove('e-clear-icon')
      }
    }
  }

  searchFieldBehaviours(): void {
    this.changeSearchFieldIcon()
    this.addListenersToShowOrHideClearButton()
    this.addListenerToCleanSearchIcon()
  }

  changeSearchFieldIcon(): void {
    const searchBtn = this.$el.querySelector('.e-search .e-input-group-icon:not(.e-sicon)')

    if (searchBtn) {
      searchBtn.classList.remove('e-search-icon')
      if (this.icon === Icons.CLOSE_SEARCH) {
        searchBtn!.classList.remove(Icons.SEARCH)
      }
      if (this.icon === Icons.SEARCH) {
        searchBtn.classList.remove(Icons.CLOSE_SEARCH)
      }
      searchBtn!.classList.add(this.icon)
    }
  }

  getTotalOfCurrentRegistersShownInTable() {
    setTimeout(() => {
      this.totalRegistersShownInTable = this.gridInstance.currentViewData.length
    }, 100)
  }

  clearSearch(): void {
    this.$set(this.gridInstance.searchSettings, 'key', '')
    this.getTotalOfCurrentRegistersShownInTable()
  }

  checkFilter(): void {
    if (this.filterButtonActive) {
      ;(this as any).$refs.grid.ej2Instances.clearFiltering()
      setDateValue([])
      this.filterButtonActive = false
      // this.saveUserPreferences()
      this.getTotalOfCurrentRegistersShownInTable()
      if (this.useServerPagination) {
        delete this.lastState.where
        this.getServerSideData(this.lastState)
      }
      this.filterDataEvent()
    }
  }

  excelExport(): void {
    if (this.useServerPagination) {
      this.lastState.skip = 0
      this.lastState.take = 0
      const pFilter = this.getFilter(this.lastState)
      this.$emit('gridExportData', JSON.stringify(pFilter))
    } else {
      const grid = this.gridElement
      const title = this.excelExportedFileName || this.title
      const excelExportProperties = {
        fileName: `${title}.xlsx`
      }

      if (this.showFirstColumn) {
        this.showActionsColumn()
      } else {
        this.showActionsColumn(false)
      }
      grid.excelExport(excelExportProperties)
    }
  }

  goToTabsView(selectedRegisterId = 0) {
    const routeData = (this as any).selectedRegisterRouteData(selectedRegisterId)
    this.$router.push(routeData)
  }

  addRegister(): void {
    // Esta lógica es de forma temporal hasta que se quite joomla
    if (this.haveRelatedForm) {
      this.$emit('gridAction', { type: 'add' })
    } else if (this.temporaryRouteName) {
      this.goToTabsView()
    }
  }

  clickHandler(args: any) {
    const target = args.originalEvent.target.closest('button')

    this.$emit('toolbarClicked', args)

    if (args.cancel) {
      return
    }

    switch (target && target.id) {
      case 'export':
        return this.excelExport()
      case 'print':
        return this.exportToPDF()
      case 'add':
        this.trackClickAdd()
        return this.addRegister()
      case 'customOption':
        return this.openCustomToolbarContextMenuComponent(args)
      case 'clear':
        return this.clearSearch()
      case 'filter':
        return this.checkFilter()
      default:
        return target ? this.$emit('otherGridButtonClicked', target.id) : false
    }
  }

  trackClickAdd() {
    if (this.isContactType) {
      trackEvent(TrackerEvents.CREATE_CONTACT)
    }
  }

  dataBound(_args: any) {
    this.hideScroll()
  }

  isGrouping() {
    const gridSettings = JSON.parse(this.gridConfiguration)
    let isGrouping = gridSettings.groupSettings && gridSettings.groupSettings.columns.length

    if (this.gridInstance && this.gridInstance.groupSettings && !this.gridInstance.groupSettings.columns.length) {
      isGrouping = false
    }

    if (isGrouping) {
      this.frozenCols = 0
    }

    return isGrouping
  }

  setGroups() {
    const gridConfiguration = JSON.parse(this.gridConfiguration)
    if (gridConfiguration.groupSettings && gridConfiguration.groupSettings.columns.length) {
      this.frozenCols = 0
      this.enableLazyLoading = true
      this.dataIsGrouped = true
      this.gridInstance.enableLazyLoading = true
      gridConfiguration.groupSettings.columns.forEach((columName: string) => {
        this.gridInstance.groupColumn(columName)
      })
    }
  }

  calculateScrollbarsWidth() {
    const contentTable = this.$el.querySelector('.e-movableheader .e-table') as HTMLElement
    if (contentTable) {
      const tableWidth = contentTable.style.width
      const frozenScrollBar = this.$el.querySelector('.e-frozenscrollbar') as HTMLElement
      const movableScrollBar = this.$el.querySelector('.e-movablechild') as HTMLElement
      if (frozenScrollBar) {
        frozenScrollBar.style.width = '25px'
      }
      if (movableScrollBar) {
        movableScrollBar.style.width = tableWidth
      }
    }
  }
}
</script>
<style lang="scss">
.multiselect-item > div {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  border-radius: 4px;
}

.multiselect-item {
  display: inline-block;
  text-align: left;
  text-indent: 0px;
}

.multiselect-item > * {
  display: inline-block;
  vertical-align: middle;
}

.multiselect-item .item-text {
  @include ellipsis;
  width: 130px;

  &:first-child {
    width: 153px;
  }
}
</style>
