<template lang="pug">

  section(v-if="!showSpinnerLayer" class="lexon-view-container")
    GridTable(
      v-if="renderGrid"
      :itemsData="isPortalUser ? listItems : expGridData"
      :itemsDataExport="serverSideDataExport"
      :gridConfiguration="listConfiguration['Config']"
      :title="$t('views.expedients.title')"
      :toolbarOptions="toolbarOptions"
      :contextMenuItems="expedientGridContextMenuItems"
      :listName="listConfiguration['Alias']"
      :useServerPagination="useServerPagination"
      :context="context"
      allowGrouping
      @toolbarClicked="onToolbarClicked"
      @gridActionChange="gridActionChange"
      @gridExportData="gridExportData"
      :archivedFilter="archivedSelectedOption"
    )

    ejs-contextmenu(
      :id="idContextMenu"
      class="context-menu-component e-contextmenu-archived"
      :items="expedientsArchivedContextMenuItems"
      :select="onExpedientsArchivedClick"
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { DataResult } from '@syncfusion/ej2-vue-grids'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { UserType } from '@/store/modules/auth/authTypes'
import { formatNumberColumnsGrid } from '@/helpers/numeric'
import { Icons } from '@/icons/icons'
import { resetLoadedCustomMultiselectFlags } from '@/components/grids/CustomFilters'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const entitiesModule: string = ModuleNamespaces.ENTITIES
const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    GridTable
  }
})
export default class ExpedientsView extends mixins(ListViewMixin, ArchivedFilterMixin) {
  @Action('fetchListExpedients', { namespace: expedientsModule })
  fetchListExpedients: (type: {}) => []

  @Action('fetchExpedients', { namespace: expedientsModule })
  fetchUserPortalExpedients: (type: {}) => []

  @Getter('getLastListConfiguration', { namespace: configurationModule })
  lastListConfiguration: object

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  idContextMenu: string = 'expedientsArchivedContextMenuComponent'

  useServerPagination = true

  expGridData: DataResult | object = []

  serverSideDataExport: object = []

  get context() {
    return ContextName.EXPEDIENTS
  }

  get expedientDataParsed() {
    return formatNumberColumnsGrid(
      this.expedientsDataFormatedDates,
      JSON.parse((this as any).listConfiguration.Config)['columns']
    )
  }

  get expedientGridContextMenuItems() {
    const updatedContextMenuItems = this.contextMenuItemsDefault.map((obj: any) => {
      if (obj.text === 'Eliminar') {
        return {
          ...obj,
          action: this.showDialog
        }
      }
      return obj
    })

    if (this.viewPermission.canSave) {
      return [
        ...updatedContextMenuItems.slice(0, 2),
        {
          text: this.$t('action_buttons.duplicate_expedient'),
          iconCss: Icons.DOCUMENTS,
          action: this.showCustomDialog
        },
        ...updatedContextMenuItems.slice(2)
      ]
    } else {
      return updatedContextMenuItems
    }
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.EXPEDIENTS)
    const config = JSON.parse((this as any).listConfiguration['Config'])
    this.archivedSelectedOption = config.hasOwnProperty('archived') ? config.archived : 0
    await this.fetchGridItems()
    this.saveMenuParentCategoryName(ParentCategory.EXPEDIENTS)
    this.saveSelectedEntityName('expedients')
    this.renderGrid = true
    this.hideSpinnerLayerAction()
    await this.$nextTick()
    this.initializeArchivedSelectedOption({ selectedOption: 0, contextMenu: this.idContextMenu })
  }

  beforeDestroy() {
    resetLoadedCustomMultiselectFlags(['fileCategory', 'fileSituation', 'fyleType'])
  }

  async fetchGridItems() {
    if (this.isPortalUser) {
      await this.fetchUserPortalExpedients({
        archived: 2,
        userType: UserType.PORTAL,
        selectedRegisterId: this.portalCustomerId,
        listName: ListNames.CUSTOMER_EXPEDIENTS
      })
    } else if (this.useServerPagination) {
      const jsonConf = this.parseUserConfig()
      await this.fetchListExpedients(JSON.parse(jsonConf))
      this.expGridData = this.formatDataPaginated(this.expedientsData)
    } else {
      const jsonConf = {
        archived: this.archivedSelectedOption,
        search: (this as any).lastListConfiguration.searchSettings
      }
      await this.fetchListExpedients(jsonConf)
      this.expGridData = formatNumberColumnsGrid(
        this.expedientsDataFormatedDates,
        JSON.parse((this as any).listConfiguration.Config)['columns']
      )
    }
  }

  parseUserConfig() {
    let pageSettings
    let sortSettings
    let searchSettings
    let filterSettings
    const sortColumns: any = []
    const jsonConfig: any = {}

    if (Object.keys(this.lastListConfiguration).length) {
      pageSettings = (this as any).lastListConfiguration.pageSettings
      sortSettings = (this as any).lastListConfiguration.sortSettings
      searchSettings = (this as any).lastListConfiguration.searchSettings
      filterSettings = (this as any).lastListConfiguration.filterSettings
      jsonConfig['search'] = searchSettings
    } else {
      pageSettings = JSON.parse((this as any).listConfiguration['Config'])['pageSettings']
      sortSettings = JSON.parse((this as any).listConfiguration['Config'])['sortSettings']
      searchSettings = JSON.parse((this as any).listConfiguration['Config'])['searchSettings']
      filterSettings = JSON.parse((this as any).listConfiguration['Config'])['filterSettings']
    }
    jsonConfig['archived'] = this.archivedSelectedOption
    jsonConfig['pageSize'] = pageSettings && pageSettings.pageSize ? pageSettings.pageSize : 10
    jsonConfig['page'] = this.rememberUserConfig && pageSettings ? pageSettings.currentPage : 0
    jsonConfig['filter'] = this.rememberUserConfig ? filterSettings : []
    if (sortSettings && sortSettings.length > 0) {
      sortSettings.forEach((sortColumn: any) => {
        const column: any = {}
        column['column'] = sortColumn.field
        column['order'] = sortColumn.direction === 'Ascending' ? 'asc' : 'desc'
        sortColumns.push(column)
      })
    }
    jsonConfig['columnOrder'] = sortColumns

    if (searchSettings !== undefined && this.rememberUserConfig) {
      jsonConfig['search'] = searchSettings
    }
    jsonConfig['paginated'] = true
    return JSON.stringify(jsonConfig)
  }

  async gridActionChange(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchListExpedients(ssp)
    this.expGridData = this.formatDataPaginated(this.expedientsData)
  }

  async gridExportData(serverSideParams: any) {
    const ssp = JSON.parse(serverSideParams)
    ssp['archived'] = this.archivedSelectedOption
    ssp['paginated'] = true
    await this.fetchListExpedients(ssp)
    this.serverSideDataExport = this.formatData(this.expedientsData)
  }

  onToolbarClicked(args: any) {
    const { item } = args
    if (item.id === 'add') {
      trackEvent(TrackerEvents.CREATE_EXPEDIENT)
    }
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onExpedientsArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(this.archivedSelectedOption, this.idContextMenu)
    await this.fetchGridItems()
  }
}
</script>
