import AgenciesView from '@/views/contacts/Agencies/AgenciesView.vue'
import CourtsView from '@/views/contacts/Courts/CourtsView.vue'
import ContactsView from '@/views/contacts/Contacts/ContactsView.vue'
import CustomersView from '@/views/contacts/Customers/CustomersView.vue'
import NotariesView from '@/views/contacts/Notaries/NotariesView.vue'
import InsurersView from '@/views/contacts/Insurers/InsurersView.vue'
import OpponentsView from '@/views/contacts/Opponents/OpponentsView.vue'
import OpponentAttorneysView from '@/views/contacts/OpponentAttorneys/OpponentAttorneysView.vue'
import OpponentLawyersView from '@/views/contacts/OpponentLawyers/OpponentLawyersView.vue'
import OwnAttorneysView from '@/views/contacts/OwnAttorneys/OwnAttorneysView.vue'
import OwnLawyersView from '@/views/contacts/OwnLawyers/OwnLawyersView.vue'
import OthersView from '@/views/contacts/Others/OthersView.vue'
import ProvidersView from '@/views/contacts/Providers/ProvidersView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import TabsView from '@/views/Tabs/TabsView.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ContactEntityId } from '@/general/entityIds'

export const contactsRoutes = [
  {
    path: `/${URLS.CONTACTS}`,
    name: URLS.CONTACTS,
    component: ContactsView,
    meta: {
      title: URLS.CONTACTS,
      requiresAuth: true
    },
    children: [
      {
        path: `${URLS.CUSTOMERS}`,
        name: URLS.CUSTOMERS,
        component: CustomersView,
        meta: {
          title: URLS.CUSTOMERS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.CUSTOMERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.CUSTOMERS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.CUSTOMERS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.CUSTOMERS,
          context: ContextName.CONTACT_CUSTOMERS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_CUSTOMERS
          },
          entityType: entity.contacts.customers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.CUSTOMERS,
          entityType: ContactEntityId.CUSTOMERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OPPONENTS}`,
        name: URLS.OPPONENTS,
        component: OpponentsView,
        meta: {
          title: URLS.OPPONENTS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.OPPONENTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OPPONENTS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.OPPONENTS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.OPPONENTS,
          context: ContextName.CONTACT_OPPONENTS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_OPPONENTS
          },
          entityType: entity.contacts.opponents.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.OPPONENTS,
          entityType: ContactEntityId.OPPONENTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROVIDERS}`,
        name: URLS.PROVIDERS,
        component: ProvidersView,
        meta: {
          title: URLS.PROVIDERS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.PROVIDERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROVIDERS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.PROVIDERS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.PROVIDERS,
          context: ContextName.CONTACT_PROVIDERS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_PROVIDERS
          },
          entityType: entity.contacts.providers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.PROVIDERS,
          requiresAuth: true,
          entityType: ContactEntityId.PROVIDERS
        }
      },
      {
        path: `${URLS.OWN_LAWYERS}`,
        name: URLS.OWN_LAWYERS,
        component: OwnLawyersView,
        meta: {
          title: URLS.OWN_LAWYERS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.OWN_LAWYERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OWN_LAWYERS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.OWN_LAWYERS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.OWN_LAWYERS,
          context: ContextName.CONTACT_OWN_LAWYERS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_OWN_LAWYERS
          },
          entityType: entity.contacts.ownLawyers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.OWN_LAWYERS,
          entityType: ContactEntityId.OWN_LAWYERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OPPONENT_LAWYERS}`,
        name: URLS.OPPONENT_LAWYERS,
        component: OpponentLawyersView,
        meta: {
          title: URLS.OPPONENT_LAWYERS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.OPPONENT_LAWYERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OPPONENT_LAWYERS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.OPPONENT_LAWYERS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.OPPONENT_LAWYERS,
          context: ContextName.CONTACT_OPPONENT_LAWYERS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_OPPONENT_LAWYERS
          },
          entityType: entity.contacts.opposingLawyers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.OPPONENT_LAWYERS,
          entityType: ContactEntityId.OPPONENT_LAWYERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OWN_ATTORNEYS}`,
        name: URLS.OWN_ATTORNEYS,
        component: OwnAttorneysView,
        meta: {
          title: URLS.OWN_ATTORNEYS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.OWN_ATTORNEYS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OWN_ATTORNEYS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.OWN_ATTORNEYS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.OWN_ATTORNEYS,
          context: ContextName.CONTACT_OWN_ATTORNEYS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_OWN_ATTORNEYS
          },
          entityType: entity.contacts.ownAttorneys.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.OWN_ATTORNEYS,
          entityType: ContactEntityId.OWN_ATTORNEYS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OPPONENT_ATTORNEYS}`,
        name: URLS.OPPONENT_ATTORNEYS,
        component: OpponentAttorneysView,
        meta: {
          title: URLS.OPPONENT_ATTORNEYS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.OPPONENT_ATTORNEYS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OPPONENT_ATTORNEYS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.OPPONENT_ATTORNEYS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.OPPONENT_ATTORNEYS,
          context: ContextName.CONTACT_OPPONENT_ATTORNEYS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_OPPONENT_ATTORNEYS
          },
          entityType: entity.contacts.opposingAttorneys.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.OPPONENT_ATTORNEYS,
          entityType: ContactEntityId.OPPONENT_ATTORNEYS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.NOTARIES}`,
        name: URLS.NOTARIES,
        component: NotariesView,
        meta: {
          title: URLS.NOTARIES,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.NOTARIES,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.NOTARIES}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.NOTARIES}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.NOTARIES,
          context: ContextName.CONTACT_NOTARIES,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_NOTARIES
          },
          entityType: entity.contacts.notaries.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.NOTARIES,
          entityType: ContactEntityId.NOTARIES,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.COURTS}`,
        name: URLS.COURTS,
        component: CourtsView,
        meta: {
          title: URLS.COURTS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.COURTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.COURTS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.COURTS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.COURTS,
          context: ContextName.CONTACT_COURTS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_COURTS
          },
          entityType: entity.contacts.courts.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.COURTS,
          entityType: ContactEntityId.COURTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.INSURERS}`,
        name: URLS.INSURERS,
        component: InsurersView,
        meta: {
          title: URLS.INSURERS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.INSURERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.INSURERS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.INSURERS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.INSURERS,
          context: ContextName.CONTACT_INSURERS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_INSURERS
          },
          entityType: entity.contacts.insurers.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.INSURERS,
          entityType: ContactEntityId.INSURERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.AGENCIES}`,
        name: URLS.AGENCIES,
        component: AgenciesView,
        meta: {
          title: URLS.AGENCIES,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.AGENCIES,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.AGENCIES}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.AGENCIES}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.AGENCIES,
          context: ContextName.CONTACT_AGENCIES,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_AGENCIES
          },
          entityType: entity.contacts.agencies.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.AGENCIES,
          entityType: ContactEntityId.AGENCIES,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OTHERS}`,
        name: URLS.OTHERS,
        component: OthersView,
        meta: {
          title: URLS.OTHERS,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.OTHERS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.OTHERS}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.OTHERS}`,
        component: TabsView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          submenuName: URLS.OTHERS,
          context: ContextName.CONTACT_OTHERS,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_OTHERS
          },
          entityType: entity.contacts.others.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS,
          title: URLS.OTHERS,
          entityType: ContactEntityId.OTHERS,
          requiresAuth: true
        }
      }
    ]
  }
]

export const contactsUrl = [
  `${URLS.CONTACTS}`,
  `${URLS.CONTACTS}-${URLS.CUSTOMERS}`,
  `${URLS.CONTACTS}-${URLS.OPPONENTS}`,
  `${URLS.CONTACTS}-${URLS.OWN_LAWYERS}`,
  `${URLS.CONTACTS}-${URLS.OPPONENT_LAWYERS}`,
  `${URLS.CONTACTS}-${URLS.OWN_ATTORNEYS}`,
  `${URLS.CONTACTS}-${URLS.OPPONENT_ATTORNEYS}`,
  `${URLS.CONTACTS}-${URLS.NOTARIES}`,
  `${URLS.CONTACTS}-${URLS.OTHERS}`
]
