<template lang="pug">
  PrintOrSaveInvoicesFieldsetComponent(
    :stepNumber="stepNumber"
    :legend="fieldsetLegend"
    class="invoices-final-resume-list-container"
  )
    div(class="row-1")
      span(:class="[icons.bill, 'bill-icon']")
      div(:class="['success-invoices-container', 'info-chip']")
        span(class="invoices-number") {{ successInvoicesLength }}
        LexonTooltipComponent(
          :icon="icons.check"
          :message="text.success"
          position="top"
        )
      div(:class="['error-invoices-container', 'info-chip']")
        span(class="invoices-number") {{ errorInvoicesLength }}
        LexonTooltipComponent(
          :icon="icons.cancel"
          :message="text.error"
          position="top"
        )
    div(class="row-2")
      SimpleGridTableComponent(
        showGrid
        class="invoice-resume-list"
        :columns="gridColumns"
        :itemsData="items"
        :showButtons="false"
        :paginationPageSize="6"
        :isFrozenColsMode="false"
      )
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'
import PrintOrSaveInvoicesFieldsetComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesFieldsetComponent.vue'
import SimpleGridTableComponent from '@/components/grids/SimpleGridTable/SimpleGridTableComponent.vue'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'
import { InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'

export const gridColumns = [
  {
    field: 'name',
    headerText: 'Archivo fallido',
    visible: true,
    width: 100
  },
  {
    field: 'numberInvoice',
    headerText: 'Número de la factura',
    visible: true,
    width: 120
  },
  {
    field: 'status',
    headerText: 'Estado',
    visible: true,
    width: 120
  }
]

@Component({
  components: {
    PrintOrSaveInvoicesFieldsetComponent,
    SimpleGridTableComponent,
    LexonTooltipComponent
  }
})
export default class InvoicesFinalResumeList extends Vue {
  @Prop({
    required: true
  })
  invoices: []

  stepNumber = PrintOrSaveInvoicesSteps.FINAL_RESUME_LIST
  fieldsetLegend = this.$t('components.invoices_final_resume_list.fieldset_legend')
  gridColumns = JSON.stringify(gridColumns)
  icons = {
    cancel: Icons.CLOSE_ROUND_FULL,
    check: Icons.CHECK_ROUND_FULL,
    bill: Icons.BILL
  }
  text = {
    success: this.$t('components.tooltip.files_created_correctly'),
    error: this.$t('components.tooltip.files_with_errors')
  }

  get items() {
    return this.invoices.filter(({ status }) => status === InvoiceStatus.ERROR)
  }

  get successInvoicesLength() {
    return this.invoices.filter(({ status }) => status === InvoiceStatus.LOADED).length
  }

  get errorInvoicesLength() {
    return this.invoices.filter(({ status }) => status === InvoiceStatus.ERROR).length
  }
}
</script>
<style lang="scss" scoped>
::v-deep .tooltip-icon {
  top: -1.5px;
  margin-left: 0;
}
.invoices-final-resume-list-container {
  --invoices-fieldset-height: 100%;
  --invoices-fieldset-content-padding: 0;
  --invoices-fieldset-container-align-items: flex-end;
  .row-1 {
    @include flex($justify-content: flex-start);
    padding: 0 6px;
    .bill-icon {
      font-size: 25px;
      color: $corporate-color;
    }
    .info-chip {
      @include borders($width: 2px);
      @include flex($justify-content: space-between);
      height: 26px;
      width: 48px;
      padding: 4px;
      margin-left: 10px;
      border-radius: 40px;
      .invoices-number {
        width: 50px;
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: bold;
        text-align: center;
      }
      &.success-invoices-container {
        color: $success-color;
        border-color: $success-color;
      }
      &.error-invoices-container {
        color: $error-color;
        border-color: $error-color;
      }
    }
  }
  .row-2 {
    margin-top: 12px;
    height: 88.72%;
    ::v-deep .grid-table {
      border: none;
      height: 100%;
      .e-gridcontent {
        pointer-events: none;
      }
      .e-pagesizes {
        display: none !important;
      }
      .e-gridpager {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
</style>
