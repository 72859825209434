import { ActionTree } from 'vuex'
import {
  GenerateInvoiceFilter,
  InvoiceGenerated,
  InvoiceStatus,
  InvoicesState,
  invoicesMutationTypes
} from '@/store/modules/invoices/invoicesTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { listItemsMutationTypes } from '@/store/modules//listItems/listItemsTypes'
import { MainService } from '@/services/MainService'
import { DialogTypes } from '../dialog/dialogTypes'
import { i18n } from '@/plugins/vue-i18n'
import { ErrorCodes } from '../errors/errorsTypes'
import { UserType } from '@/store/modules/auth/authTypes'
import { b64toBlob, createLink } from '@/helpers/blob'
import { ExternalService } from '@/services/ExternalService'
import { AxiosRequestConfig } from 'axios'
import { actualDate } from '@/helpers/dateTime'
import { arrayValuesToString } from '@/helpers/array'

const dialogModule: string = ModuleNamespaces.DIALOG
const errorsModule: string = ModuleNamespaces.ERROR

export const actions: ActionTree<InvoicesState, RootState> = {
  async fetchInvoices({ commit }, { selectedRegisterId, listName, archived = null, userType = null }) {
    try {
      let filter = null

      if (userType === UserType.PORTAL) {
        filter = {
          archived,
          public: 1,
          userType
        }
      }

      const url = `invoices/${listName}/${selectedRegisterId}/get`
      const { data } = await new MainService().postData(url, filter)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async fetchTotalUnbilled({ commit }) {
    try {
      const url = 'invoices/unbilled/total/dashboard'
      const { data } = await new MainService().getData(url)
      commit(invoicesMutationTypes.FETCH_TOTAL_UNBILLED, data[0]['total'])
    } catch (error) {}
  },
  async fetchTotalReceivable({ commit }) {
    try {
      const url = 'invoices/receivable/total/dashboard'
      const { data } = await new MainService().getData(url)
      commit(invoicesMutationTypes.FETCH_TOTAL_RECEIVABLE, data[0]['total'])
    } catch (error) {}
  },
  async fetchMaxNumberInvoice({}, { invoiceType, filter = null }) {
    try {
      const url = `invoices/${invoiceType}/max-number/get`
      const { data } = await new MainService().postData(url, filter)
      return data
    } catch (error) {}
  },
  async fetchInvoiceBankdraftAutomaticInsert({ dispatch }, { invoiceType, idInvoice, filter = null }) {
    try {
      const url = `/invoices/${invoiceType}/${idInvoice}/bankdraft/automatic/insert`
      const { data } = await new MainService().postData(url, filter)
      return data
    } catch (error) {
      const errorMessage = error.response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async fetchInvoiceTotals({}, { endpoint }) {
    try {
      const url = endpoint
      const { data: totals } = await new MainService().postData(url)
      return totals
    } catch (error) {}
  },
  async fetchInvoiceDetails({ commit }, { endpoint, filter = null }) {
    try {
      const url = endpoint
      if (filter && filter.source) {
        const { data: details } = await new MainService().postData(url, filter)
        commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, details, { root: true })
      } else {
        const { data: details } = await new MainService().getData(url)
        return details[0]
      }
    } catch (error) {}
  },
  async removeInvoiceDetail({ dispatch }, { endpoint, invoiceName }) {
    try {
      const url = endpoint
      await new MainService().deleteData(url)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_remove_invoice_detail', { invoiceName })
        },
        { root: true }
      )
    } catch (error) {
      if (error.response.data.exceptionCode === ErrorCodes.E400) {
        const errorMessage = error.response.data.exceptionDesc
        dispatch(
          `${dialogModule}/showDialog`,
          {
            type: DialogTypes.ERROR,
            message: errorMessage
          },
          { root: true }
        )
        dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
      }
    }
  },
  async saveInvoiceDetail({ dispatch }, { endpoint, formData }) {
    try {
      const url = endpoint
      await new MainService().postData(url, formData)
    } catch (error) {
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: error.response.data.exceptionDesc
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async createPartialbankdraftAction({ dispatch }, { formData, bankdraftId}) {
    try {
      await new MainService().postFormData(`/v2/bankdraft/${bankdraftId}/partialbankdraft`, formData)
    } catch (error) {
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: getExceptionResponseMessage(error, 'components.dialog.error_create_partialbankdraft')
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async editPartialbankdraftAction({ dispatch }, { formData, partialbankdraftId }) {
    try {
      await new MainService().putFormData(`/v2/partialbankdraft/${partialbankdraftId}`, formData)
    } catch (error) {
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: getExceptionResponseMessage(error, 'components.dialog.error_edit_partialbankdraft')
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async removePartialbankdraftAction({ dispatch }, { partialbankdraft }) {
    try {
      await new MainService().deleteData(`/v2/partialbankdraft/${partialbankdraft.id}`)
      await dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.SUCCESS,
          message: i18n.t('components.dialog.success_remove_partialbankdraft')
        },
        { root: true }
      )
    } catch (error) {
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: getExceptionResponseMessage(error, 'components.dialog.error_remove_partialbankdraft')
        },
        { root: true }
      )
      dispatch(`${errorsModule}/existsEndpointError`, {}, { root: true })
    }
  },
  async saveInvoiceType({ commit }, invoiceType) {
    commit(invoicesMutationTypes.SAVE_INVOICE_TYPE, invoiceType)
  },
  async convertProformaToCustomerInvoice({}, idInvoice) {
    const url = `/invoices/proforma/${idInvoice}/to-customer/save`
    const { data } = await new MainService().getData(url)
    return data.id
  },
  async saveInvoice({}, { invoiceType, filter = null }) {
    try {
      const url = `invoices/${invoiceType}/save`
      const { data } = await new MainService().postData(url, filter)
      return data.id
    } catch (error) {}
  },
  async printPdfInvoice({}, { idInvoice, invoiceType }) {
    const url = `/invoices/${invoiceType}/${idInvoice}/print/get`
    return new MainService().getData(url, { headers: { 'Content-Type': 'application/pdf' }, responseType: 'blob' })
  },
  async rectifyCustomerInvoice({}, idInvoice) {
    try {
      const url = 'invoices/customers/save'
      const { data } = await new MainService().postData(url, {
        createCorrective: 1,
        correctiveSource: idInvoice
      })
      return data.id
    } catch (error) {}
  },
  async generateInvoice({ commit }, filter: GenerateInvoiceFilter): Promise<void> {
    const { idEntity, idEntityType, name, convertToPDF } = filter
    try {
      const { data, status } = await new MainService().postData('/template/generate', filter)
      const file = convertToPDF && data.file ? b64toBlob(data.file, 'application/pdf') : null
      const invoiceGenerated = {
        ...(data.id ? { idDocument: data.id } : {}),
        idEntity,
        idEntityType,
        name,
        status: status === 200 ? InvoiceStatus.LOADED : InvoiceStatus.ERROR,
        files: data.file,
        size: file ? file.size : null
      }
      commit(invoicesMutationTypes.SAVE_GENERATE_INVOICE, invoiceGenerated)
    } catch (error) {
      const invoiceGenerated = {
        idEntity,
        idEntityType,
        name,
        status: InvoiceStatus.ERROR
      }
      commit(invoicesMutationTypes.SAVE_GENERATE_INVOICE, invoiceGenerated)
    }
  },
  async mergePdf({ state }) {
    try {
      const formData: any = {
        namePdf: 'facturas'
      }

      const invoices = state.invoicesGenerated.filter(({ files }) => files)
      invoices.forEach(({ files }: InvoiceGenerated, index: number) => {
        if (files) {
          const pdfDocuments = `pdfDocuments[${index}]`
          formData[pdfDocuments] = files
        }
      })

      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: '*/*' },
        responseType: 'blob'
      }
      const url = `${process.env.VUE_APP_PDF_TOOLS}/mergePDFDocuments`
      const { data } = await new ExternalService().postFormData(url, formData, config)
      const blobUrl = URL.createObjectURL(data)
      const pdfName = `facturas ${actualDate('DD-MM-YYYY')}.pdf`
      createLink(blobUrl, pdfName, true)
    } catch (error) {}
  },
  async fetchSageInvoices({ }, items: any[] = []) {
    try {
      const url = `v2/invoice/sage`

      if(items.length <= 0) {
        return []
      }

      const { data } = await new MainService().postData(url, {
        identificadores: arrayValuesToString(items)
      })
      return data
    } catch (error) { }
  }
}

function getExceptionResponseMessage(error: any, defaultI18nMsg: string) {
  try {
    try {
      return JSON.parse(error.response.data.exceptionDesc).exceptionDesc
    }catch (e) {
      return error.response.data.exceptionDesc
    }
  } catch (e) {
    return i18n.t(defaultI18nMsg)
  }
}
