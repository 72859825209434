import { GetterTree } from 'vuex'
import {
  DashboardChartBarItem,
  DashboardCustomer,
  DashboardDocument,
  DashboardExpedient,
  DashboardRecentActivityItem,
  DashboardResumeItem,
  DashboardInvoice,
  DashboardWidgetsState
} from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<DashboardWidgetsState, RootState> = {
  getDashboardWidgetsConfiguration(state): object {
    return state.dashboardWidgetsConfiguration
  },
  getDashboardWidgetsConfigurationLoadedFlag(state): boolean {
    return state.dashboardWidgetsConfigurationLoadedFlag
  },
  getDashboardLastExpedientsList(state): DashboardExpedient[] {
    return state.dashboardLastExpedientsList
  },
  getDashboardLastCustomersList(state): DashboardCustomer[] {
    return state.dashboardLastCustomersList
  },
  getDashboardExpedientResume(state): DashboardResumeItem[] {
    return state.dashboardExpedientResume
  },
  getDashboardExpedientCounterChart(state): DashboardChartBarItem[] {
    return state.dashboardExpedientsCounterChart
  },
  getDashboardRecentActivity(state): DashboardRecentActivityItem[] {
    return state.dashboardRecentActivityItems
  },
  getDashboardLastActionsList(state) {
    return state.dashboardLastActionsList
  },
  getDashboardCustomerResume(state): DashboardResumeItem[] {
    return state.dashboardCustomerResume
  },
  getDashboardCustomerCounterChart(state): DashboardChartBarItem[] {
    return state.dashboardCustomersCounterChart
  },
  getDashboardLastDocumentsList(state): DashboardDocument[] {
    return state.dashboardLastDocumentsList
  },
  getDashboardInactiveExpedientsList(state): DashboardExpedient[] {
    return state.dashboardInactiveExpedientsList
  },
  getDashboardUnpaidInvoicesList(state): DashboardInvoice[] {
    return state.dashboardUnpaidInvoicesList
  },
  getDashboardAmountOutstandingResume(state): DashboardResumeItem[] {
    return state.dashboardAmountOutstandingResume
  },
  getDashboardInvoicedAmountsResume(state): DashboardResumeItem[] {
    return state.dashboardInvoicedAmountsResume
  },
  getDashboardOfficeDailyWorkingTimeResume(state): DashboardResumeItem[] {
    return state.dashboardOfficeDailyWorkingTimeResume
  },
  getDashboardOfficeWeeklyWorkingTimeResume(state): DashboardResumeItem[] {
    return state.dashboardOfficeWeeklyWorkingTimeResume
  },
  getDashboardOfficeMonthlyWorkingTimeResume(state): DashboardResumeItem[] {
    return state.dashboardOfficeMonthlyWorkingTimeResume
  },
  getDashboardUserDailyWorkingTimeResume(state): DashboardResumeItem[] {
    return state.dashboardUserDailyWorkingTimeResume
  },
  getDashboardUserWeeklyWorkingTimeResume(state): DashboardResumeItem[] {
    return state.dashboardUserWeeklyWorkingTimeResume
  },
  getDashboardUserMonthlyWorkingTimeResume(state): DashboardResumeItem[] {
    return state.dashboardUserMonthlyWorkingTimeResume
  }
}
