import { i18n } from '@/plugins/vue-i18n'
import { ActionEntityId } from '@/general/entityIds'
export interface KanbanCardItem {
  actionTypeText: string
  billable: number
  dateActionAt: string
  dateWithoutHour: string
  description: string
  duration: number
  end: string
  expiration: string
  hasDocuments: number
  id: number
  idActionType: string
  idStatus: number
  idUser: number
  interveners: string
  isPublic: number
  presentationDate: string
  responsible: string
  rows: KanbanCardRow[]
  start: string
  state: string
  subject: string
}

interface KanbanCardRow {
  label: string
  value: string
}

export interface KanbanAction {
  id: number
  idActionType: string
  state: string
  stateId: number
  subject: string
  userId: number
}

export interface KanbanSidebarCheckboxInfo {
  keyField: string
  visible: boolean
}

export enum KanbanGroupBy {
  ACTION_TYPE = 'idActionType',
  RESPONSIBLE = 'responsible',
  START_DATE = 'dateWithoutHour'
}

export enum SortByOptions {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending'
}

export enum KanbanSidebarFilter {
  RESPONSIBLE = 'responsible',
  TYPE = 'actionTypeText'
}

export const kanbanColumns = [
  {
    headerText: i18n.t('components.lexon_kanban.columns.planned'),
    keyField: i18n.t('components.lexon_kanban.columns.planned')
  },
  {
    headerText: i18n.t('components.lexon_kanban.columns.initiated'),
    keyField: i18n.t('components.lexon_kanban.columns.initiated')
  },
  {
    headerText: i18n.t('components.lexon_kanban.columns.pending_resolution'),
    keyField: i18n.t('components.lexon_kanban.columns.pending_resolution')
  },
  {
    headerText: i18n.t('components.lexon_kanban.columns.done'),
    keyField: i18n.t('components.lexon_kanban.columns.done')
  },
  {
    headerText: i18n.t('components.lexon_kanban.columns.out_of_time'),
    keyField: i18n.t('components.lexon_kanban.columns.out_of_time')
  },
  {
    headerText: i18n.t('components.lexon_kanban.columns.cancelled'),
    keyField: i18n.t('components.lexon_kanban.columns.cancelled')
  }
]

export const actionTypeSidebarFilterOptions = [
  {
    actionEntityId: ActionEntityId.CALL,
    idActionType: 'LLAM',
    text: 'Llamadas'
  },
  {
    actionEntityId: ActionEntityId.MEETING,
    idActionType: 'REUN',
    text: 'Reuniones'
  },
  {
    actionEntityId: ActionEntityId.OTHER,
    idActionType: 'OTRA',
    text: 'Otras'
  },
  {
    actionEntityId: ActionEntityId.PROCEDURE,
    idActionType: 'TRAM',
    text: 'Trámites'
  }
]
