import { ActionTree } from 'vuex'
import {
  ConfigurationState,
  ViewConfiguration,
  configurationMutationTypes
} from '@/store/modules/configuration/configurationTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationService } from '@/services/ConfigurationService'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<ConfigurationState, RootState> = {
  async fetchCurrentListConfiguration({ commit, rootGetters }, alias) {
    try {
      const userId = rootGetters[`${ModuleNamespaces.AUTH}/getUserId`]
      const url = `configuration/user/lists/${userId}/${alias}/get`
      const response = await new ConfigurationService().getData(url)
      const { data } = response
      commit(configurationMutationTypes.SET_CURRENT_LIST_CONFIGURATION, data[0])
    } catch (error) {}
  },
  async fetchCurrentViewConfiguration({ commit, getters }, { objectType, alias, context }) {
    try {
      const cachedAlias = getters.checkIfViewConfigurationExists(alias)
      const repeatFetchConfigurationByAlias = getters.checkIfRepeatFetchConfigurationAnywayByAlias(alias)
      const repeatFetchConfigurationByContext = getters.checkIfRepeatFetchConfigurationAnywayByContext(context)
      const repeatAPIRequest = !cachedAlias || repeatFetchConfigurationByAlias || repeatFetchConfigurationByContext
      if (repeatAPIRequest) {
        const url = `configuration/site/component/${objectType}/${alias}/get`
        const response = await new ConfigurationService().getData(url)
        const { data } = response
        commit(configurationMutationTypes.GET_CURRENT_VIEW_CONFIGURATION, { data, context })
        const config = JSON.parse(data[0].config)
        const view: ViewConfiguration = {
          alias,
          type: objectType,
          config
        }
        commit(configurationMutationTypes.SET_VIEW_CONFIGURATION, view)
      }
    } catch (error) {}
  },
  async saveUserCustomConfigurationList({ commit }, data) {
    try {
      const url = 'configuration/user/lists/save'
      await new MainService().postData(url, data)
      commit(configurationMutationTypes.SET_LAST_LIST_CONFIGURATION, data.Config)
    } catch (error) {}
  },
  async fetchUserProfileConfiguration({ commit }) {
    try {
      const url = 'configuration/site/component/user-profile/get'
      const { data } = await new ConfigurationService().getData(url)
      commit(configurationMutationTypes.FETCH_USER_PROFILE_CONFIGURATION, data)
    } catch (error) {}
  },
  async fetchRelatedFormFields({ commit }, formName) {
    try {
      const url = `configuration/site/component/view/${formName}/get`
      const { data } = await new ConfigurationService().getData(url)
      commit(configurationMutationTypes.FETCH_RELATED_FORM_FIELDS, data)
    } catch (error) {}
  },
  async rememberUserCustomConfigurationList({ commit }, value) {
    try {
      commit(configurationMutationTypes.REMEMBER_USER_CUSTOM_CONFIGURATION_LIST, value)
    } catch (error) {}
  },
  async setRouteFromName({ commit }, value) {
    try {
      commit(configurationMutationTypes.SET_ROUTE_FROM_NAME, value)
    } catch (error) {}
  }
}
