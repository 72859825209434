<template lang="pug">

  SpinnerLayerComponent(v-if="showSpinner" class="spinner-layer")

  section(v-else class="dashboard-widgets-container")

    DynamicLayoutComponent(
      v-if="renderDashboard"
      :widgets="parsedWidgets"
      :allowDragging="allowDraggingProp"
      :columns="12"
      @changeWidgetPositions="onChangeWidgetPositions"
    )

    div(v-else class="no-widget-container")
      span(:class="noWidgetsIcon")
      p {{ texts.noWidget }}
      p {{ texts.noWidgetInfo }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConfigurationAppointmentVersion } from '@/store/modules/configuration/configurationTypes'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import DynamicLayoutComponent from '@/components/DynamicLayout/DynamicLayoutComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { DashboardWidgetItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { Icons } from '@/icons/icons'
import { DashboardWidgetStatus } from '@/components/widgets/DashboardWidgets/types/DashboardWidgetTypes'

const authModule: string = ModuleNamespaces.AUTH

@Component({
  components: {
    DynamicLayoutComponent,
    SpinnerLayerComponent
  }
})
export default class DashboardWidgetsContainerComponent extends Vue {
  @Prop({
    type: Boolean
  })
  showSpinner!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  allowDraggingProp!: boolean

  @Prop({
    type: Array,
    required: true
  })
  widgets!: DashboardWidgetItem[]

  @Getter('getCompanyAppointmentsVersion', { namespace: authModule })
  calendarVersion: ConfigurationAppointmentVersion

  internalCalendarVersion: ConfigurationAppointmentVersion = ConfigurationAppointmentVersion.INTERNAL_VERSION

  parsedWidgets: DashboardWidgetItem[] = []

  noWidgetsIcon = Icons.NEWS

  texts = {
    noWidget: this.$t('components.dashboard_widget_container.no_widget'),
    noWidgetInfo: this.$t('components.dashboard_widget_container.no_widget_info')
  }

  @Watch('widgets', { immediate: true })
  parseGridItemsWatcher() {
    if (this.widgets && this.widgets.length) {
      this.parsedWidgets = this.widgets.filter((widget) => widget.visible === DashboardWidgetStatus.SELECTED_WIDGET)
      this.parsedWidgets.forEach((parsedWidget) => {
        if (parsedWidget.id === 'agenda') {
          parsedWidget.renderedComponent = this.renderCalendarType
        }
      })
    }
  }

  get renderCalendarType() {
    return this.internalCalendarVersion === this.calendarVersion ? 'CalendarComponent' : 'LexboxCalendarComponent'
  }

  get renderDashboard() {
    return this.parsedWidgets && this.parsedWidgets.length
  }

  saveNewPositions() {
    const invisibleWidgets = this.widgets.filter((widget) => widget.visible !== DashboardWidgetStatus.SELECTED_WIDGET)
    const totalWidgets = [...invisibleWidgets, ...this.parsedWidgets]
    this.$emit('saveWidgetsPositions', totalWidgets)
  }

  onChangeWidgetPositions(newWidgetPositions: any) {
    this.parsedWidgets.forEach((widget) => {
      const currentWidget = newWidgetPositions[widget.id]
      widget.dataRow = currentWidget.row
      widget.dataCol = currentWidget.col
    })
    this.saveNewPositions()
  }
}
</script>

<style lang="scss" scoped>
.dashboard-widgets-container {
  margin-top: 10px;
}

.spinner-layer {
  --spinner-layer-min-height: 500px;
  --spinner-layer-background: $gray-04;
}

.no-widget-container {
  @include flex($flex-direction: column);
  height: 300px;
  font-family: $corporate-font-medium;
  font-size: 15px;
  color: $blue-04;
  margin-top: 3.5%;

  span {
    color: $blue-06;
    font-size: 160px;
  }

  p {
    margin: 8px 0 0 0;
    font-size: 16px;

    &:first-of-type {
      font-size: 20px;
      margin-top: 29px;
    }
  }
}
</style>
