import { Module } from 'vuex'
import { getters } from '@/store/modules/tasks/tasksGetters'
import { actions } from '@/store/modules/tasks/tasksActions'
import { mutations } from '@/store/modules/tasks/tasksMutations'
import { TasksState } from '@/store/modules/tasks/tasksTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: TasksState = {
  controlSidebarTasksObject: {
    data: [],
    pagination: {
      numPage: 0,
      totalRecs: ''
    }
  }
}

const namespaced: boolean = true

export const tasksModule: Module<TasksState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
