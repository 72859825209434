import axios, { AxiosRequestConfig } from 'axios'
import { authLexonPartnerId } from '@/store/modules/auth/authTypes'
import { getDataFromLocalStorage } from '@/helpers/helpers'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'

export const securityHeaders = {
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
  'Content-Security-Policy': 'script-src "self"',
  'X-Frame-Options': 'DENY',
  'X-Content-Type-Options': 'nosniff',
  'Referrer-Policy': 'strict-origin-when-cross-origin',
  'Permissions-Policy': `geolocation=(self "${process.env.VUE_APP_API_URL}")`,
  'Cross-Origin-Embedder-Policy': 'require-corp',
  'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
  'Clear-Site-Data': '"cookies", "cache"',
  'X-Permitted-Cross-Domain-Policies': 'none',
  'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate'
}

const axiosConfiguration: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
    ...securityHeaders
  }
}

if (axiosConfiguration && axiosConfiguration.headers) {
  axiosConfiguration.headers[authLexonPartnerId] = process.env.VUE_APP_LEXON_PARTNER_ID
}

const http = axios.create(axiosConfiguration)

type fn = (param: string) => void

const authData = getDataFromLocalStorage('auth-data')
let accessToken = ''
if (authData) {
  accessToken = authData.accessToken
  http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

let subscribers: fn[] = []

export function onAccessTokenRefreshed(accessToken: string) {
  http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  subscribers = subscribers.filter((callback) => callback(accessToken))
}

function addSubscriber(callback: () => void) {
  subscribers.push(callback)
}

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    try {
      const {
        config,
        response: { status }
      } = error
      const originalRequest = config
      if (
        !config.url.includes('login') &&
        !config.url.includes('navision-login') &&
        !config.url.includes(URLS.SELECT_COMPANY) &&
        !config.url.includes('navision/encrypted') &&
        !config.url.includes('actions') &&
        !config.url.includes('v2/token-exchange') &&
        !config.url.includes('sign/start') &&
        status === 401
      ) {
        if (!config.url.includes('auth/token/get')) {
          store.dispatch(`${ModuleNamespaces.AUTH}/refreshToken`).then(() => {
            if (getDataFromLocalStorage('auth-data')) {
              accessToken = getDataFromLocalStorage('auth-data').accessToken
              onAccessTokenRefreshed(accessToken)
            }
          })
        } else {
          store.dispatch(`${ModuleNamespaces.AUTH}/logout`)
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(() => {
            originalRequest.headers.Authorization = 'Bearer ' + accessToken
            resolve(http(originalRequest))
          })
        })
        return retryOriginalRequest
      }
    } catch (error) {
    }
    return Promise.reject(error)
  }
)

http.interceptors.request.use(
  (config) => {
    const accessToken = store.getters[`${ModuleNamespaces.AUTH}/getAuthToken`]

    if (accessToken) {
      const source = axios.CancelToken.source()
      config.cancelToken = source.token

      // NO CANCELA LA PETICIÓN DEL LISTADO DE AUDITORÍAS
      if (!config.url!.includes('audits/get')) {
        store.commit(`${ModuleNamespaces.AUTH}/ADD_CANCEL_TOKEN`, source)
      }

      store.dispatch(`${ModuleNamespaces.AUTH}/keepSessionAlive`)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default http
