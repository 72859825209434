<template lang="pug">

  ValidationProvider(
    :rules="validatorRules"
    ref="datePickerValidator"
    :name="validatorName"
    :vid="$attrs.id"
    v-slot="{ errors }"
    tag="div"
  )
    div(
      :class="[{ 'error-styles': errors[0] }, { 'disabled-field': disabled }, { 'hidden-field': hidden }, 'lf-datepicker-container']"
    )
      div(class="label-container")
        span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ label }}
        span(
          v-if="showInfo"
          :class="[icons.info, 'label-icon']"
        )

      ejs-datepicker(
        ref="datePicker"
        v-model="innerValue"
        :value="innerValue"
        :id="id"
        :name="id"
        class="lf-datepicker"
        placeholder="dd/mm/aaaa"
        :change="onValueChange"
        format="dd/MM/yyyy"
        :cleared="clearField"
        :focus="onFocus"
        :showClearButton="true"
        :strictMode="true"
        :created="onDatePickerCreated"
        :destroyed="onDatePickerDestroyed"
        :open="onDatePickerOpen"
        :close="onDatePickerClose"
      )

      div(class="messages-container")
        span(v-if="errors.length" class="alert-message") {{ errors[0] }}
        span(v-else class="help-message") {{ helpText }}

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars'
import { ValidationProvider } from 'vee-validate'
import { Icons } from '@/icons/icons'
import { changeDateFormat, convertToDate } from '@/helpers/dateTime'
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import DatePickerClearButtonMixinVue from '@/mixins/DatePickerClearButtonMixin.vue'

Vue.use(DatePickerPlugin)

@Component({
  components: {
    ValidationProvider: () => import('vee-validate').then(({ ValidationProvider }) => ValidationProvider)
  }
})
export default class LexonDatePickerComponent extends mixins(FieldBehaviorsMixin, DatePickerClearButtonMixinVue) {
  @Prop({
    type: Boolean,
    default: false
  })
  hidden!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showInfo!: boolean

  @Prop({
    type: [String, Date],
    default: ''
  })
  value!: string

  @Prop({
    type: String
  })
  id!: string

  @Prop({
    type: String
  })
  name!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  initializeNow!: boolean

  @Prop({
    type: String
  })
  outputFormat!: string

  @Prop({
    type: String,
    default: 'DD/MM/YYYY'
  })
  inputFormat!: string

  $refs!: {
    datePicker: any
    datePickerValidator: InstanceType<typeof ValidationProvider>
  }

  icons = {
    info: Icons.INFO_FULL
  }

  innerValue: any = null

  @Watch('value')
  changeInnerValue(newVal: string) {
    if (newVal === null) {
      this.innerValue = null
    } else {
      let newValDate = null
      if (typeof newVal === 'object') {
        newValDate = newVal
      } else if (newVal.indexOf('-') !== -1) {
        newValDate = convertToDate(newVal, 'YYYY-MM-DD')
      } else {
        newValDate = convertToDate(newVal, this.inputFormat)
      }
      if (newValDate !== this.innerValue) {
        this.innerValue = newValDate
      }
    }
  }

  @Watch('innerValue')
  changedValue(newVal: Date | null) {
    if (newVal !== null) {
      this.validateField()
    }
    this.emitValue(newVal)
  }

  @Emit()
  emitValue(value: any) {
    if (this.outputFormat && value) {
      value = changeDateFormat(value, this.inputFormat, this.outputFormat)
    }
    return this.$emit('input', value)
  }

  mounted() {
    this.initValidator(this.$refs.datePickerValidator)
    this.validateField()
  }

  onValueChange(args: any) {
    const date = args.value

    if (!date) {
      this.innerValue = args.value
      this.validateField()
    }
  }

  clearField() {
    this.innerValue = null
  }

  onFocus() {
    this.$refs.datePicker.show()
  }

  created() {
    if (this.value) {
      this.innerValue = convertToDate(this.value, this.inputFormat)
    } else if (this.initializeNow) {
      this.innerValue = new Date()
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-datepicker-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  width: 100%;

  &.disabled-field {
    pointer-events: none;

    .label-container {
      .label-text {
        color: $gray-02;

        &.required-field::after {
          color: $gray-02;
        }
      }
    }

    .messages-container .help-message {
      color: $gray-02;
    }

    ::v-deep .e-input-group.e-date-wrapper {
      background-color: $gray-04;
    }

    ::v-deep .e-input-group-icon.e-date-icon::before {
      color: $gray-02;
    }
  }

  &.hidden-field {
    display: none;
  }

  &.error-styles {
    .label-container .label-text {
      color: $error-color;
    }

    ::v-deep .e-input-group.e-control-wrapper.e-date-wrapper {
      border-color: $error-color !important;
      background-color: $red-07;

      &:hover {
        border-color: $error-color !important;
        background-color: $red-07;
      }

      .lf-datepicker::placeholder {
        color: $error-color;
      }
    }

    ::v-deep .e-input-group-icon.e-date-icon::before {
      color: $error-color;
    }
  }

  .lf-datepicker::placeholder {
    @include placeholder;
  }

  ::v-deep .e-input-group-icon.e-date-icon::before {
    font-family: $lf-icons-font;
    font-size: 20px;
    color: $corporate-color;
    margin-top: 4px;
    content: '\e925';
  }

  ::v-deep .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-family: $lf-icons-font;
    font-size: 20px;
    color: $corporate-color;
    content: '\e935';
    margin-right: 8px;
  }

  ::v-deep .e-input-group.e-control-wrapper.e-error .e-clear-icon::before {
    color: $error-color;
  }

  ::v-deep .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    min-height: 32px !important;
    height: 32px !important;
  }

  ::v-deep .e-input-group.e-control-wrapper.e-date-wrapper {
    border: none;
    @include textfield-styles;
    border-color: $gray-03 !important;
    margin: 0;

    &:hover {
      border: 1px solid $corporate-color !important;
    }

    &::before {
      display: none;
    }

    &.e-input-focus {
      border: none !important;
      border: 1px solid $corporate-color !important;

      &.e-error {
        border-color: $error-color !important;
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .label-container {
    @include flex;
    color: $corporate-color;

    .label-text {
      @include label-text;
    }

    .label-icon {
      font-size: 15px;
    }
  }
}
</style>
