import Vue from 'vue'
import { MutationTree } from 'vuex'
import { SpinnerState } from '@/store/modules/spinner/spinnerTypes'

export const mutations: MutationTree<SpinnerState> = {
  SHOW_SPINNER(state: SpinnerState, showMessage: boolean) {
    state.spinner = {
      show: true,
      message: showMessage
    }
  },
  HIDE_SPINNER(state: SpinnerState) {
    state.spinner = {
      show: false,
      message: false
    }
  },
  SHOW_SPINNER_LAYER(state: SpinnerState) {
    state.showSpinnerLayer = true
  },
  HIDE_SPINNER_LAYER(state: SpinnerState) {
    state.showSpinnerLayer = false
  },
  SHOW_DASHBOARD_WIDGET_SPINNER(state: SpinnerState, context: string) {
    Vue.set(state.dashboardWidgetSpinners, context, true)
  },
  HIDE_DASHBOARD_WIDGET_SPINNER(state: SpinnerState, context: string) {
    Vue.set(state.dashboardWidgetSpinners, context, false)
  },
  RESET_ALL_DASHBOARD_WIDGET_SPINNERS(state: SpinnerState) {
    Object.keys(state.dashboardWidgetSpinners).forEach((widgetName) => {
      Vue.set(state.dashboardWidgetSpinners, widgetName, true)
    })
  },
  RESET_DASHBOARD_WIDGET_SPINNERS(state: SpinnerState) {
    /* Esta mutación se utiliza para hacer que estos widgets SIEMPRE
    vuelvan a pedir los datos al pasar por el dashboard */
    // EXPEDIENT WIDGETS
    state.dashboardWidgetSpinners.lastExpedientsList = true
    state.dashboardWidgetSpinners.expedientsResume = true
    state.dashboardWidgetSpinners.inactiveExpedientsList = true
    // DOCUMENT WIDGETS
    state.dashboardWidgetSpinners.lastDocumentsList = true
    // RECENT ACTIVITY WIDGET
    state.dashboardWidgetSpinners.recentActivity = true
    // AMOUNT WIDGETS
    state.dashboardWidgetSpinners.amountsOutstandingResume = true
    state.dashboardWidgetSpinners.invoicedAmountsResume = true
    // WORKING TIME WIDGETS
    state.dashboardWidgetSpinners.officeDailyWorkingTimeResume = true
    state.dashboardWidgetSpinners.userDailyWorkingTimeResume = true
  },
  RESET_DASHBOARD_CUSTOMER_WIDGET_SPINNERS(state: SpinnerState) {
    state.dashboardWidgetSpinners.lastCustomersList = true
    state.dashboardWidgetSpinners.customersResume = true
    state.dashboardWidgetSpinners.customersCounterChart = true
  }
}
