<template lang="pug">

  section(
    v-if="selectedExpedientTopBarData && selectedExpedientTopBarData.length"
    class="expedient-global-vision-header"
  )

    div(
      v-for="(item, index) in selectedExpedientTopBarData"
      :key="index"
      :class="[item.class, 'header-item']"
    )
      span {{ item.label }}
      template(v-if="index === 3")
        ExpedientProfitabilityWarningTooltipComponent(:itemData="item.data")

      span(v-else class="data") {{ item.data }}

</template>

<script lang="ts">
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ExpedientProfitabilityWarningTooltipComponent from '@/components/expedients/ExpedientGlobalVision/ExpedientProfitabilityWarningTooltipComponent.vue'

const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES

@Component({
  components: {
    ExpedientProfitabilityWarningTooltipComponent
  }
})
export default class ExpedientGlobalVisionTopBarComponent extends Vue {
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterDataAction: ({}) => Promise<{}>

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterDataGetter: (context: string) => {}
  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint
  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  contexts = {
    topBarContext: ContextName.EXPEDIENT_GLOBAL_VISION_TOP_BAR,
    expedientContext: ContextName.EXPEDIENTS
  }

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get selectedExpedientTopBarData() {
    return (this as any).selectedRegisterDataGetter(this.contexts.topBarContext).globalVisionTopBar
  }

  async created() {
    this.fetchGlobalVisionTopBarInfo()
  }

  async fetchGlobalVisionTopBarInfo() {
    const endpoint: string = this.endpoints(this.entity(this.contexts.expedientContext).type).get

    await this.fetchSelectedRegisterDataAction({
      endpoint,
      context: this.contexts.topBarContext,
      post: {
        idExpedient: this.selectedExpedientId,
        source: this.contexts.topBarContext
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.expedient-global-vision-header {
  @include scroll-styles;
  font-family: $corporate-font-medium;
  color: $corporate-color;
  font-size: 13px;
  white-space: nowrap;
  overflow-x: auto;
  padding: 12px 0 10px;
  margin-bottom: 10px;

  .header-item {
    margin-right: 4%;
    display: inline-block;

    .data {
      font-weight: bold;
      font-size: 15px;
      margin-left: 4px;
    }

    &.profitability .data {
      position: relative;
      color: $success-color;

      &::after {
        position: absolute;
        top: 1px;
        left: 33px;
        font-family: lf-font;
        font-size: 12px;
        color: #001978;
        content: '\e9fc';
        margin-right: 4px;
      }
    }
  }
}
</style>
