<template lang="pug">
  div
    GridTable(
      v-if="renderGrid && !showSpinnerLayer"
      :itemsData="billingData"
      :gridConfiguration="listConfiguration['Config']"
      :title="$t('views.billing_provider_bankdrafts.title')"
      :toolbarOptions="toolbarOptionsWithoutAddNewButton"
      :contextMenuItems="contextMenuItems"
      :listName="listConfiguration['Alias']"
      :context="context"
      :totals="totals"
      @gridTableFilter="onGridTableFilter"
      @toolbarClicked="onToolbarClicked"
      :archivedFilter="archivedSelectedOption"
    )

    ejs-contextmenu(
      :id="idContextMenu"
      class="context-menu-component e-contextmenu-archived"
      :items="invoicesArchivedContextMenuItemsParsed"
      :select="onInvoicesArchivedClick"
    )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { Action, Getter } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'

const billingModule: string = ModuleNamespaces.BILLING

@Component({
  components: {
    GridTable
  }
})
export default class ProviderBankdraftsView extends mixins(ListViewMixin, ArchivedFilterMixin) {
  @Action('fetchBillingData', { namespace: billingModule })
  fetchBillingData: (contactType: {}) => []

  @Action('fetchTotals', { namespace: billingModule })
  fetchTotals: (source: string) => []

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'
  gridFilters: any = {}

  get context() {
    return ContextName.BILLING
  }

  get filters() {
    return {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.PROVIDER_BANKDRAFTS)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.PROVIDER_BANKDRAFTS)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    await this.fetchBillingData({
      billingType: ListNames.PROVIDER_BANKDRAFTS,
      filter: { archived: this.archivedSelectedOption }
    })
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchTotals({
      source: ListNames.PROVIDER_BANKDRAFTS,
      ...this.filters
    })
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.fetchGridItems()
    await this.fetchTotals({ source: ListNames.PROVIDER_BANKDRAFTS, ...this.filters })
  }
}
</script>
