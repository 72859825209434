import { ActionTree } from 'vuex'
import { ProfessionalsState, professionalsMutationTypes } from '@/store/modules/professionals/professionalsTypes'
import { ContextName, ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { ProfessionalsService } from '@/services/ProfessionalsService'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'

export const actions: ActionTree<ProfessionalsState, RootState> = {
  async fetchListProfessionals({ commit, rootGetters }) {
    try {
      const selectedRegisterDataGetter = `${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterData`
      const selectedProfessionalData = rootGetters[selectedRegisterDataGetter](ContextName.MAINTENANCE_USERS_CONFIG)
      const { id: selectedProfessionalId } = selectedProfessionalData
      const url = `user/professionals/?idUser=${selectedProfessionalId}`
      const { data } = await new ProfessionalsService().getData(url)
      commit(professionalsMutationTypes.FETCH_PROFESSIONALS, data)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  }
}
