<template lang="pug">

  ejs-dashboardlayout(
    ref="layout"
    class="dynamic-layout"
    :cellSpacing="spacing"
    :columns="columns"
    :allowDragging="allowDragging"
    :key="componentKey"
    :dragStop="onDragStop"
  )

    div(
      v-for="(widget, widgetIndex) in widgets"
      :key="widget.id"
      :id="widget.id"
      :class="[widget.class, 'widget', 'e-panel']"
      :data-row="calculateDataRow(widget, widgetIndex)"
      :data-col="widget.dataCol"
      :data-sizeX="isMdAndDownBreakpoint ? columns : widget.dataSizeX"
      :data-sizeY="calculateDataSizeY(widget)"
    )
      component(
        :is="widget.renderedComponent"
        :text="widget.text"
      )

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashboardLayoutPlugin } from '@syncfusion/ej2-vue-layouts'
import { VuetifyBreakpointNames } from '@/plugins/vuetify'
import CalendarComponent from '@/components/Calendar/CalendarComponent.vue'
import DashboardAmountsOutstandingWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardAmountsOutstandingWidget.vue'
import DashboardCustomersCounterChartWidget from '@/components/widgets/DashboardWidgets/charts/DashboardCustomersCounterChartWidget.vue'
import DashboardCustomersResumeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardCustomersResumeWidget.vue'
import DashboardExpedientsCounterChartWidget from '@/components/widgets/DashboardWidgets/charts/DashboardExpedientsCounterChartWidget.vue'
import DashboardExpedientsResumeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardExpedientsResumeWidget.vue'
import DashboardInactiveExpedientsListWidget from '@/components/widgets/DashboardWidgets/lists/DashboardInactiveExpedientsListWidget.vue'
import DashboardInvoicedAmountsWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardInvoicedAmountsWidget.vue'
import DashboardLastActionsListWidget from '@/components/widgets/DashboardWidgets/lists/DashboardLastActionsListWidget.vue'
import DashboardLastCustomersListWidget from '@/components/widgets/DashboardWidgets/lists/DashboardLastCustomersListWidget.vue'
import DashboardLastDocumentsListWidget from '@/components/widgets/DashboardWidgets/lists/DashboardLastDocumentsListWidget.vue'
import DashboardLastExpedientsListWidget from '@/components/widgets/DashboardWidgets/lists/DashboardLastExpedientsListWidget.vue'
import DashboardOfficeDailyWorkingTimeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardOfficeDailyWorkingTimeWidget.vue'
import DashboardOfficeMonthlyWorkingTimeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardOfficeMonthlyWorkingTimeWidget.vue'
import DashboardOfficeWeeklyWorkingTimeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardOfficeWeeklyWorkingTimeWidget.vue'
import DashboardRecentActivityWidget from '@/components/widgets/DashboardWidgets/DashboardRecentActivityWidget.vue'
import DashboardUnpaidInvoicesListWidget from '@/components/widgets/DashboardWidgets/lists/DashboardUnpaidInvoicesListWidget.vue'
import DashboardUserDailyWorkingTimeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardUserDailyWorkingTimeWidget.vue'
import DashboardUserMonthlyWorkingTimeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardUserMonthlyWorkingTimeWidget.vue'
import DashboardUserWeeklyWorkingTimeWidget from '@/components/widgets/DashboardWidgets/resumes/DashboardUserWeeklyWorkingTimeWidget.vue'
import LexboxCalendarComponent from '@/components/lexbox/LexboxCalendar/LexboxCalendarComponent.vue'

Vue.use(DashboardLayoutPlugin)

@Component({
  components: {
    CalendarComponent,
    DashboardAmountsOutstandingWidget,
    DashboardCustomersCounterChartWidget,
    DashboardCustomersResumeWidget,
    DashboardExpedientsCounterChartWidget,
    DashboardExpedientsResumeWidget,
    DashboardInactiveExpedientsListWidget,
    DashboardInvoicedAmountsWidget,
    DashboardLastActionsListWidget,
    DashboardLastCustomersListWidget,
    DashboardLastDocumentsListWidget,
    DashboardLastExpedientsListWidget,
    DashboardOfficeDailyWorkingTimeWidget,
    DashboardOfficeMonthlyWorkingTimeWidget,
    DashboardOfficeWeeklyWorkingTimeWidget,
    DashboardRecentActivityWidget,
    DashboardUnpaidInvoicesListWidget,
    DashboardUserDailyWorkingTimeWidget,
    DashboardUserMonthlyWorkingTimeWidget,
    DashboardUserWeeklyWorkingTimeWidget,
    LexboxCalendarComponent
  }
})
export default class DynamicLayoutComponent extends Vue {
  @Prop({
    type: Array
  })
  widgets!: []

  @Prop({
    type: Number,
    default: 12
  })
  columns!: number

  @Prop({
    type: String
  })
  text!: string

  @Prop({
    type: Array,
    default: () => [18, 18]
  })
  spacing!: number[]

  @Prop({
    type: Boolean,
    default: true
  })
  allowDragging!: boolean

  $refs!: {
    layout: any
  }

  componentKey = 0

  @Watch('breakpointName')
  updateComponent2() {
    this.componentKey += 1
  }

  get isMdAndDownBreakpoint(): boolean {
    return this.$vuetify.breakpoint.mdAndDown
  }

  get breakpointName(): string {
    return this.$vuetify.breakpoint.name
  }

  calculateDataSizeY(widget: any) {
    switch (this.$vuetify.breakpoint.name) {
      case VuetifyBreakpointNames.XS:
        return 1
      case VuetifyBreakpointNames.SM:
        return widget.dataSizeYSmAndMd
      case VuetifyBreakpointNames.MD:
        return widget.dataSizeYSmAndMd
      case VuetifyBreakpointNames.LG:
        return widget.dataSizeY
      case VuetifyBreakpointNames.XL:
        return widget.dataSizeY
    }
  }

  calculateDataRow(widget: any, index: number) {
    switch (this.$vuetify.breakpoint.name) {
      case VuetifyBreakpointNames.XS:
        return index
      case VuetifyBreakpointNames.SM:
        return widget.dataRowSmAndMd
      case VuetifyBreakpointNames.MD:
        return widget.dataRowSmAndMd
      case VuetifyBreakpointNames.LG:
        return widget.dataRow
      case VuetifyBreakpointNames.XL:
        return widget.dataRow
    }
  }

  async onDragStop() {
    await this.$nextTick()
    const newWidgetPositions = this.$refs.layout.ej2Instances.cloneObject
    this.$emit('changeWidgetPositions', newWidgetPositions)
  }
}
</script>

<style lang="scss" scoped>
.dynamic-layout {
  overflow: hidden;

  .widget.e-panel {
    @include borders($color: $blue-06);
    z-index: 1 !important;
    border-radius: $widget-border-radius;

    &:hover {
      border-color: $blue-06;
    }

    // IMPORTANT- Este estilo es necesario para poder mover los widgets correctamente
    ::v-deep .e-panel-container {
      display: none;
    }
  }
}
</style>
