export enum ViewConfigTypes {
  VIEW = 'view',
  LIST = 'list'
}

export interface Configuration {
  type: ConfigurationTypes
  alias: ConfigurationAlias
  subType: ConfigurationTypes
}

export interface ViewConfigurationType {
  [index: string]: object
}

export interface ViewConfiguration {
  id?: number
  name?: string
  alias: string
  config: {}
  type: ViewConfigTypes.LIST | ViewConfigTypes.VIEW
}

export interface ListConfigurationType {
  [alias: string]: object
}

export interface ConfigurationState {
  currentListConfiguration: object
  currentViewConfiguration: ViewConfigurationType
  viewsConfiguration: ViewConfiguration[]
  customConfigText: string
  editingPeriodicBilling: boolean
  enabledPeriodicBillingTab: boolean
  lastListConfiguration: object
  relatedFormConfiguration: Array<{ config: string }>
  rememberUserCustomConfigurationList: boolean
  routeFromName: string
  userProfileConfiguration: object
  visiblePeriodicBillingTab: boolean
}

export interface ListConfiguration {
  columns: any[]
  filterSettings: []
  groupSettings: {}
  pageSettings: {
    currentPage: number
    pageSize: number
  }
  searchSettings: string
  sortSettings: []
}

export enum ConfigurationElements {
  WIDGET = '1',
  USER_PROFILE = '2',
  VIEW = '3',
  TAB = '4',
  INPUT = '5',
  DATE = '6',
  SWITCH = '7',
  LIST = '8',
  COMBO = '9',
  FORM = '10',
  CUSTOMER_PORTAL = '11',
  FILE_MANAGER = '12',
  TAB_HEADER = '13',
  EXPEDIENT_ACTIONS = '14',
  EXPEDIENT_ECONOMIC_DATA = '15',
  EXPEDIENT_INSURERS = '16',
  LEGAL_SEARCH = '17',
  INVOICES_TAB = '18',
  GRID = '19',
  EXPEDIENT_INVOICES = '20',
  CUSTOM_FIELDS_TAB = '22',
  CUSTOM_FIELDS_CONTAINER = '23',
  TANDEM_SEARCH = '24',
  EXPEDIENT_PERIODIC_BILLING = '26',
  TEMPLATE_GENERATOR = '27',
  EXPEDIENT_GLOBAL_VISION = '28',
  EXPEDIENT_AGENDA = '29',
  NUMERATION = '30',
  EXPEDIENT_COURTS = '31',
  EXPEDIENT_INTERVENERS = '32',
  USERS_MAINTENANCE = '33',
  USERS_MAINTENANCE_CUSTOM_PERMISSIONS = '34',
  FORM_ALERT = '35',
  EMAIL_TEMPLATES_CUSTOMER_PORTAL_TAB = '36'
}

export enum ConfigurationTypes {
  CONFIGURATION = 'CONFIGURATION',
  PROFILE = 'PROFILE',
  VIEW = 'view'
}

export enum ConfigurationAlias {
  ACTION = 'actions',
  ACTION_CALLS = 'actionsCalls',
  ACTION_EMAILS = 'actionsEmails',
  ACTION_LEXNET = 'actionsLexnet',
  ACTION_MEETINGS = 'actionsMeetings',
  ACTION_OTHERS = 'actionsOthers',
  ACTION_PROCEDURES = 'actionsProcedures',
  BILLING_CUSTOMER_BANKDRAFTS = 'customerBankdrafts',
  BILLING_CUSTOMER_INVOICES = 'customerInvoices',
  BILLING_ORDER_FORMS = 'orderForms',
  BILLING_PROFORMA_INVOICES = 'proformaInvoices',
  BILLING_PROVIDER_BANKDRAFTS = 'providerBankdrafts',
  BILLING_PROVIDER_INVOICES = 'providerInvoices',
  BILLING_PROVISIONS = 'provisions',
  CONTACT_AGENCIES = 'agencies',
  CONTACT_COURTS = 'courts',
  CONTACT_CUSTOMERS = 'customers',
  CONTACT_INSURERS = 'insurers',
  CONTACT_NOTARIES = 'notaries',
  CONTACT_OPPONENTS = 'opponents',
  CONTACT_OPPONENT_ATTORNEYS = 'opponentsAttorneys',
  CONTACT_OPPONENT_LAWYERS = 'opponentsLawyers',
  CONTACT_OTHERS = 'others',
  CONTACT_OWN_ATTORNEYS = 'attorneys',
  CONTACT_OWN_LAWYERS = 'lawyers',
  CONTACT_PROVIDERS = 'providers',
  COURT_FORM = 'courtForm',
  DOCUMENT_PROPERTIES = 'documentsProperties',
  DOCUMENT_PROPERTIES_INTERVENERS = 'documentsPropertiesInterveners',
  EXPEDIENT = 'expedient',
  EXPEDIENT_COURTS = 'expedientCourt',
  EXPEDIENT_COURTS_FORM = 'expedientCourtForm',
  EXPEDIENT_INTERVENERS = 'expedientIntervener',
  EXPEDIENT_INTERVENERS_FORM = 'expedientIntervenerForm',
  EXPEDIENT_PERIODIC_BILLING_FORM = 'expedientPeriodicBillingForm',
  FOLDER_PROPERTIES = 'foldersProperties',
  MAINTENANCE = 'maintenance',
  MAINTENANCE_ACTIONS = 'maintenanceActions',
  MAINTENANCE_CONTACTS = 'maintenanceContacts',
  MAINTENANCE_CUSTOMER_PORTAL = 'maintenanceCustomerPortal',
  MAINTENANCE_CUSTOMER_PORTAL_TEMPLATES = 'manCustomerPortal',
  MAINTENANCE_DOCUMENTS = 'maintenanceDocuments',
  MAINTENANCE_EXPEDIENTS = 'maintenanceExpedients',
  MAINTENANCE_INVOICES = 'maintenanceInvoices',
  MAINTENANCE_USERS = 'maintenanceUsers',
  SEARCH = 'search',
  TANDEM_FILTERS = 'tandemFilters'
}

export enum WidgetTypes {
  ACTION = 'ACTION',
  CUSTOMER = 'CUSTOMER',
  EXPEDIENT = 'EXPEDIENT',
  INVOICE = 'INVOICE',
  REPORTS = 'REPORTS',
  SCHEDULE = 'SCHEDULE',
  STATISTICS = 'STATISTICS'
}

export enum ListNames {
  ACTIONS_PANEL = 'listActionsPanel',
  ACTION_ALL = 'listActionsAll',
  ACTION_EMAILS = 'listActionsEmails',
  ACTION_LEXNET = 'listActionsLexnet',
  ACTION_OTHERS = 'listActionsOthers',
  AGENCIES = 'listAgency',
  AUDIT = 'listAudit',
  AUDIT_PORTAL = 'listAuditPortal',
  CALLS = 'listActionsCalls',
  COURTS = 'listCourts',
  CUSTOMERS = 'listCustomer',
  CUSTOMER_BANKDRAFTS = 'listBankdraftsCustomers',
  CUSTOMER_BANKDRAFTS_DETAILS = 'listBankdraftsCustomersDetails',
  CUSTOMER_CONTACS = 'listCustomerContacts',
  CUSTOMER_EXPEDIENTS = 'listCustomerExpedients',
  CUSTOMER_INVOICES = 'listInvoicesCustomers',
  CUSTOMER_INVOICES_DETAILS = 'listCustomersInvoicesDetails',
  CUSTOMER_PORTAL_TEMPLATES_MAINTENANCE = 'listManCustomerPortal',
  CUSTOMER_PROFORMA_INVOICES = 'listCustomerProformaInvoices',
  CUSTOMER_PROVISIONS = 'listCustomerProvisions',
  DASHBOARD_WIDGETS = 'dashboardWidgets',
  EXPEDIENTS = 'listExpedients',
  FILE_MANAGER = 'listFileManager',
  FILE_MANAGER_ALL = 'listFileManagerAll',
  FILE_MANAGER_TEMPLATE = 'listFileManagerTemplate',
  HISTORY = 'listExpedientHistory',
  INSURERS = 'listInsurer',
  LIST_CUSTOMER_INVOICES = 'listCustomerInvoices',
  MEETINGS = 'listActionsMeetings',
  NOTARIES = 'listNotary',
  OPPONENTS = 'listOpponent',
  OPPONENT_ATTORNEYS = 'listOpponentAttorney',
  OPPONENT_LAWYERS = 'listOpponentLawyer',
  ORDER_FORMS = 'listOrderForm',
  OTHERS = 'listOther',
  OWN_ATTORNEYS = 'listAttorney',
  OWN_LAWYERS = 'listLawyer',
  PROCEDURES = 'listActionsProcedures',
  PROFESSIONALS = 'listManProfessionals',
  PROFORMA_INVOICES = 'listInvoicesProforma',
  PROFORMA_INVOICES_DETAILS = 'listProformaInvoicesDetails',
  PROVIDERS = 'listProvider',
  PROVIDER_BANKDRAFTS = 'listBankdraftsProviders',
  PROVIDER_BANKDRAFTS_DETAILS = 'listBankdraftsProvidersDetails',
  PROVIDER_INVOICES = 'listInvoicesProviders',
  PROVIDER_INVOICES_DETAILS = 'listProvidersInvoicesDetails',
  PROVISIONS = 'listProvisions',
  REPORTS = 'listReports',
  REPORTS_LIST = 'listInfGet',
  SIGN_AUDIT = 'listSignAudit',
  USERS = 'listUsers'
}

export enum companyConfigTypes {
  DOCUMENT_ALLOWED_EXTENSIONS = 'document_allowed_extensions',
  DOCUMENT_RESTRICT_EXTENSIONS = 'document_restrict_extensions',
  EXPEDIENTS_GRID_VERSION = 'expedients_grid_version',
  MAIL_APP_VERSION = 'mail_app_version',
  REPORTS_GRID_VERSION = 'reports_grid_version'
}

export const configurationMutationTypes = {
  ENABLE_OR_DISABLE_ONE_TAB: 'ENABLE_OR_DISABLE_ONE_TAB',
  FETCH_RELATED_FORM_FIELDS: 'FETCH_RELATED_FORM_FIELDS',
  FETCH_USER_PROFILE_CONFIGURATION: 'FETCH_USER_PROFILE_CONFIGURATION',
  GET_CURRENT_VIEW_CONFIGURATION: 'GET_CURRENT_VIEW_CONFIGURATION',
  REMEMBER_USER_CUSTOM_CONFIGURATION_LIST: 'REMEMBER_USER_CUSTOM_CONFIGURATION_LIST',
  RESET_CURRENT_LIST_CONFIGURATION: 'RESET_CURRENT_LIST_CONFIGURATION',
  RESET_CURRENT_VIEW_CONFIGURATION: 'RESET_CURRENT_VIEW_CONFIGURATION',
  RESET_CUSTOM_CONFIG_TEXT: 'RESET_CUSTOM_CONFIG_TEXT',
  RESET_EDITING_PERIODIC_BILLING: 'RESET_EDITING_PERIODIC_BILLING',
  RESET_VIEW_CONFIGURATION: 'RESET_VIEW_CONFIGURATION',
  SET_CURRENT_LIST_CONFIGURATION: 'SET_CURRENT_LIST_CONFIGURATION',
  SET_CURRENT_VIEW_CONFIGURATION: 'SET_CURRENT_VIEW_CONFIGURATION',
  SET_CUSTOM_CONFIG_TEXT: 'SET_CUSTOM_CONFIG_TEXT',
  SET_EDITING_PERIODIC_BILLING: 'SET_EDITING_PERIODIC_BILLING',
  SET_LAST_LIST_CONFIGURATION: 'SET_LAST_LIST_CONFIGURATION',
  SET_ROUTE_FROM_NAME: 'SET_ROUTE_FROM_NAME',
  SET_VIEW_CONFIGURATION: 'SET_VIEW_CONFIGURATION',
  SHOW_OR_HIDE_ONE_TAB: 'SHOW_OR_HIDE_ONE_TAB'
}

export enum ConfigurationAppointmentVersion {
  INTERNAL_VERSION = 1,
  LEXBOX_VERSION = 2
}
