import { MutationTree } from 'vuex'
import { ConfigurationState } from '@/store/modules/configuration/configurationTypes'
import { Vue } from 'vue-property-decorator'

const getTabIndex = (state: any, context: string, tabName: string) => {
  const tabs = state.currentViewConfiguration[context].tabs
  return tabs.findIndex((tab: any) => tab.name === tabName)
}

export const mutations: MutationTree<ConfigurationState> = {
  SET_CURRENT_LIST_CONFIGURATION(state, data) {
    state.currentListConfiguration = data
  },
  GET_CURRENT_VIEW_CONFIGURATION(state, { data, context }) {
    const configuration = data[0].config
    const parsedConfiguration = JSON.parse(configuration)
    Vue.set(state.currentViewConfiguration, context, parsedConfiguration)
  },
  SET_VIEW_CONFIGURATION(state, view) {
    state.viewsConfiguration.push(view)
  },
  RESET_VIEW_CONFIGURATION(state, alias) {
    const index = state.viewsConfiguration.findIndex((config) => config.alias === alias)
    if (index !== -1) {
      state.viewsConfiguration.splice(index, 1)
    }
  },
  SET_CURRENT_VIEW_CONFIGURATION(state, { viewConfiguration, context }) {
    Vue.set(state.currentViewConfiguration, context, viewConfiguration)
  },
  FETCH_USER_PROFILE_CONFIGURATION(state, configuration) {
    state.userProfileConfiguration = configuration
  },
  RESET_CURRENT_LIST_CONFIGURATION(state) {
    state.currentListConfiguration = {}
    state.lastListConfiguration = {}
  },
  REMEMBER_USER_CUSTOM_CONFIGURATION_LIST(state, value) {
    state.rememberUserCustomConfigurationList = value
  },
  FETCH_RELATED_FORM_FIELDS(state, configuration) {
    state.relatedFormConfiguration = configuration
  },
  RESET_CURRENT_VIEW_CONFIGURATION(state, context) {
    Vue.delete(state.currentViewConfiguration, context)
  },
  SET_ROUTE_FROM_NAME(state, value) {
    state.routeFromName = value
  },
  SET_LAST_LIST_CONFIGURATION(state, value) {
    state.lastListConfiguration = value
  },
  SHOW_OR_HIDE_ONE_TAB(state, { context, tabName, status }) {
    const tabIndex = getTabIndex(state, context, tabName)
    if(tabIndex >= 0) {
      Vue.set((state as any).currentViewConfiguration[context].tabs[tabIndex], 'hiddenTab', !status)
      state.visiblePeriodicBillingTab = !status
    }
  },
  ENABLE_OR_DISABLE_ONE_TAB(state, { context, tabName, status }) {
    const tabIndex = getTabIndex(state, context, tabName)
    Vue.set((state as any).currentViewConfiguration[context].tabs[tabIndex], 'hiddenTab', false)
    Vue.set((state as any).currentViewConfiguration[context].tabs[tabIndex], 'disabledTab', !status)
    state.visiblePeriodicBillingTab = false
    state.enabledPeriodicBillingTab = !status
  },
  SET_EDITING_PERIODIC_BILLING(state) {
    state.editingPeriodicBilling = true
  },
  RESET_EDITING_PERIODIC_BILLING(state) {
    state.editingPeriodicBilling = false
  },
  SET_CUSTOM_CONFIG_TEXT(state, value) {
    state.customConfigText = value
  },
  RESET_CUSTOM_CONFIG_TEXT(state) {
    state.customConfigText = ''
  }
}
