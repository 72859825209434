<template lang="pug">

  WidgetBaseComponent(
    class="dashboard-resume-widget"
    :loadedData="!showSpinner"
    :showFooter="false"
    :showHeader="false"
    customClass="dashboard-widget"
  )

    template(v-slot:body)
      section(:class="['widget-container', { 'vuetify-md-and-down-media-query': isMdAndDownBreakpoint }]")
        header(class="widget-header")
          span(:class="['widget-icon', headerIcon]")
          span(v-html="headerTitle" class="widget-title" :style="{ borderColor: titleBorderColor }")
        div(class="widget-body")
          div(
            v-for="item in widgetData"
            :index="item.text"
            class="item-container"
          )
            span(class="item-text") {{ item.text }}
            span(class="item-qty") {{ item.qty}}

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { DashboardResumeItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'

@Component({
  components: {
    WidgetBaseComponent
  }
})
export default class DashboardWidgetResumeType extends Vue {
  @Prop({
    type: String,
    required: true
  })
  headerIcon!: string

  @Prop({
    type: String,
    required: true
  })
  headerTitle!: string

  @Prop({
    type: String
  })
  titleBorderColor!: string

  @Prop({
    type: Array,
    required: true
  })
  widgetData!: DashboardResumeItem[]

  @Prop({
    type: Boolean,
    default: false
  })
  showSpinner!: boolean

  get isMdAndDownBreakpoint(): boolean {
    return this.$vuetify.breakpoint.mdAndDown
  }
}
</script>

<style lang="scss" scoped>
.dashboard-resume-widget {
  background: $gray-04;

  &.spinner-layer-container {
    height: 100%;
    min-height: 100%;
  }

  .widget-container {
    @include flex($flex-direction: column);

    .widget-header {
      @include flex($justify-content: flex-start);
      text-transform: uppercase;
      padding: 0;
      width: auto;

      .widget-icon {
        font-size: 1.8vw;
      }

      .widget-title {
        @include border($width: 5px);
        font-family: $corporate-font-black;
        font-size: 1vw;
        color: $corporate-color;
        margin-left: 12px;
        padding: 0 4px;

        ::v-deep .min {
          text-transform: lowercase;
        }
      }
    }

    .widget-body {
      @include flex($flex-direction: column, $align-items: flex-start);
      width: auto;
      padding-left: 6px;

      .item-container {
        @include flex($flex-direction: column, $align-items: flex-start);
        @include border($direction: left, $width: 3px);
        margin-top: 0.7vw;
        padding-left: 10px;

        &:nth-child(odd) {
          border-color: $blue-02;
        }

        &:nth-child(even) {
          border-color: $blue-04;
        }

        .item-text {
          font-family: $corporate-font-black;
          font-size: 0.65vw;
          color: $blue-05;
          text-transform: uppercase;
        }

        .item-qty {
          font-family: $corporate-font-bold;
          font-size: 1.1vw;
          color: $corporate-color;
        }
      }
    }

    &.vuetify-md-and-down-media-query {
      width: 100%;
      height: 100%;

      .widget-icon {
        font-size: 5vw;
      }

      .widget-title {
        font-size: 24px;
      }

      .widget-body {
        .item-container {
          margin-top: 0.8vw;

          .item-text {
            font-size: 2vw;
          }

          .item-qty {
            font-size: 3vw;
          }
        }
      }
    }
  }
}
</style>
