import moment from 'moment'

export const transformDate = (timestamp: any) => {
  const finalDate = (moment as any).unix(timestamp)._d
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (localTimezone !== 'Europe/Madrid') {
    const finalDateToMadrid = finalDate.toLocaleString('en-US', { timeZone: 'Europe/Madrid' })
    return (moment as any)(finalDateToMadrid, 'MM/DD/YYYY, hh:mm:ss a')._d
  }
  return finalDate
}

export const getFieldValue = (value: string | number | null) => {
  return value ? transformDate(value) : null
}

const getDateKeys = (items: any) => {
  const firstItem = items[0]
  const keys = Object.keys(firstItem)
  const dateKeys = keys.filter((key) => key.includes('EPOCH'))
  return dateKeys
}

export const isDate = (value: any) => {
  return typeof value === 'object'
}

export const formatFieldsTypeDateEPOCH = (items: any) => {
  if (items && items.length === 0) {
    return []
  }

  const dateKeys = getDateKeys(items)

  const formattedItems = items.map((item: any) => {
    if (Array.isArray(dateKeys)) {
      dateKeys.forEach((field: any) => {
        item[field] = !isDate(item[field]) ? getFieldValue(item[field]) : item[field]
      })
    } else {
      item[dateKeys] = getFieldValue(item[dateKeys])
    }
    return item
  })
  return formattedItems
}

export const changeDateFormat = (date: string, inputFormat: string, outputFormat: string) => {
  if (date) {
    const momentDate = moment(date, inputFormat)
    return momentDate.format(outputFormat)
  } else {
    return ''
  }
}

export const convertToDate = (date: string, format: string): Date => {
  return moment(date, format).toDate()
}

export const convertUTCToLocal = (date: Date, format: string): string => {
  return moment(date).local().format(format)
}

export const addTimeZoneOffset = (date: Date): Date => {
  return moment(date)
    .add(new Date().getTimezoneOffset() * -1, 'm')
    .toDate()
}

/*
 * Adds time to a date
 * @param { Date } date
 * @param { Number } value
 * @param { String } Unit type to add: days | hours | minutes | seconds | milliseconds | month | year
 * @return { Date }
 */
export const addTimeToDate = (date: Date, value: any = 1, unit: string = 'days'): Date => {
  return moment(date).add(value, unit).toDate()
}

export const actualDate = (format: string): string => {
  return moment().format(format)
}
