import { Module } from 'vuex'
import { getters } from '@/store/modules/agencies/agenciesGetters'
import { actions } from '@/store/modules/agencies/agenciesActions'
import { mutations } from '@/store/modules/agencies/agenciesMutations'
import { AgenciesState } from '@/store/modules/agencies/agenciesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: AgenciesState = {
  agencies: [],
  selectedAgency: null
}

const namespaced: boolean = true

export const agenciesModule: Module<AgenciesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
