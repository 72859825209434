export enum InvoiceTypes {
  CUSTOMER = 'customers',
  PROFORMA = 'proforma',
  PROVIDER = 'providers',
  PROVISION = 'provision'
}
export interface Invoice {
  invoiceDate: string
}

export enum InvoiceStatus {
  LOADING = 'Loading',
  ERROR = 'Error',
  LOADED = 'Loaded'
}

export interface InvoiceGenerated {
  idEntity: number
  idEntityType: number
  numberInvoice: string
  idDocument?: number
  name?: string
  size?: number
  status: InvoiceStatus.LOADING | InvoiceStatus.ERROR | InvoiceStatus.LOADED
  file?: Blob
  files?: any
}

export interface InvoicesState {
  invoices: Invoice[]
  totalUnbilled: string
  totalReceivable: string
  invoiceType: string
  invoicesGenerated: InvoiceGenerated[]
}

export const invoicesMutationTypes = {
  FETCH_INVOICES: 'FETCH_INVOICES',
  FETCH_TOTAL_UNBILLED: 'FETCH_TOTAL_UNBILLED',
  FETCH_TOTAL_RECEIVABLE: 'FETCH_TOTAL_RECEIVABLE',
  SAVE_GENERATE_INVOICE: 'SAVE_GENERATE_INVOICE',
  SAVE_INVOICE_TYPE: 'SAVE_INVOICE_TYPE',
  RESET_GENERATE_INVOICES: 'RESET_GENERATE_INVOICES',
  ADD_GENERATE_INVOICE: 'ADD_GENERATE_INVOICE'
}

export enum PrintInvoiceTypes {
  CUSTOMER = 'customers',
  ORDER_FORM = 'orderForms',
  PROFORMA = 'proforma',
  PROVIDER = 'providers',
  PROVISION = 'provisions'
}

export interface GenerateInvoiceFilter {
  templateName: string
  idEntityOrigin: number | string
  name: string
  folderTargetPath: string
  idEntity: number | string
  idEntityType: number | string
  convertToPDF: boolean
  saveDocument: boolean
  numberInvoice: string
}

export interface Partialbankdraft {
  clazz: string
  id: string
  amount: string
  chargeDate: string
  notes: string
}

export enum InvoiceTypesNames {
  CUSTOMER = 'Factura cliente',
}
