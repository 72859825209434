<template lang="pug">

  div(class="firma-dialog-container")

    //- HEADER
    CustomDialogHeaderComponent(:headerTitle="texts.title")

    p(class="explanation") {{ texts.explanation }}

    //- FIELDS
    ReceiverFieldSelectComponent(
      @emit-receiver="setReceiver"
    )

    InfoBarComponent(
      :infoText="texts.infoText"
    )

    LexonSwitchComponent(
      v-model="firmaSwitch"
      class="switch-field"
      id="switch"
      :label="texts.firmaSwitchLabel"
      name="switch"
      hide-details
    )

    //- BUTTONS
    CustomDialogActionButtonsComponent(
      :disabledButtons="disabledActionButton"
      :mainAction="signFirma"
      :primaryButtonText="texts.primaryButtonText"
    )

    //- SPINNER
    SpinnerLayerComponent(v-if="loadingSpinner" class="spinner-layer" :loadingText="spinnerText.sending")

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    InfoBarComponent: () => import('@/components/InfoBar/InfoBarComponent.vue'),
    LexonSwitchComponent: () => import('@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'),
    ReceiverFieldSelectComponent: () =>
      import('@/components/Dialog/customDialogs/firma/ReceiverFieldSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class SendToFirmaDialogComponent extends Mixins(FirmaDialogsMixin) {
  texts = {
    explanation: this.$t('components.firma_dialogs.send_to_firma.explanation'),
    firmaSwitchLabel: this.$t('components.firma_dialogs.send_to_firma.firma_switch'),
    primaryButtonText: this.$t('action_buttons.continue'),
    title: this.$t('components.firma_dialogs.send_to_firma.title'),
    infoText: this.$t('components.firma_dialogs.send_certified_email.info_text')
  }

  showInfoBar = false
}
</script>
