export interface SpinnerState {
  spinner: {
    message: boolean
    show: boolean
  }
  showSpinnerLayer: boolean
  dashboardWidgetSpinners: {
    amountsOutstandingResume: boolean
    customersCounterChart: boolean
    customersResume: boolean
    expedientsCounterChart: boolean
    expedientsResume: boolean
    inactiveExpedientsList: boolean
    invoicedAmountsResume: boolean
    lastActionsList: boolean
    lastCustomersList: boolean
    lastDocumentsList: boolean
    lastExpedientsList: boolean
    officeDailyWorkingTimeResume: boolean
    officeWeeklyWorkingTimeResume: boolean
    officeMonthlyWorkingTimeResume: boolean
    recentActivity: boolean
    unpaidInvoicesList: boolean
    userDailyWorkingTimeResume: boolean
    userWeeklyWorkingTimeResume: boolean
    userMonthlyWorkingTimeResume: boolean
  }
}

export enum SpinnerDashboardContext {
  AMOUNTS_OUTSTANDING_RESUME = 'amountsOutstandingResume',
  CUSTOMERS_COUNTER_CHART = 'customersCounterChart',
  CUSTOMERS_RESUME = 'customersResume',
  ECONOMIC_DATA_DONUT_CHART = 'economicDataDonutChart',
  ECONOMIC_DATA_RESUME = 'economicDataResume',
  EXPEDIENTS_COUNTER_CHART = 'expedientsCounterChart',
  EXPEDIENTS_RESUME = 'expedientsResume',
  INACTIVE_EXPEDIENTS_LIST = 'inactiveExpedientsList',
  INVOICED_AMOUNTS_RESUME = 'invoicedAmountsResume',
  LAST_ACTIONS_LIST = 'lastActionsList',
  LAST_CUSTOMERS_LIST = 'lastCustomersList',
  LAST_DOCUMENTS_LIST = 'lastDocumentsList',
  LAST_EXPEDIENTS_LIST = 'lastExpedientsList',
  OFFICE_DAILY_WORKING_TIME_RESUME = 'officeDailyWorkingTimeResume',
  OFFICE_MONTHLY_WORKING_TIME_RESUME = 'officeMonthlyWorkingTimeResume',
  OFFICE_WEEKLY_WORKING_TIME_RESUME = 'officeWeeklyWorkingTimeResume',
  RECENT_ACTIVITY = 'recentActivity',
  UNPAID_INVOICES_LIST = 'unpaidInvoicesList',
  USER_DAILY_WORKING_TIME_RESUME = 'userDailyWorkingTimeResume',
  USER_MONTHLY_WORKING_TIME_RESUME = 'userMonthlyWorkingTimeResume',
  USER_WEEKLY_WORKING_TIME_RESUME = 'userWeeklyWorkingTimeResume'
}

export const spinnerMutationTypes = {
  HIDE_DASHBOARD_WIDGET_SPINNER: 'HIDE_DASHBOARD_WIDGET_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
  HIDE_SPINNER_LAYER: 'HIDE_SPINNER_LAYER',
  RESET_ALL_DASHBOARD_WIDGET_SPINNERS: 'RESET_ALL_DASHBOARD_WIDGET_SPINNERS',
  RESET_DASHBOARD_CUSTOMER_WIDGET_SPINNERS: 'RESET_DASHBOARD_CUSTOMER_WIDGET_SPINNERS',
  RESET_DASHBOARD_WIDGET_SPINNERS: 'RESET_DASHBOARD_WIDGET_SPINNERS',
  SHOW_DASHBOARD_WIDGET_SPINNER: 'SHOW_DASHBOARD_WIDGET_SPINNER',
  SHOW_SPINNER: 'SHOW_SPINNER',
  SHOW_SPINNER_LAYER: 'SHOW_SPINNER_LAYER'
}
