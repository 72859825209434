<template lang="pug">

  DashboardWidgetChartBarType(
    :chartTitle="title"
    id="clientes-chart"
    :chartDataProp="chartData"
    :showSpinner="showSpinner"
  )

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DashboardWidgetChartBarType from '@/components/widgets/DashboardWidgets/widgetTypes/DashboardWidgetChartBarType.vue'
import { DashboardChartBarItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SpinnerDashboardContext } from '@/store/modules/spinner/spinnerTypes'

const spinnersModule: string = ModuleNamespaces.SPINNER
const dashboardWidgetsModule: string = ModuleNamespaces.DASHBOARD_WIDGETS

@Component({
  components: {
    DashboardWidgetChartBarType
  }
})
export default class DashboardCustomersCounterChartWidget extends Vue {
  @Action('fetchDashboardCustomersCounterDataChart', { namespace: dashboardWidgetsModule })
  fetchDashboardCustomersCounterChartDataAction: () => []

  @Getter('getDashboardWidgetsSpinner', { namespace: spinnersModule })
  showWidgetSpinner: (context: string) => boolean

  @Getter('getDashboardCustomerCounterChart', { namespace: dashboardWidgetsModule })
  chartData: DashboardChartBarItem[]

  title = this.$t('components.dashboard_widget_chart_type.customer_counter')

  get showSpinner() {
    return this.showWidgetSpinner(SpinnerDashboardContext.CUSTOMERS_COUNTER_CHART)
  }

  created() {
    // Hace la petición solo si ha guardado algun contacto. Utiliza el estado del spinner como un flag
    if (this.showSpinner) {
      this.fetchDashboardCustomersCounterChartDataAction()
    }
  }
}
</script>
