<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import TabsView from '@/views/Tabs/TabsView.vue'
import { mixins } from 'vue-class-component'
import { MaintenanceFields } from './types/MaintenanceFieldsTypes'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import TabsComponent from '@/components/tabs/TabsComponent/TabsComponent.vue'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const formsModule: string = ModuleNamespaces.FORMS
const maintenanceModule: string = ModuleNamespaces.MAINTENANCE

enum Tab {
  FORM_DATA = 0,
  EMAIL_TEMPLATES_GRID = 1
}

interface FormData {
  activated: string
  domain: string
  id: string
  lawFirm: string
  subDomain: string
}

@Component
export default class MaintenanceCustomerPortalView extends mixins(TabsView) {
  @Action('saveMaintenanceCustomerPortalFormData', { namespace: maintenanceModule })
  saveMaintenanceCustomerPortalFormDataAction: ({}) => Promise<void>

  @Mutation('RESET_VIEW_CONFIGURATION', { namespace: configurationModule })
  resetViewConfigurationMutation: (alias: string) => void

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object

  $refs!: {
    tabsComponent: InstanceType<typeof TabsComponent>
  }

  selectedTabIndex = 0

  @Watch('everythingLoaded', { immediate: true })
  hideSpinnerLayer(status: boolean) {
    if (status) {
      this.hideSpinnerLayerAction()
    }
  }

  get buttons() {
    return [
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        show: this.saveButtonEnabled
      }
    ]
  }

  get breadcrumbsItems() {
    return [
      {
        text: this.parentRouterName
      },
      {
        text: this.menuName
      }
    ]
  }

  get isValidCustomerPortalTabForm() {
    return this.checkIfFormIsValid(this.context)
  }

  get portalActivatedSwitch() {
    const formData = this.getFormData as unknown as FormData
    const portalActivated = formData.activated
    return Boolean(Number(portalActivated))
  }

  get saveButtonEnabled() {
    return (
      (!this.portalActivatedSwitch || (this.portalActivatedSwitch && this.isValidCustomerPortalTabForm)) &&
      this.selectedTabIndex === Tab.FORM_DATA
    )
  }

  created() {
    this.fetchCustomerPortalTabForm()
  }

  beforeDestroy() {
    this.resetViewConfigurationMutation(this.context)
  }

  async fetchCustomerPortalTabForm() {
    await this.fetchSelectedRegisterData({
      endpoint: 'maintenance/manCustomerDatosPortal/get',
      context: this.context
    })
  }

  async changeSelectedTabIndex(index: number) {
    this.selectedTabIndex = index
    if (index === Tab.EMAIL_TEMPLATES_GRID) {
      await this.saveMaintenanceCustomerPortalFormDataAction(this.getFormData)
      await this.fetchCustomerPortalTabForm()
    }
  }

  async save(_redirectToGridTable = true, _resolve: any = null, _idObjectType: string = '') {
    await this.saveMaintenanceCustomerPortalFormDataAction(this.getFormData)
  }

  async onInitForm(_params: any) {
    setTimeout(() => {
      this.$refs.tabsComponent.toggleStatusTabs(false)
    })
  }

  onFieldChanged(schema: any, _formData: FormData, field: any, value: string) {
    if (field.id !== MaintenanceFields.ACTIVATE_CUSTOMER_PORTAL_SWITCH) {
      return
    }

    const shouldActivatePortal = value !== '0'
    const uRLFieldset = schema.find((item: any) => item.fieldsetName === MaintenanceFields.URL_FIELDSET)

    if (!shouldActivatePortal || !this.isValidCustomerPortalTabForm) {
      this.$refs.tabsComponent.toggleStatusTabs(shouldActivatePortal)
    }

    if (!uRLFieldset) {
      return
    }

    uRLFieldset.fields.forEach((fieldItem: any) => {
      if (fieldItem.id !== MaintenanceFields.DOMAIN) {
        fieldItem.disabled = !Number(value)
      }
    })
  }
}
</script>
